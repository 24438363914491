import { useTranslation } from 'react-i18next';
import { Label, Switch } from '@knack/asterisk-react';

import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';

export function TiltLabels() {
  const [t] = useTranslation();

  const { chart, updateChart } = useActiveChartContext();

  return (
    <div className="mb-2 flex items-center gap-2">
      <Switch
        id="tilt-category-labels"
        checked={chart.layout.tilt_labels}
        onCheckedChange={(state) => {
          updateChart({
            layout: {
              ...chart.layout,
              tilt_labels: state
            }
          });
        }}
      />
      <Label htmlFor="tilt-category-labels">
        {t('pages.element_settings.report.categories.general.tilt_category_labels')}
      </Label>
    </div>
  );
}
