import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { Button, Divider, Input, Label, Switch, Tabs } from '@knack/asterisk-react';

import { type MenuView, type MenuViewLink } from '@/types/schema/views/MenuView';
import { getNormalizedIconName } from '@/utils/font-awesome';
import { KnackFontAwesomeIcon } from '@/components/font-awesome/KnackFontAwesomeIcon';
import { SelectIconModal } from '@/components/SelectIconModal';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';
import { MenuLinkPageValue } from './MenuLinkPageValue';
import { MenuLinkUrlInput } from './MenuLinkUrlInput';

export function MenuLinkSettings() {
  const [t] = useTranslation();

  const { view } = useActiveViewContext<MenuView>();
  const { viewInputId: linkId } = useViewInputEditModeContext();
  const updateView = useUpdateView<MenuView>();

  const [isSelectIconModalOpen, setIsSelectIconModalOpen] = useState(false);

  const link = view.links.find((l) => l.id === linkId);
  if (!link) {
    return t('pages.element_settings.menu.select_link');
  }

  const selectedIconName = link.icon?.icon ? getNormalizedIconName(link.icon?.icon) : undefined;
  const selectedIconPosition = link.icon?.align || 'left';

  const iconPositionOptions = [
    {
      children: t('keywords.left'),
      value: 'left'
    },
    {
      children: t('keywords.right'),
      value: 'right'
    }
  ];

  const updateMenuViewLink = (partialLink: Partial<MenuViewLink>) => {
    updateView({
      links: view.links.map((l) => {
        if (l.id === link.id) {
          return { ...l, ...partialLink } as MenuViewLink;
        }
        return l;
      })
    });
  };

  const onIconRemove = () => {
    if (!link.icon?.icon) {
      return;
    }

    updateMenuViewLink({
      icon: {
        ...link.icon,
        icon: ''
      }
    });
  };

  const onIconChange = (newSelectedIconName: string | undefined) => {
    setIsSelectIconModalOpen(false);

    if (!newSelectedIconName) {
      return;
    }

    // Ensure the link has an icon object
    if (!link.icon) {
      link.icon = {
        icon: '',
        align: 'left'
      };
    }

    updateMenuViewLink({
      icon: {
        ...link.icon,
        icon: newSelectedIconName
      }
    });
  };

  const onIconPositionChange = (newIconPosition: string) => {
    // Ensure the link has an icon object
    if (!link.icon) {
      link.icon = {
        icon: '',
        align: 'left'
      };
    }

    updateMenuViewLink({
      icon: {
        ...link.icon,
        align: newIconPosition as typeof selectedIconPosition
      }
    });
  };

  return (
    <>
      {link.type === 'scene' && <MenuLinkPageValue link={link} />}

      {link.type === 'url' && (
        <MenuLinkUrlInput
          link={link}
          onChange={(newUrl) => {
            updateMenuViewLink({ url: newUrl });
          }}
        />
      )}

      <div className="mt-4">
        <Label className="flex items-center font-medium">
          <Switch
            className="mr-2"
            checked={link.new_window}
            onCheckedChange={(checked) => {
              updateMenuViewLink({ new_window: checked });
            }}
          />
          {t('pages.element_settings.menu.link_open_in_new_tab')}
        </Label>
        <p className="mt-1 pl-10 text-xs text-subtle">
          {t('pages.element_settings.menu.link_open_in_new_tab_description')}
        </p>
      </div>

      <Divider className="my-4" />

      <div className="mb-4">
        <Label className="mb-2 block font-medium" htmlFor={`${link.id}-menu-link-label`}>
          {t('pages.element_settings.menu.link_text')}
        </Label>
        <Input
          id={`${link.id}-menu-link-label`}
          className="w-full"
          value={link.name}
          onChange={(e) => {
            updateMenuViewLink({ name: e.target.value });
          }}
        />
      </div>

      <div className="mb-4">
        <Label className="mb-2 block font-medium" htmlFor={`${link.id}-menu-link-icon`}>
          {t('components.icons.icon')}
        </Label>
        {selectedIconName ? (
          <>
            <KnackFontAwesomeIcon
              iconName={selectedIconName}
              size="xl"
              asButton
              onClick={() => setIsSelectIconModalOpen(true)}
            />
            <div className="mt-2 flex items-center gap-2">
              <Button intent="secondary" size="sm" onClick={() => setIsSelectIconModalOpen(true)}>
                {t('components.icons.change_icon')}
              </Button>
              <Button intent="secondary" size="sm" onClick={onIconRemove}>
                {t('components.icons.remove_icon')}
              </Button>
            </div>
          </>
        ) : (
          <Button intent="secondary" size="sm" onClick={() => setIsSelectIconModalOpen(true)}>
            <PlusIcon size={16} className="mr-1" />
            {t('components.icons.icon')}
          </Button>
        )}
      </div>

      {selectedIconName && (
        <div>
          <Label className="mb-2 block font-medium" htmlFor={`${link.id}-menu-link-icon-position`}>
            {t('components.icons.icon_position')}
          </Label>
          <Tabs
            value={selectedIconPosition}
            onValueChange={(newIconPosition) => {
              onIconPositionChange(newIconPosition);
            }}
          >
            <Tabs.List items={iconPositionOptions} shouldDisableResponsive />
          </Tabs>
        </div>
      )}

      {isSelectIconModalOpen && (
        <SelectIconModal
          preSelectedIcon={link.icon?.icon}
          onClose={() => setIsSelectIconModalOpen(false)}
          onSubmit={(newSelectedIconName) => {
            onIconChange(newSelectedIconName);
          }}
        />
      )}
    </>
  );
}
