import { useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as AddIcon, HiPencil as EditIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog } from '@knack/asterisk-react';
import { z } from 'zod';

import { type BuilderViewSourceSchemaCriteria } from '@/types/schema/BuilderView';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { hideValueOperators, type HideValueOperator } from '@/types/schema/KnackOperator';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { SourceFilterDialogGroupItem } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFilterDialogGroupItem';
import { SourceFilterDialogGroups } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFilterDialogGroups';
import { SourceFiltersWarningBanner } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFiltersWarningBanner';

type SourceFiltersDialogProps = {
  sourceObject: KnackObject;
  sourceFiltersCriteria: BuilderViewSourceSchemaCriteria;
  onFormSubmit: (data: BuilderViewSourceSchemaCriteria) => void;
};

function SourceFiltersDialogContent({
  sourceObject,
  sourceFiltersCriteria,
  onFormSubmit
}: SourceFiltersDialogProps) {
  const [t] = useTranslation();
  const { getOperatorsForField } = useFieldHelpers();

  const shouldShowSourceFiltersWarning = sourceFiltersCriteria.match === 'all';

  const sourceFiltersRulesSchema = z
    .object({
      field: z.custom<KnackFieldKey>((val) => val.length, t('errors.value_required')),
      operator: z.string().min(1, t('errors.value_required')),
      value: z.union([z.string(), z.boolean(), z.array(z.string()), z.record(z.any())])
    })
    .refine(
      (data) =>
        // if the operator is in the hideValueOperators array, the value is not required, otherwise it is
        hideValueOperators.includes(data.operator as HideValueOperator) || data.value,
      {
        message: t('errors.value_required'),
        path: ['value']
      }
    );

  const sourceFiltersFormSchema = z.object({
    rules: z.array(sourceFiltersRulesSchema),
    groups: z.array(z.array(sourceFiltersRulesSchema))
  });

  const form = useForm<BuilderViewSourceSchemaCriteria>({
    resolver: zodResolver(sourceFiltersFormSchema),
    defaultValues: {
      rules: sourceFiltersCriteria.rules,
      groups: sourceFiltersCriteria.groups
    }
  });

  // The first group of criteria rules is located in the `rules` array from the schema
  const {
    fields: rulesFields,
    append: addRuleField,
    remove: removeRuleField,
    update: updateRuleField
  } = useFieldArray({
    control: form.control,
    name: 'rules'
  });

  // The rest of the criteria groups are located in the `groups` array from the schema
  const { fields: groupsFields } = useFieldArray({
    control: form.control,
    name: 'groups'
  });

  const hasSourceFilters = rulesFields.length > 0;

  const getDefaultCriteriaRule = (fieldKey: KnackFieldKey) => ({
    field: fieldKey,
    operator: getOperatorsForField(fieldKey, sourceObject.fields)[0],
    value: ''
  });

  return (
    <FormProvider {...form}>
      <form className="w-full" onSubmit={form.handleSubmit(onFormSubmit)}>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {t(
                'pages.element_settings.common.categories.data_display.general_settings.source_filters'
              )}
            </Dialog.Title>
            <Dialog.Description className="text-xs text-subtle">
              {t(
                'pages.element_settings.common.categories.data_display.general_settings.source_filters_description'
              )}
            </Dialog.Description>
          </Dialog.Header>
          {hasSourceFilters && (
            <div className="mt-6">
              {shouldShowSourceFiltersWarning && <SourceFiltersWarningBanner className="mb-4" />}
              <p className="mb-4">
                {t(
                  'pages.element_settings.common.categories.data_display.general_settings.show_the_records_match'
                )}
              </p>
              <div className="space-y-2 rounded-lg bg-subtle p-3">
                {rulesFields.map((ruleField, ruleFieldIndex) => (
                  <SourceFilterDialogGroupItem
                    sourceObject={sourceObject}
                    key={ruleField.id}
                    availableFields={sourceObject.fields}
                    formFieldName={`rules.${ruleFieldIndex}`}
                    ruleField={ruleField}
                    ruleFieldIndex={ruleFieldIndex}
                    update={updateRuleField}
                    append={addRuleField}
                    remove={removeRuleField}
                  />
                ))}
              </div>
              <div>
                {groupsFields.map((groupField, groupFieldIndex) => (
                  <div key={groupField.id}>
                    <p className="my-2">{t('components.rules.or_uppercase')}</p>
                    <div className="space-y-2 rounded-lg bg-subtle p-3">
                      <SourceFilterDialogGroups groupFieldIndex={groupFieldIndex} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <Button
            intent="secondary"
            className="mt-4 gap-1"
            onClick={() => {
              if (hasSourceFilters) {
                form.setValue('groups', [
                  ...form.getValues('groups'),
                  [getDefaultCriteriaRule(sourceObject.fields[0].key)]
                ]);
              } else {
                addRuleField(getDefaultCriteriaRule(sourceObject.fields[0].key));
              }
            }}
            data-testid="source-filters-modal-add-source-filter-button"
          >
            <Button.Icon icon={AddIcon} />
            {hasSourceFilters
              ? t(
                  'pages.element_settings.common.categories.data_display.general_settings.filter_group'
                )
              : t(
                  'pages.element_settings.common.categories.data_display.general_settings.source_filters'
                )}
          </Button>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button type="submit">{t('actions.apply')}</Button>
        </Dialog.Footer>
      </form>
    </FormProvider>
  );
}

export function SourceFiltersDialog({
  sourceObject,
  sourceFiltersCriteria,
  onFormSubmit
}: SourceFiltersDialogProps) {
  const [t] = useTranslation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const hasSourceFilters = sourceFiltersCriteria.rules.length > 0;

  const handleSourceFiltersSubmit = (data: BuilderViewSourceSchemaCriteria) => {
    onFormSubmit(data);
    setIsDialogOpen(false);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Dialog.Trigger asChild>
        <Button
          intent="secondary"
          className="gap-1"
          size="sm"
          data-testid="view-settings-add-source-filters-button"
        >
          <Button.Icon icon={hasSourceFilters ? EditIcon : AddIcon} />
          {hasSourceFilters
            ? t(
                'pages.element_settings.common.categories.data_display.general_settings.edit_source_filters'
              )
            : t(
                'pages.element_settings.common.categories.data_display.general_settings.source_filters'
              )}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <SourceFiltersDialogContent
          sourceObject={sourceObject}
          sourceFiltersCriteria={sourceFiltersCriteria}
          onFormSubmit={handleSourceFiltersSubmit}
        />
      </Dialog.Content>
    </Dialog>
  );
}
