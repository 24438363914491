import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';

import { TextTooltip } from '@/components/TextTooltip';

type SelectWithLabelProps = {
  onValueChange: (value: string) => void;
  defaultValue: string;
  label: string;
  values: readonly string[];
  translationPrefix: string;
  data_testid: string;
  disabledOptions?: string[];
  disabledOptionsTooltip?: string;
};

export function SelectWithLabel({
  onValueChange,
  defaultValue,
  values,
  translationPrefix,
  label,
  data_testid,
  disabledOptions = [],
  disabledOptionsTooltip = ''
}: SelectWithLabelProps) {
  const [t] = useTranslation();

  return (
    <>
      <Label htmlFor={`${data_testid}-select`} className="font-medium">
        {label}
      </Label>
      <Select onValueChange={onValueChange} defaultValue={defaultValue}>
        <Select.Trigger
          id={`${data_testid}-select`}
          className="w-full rounded-lg"
          data-testid={`${data_testid}-select`}
        />
        <Select.Content>
          {values.map((option) => (
            <TextTooltip
              label={
                disabledOptions.includes(option) && disabledOptionsTooltip
                  ? disabledOptionsTooltip
                  : ''
              }
              key={option}
              asChild
            >
              <Select.Item
                key={option}
                value={option}
                disabled={disabledOptions.includes(option)}
                data-testid={`${data_testid}-select-value-${option}`}
              >
                {t(`${translationPrefix}.options.${option}`, { nsSeparator: false })}
              </Select.Item>
            </TextTooltip>
          ))}
        </Select.Content>
      </Select>
    </>
  );
}
