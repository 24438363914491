import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiLink as LinkIcon } from 'react-icons/hi2';
import { Divider, Input, Label, Switch } from '@knack/asterisk-react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { usePagesQuery } from '@/hooks/api/queries/usePagesQuery';
import { getApplicationBasePathSegments, getPageAncestorsUrl } from '@/utils/application';
import { PAGE_NAME_MAX_CHAR_LENGTH } from '@/utils/constants';
import { formatSlug } from '@/utils/formatters';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';

export function PageSettingsGeneral() {
  const { data: allPages } = usePagesQuery();
  const { page, updatePage } = usePageEditorContext();
  const { accountSlug, appSlug } = getApplicationBasePathSegments();
  const liveAppBasePath = `${import.meta.env.PUBLIC_LIVE_APP_URL}/${accountSlug}/${appSlug}`;
  const [t] = useTranslation();

  // Keep local copy of the updated slug so we can update it on blur
  const [localSlug, setLocalSlug] = useState(page.slug);
  const urlWithAncestors = `${liveAppBasePath}${getPageAncestorsUrl(page, allPages)}/`;
  const childPage = allPages?.find((p) => p.parentSlug === page.slug);
  // If the page is a login page, use the URL of its first child
  const pageSlug = page.requiresAuthentication && childPage ? childPage.slug : page.slug;

  const existingPageSlugs = allPages?.filter((p) => p.slug !== page.slug).map((p) => p.slug) ?? [];

  const handlePageUpdate = (pageUpdates: Partial<BuilderPage>) => {
    updatePage({
      type: 'page',
      action: 'update',
      pageUpdates
    });
  };

  return (
    <>
      <Divider className="my-4" />
      <Label className="mb-2 block font-medium">{t('pages.settings.page_name')}</Label>
      <Input
        className="mb-4"
        value={page.name}
        maxLength={PAGE_NAME_MAX_CHAR_LENGTH}
        onChange={(e) => {
          const newName = e.target.value;
          if (newName.length > PAGE_NAME_MAX_CHAR_LENGTH) return;

          handlePageUpdate({ name: newName });
        }}
      />
      <Label className="mb-2 block font-medium">{t('pages.settings.page_slug')}</Label>
      <Input
        value={localSlug}
        intent={existingPageSlugs.includes(localSlug) || !localSlug ? 'destructive' : 'default'}
        maxLength={PAGE_NAME_MAX_CHAR_LENGTH}
        onChange={(e) => {
          setLocalSlug(formatSlug(e.target.value));
        }}
        onBlur={() => {
          handlePageUpdate({ slug: localSlug });
        }}
      />
      {existingPageSlugs.includes(localSlug) && (
        <p className="mt-1 text-xs text-destructive">
          {t('pages.settings.page_slug_already_exists')}
        </p>
      )}
      <div className="mt-2 flex items-center rounded-lg bg-emphasis p-2">
        <LinkIcon className="shrink-0" />
        <a
          href={`${urlWithAncestors}${pageSlug}`}
          target="_blank"
          className="ml-2"
          style={{ overflowWrap: 'anywhere' }}
          rel="noreferrer"
        >
          {urlWithAncestors}
          <strong className="font-medium">{pageSlug}</strong>
        </a>
      </div>
      <div className="mt-2 flex items-center gap-2">
        <Switch
          id="show-title"
          checked={page.isTitleVisible}
          onCheckedChange={(newValue) => {
            handlePageUpdate({ isTitleVisible: newValue });
          }}
        />
        <Label htmlFor="show-title">{t('pages.settings.show_title')}</Label>
      </div>
    </>
  );
}
