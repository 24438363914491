import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Label, Select, Tabs, type TabsListItem } from '@knack/asterisk-react';
import debounce from 'lodash.debounce';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import {
  MapDefaultRange,
  MapStartingPoint,
  MapUnit,
  type MapView
} from '@/types/schema/views/MapView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function MapMapSettingsCategory() {
  const [t] = useTranslation();

  const { view, sourceObject } = useActiveViewContext<MapView>();
  const updateViewSchema = useUpdateView<MapView>();
  const [startingAddress, setStartingAddress] = useState(view.starting_address);

  const unitsItems: TabsListItem[] = [
    {
      value: MapUnit.Miles,
      children: t('pages.element_settings.map.map_settings.miles')
    },
    {
      value: MapUnit.Kilometers,
      children: t('pages.element_settings.map.map_settings.kilometers')
    }
  ];

  const rangesItems = [
    {
      value: MapDefaultRange.Ten,
      label: `${MapDefaultRange.Ten} ${view.units}`
    },
    {
      value: MapDefaultRange.TwentyFive,
      label: `${MapDefaultRange.TwentyFive} ${view.units}`
    },
    {
      value: MapDefaultRange.Fifty,
      label: `${MapDefaultRange.Fifty} ${view.units}`
    },
    {
      value: MapDefaultRange.Hundred,
      label: `${MapDefaultRange.Hundred} ${view.units}`
    },
    {
      value: MapDefaultRange.Any,
      label: t('pages.element_settings.map.map_settings.any_range')
    }
  ];

  const startingPointItems = [
    {
      value: MapStartingPoint.Blank,
      label: t('pages.element_settings.map.map_settings.starting_point_blank')
    },
    {
      value: MapStartingPoint.Location,
      label: t('pages.element_settings.map.map_settings.starting_point_location')
    },
    {
      value: MapStartingPoint.Address,
      label: t('pages.element_settings.map.map_settings.starting_point_address')
    }
  ];

  const updateViewSchemaAddress = useMemo(
    () =>
      debounce((address: string) => {
        updateViewSchema({
          starting_point: MapStartingPoint.Address,
          starting_address: address
        });
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [view]
  );

  const allFields = sourceObject?.fields ?? [];
  const addressFields = allFields.filter((field) => field.type === 'address');

  return (
    <BuilderAccordion>
      <div>
        <div className="mb-4">
          <Label className="mb-2 block font-medium" htmlFor={`${view.key}-view-address-field`}>
            {t('pages.element_settings.map.map_settings.address_field')}
          </Label>
          <Select
            value={view.address_field.key}
            onValueChange={(value) => {
              updateViewSchema({
                address_field: {
                  key: value as KnackFieldKey
                }
              });
            }}
          >
            <Select.Trigger
              className="mt-1 w-full text-subtle"
              id={`${view.key}-view-address-field`}
            />
            <Select.Content>
              {addressFields.map((option) => (
                <Select.Item key={option.key} value={option.key}>
                  {option.name}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>

          <p className="mt-2 text-xs text-subtle">
            {t('pages.element_settings.map.map_settings.address_field_hint')}
          </p>
        </div>

        <div className="mb-4">
          <Label className="mb-2 block font-medium" htmlFor={`${view.key}-view-title-field`}>
            {t('pages.element_settings.map.map_settings.title_field')}
          </Label>
          <Select
            value={view.title_field.key}
            onValueChange={(value) => {
              updateViewSchema({
                title_field: {
                  key: value
                }
              });
            }}
          >
            <Select.Trigger
              className="mt-1 w-full text-subtle"
              id={`${view.key}-view-title-field`}
            />
            <Select.Content>
              {allFields.map((option) => (
                <Select.Item key={option.key} value={option.key}>
                  {option.name}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
          <p className="mt-2 text-xs text-subtle">
            {t('pages.element_settings.map.map_settings.title_field_hint')}
          </p>
        </div>
      </div>

      <div>
        <BuilderAccordion.Item
          isDefaultOpen
          label={t('pages.element_settings.map.map_settings.map_options')}
        >
          <div className="space-y-2">
            <div className="mb-4">
              <Label className="mb-2 block font-medium" htmlFor={`${view.key}-view-units`}>
                {t('pages.element_settings.map.map_settings.units')}
              </Label>
              <Tabs
                defaultValue={view.units}
                onValueChange={(value) => updateViewSchema({ units: value as MapUnit })}
              >
                <Tabs.List items={unitsItems} shouldDisableResponsive />
              </Tabs>
            </div>

            <div className="mb-4">
              <Label className="mb-2 block font-medium" htmlFor={`${view.key}-view-range`}>
                {t('pages.element_settings.map.map_settings.starting_range')}
              </Label>
              <Select
                value={String(view.default_range)}
                onValueChange={(value) =>
                  updateViewSchema({
                    default_range: Number(value)
                  })
                }
              >
                <Select.Trigger className="mt-1 w-full text-subtle" id={`${view.key}-view-range`} />
                <Select.Content>
                  {rangesItems.map((option) => (
                    <Select.Item key={option.value} value={String(option.value)}>
                      {option.label}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            </div>

            <div className="mb-4">
              <Label className="mb-2 block font-medium" htmlFor={`${view.key}-view-starting-point`}>
                {t('pages.element_settings.map.map_settings.location_starting_point')}
              </Label>
              <Select
                value={view.starting_point}
                onValueChange={(value) => {
                  updateViewSchema({
                    starting_point: value as MapStartingPoint
                  });
                }}
              >
                <Select.Trigger
                  className="mt-1 w-full text-subtle"
                  id={`${view.key}-view-starting-point`}
                />
                <Select.Content>
                  {startingPointItems.map((option) => (
                    <Select.Item key={option.value} value={option.value}>
                      {option.label}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            </div>
            {view.starting_point === MapStartingPoint.Address && (
              <div>
                <Label
                  className="mb-2 block font-medium"
                  htmlFor={`${view.key}-view-starting-address`}
                >
                  {t('pages.element_settings.map.map_settings.enter_starting_address')}
                </Label>
                <Input
                  id={`${view.key}-view-starting-address`}
                  value={startingAddress}
                  onChange={(e) => {
                    setStartingAddress(e.target.value);
                    updateViewSchemaAddress(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
        </BuilderAccordion.Item>
      </div>

      <div>
        <BuilderAccordion.Item
          isDefaultOpen
          label={t('pages.element_settings.map.map_settings.appearance')}
        >
          <div className="space-y-2">
            <div className="mb-4">
              <Label className="mb-2 block font-medium" htmlFor={`${view.key}-view-map-height`}>
                {t('pages.element_settings.map.map_settings.map_height')}
              </Label>
              <Input
                id={`${view.key}-view-map-height`}
                type="number"
                value={view.map_height ?? 0}
                onChange={(e) => {
                  updateViewSchema({
                    map_height: Number(e.target.value)
                  });
                }}
              />
              <p className="mt-2 text-xs text-subtle">
                {t('pages.element_settings.map.map_settings.map_height_hint')}
              </p>
            </div>
          </div>

          <div>
            <Label className="mb-2 block font-medium" htmlFor={`${view.key}-view-container-width`}>
              {t('pages.element_settings.map.map_settings.results_container_width')}
            </Label>
            <Input
              id={`${view.key}-view-container-width`}
              type="number"
              value={view.list_width ?? 0}
              onChange={(e) => {
                updateViewSchema({
                  list_width: Number(e.target.value)
                });
              }}
            />
            <p className="mt-2 text-xs text-subtle">
              {t('pages.element_settings.map.map_settings.results_container_width_hint')}
            </p>
          </div>
        </BuilderAccordion.Item>
      </div>
    </BuilderAccordion>
  );
}
