import { Trans, useTranslation } from 'react-i18next';
import { Card } from '@knack/asterisk-react';
import capitalize from 'lodash.capitalize';

import { type KnackConnection, type KnackObject } from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { cn } from '@/utils/tailwind';
import { ConnectionTable } from '@/components/field-settings/connection/ConnectionTable';
import { type ConnectionType } from './ConnectionCardWrapper';

type ConnectionCardProps = {
  connection: KnackConnection;
  table: KnackObject;
  className?: string;
  connectionType: ConnectionType;
  children?: React.ReactNode;
};

export function ConnectionCard({
  connection,
  table,
  className,
  connectionType,
  children
}: ConnectionCardProps) {
  const [t] = useTranslation();

  const { data: app } = useApplicationQuery();
  const connectedTable = app?.objects.find((object) => object.key === connection.object);

  if (!connectedTable) return null;

  const cardinalityTranslationPrefix = 'components.data_table.right_sidebar.connections';

  return (
    <Card
      className={cn('bg-muted p-2 shadow-none sm:p-2', className)}
      data-testid={`connection-card-${connection.name}`}
    >
      <div className="flex max-w-[340px] flex-wrap items-center gap-1">
        <Trans
          i18nKey="components.data_table.right_sidebar.connections.card_content"
          components={[
            <ConnectionTable
              key="0"
              table={connectionType === 'inbound' ? connectedTable : table}
              className="bg-subtle"
            />,
            <ConnectionTable
              key="1"
              table={connectionType === 'inbound' ? table : connectedTable}
              className="bg-subtle"
            />
          ]}
          values={{
            connectionTableCardinality:
              connectionType === 'inbound'
                ? capitalize(t(`${cardinalityTranslationPrefix}.${connection.belongs_to}`))
                : capitalize(t(`${cardinalityTranslationPrefix}.${connection.has}`)),
            connectedTableCardinality:
              connectionType === 'inbound'
                ? t(`${cardinalityTranslationPrefix}.${connection.has}`)
                : t(`${cardinalityTranslationPrefix}.${connection.belongs_to}`)
          }}
        />
      </div>

      {children}
    </Card>
  );
}
