import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackField } from '@/types/schema/KnackField';
import { useCriteriaDateTimeHelpers } from '@/hooks/helpers/useCriteriaDateTimeHelpers';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { getBooleanFieldLabel } from '@/utils/fields';
import { useUserRolesTables } from '@/components/data-table/helpers/useUserRolesTables';
import { InlineKnackConnectionRecordValue } from '@/components/InlineKnackConnectionRecordValue';
import { InlineKnackValue } from '@/components/InlineKnackValue';

interface FormRuleCriteriaValueProps {
  criteria: KnackCriteria;
  criteriaField: KnackField;
  className?: string;
}

export function InlineKnackCriteriaValue({
  criteria,
  criteriaField,
  className = ''
}: FormRuleCriteriaValueProps) {
  const { getCriteriaDateTimeDisplayString } = useCriteriaDateTimeHelpers();
  const userRolesTables = useUserRolesTables();
  if (shouldHideValueBasedOnOperator(criteria.operator)) {
    return null;
  }

  // If the criteria value is an array, it means the criteria field is a connection field, so we need to display the record value
  if (criteriaField.type === 'connection' && Array.isArray(criteria.value)) {
    return criteria.value.map((value, valueIndex) => (
      <InlineKnackConnectionRecordValue
        // eslint-disable-next-line react/no-array-index-key
        key={`${value}-${valueIndex}`}
        tableKey={criteriaField.relationship.object}
        recordId={value}
        className={className}
      />
    ));
  }

  let valueToReturn = '';

  if (criteriaField.type === 'boolean' && typeof criteria.value === 'boolean') {
    valueToReturn = getBooleanFieldLabel(criteriaField.format, criteria.value);
  } else if (criteriaField.type === 'date_time') {
    valueToReturn = getCriteriaDateTimeDisplayString(criteria, criteriaField);
  } else if (criteriaField.type === 'user_roles') {
    valueToReturn =
      userRolesTables?.find((table) => table.profile_key === criteria.value)?.name || '';
  } else if (typeof criteria.value === 'string') {
    valueToReturn = criteria.value;
  }

  if (!valueToReturn) {
    return null;
  }

  return <InlineKnackValue value={valueToReturn} className={className} />;
}
