import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';

import { type KnackConnection } from '@/types/schema/KnackObject';
import { type KnackTask } from '@/types/schema/tasks/KnackTask';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { cn } from '@/utils/tailwind';

interface TaskConnectedFieldValueSelectProps {
  availableConnections: KnackConnection[];
  taskValueIndex: number;
}

export function TaskConnectedFieldValueSelect({
  availableConnections,
  taskValueIndex
}: TaskConnectedFieldValueSelectProps) {
  const [t] = useTranslation();

  const { getObjectByKey } = useObjectHelpers();

  const {
    formState: { errors }
  } = useFormContext<KnackTask>();

  return (
    <Controller
      name={`action.values.${taskValueIndex}.connection_field`}
      render={({ field: taskConnectedTable }) => (
        <Select onValueChange={taskConnectedTable.onChange} defaultValue={taskConnectedTable.value}>
          <Select.Trigger
            placeholder={t('actions.select')}
            className={cn('w-full', {
              'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                errors?.action?.values?.[taskValueIndex]?.connection_field
            })}
            {...taskConnectedTable}
          />
          <Select.Content>
            {availableConnections.map((connection) => {
              const connectionTable = getObjectByKey(connection.object);
              return connectionTable?.fields.map((field) => (
                <Select.Item
                  key={`${connection.key}-${field.key}`}
                  value={`${connection.key}-${field.key}`}
                  data-testid={`task-connection-${connection.key}-${field.key}`}
                >
                  <Trans
                    i18nKey="components.data_table.right_sidebar.tasks.values.connected_value_select_trigger"
                    values={{
                      connectedTable: connectionTable.name,
                      connectionField: connection.field.name,
                      connectedTableField: field.name
                    }}
                  />
                </Select.Item>
              ));
            })}
          </Select.Content>
        </Select>
      )}
    />
  );
}
