import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { type IconType } from 'react-icons';
import { Link } from 'react-router-dom';
import { Badge, Button, Tooltip } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

interface RightSidebarProps {
  children: React.ReactNode;
}

interface RightSidebarItemProps {
  icon: IconType;
  iconBadgeNumber?: number;
  tooltipText: string;
  onClick?: () => void;
  isActive?: boolean;
  dataTestId?: string;
  link?: string;
  isDisabled?: boolean;
}

function RightSidebar({ children }: RightSidebarProps, ref: React.Ref<HTMLDivElement>) {
  return (
    <aside
      ref={ref}
      data-testid="right-nav"
      className="flex h-full w-14 flex-col border-l border-subtle bg-base pt-1"
    >
      {children}
    </aside>
  );
}

function RightSidebarItem(
  {
    icon: Icon,
    iconBadgeNumber = 0,
    tooltipText,
    onClick,
    isActive,
    dataTestId,
    link,
    isDisabled = false
  }: RightSidebarItemProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const [t] = useTranslation();

  const icon = (
    <span className="relative">
      <Icon
        size={24}
        className={cn('mb-1', {
          'fill-[url(#svg-gradient-1)]': isActive
        })}
      />
      {iconBadgeNumber > 0 && (
        <Badge
          className={cn(
            'absolute bottom-0 right-0 mb-[-10px] mr-[-14px] rounded px-1.5 py-0 text-xs',
            {
              'bg-brand-muted': isActive
            }
          )}
        >
          {iconBadgeNumber}
        </Badge>
      )}
    </span>
  );

  const onItemClick = () => {
    if (!onClick || link || isDisabled) {
      return;
    }

    onClick();
  };

  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <Button
          intent="minimal"
          ref={ref}
          aria-label={tooltipText}
          disabled={isDisabled}
          data-testid={dataTestId ?? 'right-nav-item'}
          className={cn(
            'm-1 h-auto flex-col whitespace-nowrap rounded p-2 hover:bg-muted focus:outline-transparent disabled:pointer-events-auto hover:disabled:bg-transparent',
            {
              'bg-brand-100 hover:bg-brand-100 focus:bg-brand-100': isActive
            }
          )}
          onClick={onItemClick}
          asChild={link ? true : undefined}
        >
          {link ? <Link to={link}>{icon}</Link> : icon}
        </Button>
      </Tooltip.Trigger>
      <Tooltip.Content side="right">
        {isDisabled ? t('components.data_table.disabled_because_draftmode') : tooltipText}
      </Tooltip.Content>
    </Tooltip>
  );
}

const RightSidebarWithRef = forwardRef<HTMLDivElement, RightSidebarProps>(RightSidebar);

const CompoundRightSidebar = Object.assign(RightSidebarWithRef, {
  Item: forwardRef<HTMLButtonElement, RightSidebarItemProps>(RightSidebarItem)
});

RightSidebarItem.displayName = 'RightSidebar.Item';

export { CompoundRightSidebar as RightSidebar };
