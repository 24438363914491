import { useCallback, useEffect, useMemo, useState, type ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiChevronDown as ChevronDownIcon,
  HiChevronUp as ChevronUpIcon,
  HiViewColumns as FieldsIcon
} from 'react-icons/hi2';
import { Handle, Position, type Node, type NodeProps } from 'reactflow';
import { Badge, Button, Collapsible, InputSearch, Tooltip } from '@knack/asterisk-react';

import { type KnackFieldType } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { cn } from '@/utils/tailwind';
import { FieldIcon } from '@/components/FieldIcon';
import { KnackTableIcon } from '@/components/KnackTableIcon';
import {
  DEFAULT_NODE_WIDTH,
  INITIAL_VISIBLE_FIELDS_PER_TABLE
} from '@/pages/data-model/helpers/constants';
import { useDataModelSidePanelStore } from '@/pages/data-model/helpers/useDataModelSidePanelStore';
import { useDataModelStore } from '@/pages/data-model/helpers/useDataModelStore';
import {
  FieldsCollapsibleStatus,
  FieldsVisibilityStatus,
  useDataModelViewOptionsStore
} from '@/pages/data-model/helpers/useDataModelViewOptionsStore';

export type TableNodeField = {
  key: string;
  name: string;
  type: KnackFieldType;
  isDisplayField: boolean;
};

type NodeData = {
  key: string;
  name: string;
  type: KnackObject['type'];
  totalRecords: number | null;
  totalIncomingConnections: number;
  totalOutgoingConnections: number;
  fields: TableNodeField[];
};

export type TableNode = Node<NodeData>;

export const tableNodeTypeName = 'table-node';

function getFilteredFieldsAndConnections(initialFields: TableNodeField[], searchValue: string) {
  const filteredFields: TableNodeField[] = searchValue ? [] : initialFields;
  const filteredOutConnections: TableNodeField[] = [];

  if (searchValue) {
    initialFields.forEach((field) => {
      if (field.name.toLowerCase().includes(searchValue.toLowerCase())) {
        filteredFields.push(field);
      } else if (field.type === 'connection') {
        filteredOutConnections.push(field);
      }
    });
  }

  return { filteredFields, filteredOutConnections };
}

function TargetHandles({ tableKey }: { tableKey: string }) {
  return (
    <>
      <Handle
        type="target"
        id={`${tableKey}-top`}
        position={Position.Top}
        isConnectable={false}
        className="rounded-full bg-transparent"
      />
      <Handle
        type="target"
        id={`${tableKey}-left`}
        position={Position.Left}
        isConnectable={false}
        className="left-0 -ml-6 rounded-full bg-transparent"
      />
      <Handle
        type="target"
        id={`${tableKey}-right`}
        position={Position.Right}
        isConnectable={false}
        className="right-0 -mr-6 rounded-full bg-transparent"
      />
    </>
  );
}

function SourceHandles({ fieldKey }: { fieldKey: string }) {
  return (
    <>
      <Handle
        type="source"
        id={`${fieldKey}-left`}
        position={Position.Left}
        isConnectable={false}
        className="-left-[29px] rounded-full bg-transparent"
      />
      <Handle
        type="source"
        id={`${fieldKey}-right`}
        position={Position.Right}
        isConnectable={false}
        className="-right-[28px] rounded-full bg-transparent"
      />
    </>
  );
}

export function TableNodeComponent({ data: table, selected }: NodeProps<NodeData>) {
  const [t] = useTranslation();

  const { filterTableEdges } = useDataModelStore((state) => ({
    filterTableEdges: state.filterTableEdges
  }));
  const { setSidePanelItem } = useDataModelSidePanelStore((state) => ({
    setSidePanelItem: state.setSidePanelItem
  }));
  const {
    showFieldKeys,
    fieldsVisibilityStatus,
    fieldsCollapsibleStatus,
    setFieldsCollapsibleStatus
  } = useDataModelViewOptionsStore((state) => ({
    showFieldKeys: state.showFieldKeys,
    fieldsVisibilityStatus: state.fieldsVisibilityStatus,
    fieldsCollapsibleStatus: state.fieldsCollapsibleStatus,
    setFieldsCollapsibleStatus: state.setFieldsCollapsibleStatus
  }));

  const [isFieldsExpanded, setIsFieldsExpanded] = useState(true);
  const [showTruncatedFields, setShowTruncatedFields] = useState(false);
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const initialFields = useMemo(() => {
    if (fieldsVisibilityStatus === FieldsVisibilityStatus.ALL_FIELDS) {
      return table.fields;
    }
    return table.fields.filter((field) => field.type === 'connection');
  }, [fieldsVisibilityStatus, table.fields]);

  const { filteredFields, filteredOutConnections } = useMemo(
    () => getFilteredFieldsAndConnections(initialFields, searchValue ?? ''),
    [initialFields, searchValue]
  );

  const hasTruncatedFields = filteredFields.length > INITIAL_VISIBLE_FIELDS_PER_TABLE;

  const allConnectionFields = useMemo(() => {
    if (fieldsVisibilityStatus === FieldsVisibilityStatus.ONLY_CONNECTIONS) {
      return initialFields;
    }
    return filteredFields.filter((field) => field.type === 'connection');
  }, [fieldsVisibilityStatus, filteredFields, initialFields]);

  const truncatedConnectionFields = useMemo(
    () =>
      filteredFields
        .slice(INITIAL_VISIBLE_FIELDS_PER_TABLE)
        .filter((field) => field.type === 'connection'),
    [filteredFields]
  );

  const visibleFields =
    hasTruncatedFields && !showTruncatedFields
      ? filteredFields.slice(0, INITIAL_VISIBLE_FIELDS_PER_TABLE)
      : filteredFields;

  let truncatedFieldsGroupText = t('components.data_model.table_truncated_fields_all_fields', {
    count: filteredFields.length
  });
  if (!showTruncatedFields) {
    truncatedFieldsGroupText = `${t('components.data_model.table_truncated_fields_group', {
      count: filteredFields.length - INITIAL_VISIBLE_FIELDS_PER_TABLE
    })}`;
    if (truncatedConnectionFields.length) {
      truncatedFieldsGroupText += `, ${t(
        'components.data_model.table_truncated_fields_group_connections',
        {
          count: truncatedConnectionFields.length
        }
      )}`;
    }
  }

  const toggleExpandFields = useCallback(() => {
    setIsFieldsExpanded((prev) => !prev);
    setFieldsCollapsibleStatus(FieldsCollapsibleStatus.MIXED);
  }, [setFieldsCollapsibleStatus]);
  const toggleShowTruncatedFields = () => setShowTruncatedFields((prev) => !prev);
  const handleFieldSearch = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchValue(event.target.value);
  const onTableConnectionsClick = () => setSidePanelItem({ tableKey: table.key });
  const onFieldClick = (fieldKey: string) => setSidePanelItem({ tableKey: table.key, fieldKey });

  useEffect(() => {
    if (searchValue === null) return;

    filterTableEdges(table.key, filteredOutConnections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (fieldsCollapsibleStatus === FieldsCollapsibleStatus.ALL_EXPANDED) {
      setIsFieldsExpanded(true);
    }
    if (fieldsCollapsibleStatus === FieldsCollapsibleStatus.ALL_COLLAPSED) {
      setIsFieldsExpanded(false);
    }
  }, [fieldsCollapsibleStatus]);

  return (
    <div
      className={cn('data-model-table-node', { 'data-model-table-node--selected': selected })}
      style={{ width: `${DEFAULT_NODE_WIDTH}px` }}
      data-testid={`table-node-${table.key}`}
    >
      <div className="relative mb-6" data-testid="table-node-name-container">
        <TargetHandles tableKey={table.key} />
        <div className="mb-2 flex flex-wrap items-center">
          <KnackTableIcon tableType={table.type} size={26} className="mr-2 text-emphasis" />
          <span
            className="mr-auto block text-xl font-medium text-emphasis"
            data-testid="table-node-table-name"
          >
            {table.name}
          </span>
          {table.totalRecords !== null && (
            <span className="text-subtle" data-testid="table-node-record-count">
              {t('components.data_model.table_total_records', {
                count: table.totalRecords
              })}
            </span>
          )}
        </div>
        <Button
          intent="link"
          className="flex h-auto p-0 text-xs"
          onClick={onTableConnectionsClick}
          data-testid="table-node-connections-button"
        >
          {t('components.data_model.table_incoming_connections', {
            count: table.totalIncomingConnections
          })}
          ,{' '}
          {t('components.data_model.table_outgoing_connections', {
            count: table.totalOutgoingConnections
          })}
        </Button>
      </div>
      <div className="relative">
        <Collapsible open={isFieldsExpanded} onOpenChange={toggleExpandFields}>
          <Collapsible.Trigger
            className="relative z-10 flex w-full items-center text-subtle"
            data-testid="table-node-fields-collapsible-trigger"
          >
            <>
              <FieldsIcon size={22} className="mr-2" />
              <h3 className="font-semibold">
                {t('components.data_model.fields')}{' '}
                <span className="font-normal">({filteredFields.length})</span>
              </h3>
              {isFieldsExpanded ? (
                <ChevronUpIcon size={14} className="ml-auto" />
              ) : (
                <ChevronDownIcon size={14} className="ml-auto" />
              )}
            </>
          </Collapsible.Trigger>

          {isFieldsExpanded ? (
            <>
              <div
                className="mt-2 flex flex-col gap-2"
                data-testid="table-node-expanded-fields-container"
              >
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor={`fields-search-label-${table.key}`} className="sr-only">
                  {t('components.data_model.search_fields_placeholder')}
                </label>
                <InputSearch
                  id={`fields-search-label-${table.key}`}
                  aria-label={t('components.data_model.search_fields_placeholder')}
                  placeholder={t('components.data_model.search_fields_placeholder')}
                  className="relative z-10 w-full"
                  size="sm"
                  value={searchValue ?? ''}
                  onChange={handleFieldSearch}
                  data-testid="table-node-fields-search-input"
                />
                {visibleFields.map((field) => (
                  <button
                    key={field.key}
                    type="button"
                    onClick={() => onFieldClick(field.key)}
                    className="relative rounded border border-solid border-subtle p-2 outline outline-2 outline-offset-[-2px] outline-transparent hover:bg-subtle focus:outline-brand"
                    data-testid={`table-node-${table.key}-${field.key}`}
                  >
                    {field.type === 'connection' && <SourceHandles fieldKey={field.key} />}
                    <div className="flex items-center">
                      <Tooltip>
                        <Tooltip.Trigger asChild>
                          <span className="mr-1.5 truncate font-medium">{field.name}</span>
                        </Tooltip.Trigger>
                        <Tooltip.Content className="z-10 text-center">{field.name}</Tooltip.Content>
                      </Tooltip>
                      {showFieldKeys && (
                        <span
                          className="mr-1.5 text-subtle/60"
                          data-testid="table-node-fields-list-field-key"
                        >
                          {field.key}
                        </span>
                      )}
                      <div className="ml-auto flex items-center text-subtle">
                        {field.isDisplayField && (
                          <Badge className="mr-4 whitespace-nowrap bg-emphasis text-xs font-thin">
                            {t('keywords.display_field')}
                          </Badge>
                        )}
                        <FieldIcon name={field.type} className="mr-1.5" />
                        <span className="whitespace-nowrap">
                          {t(`attributes.field_types.${field.type}`)}
                        </span>
                      </div>
                    </div>
                  </button>
                ))}
              </div>

              {hasTruncatedFields && (
                <div className="relative mt-4">
                  <Button
                    type="button"
                    intent="minimalStandalone"
                    onClick={toggleShowTruncatedFields}
                    className="peer relative z-10 h-auto w-full justify-normal rounded border border-solid border-subtle bg-card !p-2 font-normal text-default hover:bg-subtle"
                    data-testid="table-node-show-truncated-fields-toggle"
                  >
                    <span className="text-left">{truncatedFieldsGroupText}</span>
                    <span className="ml-auto basis-2/6 text-right">
                      {showTruncatedFields ? t('actions.show_less') : t('actions.show_all')}
                    </span>
                  </Button>
                  {!showTruncatedFields && (
                    <div className="absolute bottom-[-5px] left-[5px] mx-auto h-[10px] w-[calc(100%-10px)] rounded border border-solid border-subtle peer-hover:bg-emphasis" />
                  )}

                  {!showTruncatedFields &&
                    truncatedConnectionFields.map((field) => (
                      <div
                        className="pointer-events-none absolute left-0 top-0 h-full w-full p-2 opacity-0"
                        key={field.key}
                      >
                        <SourceHandles fieldKey={field.key} />
                      </div>
                    ))}
                </div>
              )}
            </>
          ) : (
            allConnectionFields.map((field) => (
              <div className="absolute left-0 top-0 -z-[1] h-full w-full opacity-0" key={field.key}>
                <SourceHandles fieldKey={field.key} />
              </div>
            ))
          )}
        </Collapsible>

        {/* The connections that have been filtered out still need their handles rendered so that the edges can be drawn, even though they are hidden */}
        {filteredOutConnections.map((field) => (
          <div className="absolute left-0 top-0 -z-[1] h-full w-full opacity-0" key={field.key}>
            <SourceHandles fieldKey={field.key} />
          </div>
        ))}
      </div>
    </div>
  );
}
