import { useTranslation } from 'react-i18next';

import { type KnackFilter, type KnackMenuFilter } from '@/types/schema/KnackFilter';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { InlineKnackCriteriaValue } from '@/components/InlineKnackCriteriaValue';
import { InlineKnackField } from '@/components/InlineKnackField';

type FiltersGroupCardProps = {
  filtersCriteria: KnackFilter[] | KnackMenuFilter[];
};

export function FiltersGroupSingleCard({ filtersCriteria }: FiltersGroupCardProps) {
  const [t] = useTranslation();
  const { getFieldByKey } = useFieldHelpers();

  return (
    <div>
      <div data-testid="filters-group-card ">
        {filtersCriteria.map((filterRule, filterRuleIndex) => {
          const knackField = getFieldByKey(filterRule.field);

          if (!knackField) {
            return null;
          }

          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${filterRule.field}-${filterRuleIndex}-filter`}
              className="my-2 rounded-lg bg-subtle p-2"
            >
              <p className="mb-2 text-emphasis">
                {t(
                  'pages.element_settings.common.categories.data_display.filtering_section.link_number',
                  { linkNumber: filterRuleIndex + 1 }
                )}
              </p>
              <InlineKnackField
                fieldName={knackField.name}
                fieldType={knackField.type}
                className="bg-action text-default"
              />
              <span className="ml-1">{filterRule.operator}</span>
              <InlineKnackCriteriaValue
                criteriaField={knackField}
                criteria={filterRule}
                className="mx-1 bg-action py-1.5"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
