import { useLocation } from 'react-router-dom';
import i18n from 'i18next';

import { ROUTES } from '@/Router';

function getSettingsTopBarHeading(pathname: string) {
  switch (pathname) {
    case ROUTES.SETTINGS_GENERAL:
      return i18n.t('app_settings.sections.general');
    case ROUTES.SETTINGS_USERS:
      return i18n.t('app_settings.sections.user_logins');
    case ROUTES.SETTINGS_API:
      return i18n.t('app_settings.sections.api');
    default:
      return i18n.t('app_settings.sections.general');
  }
}

export function SettingsTopBar() {
  const { pathname } = useLocation();
  const topbarHeading = getSettingsTopBarHeading(pathname);

  return <h2 className="mb-7 text-xl font-medium text-emphasis">{topbarHeading}</h2>;
}
