import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as AddIcon, HiXMark as RemoveIcon } from 'react-icons/hi2';
import { Button, Select } from '@knack/asterisk-react';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  NON_COMPATIBLE_FIELD_TYPES_FOR_RECORD_RULE_VALUES,
  RECORD_RULE_MULTIPLE_CHOICE_ACTION_TYPES,
  type RecordRule,
  type RecordRuleValue
} from '@/types/schema/rules/RecordRule';
import { useRecordRuleHelpers } from '@/hooks/useRecordRuleHelpers';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { FieldIcon } from '@/components/FieldIcon';
import { RecordRuleValueInput } from '@/components/record-rule/RecordRuleValueInput';

interface RecordRuleDialogGroupItemProps {
  tableConnectionsNumber: number;
  availableConnectedFields?: KnackField[];
  sourceObject: KnackObject;
}

export function RecordRuleValueGroup({
  availableConnectedFields,
  tableConnectionsNumber,
  sourceObject
}: RecordRuleDialogGroupItemProps) {
  const [t] = useTranslation();
  const {
    createDefaultRecordRuleValue,
    getDefaultRecordValue,
    getRecordValueTypeAvailableFields,
    getConnectedFieldsFromSourceObjects
  } = useRecordRuleHelpers();
  const availableFields = availableConnectedFields || sourceObject.fields;
  const connectionFieldsWithRelationship = getConnectedFieldsFromSourceObjects(availableFields);

  const {
    control,
    getValues,
    clearErrors,
    formState: { errors }
  } = useFormContext<RecordRule>();

  const {
    append,
    update,
    remove,
    fields: recordRuleValues
  } = useFieldArray({
    control,
    name: 'values'
  });

  const connectionValueType: RecordRuleValue['type'] = 'connection';
  const recordValueTypes: RecordRuleValue['type'][] = ['value', 'record'];
  const dateRecordValueTypes: RecordRuleValue['type'][] = [...recordValueTypes, 'current_date'];
  const addressRecordValueTypes: RecordRuleValue['type'][] = [
    ...recordValueTypes,
    'current_location'
  ];

  const getRecordTypesList = (field: KnackField) => {
    const actionType = getValues('action');
    if (field.type === 'date_time') {
      return dateRecordValueTypes;
    }

    if (field.type === 'address') {
      return addressRecordValueTypes;
    }

    if (tableConnectionsNumber === 0 || actionType === 'insert' || actionType === 'connection') {
      return recordValueTypes;
    }

    if (connectionFieldsWithRelationship.length === 0) {
      return recordValueTypes;
    }

    return [...recordValueTypes, connectionValueType];
  };

  const filteredFields = availableFields.filter(
    (field) => !NON_COMPATIBLE_FIELD_TYPES_FOR_RECORD_RULE_VALUES.includes(field.type)
  );
  const defaultRecordRuleValue = getDefaultRecordValue(filteredFields[0]?.key);

  return recordRuleValues.map((ruleField, ruleFieldIndex) => {
    const selectedField = availableFields.find((f) => f.key === ruleField.field);

    if (!selectedField) {
      return null;
    }

    return (
      <div key={ruleField.id} className="flex gap-2">
        <div className="w-full">
          <div className="flex">
            <div className="-m-1 flex-1 space-y-2 p-1">
              <div className="flex gap-2">
                <div className="flex-1">
                  <Controller
                    name={`values.${ruleFieldIndex}.field`}
                    render={({ field: { value: fieldKey } }) => {
                      const isValidFieldKey = availableFields.some((f) => f.key === fieldKey);

                      return (
                        <Select
                          value={isValidFieldKey ? fieldKey : undefined}
                          onValueChange={(newFieldKey: KnackFieldKey) => {
                            update(ruleFieldIndex, {
                              ...ruleField,
                              field: newFieldKey,
                              type: 'value',
                              value: ''
                            });
                            clearErrors();
                          }}
                          disabled={availableFields.length === 0}
                        >
                          <Select.Trigger
                            placeholder={t('actions.select')}
                            className={cn('w-full max-w-[168px]', {
                              'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                                errors.values?.[ruleFieldIndex]?.field
                            })}
                          />
                          <Select.Content className="min-w-[200px]">
                            {availableFields.map((field) => {
                              if (
                                NON_COMPATIBLE_FIELD_TYPES_FOR_RECORD_RULE_VALUES.includes(
                                  field.type
                                )
                              ) {
                                return null;
                              }

                              return (
                                <Select.Item key={field.key} value={field.key}>
                                  <span className="flex items-center">
                                    <FieldIcon
                                      className="mr-2 shrink-0 text-subtle"
                                      size={16}
                                      name={field.type}
                                    />
                                    {field.name}
                                  </span>
                                </Select.Item>
                              );
                            })}
                          </Select.Content>
                        </Select>
                      );
                    }}
                  />
                  <FormErrorMessage
                    errors={errors}
                    name={`values.${ruleFieldIndex}.field`}
                    className="mt-1"
                  />
                </div>
                <div className="flex-1">
                  <Controller
                    name={`values.${ruleFieldIndex}.type`}
                    render={({ field: { value: valueType, onChange } }) => (
                      <Select
                        value={valueType}
                        onValueChange={(newValueType: RecordRuleValue['type']) => {
                          update(
                            ruleFieldIndex,
                            createDefaultRecordRuleValue(
                              getValues(`values.${ruleFieldIndex}`),
                              newValueType,
                              availableFields,
                              sourceObject
                            )
                          );
                          onChange(newValueType);
                          clearErrors();
                        }}
                      >
                        <Select.Trigger
                          placeholder={`${t('actions.select')}...`}
                          className="w-full max-w-[168px]"
                        />
                        <Select.Content className="min-w-52">
                          {getRecordTypesList(selectedField).map(
                            (updateRecordType: RecordRuleValue['type']) => {
                              let isOptionDisabled = false;

                              // Disabling the "to a field value" option if the selected field is not compatible
                              // with any selectable field to update
                              // e.g if we have an Image field, we can only update it with another Image field.
                              if (updateRecordType === 'record') {
                                const compatibleFields = getRecordValueTypeAvailableFields(
                                  selectedField,
                                  sourceObject.fields
                                );

                                if (compatibleFields.length === 0) {
                                  isOptionDisabled = true;
                                }
                              }

                              return (
                                <Select.Item
                                  key={updateRecordType}
                                  value={updateRecordType}
                                  disabled={isOptionDisabled}
                                >
                                  {t(`components.rules.record_rules.types.${updateRecordType}`)}
                                </Select.Item>
                              );
                            }
                          )}
                        </Select.Content>
                      </Select>
                    )}
                  />
                </div>
              </div>
              {/* Only render this select for Multiple choice Fields with multiple choice formats */}
              {selectedField.type === 'multiple_choice' &&
                (selectedField.format.type === 'multi' ||
                  selectedField.format.type === 'checkboxes') && (
                  <Controller
                    name={`values.${ruleFieldIndex}.action`}
                    render={({ field: { value: actionType, onChange } }) => (
                      <Select
                        value={actionType || 'replace'}
                        onValueChange={(val) => {
                          if (val === 'replace') {
                            onChange('');
                            return;
                          }
                          onChange(val);
                        }}
                      >
                        <Select.Trigger className="w-full" defaultValue="replace" />
                        <Select.Content>
                          <Select.Item value="replace">
                            {t(`components.rules.record_rules.types.replacing`)}
                          </Select.Item>
                          {RECORD_RULE_MULTIPLE_CHOICE_ACTION_TYPES.map((type) => (
                            <Select.Item key={type} value={type}>
                              {t(`components.rules.record_rules.types.${type}`)}
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select>
                    )}
                  />
                )}
              {ruleField.type !== 'current_date' && ruleField.type !== 'current_location' && (
                <RecordRuleValueInput
                  selectedField={selectedField}
                  recordRuleActionType={ruleField.type}
                  availableFields={availableFields}
                  formFieldName={`values.${ruleFieldIndex}`}
                  sourceObject={sourceObject}
                />
              )}
            </div>
            <div className="flex">
              <Button
                intent="minimal"
                aria-label={t('components.rules.delete_condition')}
                size="xs"
                className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
                onClick={() => remove(ruleFieldIndex)}
                disabled={recordRuleValues.length < 2}
              >
                <RemoveIcon size={16} />
              </Button>
              <Button
                intent="minimal"
                aria-label={t('components.rules.delete_condition')}
                size="xs"
                className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
                onClick={() => {
                  append(defaultRecordRuleValue);
                }}
              >
                <AddIcon size={16} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  });
}
