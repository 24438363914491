import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { HiArrowDownTray as ExportIcon } from 'react-icons/hi2';
import { DropdownMenu } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';
import { TextTooltip } from '@/components/TextTooltip';

export function ExportDataItem({ table }: { table: KnackObject }) {
  const [t] = useTranslation();
  const { data: application } = useApplicationQuery();

  const dataTableStore = useDataTableStore();

  const isDraftModeEnabled = useGlobalState((state) => state.isDraftModeEnabled);

  const ExportData = useCallback(
    (type: 'csv' | 'json' | 'text') => {
      const { filters, sortBy, sortOrder, search } = dataTableStore.getState().persist.getState();

      // If we export when the dataTable has not been initialized, we set the params to undefined to bring all the records
      const queryParams = {
        filters: filters ? JSON.stringify(filters) : null,
        search,
        sort_field: sortBy,
        sort_order: sortOrder,
        type
      };

      const searchParams = new URLSearchParams();
      Object.keys(queryParams).forEach(
        (key) => queryParams[key] !== null && searchParams.append(key, queryParams[key])
      );

      window.location.replace(
        `${import.meta.env.PUBLIC_API_URL}/v1/objects/${table.key}/records/export/applications/${application?.id}?${searchParams.toString()}`
      );
    },
    [application?.id, dataTableStore, table.key]
  );

  return (
    <DropdownMenu.SubMenu>
      <TextTooltip
        label={isDraftModeEnabled ? t('components.data_table.disabled_because_draftmode') : ''}
      >
        <DropdownMenu.SubTrigger disabled={isDraftModeEnabled}>
          <ExportIcon size={18} className="mr-2" />
          {t('components.tables_list.export')}
        </DropdownMenu.SubTrigger>
      </TextTooltip>
      <DropdownMenu.Portal>
        <DropdownMenu.SubContent
          className="DropdownMenuSubContent"
          sideOffset={5}
          alignOffset={-10}
        >
          <DropdownMenu.Item
            disabled={isDraftModeEnabled}
            onClick={() => {
              ExportData('csv');
            }}
          >
            CSV
          </DropdownMenu.Item>
          <DropdownMenu.Item
            disabled={isDraftModeEnabled}
            onClick={() => {
              ExportData('json');
            }}
          >
            JSON
          </DropdownMenu.Item>
          <DropdownMenu.Item
            disabled={isDraftModeEnabled}
            onClick={() => {
              ExportData('text');
            }}
          >
            {t('components.tables_list.export_type_text')}
          </DropdownMenu.Item>
        </DropdownMenu.SubContent>
      </DropdownMenu.Portal>
    </DropdownMenu.SubMenu>
  );
}
