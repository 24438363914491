import { Trans, useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import { type FormView } from '@/types/schema/views/FormView';
import { LearnMoreLink } from '@/components/LearnMoreLink';
import { TabListItemCountChip } from '@/components/TabListItemCountChip';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { FormSubmitRules } from './submit-rules/FormSubmitRules';

export function FormSubmissionCategory() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<FormView>();

  const tabListItems: TabsListItem[] = [
    {
      value: 'submit-rules',
      children: t('pages.element_settings.form.categories.form_submission.submit_rules.label')
    },
    {
      value: 'other-rules',
      children: (
        <>
          {t('pages.element_settings.form.categories.form_submission.other_rules.label')}
          <TabListItemCountChip
            count={(view.rules.records?.length ?? 0) + (view.rules.emails?.length ?? 0)}
          />
        </>
      )
    }
  ];

  return (
    <Tabs defaultValue="submit-rules">
      <Tabs.List items={tabListItems} intent="page" shouldDisableResponsive shouldUseGradient />
      <Tabs.Content value="submit-rules" className="p-0 text-default">
        {import.meta.env.PUBLIC_IS_PRODUCTION === 'true' ? (
          'Coming Soon'
        ) : (
          <div className="mb-4">
            <p className="mb-4 text-xs text-subtle">
              <Trans
                i18nKey="pages.element_settings.form.categories.form_submission.submit_rules.submit_rules_description"
                components={[
                  <LearnMoreLink
                    key="0"
                    href="https://learn.knack.com/apps/elements/forms/submit-actions"
                  />
                ]}
              />
            </p>

            <FormSubmitRules />
          </div>
        )}
      </Tabs.Content>
      <Tabs.Content value="other-rules" className="p-0 text-default">
        Coming Soon
      </Tabs.Content>
    </Tabs>
  );
}
