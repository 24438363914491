import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon, HiXMark as RemoveIcon } from 'react-icons/hi2';
import { Fragment } from 'react/jsx-runtime';
import { Button, Form, Label, Select } from '@knack/asterisk-react';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  TASK_VALUE_TYPE_OPTIONS,
  type KnackTask,
  type TaskValueType
} from '@/types/schema/tasks/KnackTask';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { FieldIcon } from '@/components/FieldIcon';
import { getDefaultValue } from '@/components/import/ConfirmImport';
import {
  ActionFieldInput,
  ValueInput
} from '@/pages/tables/toolkit-sidebar/rules/conditional-rules/form-sections/ConditionalRuleFormActionValue';
import { getFilteredFieldActionValueFields } from '@/pages/tables/toolkit-sidebar/rules/conditional-rules/utils';
import { TaskConnectedFieldValueSelect } from '@/pages/tables/toolkit-sidebar/tasks/TaskConnectedFieldValueSelect';
import {
  NON_COMPATIBLE_FIELD_TYPES_FOR_TASK_VALUES,
  useTaskFormHelpers
} from './helpers/useTaskFormHelpers';

interface TaskValueFormProps {
  table: KnackObject;
  isConnectionTypeTask: boolean;
}

export function TaskValueForm({ isConnectionTypeTask, table }: TaskValueFormProps) {
  const [t] = useTranslation();

  const { getDefaultTaskValue, getRecordValueTypeDefaultTargetField } = useTaskFormHelpers();
  const { getObjectByKey } = useObjectHelpers();

  const {
    control,
    getValues,
    clearErrors: clearFormErrors,
    watch,
    formState: { errors }
  } = useFormContext<KnackTask>();

  const {
    fields: valueFormFields,
    append: appendValue,
    remove: removeValue,
    update: updateValue
  } = useFieldArray({
    name: 'action.values',
    control
  });

  const allConnections = [...table.connections.inbound, ...table.connections.outbound];
  const connectedObjectFields = isConnectionTypeTask
    ? getObjectByKey(watch('action.connection')?.split('.')[0] || allConnections[0]?.object)
        ?.fields || []
    : [];

  const availableValueFields = isConnectionTypeTask ? connectedObjectFields : table.fields;

  const renderFieldInput = (selectedField: KnackField, index: number) => (
    <ValueInput
      field={selectedField}
      formFieldName={`action.values.${index}.value`}
      table={table}
    />
  );

  const renderFieldValueSelector = (selectedField: KnackField, index: number) => {
    const filteredFieldActionValueFields = getFilteredFieldActionValueFields(
      selectedField,
      table.fields
    );

    return (
      <ActionFieldInput
        formFieldName={`action.values.${index}.input`}
        availableFields={filteredFieldActionValueFields}
      />
    );
  };

  const renderConnectionFieldValueSelector = (index: number) => {
    const availableInboundConnections = table.connections.inbound.filter(
      (connection) => connection.belongs_to === 'one'
    );
    const availableOutboundConnections = table.connections.outbound.filter(
      (connection) => connection.has === 'one'
    );
    const availableConnections = [...availableInboundConnections, ...availableOutboundConnections];

    return (
      <TaskConnectedFieldValueSelect
        availableConnections={availableConnections}
        taskValueIndex={index}
      />
    );
  };

  const getFieldValueInput = (
    inputType: TaskValueType,
    selectedField: KnackField,
    index: number
  ) => {
    switch (inputType) {
      case 'value':
        return renderFieldInput(selectedField, index);
      case 'record':
        return renderFieldValueSelector(selectedField, index);
      case 'connection':
        return renderConnectionFieldValueSelector(index);
      default:
        return null;
    }
  };

  const onValueTypeChange = ({
    valueIndex,
    newValueType
  }: {
    valueIndex: number;
    newValueType: TaskValueType;
  }) => {
    const fieldToApplyChanges = getValues(`action.values.${valueIndex}.field`);

    updateValue(valueIndex, {
      ...getValues(`action.values.${valueIndex}`),
      type: newValueType,
      value: '',
      input:
        newValueType === 'record'
          ? getRecordValueTypeDefaultTargetField(fieldToApplyChanges, table.fields)
          : undefined
    });
    clearFormErrors();
  };

  // Show it when a relationship has a one-to-X relationship, no matter if it's inbound or outbound
  const shouldShowConnectedToValueType =
    table.connections.inbound.some((connection) => connection.belongs_to === 'one') ||
    table.connections.outbound.some((connection) => connection.has === 'one');

  return (
    <Form.Section>
      <div className="mb-2">
        <Label className="font-medium">
          {t('components.data_table.right_sidebar.tasks.values.set_values')}
        </Label>
      </div>

      {valueFormFields.map((valueFormField, valueFormFieldIndex) => {
        const isFieldAvailable = availableValueFields.some(
          (field) => field.key === valueFormField.field
        );

        const selectedField = availableValueFields.find(
          (field) => field.key === valueFormField.field
        );

        if (!selectedField) {
          return null;
        }

        const filteredFieldActionValueFields = getFilteredFieldActionValueFields(
          selectedField,
          table.fields
        );

        const fieldValueInput = getFieldValueInput(
          valueFormField.type,
          selectedField,
          valueFormFieldIndex
        );

        const isLastCriteria = valueFormFieldIndex === valueFormFields.length - 1;

        return (
          <Fragment key={valueFormField.id}>
            <div className="my-2 flex">
              <div className="-m-1 flex-1 overflow-hidden p-1">
                <div className="flex gap-2">
                  <div className="flex-1">
                    <Controller
                      control={control}
                      name={`action.values.${valueFormFieldIndex}.field`}
                      render={({ field: { value: fieldKey } }) => (
                        <Select
                          value={isFieldAvailable ? fieldKey : undefined}
                          onValueChange={(newFieldKey: KnackFieldKey) => {
                            const newField = availableValueFields.find(
                              (field) => field.key === newFieldKey
                            );

                            if (!newField) {
                              return;
                            }

                            updateValue(valueFormFieldIndex, {
                              ...getValues(`action.values.${valueFormFieldIndex}`),
                              field: newFieldKey,
                              value: getDefaultValue(newField) as any
                            });

                            if (
                              getValues(`action.values.${valueFormFieldIndex}.type`) === 'record' &&
                              filteredFieldActionValueFields.length === 0
                            ) {
                              updateValue(valueFormFieldIndex, {
                                ...getValues(`action.values.${valueFormFieldIndex}`),
                                type: 'value'
                              });
                            }
                            clearFormErrors();
                          }}
                        >
                          <Select.Trigger
                            placeholder={t('actions.select')}
                            className={cn('w-full', {
                              'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                                errors?.action?.values?.[valueFormFieldIndex]?.field
                            })}
                            data-testid={`task-value-field-select-${valueFormFieldIndex}`}
                          />
                          <Select.Content>
                            {availableValueFields.map((field) => (
                              <Select.Item
                                key={field.key}
                                value={field.key}
                                disabled={NON_COMPATIBLE_FIELD_TYPES_FOR_TASK_VALUES.includes(
                                  field.type
                                )}
                              >
                                <span className="flex items-center">
                                  <FieldIcon
                                    className="mr-2 shrink-0 text-subtle"
                                    size={16}
                                    name={field.type}
                                  />
                                  {field.name}
                                </span>
                              </Select.Item>
                            ))}
                          </Select.Content>
                        </Select>
                      )}
                    />

                    <FormErrorMessage
                      errors={errors}
                      name={`action.values.${valueFormFieldIndex}.field`}
                    />
                  </div>
                  <div className="flex-1">
                    <Controller
                      control={control}
                      name={`action.values.${valueFormFieldIndex}.type`}
                      render={({ field: { value: valueType } }) => (
                        <Select
                          value={valueType}
                          onValueChange={(newValueType: TaskValueType) => {
                            onValueTypeChange({
                              valueIndex: valueFormFieldIndex,
                              newValueType
                            });
                          }}
                        >
                          <Select.Trigger
                            placeholder={t('actions.select')}
                            className="w-full"
                            data-testid={`task-value-type-select-${valueFormFieldIndex}`}
                          />
                          <Select.Content>
                            {TASK_VALUE_TYPE_OPTIONS.map((taskValueType) => {
                              if (
                                taskValueType === 'connection' &&
                                !shouldShowConnectedToValueType
                              ) {
                                return null;
                              }

                              if (
                                taskValueType === 'record' &&
                                filteredFieldActionValueFields.length === 0
                              ) {
                                return null;
                              }

                              return (
                                <Select.Item key={taskValueType} value={taskValueType}>
                                  {t(
                                    `components.data_table.right_sidebar.tasks.values.types.${taskValueType}`
                                  )}
                                </Select.Item>
                              );
                            })}
                          </Select.Content>
                        </Select>
                      )}
                    />
                  </div>
                </div>
                {fieldValueInput && (
                  <div className="mt-2 flex flex-col gap-2">
                    {fieldValueInput}
                    <FormErrorMessage
                      errors={errors}
                      name={`action.values.${valueFormFieldIndex}.value`}
                    />
                  </div>
                )}

                {!isLastCriteria && (
                  <div className="mt-2 font-medium">{t('components.rules.and_uppercase')}</div>
                )}
              </div>

              {valueFormFields.length > 1 && (
                <Button
                  intent="minimal"
                  aria-label={t('components.rules.delete_condition')}
                  size="xs"
                  className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
                  onClick={() => removeValue(valueFormFieldIndex)}
                >
                  <RemoveIcon size={16} />
                </Button>
              )}
            </div>
          </Fragment>
        );
      })}

      <Button
        intent="secondary"
        data-testid="add-task-value-button"
        aria-label={t('components.rules.add_condition')}
        onClick={() => {
          const defaultValue = getDefaultTaskValue(availableValueFields);
          if (defaultValue) {
            appendValue(defaultValue);
          }
        }}
      >
        <Button.Icon icon={PlusIcon} position="left" />
        {t('components.data_table.right_sidebar.tasks.values.value')}
      </Button>
    </Form.Section>
  );
}
