import { useTranslation } from 'react-i18next';
import { Button } from '@knack/asterisk-react';

import { getChartNameFromType } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { AddRecordViewFlowSteps, useAddRecordViewFlowContext } from './AddRecordViewFlowContext';

export function FlowContinueButton() {
  const [t] = useTranslation();

  const {
    selectedViewSourcePath,
    selectedViewSource,
    selectedViewSubType,
    isAddingChartToReportView,
    currentStep,
    onContinue
  } = useAddRecordViewFlowContext();

  if (currentStep === AddRecordViewFlowSteps.selectViewSource) {
    return (
      <Button
        onClick={onContinue}
        disabled={!selectedViewSource}
        data-testid="add-view-modal-continue-button"
      >
        {t('actions.continue')}
      </Button>
    );
  }

  if (currentStep === AddRecordViewFlowSteps.selectViewSourcePath) {
    return (
      <Button
        onClick={onContinue}
        disabled={!selectedViewSourcePath}
        data-testid="add-view-modal-continue-button"
      >
        {t('actions.continue')}
      </Button>
    );
  }

  if (isAddingChartToReportView && selectedViewSubType) {
    return (
      <Button onClick={onContinue} data-testid="add-chart-modal-save-button">
        {t('pages.element_settings.report.add_new_chart.confirm_add_chart_button', {
          chartName: getChartNameFromType(selectedViewSubType)
        })}
      </Button>
    );
  }

  return (
    <Button onClick={onContinue} data-testid="add-view-modal-save-button">
      {t('views.add_view_dialog.confirm_add_view.confirm_button')}
    </Button>
  );
}
