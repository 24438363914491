import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  NON_COMPATIBLE_FIELD_TYPES_FOR_RECORD_RULE_VALUES,
  type RecordRule,
  type RecordRuleValue
} from '@/types/schema/rules/RecordRule';
import { useRecordRuleHelpers } from '@/hooks/useRecordRuleHelpers';
import { FieldIcon } from '@/components/FieldIcon';
import { FieldRecordValueInput } from '@/components/inputs/FieldRecordValueInput';

interface RecordRuleValueInputProps {
  availableFields: KnackField[];
  recordRuleActionType: RecordRuleValue['type'];
  sourceObject: KnackObject;
  selectedField: KnackField;
  formFieldName: `values.${number}`;
}

export function RecordRuleValueInput({
  availableFields,
  recordRuleActionType,
  selectedField,
  sourceObject,
  formFieldName
}: RecordRuleValueInputProps) {
  const [t] = useTranslation();
  const { getConnectedFieldsFromSourceObjects, getRecordValueTypeAvailableFields } =
    useRecordRuleHelpers();

  const connectionFieldsWithRelationship = getConnectedFieldsFromSourceObjects(availableFields);

  const {
    formState: { errors }
  } = useFormContext<RecordRule>();

  if (recordRuleActionType === 'connection' && connectionFieldsWithRelationship.length) {
    return (
      <Controller
        name={`${formFieldName}.connection_field`}
        render={({ field: { value: fieldKey, onChange } }) => (
          <Select value={fieldKey} onValueChange={onChange}>
            <Select.Trigger className="w-full" />
            <Select.Content>
              {connectionFieldsWithRelationship.map((connectedField) =>
                connectedField.connectionObject.fields.map((field) => (
                  <Select.Item
                    key={`${connectedField.field.key}-${field.key}`}
                    // value has to be like field_204-field_24. The first field is the source connected field and the second field is the field in the connected object
                    value={`${connectedField.field.key}-${field.key}`}
                  >
                    <span className="flex items-center">
                      {`${connectedField.connectionObject.name} (${connectedField.field.name}) > ${field.name}`}
                    </span>
                  </Select.Item>
                ))
              )}
            </Select.Content>
          </Select>
        )}
      />
    );
  }

  if (recordRuleActionType === 'record') {
    return (
      <Controller
        name={`${formFieldName}.input`}
        render={({ field: { value: fieldKey, onChange } }) => (
          <Select value={fieldKey} onValueChange={onChange}>
            <Select.Trigger className="w-full" />
            <Select.Content>
              {sourceObject.fields.map((field) => {
                if (NON_COMPATIBLE_FIELD_TYPES_FOR_RECORD_RULE_VALUES.includes(field.type)) {
                  return null;
                }

                // We need to check if the selected field is compatible with the field we use to update.
                // e.g if we have an Image field, we can only update it with another Image field.
                const compatibleFields = getRecordValueTypeAvailableFields(
                  selectedField,
                  sourceObject.fields
                );

                const isCompatible = compatibleFields.some((f) => f.key === field.key);

                return (
                  <Select.Item key={field.key} value={field.key} disabled={!isCompatible}>
                    <span className="flex items-center">
                      <FieldIcon
                        className="mr-2 shrink-0 text-subtle"
                        size={16}
                        name={field.type}
                      />
                      {field.name}
                    </span>
                  </Select.Item>
                );
              })}
            </Select.Content>
          </Select>
        )}
      />
    );
  }

  if (!selectedField) {
    return null;
  }

  return (
    <>
      <FieldRecordValueInput<RecordRule>
        field={selectedField}
        formFieldName={`${formFieldName}.value`}
        sourceObject={sourceObject}
      />
      {errors.values && (
        <span className="text-xs text-destructive">
          {errors.values.message || t('errors.value_required')}
        </span>
      )}
    </>
  );
}
