import { useMemo, type MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function SaveWithoutValidationDialog({ onClose }: { onClose: () => void }) {
  const [t] = useTranslation();
  const draftRows = useDataTableStore().use.draftRows();
  const rowErrorList = useDataTableStore().use.rowErrorList();
  const { saveDraftRow } = useDataTableStore().use.actions();

  const rowErrorsCount = useMemo(
    () =>
      Object.keys(rowErrorList).reduce((acc, rowId) => {
        const rowErrors = rowErrorList[rowId];
        if (!rowErrors) return acc;
        let count = 0;
        Object.keys(rowErrors).forEach(() => {
          count += 1;
        });
        return acc + count;
      }, 0),
    [rowErrorList]
  );

  const handleDeleteFieldClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    Object.keys(draftRows).forEach((rowId) => {
      void saveDraftRow(rowId, true);
    });
    onClose();
  };

  return (
    <Dialog open onOpenChange={() => onClose()}>
      <Dialog.Content data-testid="discard-changes-modal">
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>{t('components.data_table.save_without_validation.title')}</Dialog.Title>
          </Dialog.Header>
          <div className="mt-6 space-y-6">
            <p className="rounded-lg bg-destructive p-2 text-destructive-emphasis">
              <Trans
                i18nKey="components.data_table.save_without_validation.error_count"
                values={{
                  count: rowErrorsCount
                }}
              >
                <span className="font-semibold">5</span>
              </Trans>
            </p>

            <p>{t('components.data_table.save_without_validation.description')}</p>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal" data-testid="discard-changes-modal-cancel-button">
              {t('components.data_table.save_without_validation.continue_editing')}
            </Button>
          </Dialog.Close>
          <Dialog.Close asChild>
            <Button
              intent="destructive"
              isLoading={false}
              onClick={handleDeleteFieldClick}
              data-testid="discard-changes-modal-confirm-button"
            >
              {t('components.data_table.save_without_validation.save_with_errors')}
            </Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
