import { type BuilderPageKey } from '@/types/schema/BuilderPage';
import {
  type BuilderViewBase,
  type BuilderViewColumnIcon,
  type BuilderViewLinkColumnType,
  type BuilderViewSourceSchema
} from '@/types/schema/BuilderView';
import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type FormView } from '@/types/schema/views/FormView';

export const DETAILS_VIEW_DIVIDER_INPUT_ID_PREFIX = 'divider_';
export const DETAILS_VIEW_RICH_TEXT_INPUT_ID_PREFIX = 'rich_text_';
export const DETAILS_VIEW_LINK_INPUT_ID_PREFIX = 'scene_link_';

export interface DetailsView extends BuilderViewBase {
  id: string;
  type: 'details';
  columns: DetailsViewOuterColumn[];
  layout: 'full' | 'one-column' | 'two-column' | 'three-column';
  label_format: 'left' | 'right' | 'top' | 'none';
  groups: unknown[];
  inputs: unknown[];
  links: unknown[];
  source: BuilderViewSourceSchema;
  hide_fields: boolean;
  rules?: {
    fields: DetailsViewDisplayRule[];
  };
  design: unknown;
}

export interface DetailsViewOuterColumn {
  id: string;
  groups: DetailsViewOuterColumnGroup[];
  width: number;
}

export interface DetailsViewOuterColumnGroup {
  id: string;
  columns: DetailsViewColumn[];
}

export type DetailsViewColumn = DetailsViewInput[];

export interface DetailsViewInput {
  id: string;
  label: string;
  key: KnackFieldKey;
  type: 'field' | 'scene_link' | 'action_link' | 'delete' | 'divider' | 'special_title';
  name: string;
  scene?: string | DetailsViewChildScenePayload;
  action_rules?: DetailsViewActionRule[];
  conn_separator: string;
  conn_link: string;
  link_type: BuilderViewLinkColumnType;
  link_text: string;
  link_field: string;
  link_design_active: boolean;
  icon: BuilderViewColumnIcon;
  img_gallery: string;
  show_map: boolean;
  map_width: number;
  map_height: number;
  value: string;
  copy: string;
  format: {
    label_custom: boolean;
    label_format: string;
    styles: unknown[];
  };
  connection?: {
    key: KnackFieldKey;
  };
}

export interface DetailsViewChildScenePayload {
  name: string;
  object: KnackObject['key'];
  parent: string;
  views: Array<DetailsView | FormView>;
}

export interface DetailsViewActionRule {
  key?: string;
  link_text: string;
  criteria: KnackCriteria[];
  record_rules: DetailsViewRecordRule[];
  submit_rules: DetailsViewSubmitRule[];
}

export interface DetailsViewRecordRule {
  key: string;
  criteria: null;
  action: string;
  values: Array<{
    field: KnackFieldKey;
    type: string;
    input: string;
    connection_field: KnackFieldKey;
    value: string;
  }>;
}

export interface DetailsViewSubmitRule {
  key: string;
  action: 'message' | 'existing_page' | 'child_page' | 'url';
  criteria?: KnackCriteria[];
  reload_show: boolean;
  message: string;
  reload_auto?: boolean;
  is_default: boolean;
  existing_page?: string;
  scene?: BuilderPageKey | null;
  url?: string;
}

export interface DetailsViewDisplayRule {
  key: KnackFieldKey;
  criteria: KnackCriteria[];
  actions: DetailsViewDisplayRuleAction[];
}

export interface DetailsViewDisplayRuleAction {
  action: DetailsViewDisplayRuleActionType;
  field: KnackFieldKey;
  value: string;
  color?: string;
  bold?: boolean;
  italic?: boolean;
  strikethrough?: boolean;
  icon?: DetailsViewDisplayRuleIcon;
}

export const DETAILS_VIEW_DISPLAY_RULE_ACTION_TYPES = [
  'show',
  'hide',
  'label',
  'bg-color',
  'text-color',
  'text-style'
] as const;

export type DetailsViewDisplayRuleActionType =
  (typeof DETAILS_VIEW_DISPLAY_RULE_ACTION_TYPES)[number];

export type DetailsViewDisplayRuleIcon = {
  icon: string;
  align: 'left' | 'right';
  color: string;
};

export type NewlyInsertedDetailsDisplayRule = {
  key: string;
  insertAction: 'new' | 'duplicate';
};
