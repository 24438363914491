import { useTranslation } from 'react-i18next';
import { Label, Switch } from '@knack/asterisk-react';

import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';

export function ShowTotal() {
  const [t] = useTranslation();

  const { chart, updateChart } = useActiveChartContext();

  return (
    <div className="mb-2 flex items-center gap-2">
      <Switch
        id="show-total"
        checked={chart.layout.shouldShowTotal}
        disabled={chart.layout.pie_type === 'filled'}
        onCheckedChange={(state) => {
          updateChart({
            layout: {
              ...chart.layout,
              shouldShowTotal: state
            }
          });
        }}
      />
      <Label htmlFor="show-total">
        {t('pages.element_settings.report.categories.general.show_total')}
      </Label>
    </div>
  );
}
