// Regex for validating strings that contain numeric values (e.g. Number fields)
export const numericStringRegex = /^[0-9. +,-:]+$/; // Includes negative and decimal numbers

// Regex for validating URL strings as the one provided by Zod is too restrictive
export const urlRegex =
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

export const timeRegex = {
  // Matches time in the format HH:MM
  'HH:MM am': /^([0-9]|0[0-9]|1[0-2]):[0-5][0-9]$/,

  // Matches time in the format HH:MM (military)
  'HH MM (military)': /^([0-9]|[01][0-9]|2[0-3]):([0-5][0-9])$/,
  // TODO: Needed to fix a bug where we are using an invalid regex because this key doesn't exist
  'Ignore Time': /^([01][0-9]|2[0-3]):([0-5][0-9])$/
};

export const dateRegex = {
  // Matches dates in the format MM/DD/YYYY
  'mm/dd/yyyy': /^(0[1-9]|1[1,2])(\/)(0[1-9]|[12][0-9]|3[01])(\/)(19|20)(?:\d{4}|\d{2})$/,

  // Matches dates in the format DD/MM/YYYY
  'dd/mm/yyyy': /^(0[1-9]|[12][0-9]|3[01])(\/)(0[1-9]|1[1,2])(\/)(19|20)(?:\d{4}|\d{2})$/,

  // M D, yyyy uses mm/dd/yyyy for the input.
  'M D, yyyy': /^(0[1-9]|1[1,2])(\/)(0[1-9]|[12][0-9]|3[01])(\/)(19|20)(?:\d{4}|\d{2})$/,
  // TODO: Needed to fix a bug where we are using an invalid regex because this key doesn't exist
  'Ignore Date': /^(0[1-9]|1[1,2])(\/)(0[1-9]|[12][0-9]|3[01])(\/)(19|20)(?:\d{4}|\d{2})$/
};
