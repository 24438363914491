import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LiveAppFrame } from '@/components/LiveAppFrame';
import { LiveAppPreview, SCREEN_SIZES, type ScreenSize } from '@/components/LiveAppPreview';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { usePageEditorMessagingContext } from '@/pages/pages/page-editor/PageEditorMessagingContext';

export function PageEditorLiveAppFrame() {
  const { page, pageSourceObjectRecords, isPreviewMode, setIsPreviewMode } = usePageEditorContext();
  const { registerIframeRef } = usePageEditorMessagingContext();
  const [t] = useTranslation();

  const [previewScreenSize, setPreviewScreenSize] = useState<ScreenSize>(SCREEN_SIZES.desktop);

  let pagePath = page.slug;
  if (pageSourceObjectRecords && pageSourceObjectRecords.length > 0) {
    pagePath += `/${pageSourceObjectRecords[0].id}`;
  }

  if (isPreviewMode) {
    return (
      <LiveAppPreview
        title={page.name}
        closeButtonLabel={t('actions.stop_preview')}
        onClose={() => {
          setPreviewScreenSize(SCREEN_SIZES.desktop);
          setIsPreviewMode(false);
        }}
        onScreenSizeChange={(size) => setPreviewScreenSize(size)}
      >
        <LiveAppFrame
          pagePath={page.slug}
          registerIFrame={registerIframeRef}
          screenSize={previewScreenSize}
          isPreviewMode
        />
      </LiveAppPreview>
    );
  }

  return (
    <LiveAppFrame
      key={page.key}
      pagePath={pagePath}
      registerIFrame={registerIframeRef}
      isPageEditorMode
    />
  );
}
