import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon, HiXMark as RemoveIcon } from 'react-icons/hi2';
import { Fragment } from 'react/jsx-runtime';
import { Button, Form, Input, Select } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import {
  DETAILS_VIEW_DISPLAY_RULE_ACTION_TYPES,
  type DetailsView,
  type DetailsViewDisplayRule,
  type DetailsViewDisplayRuleActionType
} from '@/types/schema/views/DetailsView';
import { cn } from '@/utils/tailwind';
import { FieldIcon } from '@/components/FieldIcon';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import {
  DisplayRuleFormActionPicker,
  TextStyleActionSelect
} from '@/pages/pages/settings-panel/view-settings/details/display-rules/DisplayRuleFormActionPicker';
import { useDisplayRulesHelpers } from './useDisplayRulesHelpers';

interface DetailsDisplayRuleFormActionsProps {
  availableViewFields: KnackField[];
}

export function DisplayRuleFormActions({
  availableViewFields
}: DetailsDisplayRuleFormActionsProps) {
  const [t] = useTranslation();

  const { view, sourceObject } = useActiveViewContext<DetailsView>();
  const { getDefaultDisplayRuleAction } = useDisplayRulesHelpers({ view, sourceObject });

  const {
    control,
    register,
    getValues,
    clearErrors: clearFormErrors,
    formState: { errors }
  } = useFormContext<DetailsViewDisplayRule>();

  const {
    fields: actionFormFields,
    append: appendAction,
    remove: removeAction,
    update: updateAction
  } = useFieldArray({
    name: 'actions',
    control
  });

  return (
    <Form.Section className="rounded-lg bg-subtle p-2">
      {actionFormFields.length === 0 && (
        <p className="text-subtle">{t('components.rules.no_actions')}</p>
      )}

      {actionFormFields.map((actionFormField, actionFormFieldIndex) => {
        const isFieldInView = availableViewFields.some(
          (field) => field.key === actionFormField.field
        );

        return (
          <Fragment key={actionFormField.id}>
            {actionFormFieldIndex > 0 && (
              <p className="my-3 text-xs font-medium">{t('components.rules.and_uppercase')}</p>
            )}

            <div className="flex">
              <div className="-m-1 flex flex-1 flex-wrap gap-2 overflow-hidden p-1">
                <div
                  className={cn('flex-1', {
                    'basis-full': actionFormField.action === 'label'
                  })}
                >
                  <Controller
                    control={control}
                    name={`actions.${actionFormFieldIndex}.action`}
                    render={({ field: { value: selectedActionType } }) => (
                      <Select
                        value={selectedActionType}
                        onValueChange={(newActionType: DetailsViewDisplayRuleActionType) => {
                          updateAction(actionFormFieldIndex, {
                            ...getValues(`actions.${actionFormFieldIndex}`),
                            action: newActionType
                          });
                          clearFormErrors();
                        }}
                      >
                        <Select.Trigger placeholder={t('actions.select')} className="w-full" />
                        <Select.Content>
                          {DETAILS_VIEW_DISPLAY_RULE_ACTION_TYPES.map((actionType: string) => (
                            <Select.Item key={actionType} value={actionType}>
                              {t(`components.display_rule_card.actions.${snakeCase(actionType)}`)}
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select>
                    )}
                  />
                </div>
                <div className="flex-1">
                  <div className="flex items-center">
                    {actionFormField.action === 'label' && (
                      <span className="mr-2 basis-8">
                        {t('components.display_rule_card.label_action_for')}
                      </span>
                    )}

                    <div className="flex-1">
                      <Controller
                        control={control}
                        name={`actions.${actionFormFieldIndex}.field`}
                        render={({ field: { value: fieldKey } }) => (
                          <Select
                            value={isFieldInView ? fieldKey : undefined}
                            onValueChange={(newFieldKey: KnackFieldKey) => {
                              updateAction(actionFormFieldIndex, {
                                ...getValues(`actions.${actionFormFieldIndex}`),
                                field: newFieldKey
                              });
                              clearFormErrors();
                            }}
                            disabled={availableViewFields.length === 0}
                          >
                            <Select.Trigger
                              placeholder={t('actions.select')}
                              className={cn('w-full', {
                                'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                                  errors?.actions?.[actionFormFieldIndex]?.field
                              })}
                            />
                            <Select.Content>
                              {availableViewFields.map((field) => (
                                <Select.Item key={field.key} value={field.key}>
                                  <span className="flex items-center">
                                    <FieldIcon
                                      className="mr-2 shrink-0 text-subtle"
                                      size={16}
                                      name={field.type}
                                    />
                                    {field.name}
                                  </span>
                                </Select.Item>
                              ))}
                            </Select.Content>
                          </Select>
                        )}
                      />

                      {errors?.actions?.[actionFormFieldIndex]?.field && (
                        <p className="mt-1 text-sm text-destructive">
                          {errors.actions[actionFormFieldIndex]?.field?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                {actionFormField.action === 'label' && (
                  <div className="basis-full">
                    <div className="flex items-center">
                      <span className="mr-2 basis-8">
                        {t('components.display_rule_card.label_action_to')}
                      </span>
                      <Input
                        className="flex-1 text-sm"
                        intent={
                          errors?.actions?.[actionFormFieldIndex]?.value ? 'destructive' : undefined
                        }
                        placeholder={t('actions.enter_value')}
                        disabled={!isFieldInView}
                        {...register(`actions.${actionFormFieldIndex}.value`)}
                      />
                    </div>

                    {errors?.actions?.[actionFormFieldIndex]?.value && (
                      <p className="ml-10 mt-1 text-sm text-destructive">
                        {errors.actions[actionFormFieldIndex]?.value?.message}
                      </p>
                    )}
                  </div>
                )}

                {(actionFormField.action === 'bg-color' ||
                  actionFormField.action === 'text-color') && (
                  <DisplayRuleFormActionPicker
                    action={actionFormField.action}
                    formName={`actions.${actionFormFieldIndex}`}
                  />
                )}

                {actionFormField.action === 'text-style' && (
                  <div className="w-full">
                    <TextStyleActionSelect formName={`actions.${actionFormFieldIndex}`} />
                  </div>
                )}
              </div>

              {actionFormFields.length > 1 && (
                <Button
                  intent="minimal"
                  aria-label={t('components.rules.delete_action')}
                  size="xs"
                  className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
                  onClick={() => removeAction(actionFormFieldIndex)}
                >
                  <RemoveIcon size={16} />
                </Button>
              )}
            </div>
          </Fragment>
        );
      })}

      {availableViewFields.length > 0 && (
        <Button
          intent="secondary"
          aria-label={t('components.rules.add_action')}
          className="mt-3"
          onClick={() => {
            const defaultAction = getDefaultDisplayRuleAction();
            if (defaultAction) {
              appendAction(defaultAction);
            }
          }}
        >
          <Button.Icon icon={PlusIcon} position="left" />
          {t('components.rules.action')}
        </Button>
      )}
    </Form.Section>
  );
}
