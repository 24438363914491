import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@knack/asterisk-react';

interface DisplayRuleColorPickerProps {
  value: string;
  onChange: (value: string) => void;
}

export function DisplayRuleColorPicker({ value, onChange }: DisplayRuleColorPickerProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <div className="flex w-full items-center rounded-lg border border-default bg-input">
      <button
        type="button"
        className="h-auto rounded-l-lg border-r border-default p-2 hover:cursor-pointer hover:bg-muted"
        onClick={handleClick}
        aria-label={t('compoents.color_picker.color_picker')}
      >
        <div
          className="border-muted size-6 rounded-md border p-px py-0"
          style={{ backgroundColor: value }}
        >
          <input
            ref={inputRef}
            type="color"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className="invisible"
            tabIndex={-1}
          />
        </div>
      </button>
      <Input
        className="rounded-l-none border-0 text-sm focus:-outline-offset-1"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}
