import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';

import {
  DATE_TIME_GROUP_BY_LIMIT_TYPES,
  DATE_TIME_GROUP_BY_RANGE_TYPES,
  type DateTimeGroupByLimitType,
  type DateTimeGroupByRangeType,
  type ReportViewChartGroup
} from '@/types/schema/views/ReportView';
import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';
import { CategoryGroupDateByInputs } from '@/pages/pages/settings-panel/view-settings/report/data-display/sorting/CategoryGroupDateByInputs';

type CategoryGroupDateByProps = {
  group: ReportViewChartGroup;
  index: number;
};

export function CategoryGroupDateBy({ group, index }: CategoryGroupDateByProps) {
  const [t] = useTranslation();

  const { chart, updateChart } = useActiveChartContext();

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="group-date-by-range">
          {t('pages.element_settings.report.categories.data_display.sorting.group_dates_by_label')}
        </Label>
        <Select
          onValueChange={(value) => {
            const newGroups = [...chart.groups];
            newGroups[index].range = value as DateTimeGroupByRangeType;

            updateChart({
              groups: newGroups
            });
          }}
          defaultValue={group.range || DATE_TIME_GROUP_BY_RANGE_TYPES[0]}
        >
          <Select.Trigger
            id="group-date-by-range"
            placeholder={t('actions.select')}
            className="w-full truncate"
            data-testid={`group-date-by-range-selector-${index}`}
          />
          <Select.Content>
            {DATE_TIME_GROUP_BY_RANGE_TYPES.map((rangeType) => (
              <Select.Item
                key={rangeType}
                value={rangeType}
                data-testid={`group-date-by-range-option-${rangeType}-${index}`}
              >
                {t(`pages.element_settings.report.categories.data_display.sorting.${rangeType}`)}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>

      <div className="space-y-2">
        <Label htmlFor="group-date-by-limit">
          {t('pages.element_settings.report.categories.data_display.sorting.show_label')}
        </Label>
        <Select
          onValueChange={(value) => {
            const newGroups = [...chart.groups];
            newGroups[index].limit = value as DateTimeGroupByLimitType;

            updateChart({
              groups: newGroups
            });
          }}
          defaultValue={group.limit || DATE_TIME_GROUP_BY_LIMIT_TYPES[0]}
        >
          <Select.Trigger
            id="group-date-by-limit"
            placeholder={t('actions.select')}
            className="w-full truncate"
            data-testid={`group-date-by-limit-selector-${index}`}
          />
          <Select.Content>
            {DATE_TIME_GROUP_BY_LIMIT_TYPES.map((limitType) => (
              <Select.Item
                key={limitType}
                value={limitType}
                data-testid={`group-date-by-limit-option-${limitType}-${index}`}
              >
                {t(`pages.element_settings.report.categories.data_display.sorting.${limitType}`)}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
        <CategoryGroupDateByInputs group={group} index={index} />
      </div>
    </div>
  );
}
