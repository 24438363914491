export const sendMessageToIframe = (
  message: unknown,
  iframeLoaded: boolean,
  iframeRef: HTMLIFrameElement | null
) => {
  if (!iframeLoaded || !iframeRef || !iframeRef.contentWindow) {
    return;
  }

  const frameWindow = iframeRef.contentWindow;
  frameWindow.postMessage(message, import.meta.env.PUBLIC_LIVE_APP_URL);
};
