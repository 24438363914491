import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance } from '@/utils/axiosConfig';

type ApiUsageResponse = {
  results: {
    count: string;
    date: string;
    reset_date: string;
  }[];
};

async function getApiUsage(accountId: string) {
  const { data } = await axiosInstance.get<ApiUsageResponse>(
    `/v1/accounts/${accountId}/usage/api`,
    { withCredentials: true }
  );

  return data;
}

export function useApiUsageQuery() {
  const session = useSession();
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return useQuery({
    queryKey: [queryKeys.apiUsage, accountSlug, appSlug],
    queryFn: () => getApiUsage(session.account.id),
    staleTime: 1000 * 60 * 5
  });
}
