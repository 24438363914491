import { forwardRef, type InputHTMLAttributes } from 'react';

import { cn } from '@/utils/tailwind';

interface PasswordInputProps extends InputHTMLAttributes<HTMLInputElement> {
  intent?: 'destructive' | 'default';
}

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ className, intent = 'default', ...props }, ref) => (
    <input
      ref={ref}
      spellCheck
      type="password"
      className={cn(
        'min-h-full min-w-full resize border-0 bg-base p-2 ring-2 ring-black focus:ring-2 focus:ring-black',
        {
          'ring-2 ring-destructive focus:ring-destructive': intent === 'destructive'
        },
        className
      )}
      {...props}
    />
  )
);

PasswordInput.displayName = 'PasswordInput';
