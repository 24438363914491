import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { autoUpdate, flip, offset, size, useFloating } from '@floating-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { RichTextEditor } from '@knack/asterisk-react';
import { type Editor } from '@tiptap/core';
import { type z } from 'zod';

import { cn } from '@/utils/tailwind';
import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { type RichTextField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { getRichTextSchema } from '@/components/data-table/display/fields/rich-text/RichTextSchema';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function RichTextEdit(props: FieldRenderProps<RichTextField>) {
  const { rawValue, fieldId, rowId, type } = props;
  const [isFocused, setIsFocused] = useState(false);

  const editorRef = useRef<Editor>();
  const { moveSelectionHorizontal } = useSelectionStrategy();

  const selectedCell = useDataTableStore().use.selectedCell();
  const cellErrors = useDataTableStore().use.cellErrors(rowId, fieldId);
  const useDataTableState = useDataTableStore();

  const { saveCell, setIsEditing, clearCellErrors } = useDataTableStore().use.actions();

  if (!selectedCell) throw new Error('No selected cell');

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);

  const formSchema = getRichTextSchema(currentField);

  type FormSchema = z.infer<typeof formSchema>;

  const {
    getValues,
    getFieldState,
    setValue,
    formState: { errors, isValid }
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      richText: rawValue
    }
  });

  const saveForm = useCallback(() => {
    const formState = getFieldState('richText');
    if (formState.isDirty && !formState.invalid) {
      const newValue = getValues('richText');

      void saveCell(rowId, fieldId, newValue, {
        value: newValue,
        rawValue: newValue
      });
    }
  }, [fieldId, getFieldState, getValues, rowId, saveCell]);

  const handleKeyDown = (view: unknown, e: KeyboardEvent) => {
    // Clean server error on change
    clearCellErrors(rowId, fieldId);

    if (e.key === 'Escape') {
      setIsEditing(false);
      saveForm();
      e.preventDefault();
    }

    if (e.key === 'Tab' && isValid) {
      moveSelectionHorizontal('right');
      e.preventDefault();
    }
    if (e.key === 'Enter' && isValid) {
      if (!selectedCell.isEditing) {
        e.preventDefault();
        setIsEditing(true);
      }
    }
  };

  const handleClick = () => {
    if (!selectedCell.isEditing) {
      setIsEditing(true);
    }
  };

  useEffect(
    () => () => {
      saveForm();
      setIsEditing(false);
    },
    [saveForm, setIsEditing]
  );

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [
      offset((state) => ({ mainAxis: -state.rects.reference.height })),
      flip({
        crossAxis: true
      }),
      size({
        padding: 25,
        apply({ availableHeight, availableWidth, elements }) {
          // This function is cached, so we need the zustand state directly to update this correctly
          if (!useDataTableState.getState().selectedCell?.isEditing) {
            elements.floating.style.height = '100%';
            elements.floating.style.width = '100%';
            return;
          }

          elements.floating.style.height = `${availableHeight}px`;
          elements.floating.style.width = `${availableWidth}px`;
        }
      })
    ],
    whileElementsMounted: autoUpdate
  });

  return (
    <div className="flex size-full">
      <div ref={refs.setReference} className="flex size-full" />
      <div
        ref={refs.setFloating}
        style={floatingStyles}
        className={cn('size-full', {
          'max-h-[400px] min-h-[200px] min-w-[320px] max-w-full': selectedCell.isEditing,
          'ring-destructive': errors?.richText?.message || cellErrors
        })}
      >
        <RichTextEditor
          data-testid={`edit-rich-text-input-${rowId}-${fieldId}`}
          className={cn('size-full !rounded-none bg-base ring-2 ring-black', {
            'cursor-pointer opacity-0': !selectedCell.isEditing,
            // We don't have ring-emphasis, we should either stop using borders or add the missing color.
            // This is an edge case where we want the black border to be in internal inputs instead of the external div.
            'ring-[#898088]': !isFocused
          })}
          menuBarClassName={cn('!rounded-t-none', {
            hidden: !selectedCell.isEditing
          })}
          showOverflowGradient={false}
          footerClassName={cn('!rounded-none', {
            hidden: !selectedCell.isEditing
          })}
          editorProps={{
            handleKeyDown,
            handleClick,
            attributes: {
              class: `size-full`
            }
          }}
          onCreate={({ editor }) => {
            // We save the editor instance so we can use it anywhere
            editorRef.current = editor;
            editorRef.current?.commands.focus('end', { scrollIntoView: false });
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onUpdate={({ editor }) => {
            if (!selectedCell.isEditing) {
              setIsEditing(true);
            }

            const content = editor.isEmpty ? '' : editor.getHTML();
            setValue('richText', content, {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true
            });
          }}
          content={rawValue}
        />

        <CellErrors
          rowId={rowId}
          fieldId={fieldId}
          testIdPrefix="rich-text-edit-error"
          additionalErrors={selectedCell.isEditing && errors?.richText ? [errors.richText] : []}
        />
      </div>
    </div>
  );
}
