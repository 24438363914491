import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label } from '@knack/asterisk-react';

import { type AddressSchema } from '@/components/data-table/display/fields/address/AddressEditInput';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

type LatLongEditInputProps = {
  rowId: string;
  fieldId: string;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onValid: (params: AddressSchema) => void;
};

export function LatLongEditInput({
  rowId,
  fieldId,
  handleKeyDown,
  onValid
}: LatLongEditInputProps) {
  const [t] = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useFormContext();

  const selectedCell = useDataTableStore().use.selectedCell();
  const { setIsEditing } = useDataTableStore().use.actions();

  if (!selectedCell) throw new Error('No selected cell');

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-1">
        <Label className="font-medium">
          {t('components.data_table.attributes.field_labels.address.labels.latitude')}
        </Label>
        <Input
          type="number"
          className="h-full resize-none rounded border-2 bg-base ring-0 [appearance:textfield] focus:border-current focus:outline-0 focus:ring-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          onKeyDown={handleKeyDown}
          data-testid={`edit-address-latitude-input-${rowId}-${fieldId}`}
          {...register('latitude', {
            onChange: async () => {
              if (!selectedCell?.isEditing) {
                setIsEditing(true);
              }

              await handleSubmit(onValid)();
            }
          })}
        />
        {errors.latitude && (
          <div className="w-20 min-w-full bg-action-destructive-primary">
            <p
              key={`latitude-edit-address-error-${rowId}-${fieldId}`}
              data-testid={`latitude-edit-address-error-${rowId}-${fieldId}`}
              className="m-1 text-inverted"
            >
              {errors?.latitude?.message as string}
            </p>
          </div>
        )}
      </div>

      <div className="flex flex-col gap-1">
        <Label className="font-medium">
          {t('components.data_table.attributes.field_labels.address.labels.longitude')}
        </Label>
        <input
          type="number"
          className="h-full resize-none rounded border-2 bg-base ring-0 [appearance:textfield] focus:border-current focus:ring-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          onKeyDown={handleKeyDown}
          data-testid={`edit-address-longitude-input-${rowId}-${fieldId}`}
          {...register('longitude', {
            onChange: async () => {
              if (!selectedCell?.isEditing) {
                setIsEditing(true);
              }

              await handleSubmit(onValid)();
            }
          })}
        />
        {errors.longitude && (
          <div className="w-20 min-w-full bg-action-destructive-primary">
            <p
              key={`longitude-edit-address-error-${rowId}-${fieldId}`}
              data-testid={`longitude-edit-address-error-${rowId}-${fieldId}`}
              className="m-1 text-inverted"
            >
              {errors?.longitude?.message as string}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
