import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiTrash as DeleteIcon,
  HiDocumentDuplicate as DuplicateIcon,
  HiPencil as PencilIcon
} from 'react-icons/hi2';
import { MdDragIndicator as DragIcon } from 'react-icons/md';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Dialog, useToast } from '@knack/asterisk-react';

import { type KnackField, type ValidationRule } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useFieldMutation } from '@/hooks/api/mutations/useFieldMutation';
import { cn } from '@/utils/tailwind';
import { TextTooltip } from '@/components/TextTooltip';
import { ValidationRuleCard } from '@/components/ValidationRuleCard';
import { ValidationRuleForm } from './ValidationRuleForm';

export function ValidationRuleCardWrapper({
  rule,
  table,
  field,
  handleRenderDuplicationForm
}: {
  rule: ValidationRule;
  table: KnackObject;
  field: KnackField;
  handleRenderDuplicationForm: ({
    rule,
    fieldKey
  }: {
    rule: ValidationRule;
    fieldKey: string;
  }) => void;
}) {
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const { mutate: updateField } = useFieldMutation();
  const [shouldRenderEditForm, setShouldRenderEditForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: rule.key
  });

  const sortableItemStyles = {
    transform: CSS.Translate.toString(transform),
    transition
  };

  const handleDeleteRule = () => {
    if (!field.validation) return;

    updateField(
      {
        tableKey: table.key,
        updatedField: {
          ...field,
          validation: field.validation.filter((r) => r.key !== rule.key)
        } as KnackField // TODO: FE-1944 iteratively refine type while working on additional field types
      },
      {
        onSuccess: () => {
          presentToast({
            title: t('components.data_table.right_sidebar.rule_deleted')
          });
          setShowModal(false);
        },
        onError: () => {
          presentToast({
            title: t('components.data_table.right_sidebar.rule_delete_error')
          });
        }
      }
    );
  };

  return (
    <div
      data-testid={`validation-rule-card-wrapper-${field.key}-${rule.key}`}
      ref={setNodeRef}
      style={sortableItemStyles}
    >
      {!shouldRenderEditForm && (
        <div className="group relative">
          {field.validation && field.validation.length > 1 && (
            <div
              data-testid={`validation-rule-card-rearrange-icon-${field.key}-${rule.key}`}
              className={cn(
                'invisible absolute -left-2.5 top-4 cursor-grab rounded-l align-middle active:cursor-grabbing group-hover:visible group-hover:bg-subtle',
                {
                  'group-hover:visible': !transform,
                  visible: isDragging
                }
              )}
              {...listeners}
              {...attributes}
            >
              <DragIcon size={20} />
            </div>
          )}
          <ValidationRuleCard className="group-hover:bg-subtle" rule={rule} table={table}>
            <span
              className="invisible absolute right-4 top-4 space-x-2 bg-subtle group-hover:visible"
              data-testid="action-buttons"
            >
              <TextTooltip label={t('actions.edit')}>
                <Button
                  intent="minimal"
                  aria-label={t('components.validation_rule_card.edit_rule')}
                  size="xs"
                  className="text-default hover:bg-muted"
                  data-testid="edit-rule-button"
                  onClick={() => setShouldRenderEditForm(true)}
                >
                  <PencilIcon size={14} />
                </Button>
              </TextTooltip>
              <TextTooltip label={t('actions.duplicate')}>
                <Button
                  intent="minimal"
                  aria-label={t('components.validation_rule_card.duplicate_rule')}
                  size="xs"
                  className="text-default hover:bg-muted"
                  data-testid="copy-rule-button"
                  onClick={() => handleRenderDuplicationForm({ rule, fieldKey: field.key })}
                >
                  <DuplicateIcon size={14} />
                </Button>
              </TextTooltip>
              <TextTooltip label={t('actions.delete')}>
                <Button
                  intent="minimal"
                  aria-label={t('components.validation_rule_card.delete_rule')}
                  size="xs"
                  className="text-default hover:bg-muted"
                  data-testid="delete-rule-button"
                  onClick={() => setShowModal(true)}
                >
                  <DeleteIcon size={14} />
                </Button>
              </TextTooltip>
            </span>
          </ValidationRuleCard>
        </div>
      )}

      {shouldRenderEditForm && (
        <div className="relative">
          <ValidationRuleForm
            table={table}
            setShouldRenderForm={setShouldRenderEditForm}
            fieldKey={field.key}
            ruleToEdit={rule}
          />
          <span className="absolute right-4 top-4">
            <TextTooltip label={t('actions.delete')}>
              <Button
                intent="minimal"
                aria-label={t('components.validation_rule_card.delete_rule')}
                size="xs"
                className="text-default hover:bg-muted"
                data-testid="delete-rule-button"
                onClick={() => setShowModal(true)}
              >
                <DeleteIcon size={14} />
              </Button>
            </TextTooltip>
          </span>
        </div>
      )}

      <Dialog open={showModal} onOpenChange={setShowModal}>
        <Dialog.Content data-testid="delete-rule-modal">
          <Dialog.MainContent className="pb-0">
            <Dialog.Header>
              <Dialog.Title className="text-xl font-medium capitalize leading-6">
                {t('components.data_table.right_sidebar.delete_rule')}
              </Dialog.Title>
            </Dialog.Header>
            <div className="mt-6">
              <p>{t('components.data_table.right_sidebar.delete_rule_text')}</p>
              <ValidationRuleCard className="mt-4" rule={rule} table={table} />
            </div>
          </Dialog.MainContent>
          <Dialog.Footer className="gap-2">
            <Dialog.Close asChild>
              <Button intent="minimal">{t('actions.cancel')}</Button>
            </Dialog.Close>
            <Button intent="destructive" onClick={handleDeleteRule}>
              {t('actions.delete')}
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    </div>
  );
}
