import { useTranslation } from 'react-i18next';
import { HiPencil as EditIcon } from 'react-icons/hi2';
import { Button, Checkbox, Label } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { cn } from '@/utils/tailwind';
import { FieldIcon } from '@/components/FieldIcon';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';

interface TableViewColumnFieldItemProps {
  field: KnackField;
  columnId?: string;
  isChecked?: boolean;
  isDisallowed?: boolean;
  onCheck?: (isChecked: boolean) => void;
}

export function TableViewColumnFieldItem({
  field,
  columnId,
  onCheck,
  isChecked = false,
  isDisallowed = false
}: TableViewColumnFieldItemProps) {
  const [t] = useTranslation();
  const { setIsViewInputEditMode, setViewInputId } = useViewInputEditModeContext();

  const handleClickEditColumn = () => {
    if (!columnId) return;

    setIsViewInputEditMode(true);
    setViewInputId(columnId);
  };

  return (
    <div
      key={field.key}
      className={cn(
        'group flex h-9 items-center justify-between rounded-lg border border-default bg-card py-1 pl-2.5 pr-2 hover:border-emphasis hover:bg-muted',
        {
          'bg-muted hover:border-default hover:bg-muted': isDisallowed
        }
      )}
    >
      <Label
        className="-ml-1 flex items-center truncate py-1 pl-1 text-emphasis"
        htmlFor={`field-checkbox-${field.key}`}
      >
        <Checkbox
          id={`field-checkbox-${field.key}`}
          disabled={isDisallowed}
          className="mr-2"
          checked={isChecked}
          onCheckedChange={onCheck}
        />
        <FieldIcon size={16} className="text-subtle" name={field.type} />
        <span className="ml-2 truncate">{field.name}</span>
      </Label>

      {!isDisallowed && (
        <Button
          intent="minimal"
          size="xs"
          className="hidden size-6 rounded-md text-subtle group-hover:flex"
          onClick={() => handleClickEditColumn()}
        >
          <EditIcon size={14} />
          <span className="sr-only">
            {t(
              'pages.element_settings.form.categories.form_fields.field_management.edit_field_input'
            )}
          </span>
        </Button>
      )}
    </div>
  );
}
