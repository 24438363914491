import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import { type TableView } from '@/types/schema/views/TableView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewSettingsFiltering } from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsFiltering';
import { DataDisplayGeneralSettings } from '@/pages/pages/settings-panel/view-settings/table/data-display/DataDisplayGeneralSettings';
import { TableFieldManagement } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/TableFieldManagement';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function TableDataDisplaySettingsCategory() {
  const [t] = useTranslation();
  const { view, sourceObject } = useActiveViewContext<TableView>();
  const updateViewSchema = useUpdateView<TableView>();

  const tabListItems: TabsListItem[] = [
    {
      value: 'general',
      children: t('keywords.general')
    },
    {
      value: 'fields',
      children: t('keywords.fields')
    },
    {
      value: 'filtering',
      children: t('pages.element_settings.common.categories.data_display.filtering')
    }
  ];

  const tableViewFilteringOptions = {
    filter_type: view.filter_type,
    filter_fields: view.filter_fields,
    preset_filters: view.preset_filters,
    menu_filters: view.menu_filters
  };

  return (
    <Tabs defaultValue="general">
      <Tabs.List items={tabListItems} intent="page" shouldDisableResponsive shouldUseGradient />
      <Tabs.Content value="general" className="p-0 text-default">
        <DataDisplayGeneralSettings />
      </Tabs.Content>
      <Tabs.Content value="fields" className="p-0 text-default">
        <TableFieldManagement />
      </Tabs.Content>
      <Tabs.Content value="filtering" className="p-0 text-default">
        <ViewSettingsFiltering
          sourceObject={sourceObject}
          updateViewSchema={updateViewSchema}
          options={tableViewFilteringOptions}
        />
      </Tabs.Content>
    </Tabs>
  );
}
