import { useState } from 'react';
import { HiChevronDown as ChevronDownIcon, HiPlus as PlusIcon } from 'react-icons/hi2';
import { Button, Chip, Collapsible } from '@knack/asterisk-react';

import { useLeftPanelContext } from '@/pages/pages/LeftPanelContext';
import { NewPageDialog } from '@/pages/pages/page-tree/new-page-dropdown-menu/NewPageDialog';
import { NewPageDropdownMenu } from '@/pages/pages/page-tree/new-page-dropdown-menu/NewPageDropdownMenu';

export function CollapsibleSectionHeader({
  sectionName,
  isUserPages
}: {
  sectionName: string;
  isUserPages?: boolean;
}) {
  const [isNewPageDropdownOpen, setIsNewPageDropdownOpen] = useState(false);
  const [isNewUserPageDialogOpen, setIsNewUserPageDialogOpen] = useState(false);
  const { userPages, nonUserRootPages } = useLeftPanelContext();
  const pages = isUserPages ? userPages : nonUserRootPages;
  const pagesLength = pages.length > 999 ? '999+' : String(pages.length);

  return (
    <div className="flex w-full items-center justify-between gap-3">
      <div className="flex items-center">
        <span className="mr-1">{sectionName}</span>
        <Chip className="m-0" text={pagesLength} size="sm" />
      </div>
      <div className="flex items-center">
        {isUserPages ? (
          <>
            <Button intent="secondary" size="xs" onClick={() => setIsNewUserPageDialogOpen(true)}>
              <Button.Icon icon={PlusIcon} />
            </Button>
            <NewPageDialog
              isDialogOpen={isNewUserPageDialogOpen}
              setIsDialogOpen={setIsNewUserPageDialogOpen}
              newPageType="user"
            />
          </>
        ) : (
          <NewPageDropdownMenu
            onDropdownMenuOpenChange={() => setIsNewPageDropdownOpen(!isNewPageDropdownOpen)}
            triggerElement={
              <Button intent="secondary" size="xs">
                <Button.Icon icon={PlusIcon} />
              </Button>
            }
          />
        )}
        <Collapsible.Trigger className="group ml-2" data-testid="page-tree-collapsible-trigger">
          <ChevronDownIcon
            size={20}
            className="transition-transform duration-300 group-data-[state=open]:rotate-180"
          />
        </Collapsible.Trigger>
      </div>
    </div>
  );
}
