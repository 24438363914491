import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';

import { queryKeys } from '@/hooks/api/queryKeys';
import { ApiWrapper } from '@/utils/api-wrapper';
import { getApplicationBasePathSegments } from '@/utils/application';
import { setApiBaseUrls, setAxiosHeaders } from '@/utils/axiosConfig';

let isInitialLoad = true;

async function getApplication(accountSlug: string, appSlug: string) {
  const application = await ApiWrapper.builder.getApplication(accountSlug, appSlug);

  if (isInitialLoad && application) {
    // This is needed for axios calls that are not made via the api-wrapper (e.g. dashboard urls). The api-wrapper handles this internally.
    setAxiosHeaders({ 'x-knack-application-id': application.id });
    setApiBaseUrls(
      application.settings.clientSubdomainMap.read[0],
      application.settings.clientSubdomainMap.write[0]
    );

    isInitialLoad = false;
  }

  // IMPORTANT: We rename the table Accounts to All Users for the UI
  application.objects = application.objects.map((table) => {
    if (table.profile_key === 'all_users') {
      table.name = t('keywords.all_users');
    }
    return table;
  });

  return application;
}

export function useApplicationQuery(enabled = true) {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return useQuery({
    queryKey: [queryKeys.application, accountSlug, appSlug],
    queryFn: () => getApplication(accountSlug, appSlug),
    staleTime: 1000 * 60 * 5,
    retry: false,
    enabled
  });
}
