import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi';
import { HiTrash as RemoveIcon } from 'react-icons/hi2';
import { Button, Input, Label } from '@knack/asterisk-react';

import type { ReportView, ReportViewCalculation } from '@/types/schema/views/ReportView';
import { NUMERIC_FIELD_TYPES } from '@/hooks/helpers/useFieldHelpers';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';
import {
  CalculationFieldSelector,
  type CalculationFieldSelectorItem
} from '@/pages/pages/settings-panel/view-settings/report/data-display/CalculationFieldSelector';
import { CalculationSelector } from '@/pages/pages/settings-panel/view-settings/report/data-display/CalculationSelector';
import { ReportFiltersDialog } from '@/pages/pages/settings-panel/view-settings/report/data-display/filters/ReportDataDisplayFiltersDialog';
import { ReportFiltersGroupsCard } from '@/pages/pages/settings-panel/view-settings/report/data-display/filters/ReportFiltersGroupsCard';

const MAX_CALCULATIONS_ALLOWED = 5;

export function ReportDataDisplayCalculation() {
  const [t] = useTranslation();

  const { sourceObject } = useActiveViewContext<ReportView>();
  const { chart, updateChart } = useActiveChartContext();

  const { groups, calculations } = chart;

  const isGroupedByTotalsOrAverages = chart.groups.some(({ type }) =>
    ['totals', 'averages'].includes(type)
  );

  // Get all numeric field keys from the source object
  const availableNumericFields: CalculationFieldSelectorItem[] = sourceObject.fields
    .filter((field) => NUMERIC_FIELD_TYPES.includes(field.type))
    .map((field) => ({
      key: field.key,
      name: field.name,
      type: field.type
    }));

  // Add a count field if is not grouped by totals or averages
  if (!isGroupedByTotalsOrAverages) {
    availableNumericFields.push({
      key: 'count',
      name: t('pages.element_settings.report.categories.data_display.total_record_count'),
      type: 'count'
    });
  }

  const defaultCalculation: ReportViewCalculation = {
    calculation: 'sum',
    field: availableNumericFields[0]?.key,
    label: availableNumericFields[0]?.name
  };

  return (
    <BuilderAccordion.Item
      isDefaultOpen
      label={t('pages.element_settings.report.categories.data_display.y_axis')}
    >
      {calculations.map((calculation, index) => (
        <BuilderAccordion.Item
          key={calculation.field}
          isDefaultOpen
          label={t('pages.element_settings.report.categories.data_display.calculation_n', {
            count: index + 1
          })}
          className="mb-4 rounded-xl bg-subtle p-2"
          removeItemButton={
            calculations.length > 1 ? (
              <Button
                intent="minimal"
                onClick={() => {
                  updateChart({
                    calculations: calculations.filter((_, i) => i !== index)
                  });
                }}
              >
                <RemoveIcon className="text-subtle" />
              </Button>
            ) : undefined
          }
        >
          <div className="mb-4">
            <Label className="mb-2 block" htmlFor="calculation-label">
              {t('pages.element_settings.report.categories.data_display.label')}
            </Label>
            <Input
              id="calculation-label"
              value={calculation.label}
              onChange={(e) => {
                const newCalculations = [...calculations];
                newCalculations[index].label = e.target.value;

                updateChart({
                  calculations: newCalculations
                });
              }}
            />
          </div>
          <div>
            <CalculationFieldSelector
              id={`calculation-field-selector-${index}`}
              availableNumericFields={availableNumericFields}
              defaultValue={calculation.field}
              onSelectCalculationField={(calculationField) => {
                const newCalculations = [...calculations];

                newCalculations[index].field = calculationField.key;
                newCalculations[index].field = calculationField.key;
                if (calculationField.key === 'count') {
                  newCalculations[index].label = calculationField.name;
                  newCalculations[index].calculation = 'count';
                } else {
                  newCalculations[index].label = calculationField.name;
                  newCalculations[index].calculation = 'sum';
                }

                updateChart({
                  calculations: newCalculations
                });
              }}
            />
            {!isGroupedByTotalsOrAverages && calculation.calculation !== 'count' && (
              <>
                <div className="mb-2 mt-4">
                  {t('pages.element_settings.report.categories.data_display.calculation')}
                </div>
                <CalculationSelector
                  id={`calculation-selector-${index}`}
                  defaultValue={calculation.calculation}
                  onValueChange={(value) => {
                    const newCalculations = [...calculations];
                    newCalculations[index].calculation = value;

                    updateChart({
                      calculations: newCalculations
                    });
                  }}
                />
              </>
            )}
            <div className="mt-4 flex flex-col">
              <Label className="mb-2">
                {t('pages.element_settings.report.categories.data_display.filters.filters_label')}
              </Label>
              <div className="space-y-4">
                <ReportFiltersGroupsCard
                  sourceObject={sourceObject}
                  filters={calculation.filters ?? []}
                />
                <ReportFiltersDialog
                  sourceObject={sourceObject}
                  filters={calculation.filters ?? []}
                  onFormSubmit={({ filters }) => {
                    const newCalculations = [...calculations];
                    newCalculations[index].filters = filters;

                    updateChart({
                      calculations: newCalculations
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </BuilderAccordion.Item>
      ))}
      {chart.type !== 'pie' && (
        <Button
          intent="secondary"
          disabled={groups.length > 1 || calculations.length >= MAX_CALCULATIONS_ALLOWED}
          onClick={() => {
            updateChart({
              calculations: [...calculations, defaultCalculation]
            });
          }}
        >
          <PlusIcon size={16} className="mr-1" />
          {t('pages.element_settings.report.categories.data_display.y_axis_calculation')}
        </Button>
      )}
    </BuilderAccordion.Item>
  );
}
