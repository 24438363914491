import { useEffect } from 'react';
import { Controller, useFormContext, type Path } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';

import { type KnackConnection, type KnackObject } from '@/types/schema/KnackObject';
import { type RecordRule, type RecordRuleConnectionKey } from '@/types/schema/rules/RecordRule';
import { type KnackTask } from '@/types/schema/tasks/KnackTask';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { useRecordRuleHelpers } from '@/hooks/useRecordRuleHelpers';

type BuilderTasksActionPath = keyof Pick<KnackTask, 'action'>;
type RecordRuleConnectionPath = keyof Pick<RecordRule, 'connection'>;

type BuilderRecordRuleConnectionPath =
  | RecordRuleConnectionPath
  | Extract<Path<Pick<KnackTask, 'action'>>, 'action.connection'>;

interface RecordRuleConnectionSelectProps {
  tableConnections: KnackConnection[];
  sourceObject: KnackObject;
  formFieldNamePrefix?: BuilderTasksActionPath;
  isInsert: boolean;
}

export function RecordRuleConnectionSelect({
  tableConnections,
  sourceObject,
  formFieldNamePrefix,
  isInsert
}: RecordRuleConnectionSelectProps) {
  const [t] = useTranslation();
  const { getObjectByKey, getFormattedConnectedObjects } = useObjectHelpers();
  const { getDefaultRecordValue } = useRecordRuleHelpers();
  const { getFieldByKey } = useFieldHelpers();
  const { setValue, getValues } = useFormContext<KnackTask | RecordRule>();

  const allConnectedObjects = getFormattedConnectedObjects(sourceObject);

  const formFieldName: BuilderRecordRuleConnectionPath = formFieldNamePrefix
    ? `${formFieldNamePrefix}.connection`
    : 'connection';

  // Set the default value if the field is empty
  useEffect(() => {
    if (!getValues(formFieldName)) {
      setValue(formFieldName, `${tableConnections[0].object}.${tableConnections[0].key}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeRecordRuleConnection = (selectedConnectionKey: RecordRuleConnectionKey) => {
    const objectKey = selectedConnectionKey?.split('.')[0];
    const connectedObject = allConnectedObjects.find((o) => o.object.key === objectKey);

    if (connectedObject) {
      setValue('values', [getDefaultRecordValue(connectedObject.object.fields[0].key)]);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor="record-rule-connection-select" className="font-medium">
        {t('components.rules.record_rules.connections.label')}
      </Label>
      <Controller
        name={formFieldName}
        render={({ field: { value, onChange: onFieldChange } }) => (
          <Select
            onValueChange={(val: RecordRuleConnectionKey) => {
              handleChangeRecordRuleConnection(val);
              onFieldChange(val);
            }}
            value={value}
          >
            <Select.Trigger
              id="record-rule-connection-select"
              placeholder={t('actions.select')}
              className="w-full"
            />
            <Select.Content>
              {tableConnections.map((connection, index) => (
                <Select.Item
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${connection.object}-${connection.key}-${index}`}
                  value={`${connection.object}.${connection.key}`}
                  data-testid={`record-rule-connection-${connection.object}-${connection.key}`}
                >
                  <Trans
                    i18nKey={`components.rules.record_rules.connections.${isInsert ? 'insert' : 'update'}_connected_table`}
                    values={{
                      connectedTable: getObjectByKey(connection.object)?.name,
                      connectedField: getFieldByKey(connection.key)?.name
                    }}
                  />
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
    </div>
  );
}
