import { type KnackCriteriaWithValueType } from '@/types/schema/KnackCriteria';
import { type KnackFieldKey, type KnackFieldType } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type Field } from '@/components/data-table/display/fields/Field';

export const NON_COMPATIBLE_FIELD_TYPES_FOR_RECORD_RULE_VALUES: KnackFieldType[] = [
  'auto_increment',
  'concatenation',
  'sum',
  'min',
  'max',
  'average',
  'count',
  'equation'
];

export const RECORD_RULE_MULTIPLE_CHOICE_ACTION_TYPES = ['append', 'remove'] as const;
export const RECORD_RULE_ACTION_TYPES = ['record', 'insert', 'connection', 'email'] as const;

export type RecordRuleActionType = (typeof RECORD_RULE_ACTION_TYPES)[number];
export type RecordRuleMultipleChoiceActionType =
  (typeof RECORD_RULE_MULTIPLE_CHOICE_ACTION_TYPES)[number];

export const EMAIL_RECIPIENT_MODE_OPTIONS = ['to', 'cc', 'bcc', 'reply_to'] as const;
export const EMAIL_RECIPIENT_TYPE_OPTIONS = ['custom', 'field'] as const;

export type RecordRuleEmailRecipientMode = (typeof EMAIL_RECIPIENT_MODE_OPTIONS)[number];
export type RecordRuleEmailRecipientType = (typeof EMAIL_RECIPIENT_TYPE_OPTIONS)[number];

interface RecordRuleValueBase {
  connection_field?: `${KnackFieldKey}-${KnackFieldKey}`; // The field that will be updated (if type is connection)
  field: KnackFieldKey; // The field that will be updated
  input?: KnackFieldKey | string; // The field where the value is coming from (if type is record)
}

interface RecordRuleRecordValue extends RecordRuleValueBase {
  type: 'record';
  value: string;
}

interface RecordRulePlainValue extends RecordRuleValueBase {
  type: 'value';
  value: Field['rawValue'];
}

interface RecordRuleConnectionValue extends RecordRuleValueBase {
  type: 'connection';
  value: string;
}

interface RecordRuleCurrentDateValue extends RecordRuleValueBase {
  type: 'current_date';
  value: string;
}

interface RecordRuleCurrentLocationValue extends RecordRuleValueBase {
  type: 'current_location';
  value: string;
}

export type RecordRuleValue =
  | RecordRuleRecordValue
  | RecordRulePlainValue
  | RecordRuleConnectionValue
  | RecordRuleCurrentDateValue
  | RecordRuleCurrentLocationValue;

export interface RecordRule {
  action: RecordRuleActionType;
  connection?: RecordRuleConnectionKey;
  criteria: KnackCriteriaWithValueType[];
  values: RecordRuleValue[];
  email?: RecordRuleEmail;
  key: string;
}

export type RecordRuleConnectionKey = `${KnackObject['key']}.${KnackFieldKey}`;

export interface RecordRuleEmail {
  from_email: string;
  from_name: string;
  message: string;
  recipients: RecordRuleEmailRecipient[];
  subject: string;
}

export interface RecordRuleEmailRecipient {
  email: string;
  recipient_mode: RecordRuleEmailRecipientMode;
  recipient_type: RecordRuleEmailRecipientType;
  field: KnackFieldKey | `${KnackFieldKey}-${KnackFieldKey}` | null;
}
