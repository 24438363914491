import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Dialog, Label, Switch } from '@knack/asterisk-react';
import { z } from 'zod';

import { type FileField } from '@/types/schema/fields';

type FileFormSettingsProps = {
  field: FileField;
};

export const fileSchema = z.object({
  format: z.object({
    secure: z.boolean()
  })
});

const AWS_ASSETS_URL = 'https://s3.amazonaws.com/*';
const KNACK_ASSETS_URL = 'http://assets.knack.com/*';

export function FileFormSettings({ field }: FileFormSettingsProps) {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [t] = useTranslation();

  const { setValue } = useFormContext();

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center">
        <Controller
          name="format.secure"
          defaultValue={field?.format?.secure || false}
          render={({ field: { value } }) => (
            <Switch
              id="enable-secure-file-switch"
              className="mr-2"
              data-testid="enable-secure-file-switch"
              checked={value}
              onCheckedChange={
                !value ? () => setIsConfirmDialogOpen(true) : () => setValue('format.secure', false)
              }
            />
          )}
        />
        <Label htmlFor="enable-secure-file-switch" className="font-medium">
          {t('components.data_table.attributes.field_settings.file.secure_label')}
        </Label>
        <Dialog open={isConfirmDialogOpen} onOpenChange={setIsConfirmDialogOpen}>
          <Dialog.Content data-testid="secure-file-confirmation">
            <Dialog.MainContent>
              <Dialog.Header>
                <Dialog.Title>
                  {t(
                    'components.data_table.attributes.field_settings.file.secure_confirmation_dialog.title'
                  )}
                </Dialog.Title>
              </Dialog.Header>
              <div className="mt-6 text-justify">
                <p className="text-default">
                  {t(
                    'components.data_table.attributes.field_settings.file.secure_confirmation_dialog.new_secured_text'
                  )}
                </p>
                <p className="mt-4 text-default">
                  <Trans
                    i18nKey="components.data_table.attributes.field_settings.file.secure_confirmation_dialog.previous_link_text"
                    values={{
                      awsAssetsURL: AWS_ASSETS_URL,
                      knackAssetsURL: KNACK_ASSETS_URL
                    }}
                  >
                    <span className="font-semibold">{AWS_ASSETS_URL}</span>
                    <span className="font-semibold">{KNACK_ASSETS_URL}</span>
                  </Trans>
                </p>
                <p className="mt-4 font-semibold text-default">
                  {t(
                    'components.data_table.attributes.field_settings.file.secure_confirmation_dialog.cant_undo_text'
                  )}
                </p>
              </div>
            </Dialog.MainContent>
            <Dialog.Footer>
              <Dialog.Close asChild>
                <Button intent="minimal" data-testid="secure-file-confirmation-cancel">
                  {t(
                    'components.data_table.attributes.field_settings.file.secure_confirmation_dialog.cancel'
                  )}
                </Button>
              </Dialog.Close>
              <Button
                intent="primary-gradient"
                onClick={() => {
                  setValue('format.secure', true);
                  setIsConfirmDialogOpen(false);
                }}
                data-testid="secure-file-confirmation-delete"
              >
                {t(
                  'components.data_table.attributes.field_settings.file.secure_confirmation_dialog.secure'
                )}
              </Button>
            </Dialog.Footer>
          </Dialog.Content>
        </Dialog>
      </div>
    </div>
  );
}
