import { CellLoading } from '@/components/data-table/display/fields/CellLoading';
import { type FileField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { FileEdit } from '@/components/data-table/display/fields/file/FileEdit';
import { getFileIconByType } from '@/components/data-table/display/fields/file/getFileIconByType';

export function FieldValue(props: FieldRenderProps<FileField>) {
  const { value, rawValue, rowId, fieldId } = props;
  const FileIcon = getFileIconByType(rawValue?.filename || '');

  if (!value) {
    return null;
  }

  return (
    <div className="flex size-full" data-testid={`file-field-render-${rowId}-${fieldId}`}>
      <CellLoading rowId={rowId} fieldId={fieldId} />

      <div className="m-1 flex h-fit min-w-0 max-w-full items-center rounded bg-subtle p-1">
        <FileIcon
          size="16"
          className="mr-1 shrink-0"
          data-testid={`file-icon-render-${rowId}-${fieldId}`}
        />
        <p
          className="truncate"
          data-testid={`file-field-value-render-${rowId}-${fieldId}`}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: value }}
        />
      </div>
    </div>
  );
}

export function FileRender(props: FieldRenderProps<FileField>) {
  const { isFloating } = props;

  if (isFloating) {
    return <FileEdit {...props} />;
  }

  return <FieldValue {...props} />;
}
