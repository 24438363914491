import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';

import { type ConnectionField } from '@/types/schema/fields';
import {
  type FormViewConnectionInput,
  type FormViewConnectionInputSource
} from '@/types/schema/views/form/Connection';
import {
  useConnectionParentOptions,
  type SelectableConnectionParentOption
} from '@/pages/pages/settings-panel/view-settings/helpers/useConnectionParentOptions';

interface ConnectionParentOptionsSelectProps {
  connectionInputToUpdate: FormViewConnectionInput;
  setConnectionInputToUpdate: (input: FormViewConnectionInput) => void;
  connectionField: ConnectionField;
}

export function ConnectionParentOptionsSelect({
  connectionInputToUpdate,
  setConnectionInputToUpdate,
  connectionField
}: ConnectionParentOptionsSelectProps) {
  const [t] = useTranslation();
  const connectionParentOptions = useConnectionParentOptions({ field: connectionField });

  // If there is only one option, it means it's the default option ('All records'), so there are not connection parents to select
  if (connectionParentOptions.length === 1) {
    return null;
  }

  const getSelectedValue = (): SelectableConnectionParentOption['value'] => {
    if (connectionInputToUpdate.source) {
      const {
        connection_key: connectionKey,
        remote_key: remoteKey,
        type
      } = connectionInputToUpdate.source;

      if (connectionKey && remoteKey && type) {
        return `${connectionKey}-${remoteKey}-${type}`;
      }

      return 'all';
    }

    return 'all';
  };

  const handleValueChange = (value: SelectableConnectionParentOption['value']) => {
    if (value === 'all') {
      setConnectionInputToUpdate({
        ...connectionInputToUpdate,
        source: {
          filters: connectionInputToUpdate.source?.filters || []
        }
      });
      return;
    }

    setConnectionInputToUpdate({
      ...connectionInputToUpdate,
      source: {
        connection_key: value.split('-')[0],
        remote_key: value.split('-')[1],
        type: value.split('-')[2],
        filters: connectionInputToUpdate.source?.filters || []
      } as FormViewConnectionInputSource
    });
  };

  return (
    <div className="mb-4">
      <span className="mb-2 block font-medium">
        {t(
          'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.field_records_to_include'
        )}
      </span>
      <Select value={getSelectedValue()} onValueChange={handleValueChange}>
        <Select.Trigger placeholder={t('actions.select')} className="w-full" />
        <Select.Content>
          {(connectionParentOptions as SelectableConnectionParentOption[]).map((option) => (
            <Select.Item key={option.value} value={option.value}>
              {option.label}
            </Select.Item>
          ))}
        </Select.Content>
      </Select>
    </div>
  );
}
