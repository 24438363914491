import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import type { StackingType } from '@/types/schema/views/ReportView';
import { ChartLineType } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/ChartLineType';
import { Contrast } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/Contrast';
import { LegendPlacement } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/LegendPlacement';
import { ShowLabels } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/ShowLabels';
import { TiltLabels } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/TiltLabels';
import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';

export function AreaChartAppearanceSettings() {
  const [t] = useTranslation();

  const { chart, updateChart } = useActiveChartContext();

  const hasMoreThanOneGroup = chart.groups.length > 1;

  const stackingOptions: TabsListItem[] = [
    {
      children: <span>{t('pages.element_settings.report.categories.general.none')}</span>,
      value: 'none',
      isDisabled: hasMoreThanOneGroup
    },
    {
      children: <span>{t('pages.element_settings.report.categories.general.normal')}</span>,
      value: 'normal',
      isDisabled: hasMoreThanOneGroup
    },
    {
      children: <span>{t('pages.element_settings.report.categories.general.percent')}</span>,
      value: 'percent',
      isDisabled: hasMoreThanOneGroup
    }
  ];

  return (
    <>
      <div className="mb-4">
        <div className="mb-2">{t('pages.element_settings.report.categories.general.stacking')}</div>
        <Tabs
          defaultValue={chart.layout.stacking}
          onValueChange={(value) => {
            updateChart({
              layout: {
                ...chart.layout,
                stacking: value as StackingType
              }
            });
          }}
        >
          <Tabs.List items={stackingOptions} shouldDisableResponsive />
        </Tabs>
      </div>
      <ChartLineType />
      <TiltLabels />
      <ShowLabels />
      <Contrast />
      <LegendPlacement />
    </>
  );
}
