import { useTranslation } from 'react-i18next';
import { HiDocument as DocumentIcon } from 'react-icons/hi2';
import { Label } from '@knack/asterisk-react';

import { type MenuViewLink } from '@/types/schema/views/MenuView';
import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';
import { InlineKnackValue } from '@/components/InlineKnackValue';

interface MenuLinkPageValueProps {
  link: MenuViewLink;
}

export function MenuLinkPageValue({ link }: MenuLinkPageValueProps) {
  const [t] = useTranslation();

  const { getPageBySlug } = usePageHelpers();

  if (link.type !== 'scene') {
    return null;
  }

  let pageName = '';

  // If the scene is a string, it means it's already the slug of the page
  if (typeof link.scene === 'string') {
    const page = getPageBySlug(link.scene);
    pageName = page ? page.name : link.scene;
  } else {
    // If the scene is an object, it means it's a new page that hasn't been created yet, so we just use the name
    pageName = link.scene.name;
  }

  return (
    <div className="mb-4">
      <Label className="mb-2 block font-medium" htmlFor={`${link.id}-menu-link-page`}>
        {t('keywords.page')}
      </Label>
      <InlineKnackValue className="text-default">
        <DocumentIcon size="16" className="mr-1 text-subtle" />
        {pageName}
      </InlineKnackValue>
    </div>
  );
}
