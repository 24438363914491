import React, { useState, type FunctionComponent, type SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiComputerDesktop as DesktopIcon,
  HiDevicePhoneMobile as MobileIcon,
  HiDeviceTablet as TabletIcon
} from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';
import * as Dialog from '@radix-ui/react-dialog';

import { cn } from '@/utils/tailwind';

export const SCREEN_SIZES = {
  desktop: {
    type: 'desktop',
    maxWidth: '100%'
  },
  tablet: {
    type: 'tablet',
    maxWidth: '1024px'
  },
  mobile: {
    type: 'mobile',
    maxWidth: '370px'
  }
} as const;

export type ScreenSize = (typeof SCREEN_SIZES)[keyof typeof SCREEN_SIZES];
export type ScreenSizeType = keyof typeof SCREEN_SIZES;

interface LiveAppPreviewProps {
  title: string;
  onClose: () => void;
  onScreenSizeChange: (screenSize: ScreenSize) => void;
  children: React.ReactNode;
  closeButtonLabel: string;
}

type ScreenSizeButton = {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  id: ScreenSizeType;
  className: string;
};

const screenSizeButtons: ScreenSizeButton[] = [
  { icon: DesktopIcon, id: 'desktop', className: 'rounded-tr-none rounded-br-none' },
  { icon: TabletIcon, id: 'tablet', className: 'rounded-none border-l-0 border-r-0' },
  { icon: MobileIcon, id: 'mobile', className: 'rounded-tl-none rounded-bl-none' }
];

export function LiveAppPreview({
  title,
  onScreenSizeChange,
  onClose,
  children,
  closeButtonLabel
}: LiveAppPreviewProps) {
  const [t] = useTranslation();
  const [selectedScreenSize, setSelectedScreenSize] = useState<ScreenSize>(SCREEN_SIZES.desktop);

  const handleScreenSizeChange = (size: ScreenSize) => {
    onScreenSizeChange(size);
    setSelectedScreenSize(size);
  };

  return (
    <Dialog.Root open>
      <Dialog.Portal>
        <Dialog.Content className="fixed inset-0">
          <Dialog.Title className="sr-only">
            {t('pages.dialogs.live_app_preview_aria_title')}
          </Dialog.Title>
          <Dialog.Description className="sr-only">
            {t('pages.dialogs.live_app_preview_aria_description')}
          </Dialog.Description>
          <div className="flex items-center justify-between border-b border-subtle bg-muted px-6 py-4">
            <h2 className="text-xl font-medium text-emphasis">{title}</h2>
            <div>
              {screenSizeButtons.map((button) => (
                <Button
                  key={button.id}
                  size="sm"
                  intent="secondary"
                  className={cn(button.className, {
                    'relative border-2 border-purple-400 bg-brand-100 hover:border-purple-400 hover:bg-brand-100 focus:z-10':
                      selectedScreenSize.type === button.id
                  })}
                  onClick={() => handleScreenSizeChange(SCREEN_SIZES[button.id])}
                >
                  <Button.Icon
                    icon={button.icon}
                    gradientId={selectedScreenSize.type === button.id ? 'gradient-1' : undefined}
                  />
                </Button>
              ))}
            </div>
            <div className="flex items-center">
              <Button size="sm" intent="secondary" className="mr-2 min-w-16" onClick={onClose}>
                {closeButtonLabel}
              </Button>
            </div>
          </div>
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
