import { Trans } from 'react-i18next';

import { type ReportView } from '@/types/schema/views/ReportView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewSourceBanner } from '@/pages/pages/settings-panel/view-settings/ViewSourceBanner';

export function ChartSourceTableInfo() {
  const { sourceObject } = useActiveViewContext<ReportView>();

  return (
    <ViewSourceBanner className="mb-6">
      <Trans
        i18nKey="pages.element_settings.report.categories.general.table"
        values={{
          tableName: sourceObject.name
        }}
      >
        <ViewSourceBanner.ObjectName objectName={sourceObject.name} />
      </Trans>
    </ViewSourceBanner>
  );
}
