import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input, Label, Switch } from '@knack/asterisk-react';
import { nanoid } from 'nanoid';
import { z } from 'zod';

import { type MenuView } from '@/types/schema/views/MenuView';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

interface MenuLinkNewChildPageFormProps {
  formId: string;
  onSubmit: () => void;
}

export function MenuLinkNewChildPageForm({ formId, onSubmit }: MenuLinkNewChildPageFormProps) {
  const [t] = useTranslation();

  const { page } = usePageEditorContext();
  const { view } = useActiveViewContext<MenuView>();
  const updateView = useUpdateView<MenuView>();

  const newChildPageFormSchema = z.object({
    pageName: z.string().min(1, t('errors.value_required')),
    openInNewTab: z.boolean().optional()
  });

  type NewChildPageFormSchema = z.infer<typeof newChildPageFormSchema>;

  const {
    control,
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<NewChildPageFormSchema>({
    resolver: zodResolver(newChildPageFormSchema),
    defaultValues: {
      pageName: '',
      openInNewTab: false
    }
  });

  const onAddLink = (formData: NewChildPageFormSchema) => {
    updateView({
      links: [
        ...view.links,
        {
          id: `link_${nanoid(10)}`,
          type: 'scene',
          name: formData.pageName,
          new_window: formData.openInNewTab,
          scene: {
            name: formData.pageName,
            parent: page.slug,
            views: []
          }
        }
      ]
    });

    onSubmit();
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onAddLink)}>
      <Label className="mb-2 block font-medium" htmlFor="new-child-page-name">
        {t('pages.element_settings.menu.add_new_link_modal.new_child_page_name')}
      </Label>
      <Input
        id="new-child-page-name"
        className="w-full"
        intent={errors.pageName ? 'destructive' : undefined}
        {...register('pageName')}
      />
      <FormErrorMessage<NewChildPageFormSchema> className="mt-1" errors={errors} name="pageName" />

      <Label className="mt-4 flex items-center font-medium">
        <Controller
          name="openInNewTab"
          control={control}
          render={({ field: { value: openInNewTab, onChange } }) => (
            <Switch
              className="mr-2"
              checked={openInNewTab}
              onCheckedChange={(checked) => onChange(checked)}
            />
          )}
        />
        {t('pages.element_settings.menu.link_open_in_new_tab')}
      </Label>
    </form>
  );
}
