import { useMemo, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { Collapsible } from '@knack/asterisk-react';
import { useLocalStorage } from 'usehooks-ts';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { KnackPageIcon } from '@/components/KnackPageIcon';
import { useLeftPanelContext } from '@/pages/pages/LeftPanelContext';
import { AddNewPageButton } from '@/pages/pages/page-tree/AddNewPageButton';
import { ItemWrapper } from '@/pages/pages/page-tree/ItemWrapper';
import { SortableWrapper } from '@/pages/pages/page-tree/SortableWrapper';
import { ROUTES, type PageUrlParams } from '@/Router';

interface PageTreeItemProps {
  page: BuilderPage;
  leftPadding?: number;
  isRootItem?: boolean;
  shoukdSkipChildren?: boolean;
}

const LEFT_PADDING_STEP = 24;
const OPEN_COLLAPSIBLES_KEY = 'page-tree-open-menus';

export function PageTreeItem({
  page,
  leftPadding = 20,
  isRootItem = false,
  shoukdSkipChildren = false
}: PageTreeItemProps) {
  const { data: app } = useApplicationQuery();
  const urlParams = useParams<PageUrlParams>();
  const { nonUserPages } = useLeftPanelContext();
  const { id: selectedPageKey } = urlParams;
  const isActive = selectedPageKey === page.key;
  const to = generatePath(ROUTES.PAGES_ID, { id: page.key });
  const wrapperClasses = 'flex h-9 w-full items-center gap-2 rounded-lg p-2 hover:bg-subtle';
  const [localStorageOpenCollapsibles, setLocalStorageOpenCollapsibles] = useLocalStorage<string[]>(
    `${app?.id}-${OPEN_COLLAPSIBLES_KEY}`,
    []
  );

  const childrenItems = useMemo(() => {
    if (shoukdSkipChildren) {
      return [];
    }
    return nonUserPages?.filter(
      (item) =>
        (item.parentSlug && item.parentSlug === page.slug) ||
        (item.menuPageKey && item.menuPageKey === page.key)
    );
  }, [nonUserPages, page.slug, page.key, shoukdSkipChildren]);

  // Check if there is at least one active child page
  const hasActiveChild = useMemo(
    () =>
      childrenItems?.some((child) => {
        if (selectedPageKey === child.key) {
          return true;
        }
        return nonUserPages?.some(
          (item) =>
            selectedPageKey === item.key &&
            ((item.parentSlug && item.parentSlug === child.slug) ||
              (item.menuPageKey && item.menuPageKey === child.key))
        );
      }),
    [childrenItems, selectedPageKey, nonUserPages]
  );

  const isCollapsibleOpenStateSaved = localStorageOpenCollapsibles.includes(page.key);
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(
    hasActiveChild || isCollapsibleOpenStateSaved
  );

  const updateOpenCollapsibles = (state: boolean) => {
    setIsCollapsibleOpen(state);
    let newItems: string[] = [];
    if (state) {
      newItems = [...localStorageOpenCollapsibles];
      newItems.push(page.key);
    } else {
      newItems = localStorageOpenCollapsibles.filter((item) => item !== page.key);
    }
    setLocalStorageOpenCollapsibles(newItems);
  };

  const item = (
    <ItemWrapper
      page={page}
      classes={wrapperClasses}
      to={to}
      isActive={isActive}
      style={{ paddingLeft: `${leftPadding}px` }}
      isCollapsibleOpen={childrenItems?.length === 0 ? undefined : isCollapsibleOpen}
    >
      <KnackPageIcon page={page} isGradient={isActive} />
      <span className="flex-1 truncate">{page.name}</span>
    </ItemWrapper>
  );

  const itemWithChildren = (
    <Collapsible onOpenChange={updateOpenCollapsibles} defaultOpen={isCollapsibleOpen}>
      {item}
      <Collapsible.Content
        className="collapsible-animation rounded-lg bg-muted"
        data-testid="page-tree-item-collapsible-content"
      >
        {childrenItems?.map((childPage) => (
          <PageTreeItem
            key={childPage.key}
            page={childPage}
            leftPadding={leftPadding + LEFT_PADDING_STEP}
          />
        ))}
        {page.type === 'menu' && <AddNewPageButton parentPage={page} />}
      </Collapsible.Content>
    </Collapsible>
  );

  // The item doesn't have any children
  if (childrenItems?.length === 0) {
    if (isRootItem) {
      // Apply the sortable wrapper only to the root item to avoid dragging issues
      return <SortableWrapper pageKey={page.key}>{item}</SortableWrapper>;
    }
    return item;
  }

  if (isRootItem) {
    return <SortableWrapper pageKey={page.key}>{itemWithChildren}</SortableWrapper>;
  }

  return itemWithChildren;
}
