import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCollapseVertical as CollapseIcon, BiExpandVertical as ExpandIcon } from 'react-icons/bi';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { PiPlugs as ConnectionIcon } from 'react-icons/pi';
import { Button, Divider } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { cn } from '@/utils/tailwind';
import { EmptyState } from '@/components/EmptyState';
import { FieldSettings } from '@/components/field-settings/FieldSettings';
import { SidePanel } from '@/components/SidePanel';
import { ConnectionCollapsible } from './ConnectionCollapsible';

interface ExpandedConnectionsState {
  isInboundExpanded: boolean;
  isOutboundExpanded: boolean;
}

export function ConnectionsPanel({ table }: { table: KnackObject }) {
  const [t] = useTranslation();
  const [shouldRenderAddConnectionForm, setShouldRenderAddConnectionForm] = useState(false);
  const [expandedConnectionsState, setExpandedConnectionsState] =
    useState<ExpandedConnectionsState>({
      isInboundExpanded: true,
      isOutboundExpanded: true
    });

  const [tableInboundConnections, setTableInboundConnections] = useState(table.connections.inbound);
  const [tableOutboundConnections, setTableOutboundConnections] = useState(
    table.connections.outbound
  );

  const tableHasConnections =
    tableOutboundConnections.length > 0 || tableInboundConnections.length > 0;

  const showExpandCollapseActions =
    tableOutboundConnections.length >= 1 && tableInboundConnections.length >= 1;

  useEffect(() => {
    setTableInboundConnections(table.connections.inbound);
    setTableOutboundConnections(table.connections.outbound);
  }, [table.connections]);

  return (
    <>
      <SidePanel.Header>
        <SidePanel.Title className="text-xl font-medium leading-6" data-testid="connections-title">
          {t('components.data_table.right_sidebar.connections.title')}
        </SidePanel.Title>
        <SidePanel.Description className="text-xs">
          {t('components.data_table.right_sidebar.connections.subtitle')}
        </SidePanel.Description>
      </SidePanel.Header>

      {!tableHasConnections && !shouldRenderAddConnectionForm && (
        <div className="mt-2">
          <EmptyState>
            <EmptyState.Icon icon={<ConnectionIcon />} />
            <EmptyState.Description
              className="text-emphasis"
              data-testid="connections-empty-state-text"
            >
              {t('components.data_table.right_sidebar.connections.description')}
            </EmptyState.Description>
          </EmptyState>
        </div>
      )}

      {shouldRenderAddConnectionForm ? (
        <div className="-mx-2 mt-4 h-[calc(100%-64px)] overflow-auto px-2">
          <FieldSettings
            objectKey={table.key}
            initialType="connection"
            onCancel={() => {
              setShouldRenderAddConnectionForm(false);
            }}
            onSave={() => setShouldRenderAddConnectionForm(false)}
            newFieldState={{
              isBeingCreated: true,
              newIndex: table.fields.length,
              shouldNotAllowTypeChange: true
            }}
          />
        </div>
      ) : (
        <div className="align-center mt-4 flex flex-row justify-between gap-2">
          <Button
            className={cn({
              'w-full': !showExpandCollapseActions
            })}
            intent="secondary"
            onClick={() => setShouldRenderAddConnectionForm(true)}
            data-testid="connections-add-button"
          >
            <Button.Icon icon={PlusIcon} position="left" />
            {t('components.data_table.right_sidebar.connections.connection')}
          </Button>
          {showExpandCollapseActions && (
            <span className="flex flex-row items-center gap-2">
              <Button
                className="py-2 pl-1 pr-2"
                intent="link"
                onClick={() =>
                  setExpandedConnectionsState({
                    isInboundExpanded: true,
                    isOutboundExpanded: true
                  })
                }
                // We disable the 'Expand All' button if both connection groups are already expanded.
                disabled={
                  expandedConnectionsState.isInboundExpanded &&
                  expandedConnectionsState.isOutboundExpanded
                }
              >
                <Button.Icon icon={ExpandIcon} position="left" />
                {t('components.data_table.right_sidebar.expand_all')}
              </Button>
              <Button
                className="py-2 pl-1 pr-2"
                intent="link"
                onClick={() =>
                  setExpandedConnectionsState({
                    isInboundExpanded: false,
                    isOutboundExpanded: false
                  })
                }
                // We disabled the 'Collapse All' button if both connection groups are already collapsed.
                disabled={
                  !expandedConnectionsState.isInboundExpanded &&
                  !expandedConnectionsState.isOutboundExpanded
                }
              >
                <Button.Icon icon={CollapseIcon} position="left" />
                {t('components.data_table.right_sidebar.collapse_all')}
              </Button>
            </span>
          )}
        </div>
      )}

      {tableHasConnections && !shouldRenderAddConnectionForm && (
        <>
          <Divider className="mt-4" />
          <div
            className="-mx-2 h-[calc(100%-124px)] space-y-4 overflow-auto p-4"
            data-testid="connections-list-container"
          >
            <ConnectionCollapsible
              open={expandedConnectionsState.isOutboundExpanded}
              onOpenChange={(isExpanded) =>
                setExpandedConnectionsState((prev) => ({ ...prev, isOutboundExpanded: isExpanded }))
              }
              connectionList={tableOutboundConnections}
              setConnectionList={setTableOutboundConnections}
              table={table}
              connectionType="outbound"
            />

            <ConnectionCollapsible
              open={expandedConnectionsState.isInboundExpanded}
              onOpenChange={(isExpanded) =>
                setExpandedConnectionsState((prev) => ({ ...prev, isInboundExpanded: isExpanded }))
              }
              connectionList={tableInboundConnections}
              setConnectionList={setTableInboundConnections}
              table={table}
              connectionType="inbound"
            />
          </div>
        </>
      )}
    </>
  );
}
