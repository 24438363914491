import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input, Label, Switch } from '@knack/asterisk-react';
import { nanoid } from 'nanoid';
import { z } from 'zod';

import { type MenuView } from '@/types/schema/views/MenuView';
import { urlRegex } from '@/components/data-table/helpers/zod';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

interface MenuLinkExternalUrlFormProps {
  formId: string;
  onSubmit: () => void;
}

export function MenuLinkExternalUrlForm({ formId, onSubmit }: MenuLinkExternalUrlFormProps) {
  const [t] = useTranslation();

  const { view } = useActiveViewContext<MenuView>();
  const updateView = useUpdateView<MenuView>();

  const existingPageFormSchema = z.object({
    url: z.string().regex(urlRegex, {
      message: t('errors.url_invalid')
    }),
    openInNewTab: z.boolean().optional()
  });

  type ExistingPageFormSchema = z.infer<typeof existingPageFormSchema>;

  const {
    control,
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<ExistingPageFormSchema>({
    resolver: zodResolver(existingPageFormSchema),
    defaultValues: {
      url: '',
      openInNewTab: true
    }
  });

  const onAddLink = (formData: ExistingPageFormSchema) => {
    updateView({
      links: [
        ...view.links,
        {
          id: `link_${nanoid(10)}`,
          type: 'url',
          name: 'New Link',
          new_window: formData.openInNewTab,
          url: formData.url
        }
      ]
    });

    onSubmit();
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onAddLink)}>
      <Label className="mb-2 block font-medium" htmlFor="external-url">
        {t('keywords.url')}
      </Label>
      <Input
        id="external-url"
        className="w-full"
        intent={errors.url ? 'destructive' : undefined}
        placeholder={t('keywords.url_example')}
        {...register('url')}
      />
      <FormErrorMessage<ExistingPageFormSchema> className="mt-1" errors={errors} name="url" />

      <Label className="mt-4 flex items-center font-medium">
        <Controller
          name="openInNewTab"
          control={control}
          render={({ field: { value: openInNewTab, onChange } }) => (
            <Switch
              className="mr-2"
              checked={openInNewTab}
              onCheckedChange={(checked) => onChange(checked)}
            />
          )}
        />
        {t('pages.element_settings.menu.link_open_in_new_tab')}
      </Label>
    </form>
  );
}
