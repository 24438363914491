import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MultiSelect, Select } from '@knack/asterisk-react';

import { type MultipleChoiceField } from '@/types/schema/fields';

interface MultipleChoiceInputProps {
  targetField: MultipleChoiceField;
  name: string;
  id: string;
}

export function MultipleChoiceInput({ targetField, name, id }: MultipleChoiceInputProps) {
  const [t] = useTranslation();
  const { getFieldState } = useFormContext();
  const options = targetField.format?.options || [];
  const multiple =
    targetField.format?.type === 'multi' || targetField.format?.type === 'checkboxes';

  const hasError = getFieldState(name)?.error;

  if (multiple) {
    return (
      <Controller
        name={name}
        render={({ field }) => {
          const fieldValues =
            (field.value &&
              field.value.map((val: string) => ({
                label: val,
                value: val,
                key: val
              }))) ||
            [];

          return (
            <MultiSelect
              id={id}
              options={
                options.map((option) => ({
                  label: option,
                  key: option
                })) || []
              }
              selectedOptions={fieldValues}
              maxVisibleChips={5}
              intent={hasError ? 'destructive' : 'default'}
              isSearchEnabled
              onSelectOptions={(values) => {
                field.onChange(values.map((val) => val.key));
              }}
            />
          );
        }}
      />
    );
  }
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <Select value={field.value || ''} onValueChange={field.onChange}>
          <Select.Trigger
            id={id}
            intent={hasError ? 'destructive' : 'default'}
            placeholder={t('actions.select')}
            className="w-full truncate rounded-lg"
            {...field}
          />
          <Select.Content>
            {options.map((option: string) => (
              <Select.Item key={option} value={option}>
                {option}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      )}
    />
  );
}
