import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';

import { type ReportViewChartGroup } from '@/types/schema/views/ReportView';
import {
  getViewSettingsSortingOptions,
  type SortingOption
} from '@/pages/pages/settings-panel/view-settings/common/sorting/helper';

type CategorySortSelectorProps = {
  id: string;
  group: ReportViewChartGroup;
  onSelectSortCategory: (sortOption: SortingOption['value']) => void;
};

export function CategorySortSelector({
  id,
  group,
  onSelectSortCategory
}: CategorySortSelectorProps) {
  const [t] = useTranslation();

  return (
    <>
      <Label className="mb-2 mt-4 block">
        {t('pages.element_settings.report.categories.data_display.order')}
      </Label>
      <Select onValueChange={onSelectSortCategory} defaultValue={group.sort || 'asc'}>
        <Select.Trigger
          id={id}
          className="w-full"
          placeholder={`${t('actions.select')}`}
          data-testid={id}
        />
        <Select.Content>
          {getViewSettingsSortingOptions(group.type).map((option) => (
            <Select.Item key={option.value} value={option.value}>
              {option.label}
            </Select.Item>
          ))}
        </Select.Content>
      </Select>
    </>
  );
}
