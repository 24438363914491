import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type ThemeMode } from '@knack/asterisk-react';

import { ColorPicker } from '@/pages/themes/theme-editor/styles/ColorPicker';
import { GradientWheel } from '@/pages/themes/theme-editor/styles/GradientAngleWheel';
import { useThemesPageContext } from '@/pages/themes/ThemesPageContext';

const defaultGradientInfo = {
  light: {
    angle: 90,
    firstColor: '#FFFFFF',
    secondColor: '#FFFFFF'
  },
  dark: {
    angle: 90,
    firstColor: '#000000',
    secondColor: '#000000'
  }
};

function extractGradientInfo(
  gradient: string
): { angle: number; firstColor: string; secondColor: string } | null {
  // Regular expression to match the angle in the gradient (assuming it's in degrees)
  const angleRegex = /(\d+deg)/;
  const colorRegex = /#([0-9a-fA-F]{3,6})\b/g;

  // Extract the angle
  const angleMatch = gradient.match(angleRegex);
  const angle = angleMatch ? Number(angleMatch[0].replace('deg', '')) : null;

  // Find all color matches
  const colorMatches = gradient.match(colorRegex);

  if (angle && colorMatches && colorMatches.length >= 2) {
    return {
      angle,
      firstColor: colorMatches[0],
      secondColor: colorMatches[1]
    };
  }

  // Return null if the angle or less than two colors are found
  return null;
}

function ColorSelector({ mode }: { mode: ThemeMode }) {
  const [t] = useTranslation();
  const { theme } = useThemesPageContext();
  const [firstColor, setFirstColor] = useState<string>(defaultGradientInfo[mode].firstColor);
  const [secondColor, setSecondColor] = useState<string>(defaultGradientInfo[mode].secondColor);
  const [angle, setAngle] = useState<number>(defaultGradientInfo[mode].angle);

  useEffect(() => {
    const gradientInfo = extractGradientInfo(theme.background[mode]);
    setFirstColor(gradientInfo?.firstColor || defaultGradientInfo[mode].firstColor);
    setSecondColor(gradientInfo?.secondColor || defaultGradientInfo[mode].secondColor);
    setAngle(gradientInfo?.angle ? gradientInfo?.angle : defaultGradientInfo[mode].angle);
  }, [theme.background, mode]);

  return (
    <div className="max-w-72">
      <div className="mt-4">{t(`themes.${mode}_mode`)}</div>
      <div className="mt-2 flex justify-between">
        <div className="flex flex-col">
          <div className="text-xs">
            {t('themes.color_n', {
              count: 1
            })}
          </div>
          <ColorPicker
            mode={mode}
            firstColor={firstColor}
            secondColor={secondColor}
            angle={angle}
            isFirstColor
            setColor={setFirstColor}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-xs">{t('themes.angle')}</div>
          <GradientWheel
            mode={mode}
            firstColor={firstColor}
            secondColor={secondColor}
            angle={angle}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-xs">
            {t('themes.color_n', {
              count: 2
            })}
          </div>
          <ColorPicker
            mode={mode}
            firstColor={firstColor}
            secondColor={secondColor}
            angle={angle}
            setColor={setSecondColor}
          />
        </div>
      </div>
    </div>
  );
}

export function BackgroundImage() {
  const { theme } = useThemesPageContext();

  return (
    <>
      {(theme.mode === 'light' || theme.mode === 'system' || theme.shouldAllowModeToggle) && (
        <ColorSelector mode="light" />
      )}
      {(theme.mode === 'dark' || theme.mode === 'system' || theme.shouldAllowModeToggle) && (
        <ColorSelector mode="dark" />
      )}
    </>
  );
}
