import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiQuestionMarkCircle as QuestionMarkIcon } from 'react-icons/hi';
import { Label, Tabs } from '@knack/asterisk-react';
import { z } from 'zod';

import {
  EQUATION_DATE_FORMAT_OPTIONS,
  EQUATION_DATE_RESULT_OPTIONS,
  EQUATION_DATE_TYPE_OPTIONS,
  EQUATION_MARK_DECIMAL_OPTIONS,
  EQUATION_NUMBER_FORMAT_OPTIONS,
  EQUATION_ROUNDING_OPTIONS,
  EQUATION_THOUSANDS_OPTIONS,
  EQUATION_TIME_FORMAT_OPTIONS,
  EQUATION_TYPE_OPTIONS,
  type EquationField
} from '@/types/schema/fields';
import { EquationDateResultFormat } from '@/components/field-settings/equation/EquationDateResultFormat';
import { EquationNumericResultFormat } from '@/components/field-settings/equation/EquationNumericResultFormat';
import { EquationTypeSelector } from '@/components/field-settings/equation/EquationTypeSelector';
import { RichTextCodeEditor } from '@/components/field-settings/utils/rich-text/RichTextCodeEditor';
import { TextTooltip } from '@/components/TextTooltip';

type EquationFormSettingsProps = {
  field: EquationField;
};

export const equationSchema = z.object({
  format: z.object({
    equation_type: z.enum(EQUATION_TYPE_OPTIONS),
    date_result: z.enum(EQUATION_DATE_RESULT_OPTIONS),
    date_type: z.enum(EQUATION_DATE_TYPE_OPTIONS),
    mark_decimal: z.enum(EQUATION_MARK_DECIMAL_OPTIONS),
    rounding: z.enum(EQUATION_ROUNDING_OPTIONS),
    format: z.enum(EQUATION_NUMBER_FORMAT_OPTIONS).or(z.literal('')),
    mark_thousands: z.enum(EQUATION_THOUSANDS_OPTIONS),
    pre: z.string(),
    post: z.string(),

    equation: z.string(),
    precision: z.string(),

    date_format: z.enum(EQUATION_DATE_FORMAT_OPTIONS),
    time_format: z.enum(EQUATION_TIME_FORMAT_OPTIONS)
  })
});

export type EquationSchemaType = z.infer<typeof equationSchema>;

export function EquationFormSettings({ field }: EquationFormSettingsProps) {
  const [t] = useTranslation();

  return (
    <Controller<EquationSchemaType, 'format'>
      name="format"
      defaultValue={
        field?.format || {
          equation_type: 'numeric',
          mark_decimal: 'none',
          precision: '1',
          rounding: 'none',
          mark_thousands: 'none',
          format: 'none',
          equation: '',
          date_format: 'mm/dd/yyyy',
          time_format: 'HH:MM am',
          date_result: 'number',
          date_type: 'days',
          post: '',
          pre: ''
        }
      }
      render={({ field: currentField }) => {
        const isNumericResultFormat =
          (currentField.value.equation_type === 'date' &&
            currentField.value.date_result === 'number') ||
          currentField.value.equation_type === 'numeric';

        const isDateResultFormat =
          currentField.value.equation_type === 'date' && currentField.value.date_result === 'date';

        const items = [
          {
            children: (
              <span>{t('components.data_table.attributes.field_settings.equation.equation')}</span>
            ),
            value: 'equation'
          },
          {
            children: (
              <span>
                {t('components.data_table.attributes.field_settings.equation.result_format')}
              </span>
            ),
            value: 'result-format'
          }
        ];

        return (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <EquationTypeSelector currentField={currentField} />

              <Tabs defaultValue="equation">
                <Tabs.List items={items} />
                <Tabs.Content value="equation">
                  <RichTextCodeEditor
                    field={field}
                    functionTypes={currentField.value.equation_type}
                    content={currentField.value.equation}
                    onChange={(value) => {
                      currentField.onChange({
                        ...currentField.value,
                        equation: value
                      });
                    }}
                  />
                </Tabs.Content>
                <Tabs.Content value="result-format">
                  {isNumericResultFormat && (
                    <EquationNumericResultFormat currentField={currentField} />
                  )}

                  {isDateResultFormat && <EquationDateResultFormat currentField={currentField} />}
                </Tabs.Content>
              </Tabs>

              <Label className="flex font-medium">
                {t('components.data_table.attributes.field_settings.equation.preview_output')}

                <TextTooltip
                  label={t(
                    'components.data_table.attributes.field_settings.equation.preview_tooltip'
                  )}
                  asChild
                >
                  <div>
                    <QuestionMarkIcon />
                  </div>
                </TextTooltip>
              </Label>
              <p>{t('keywords.coming_soon')}</p>
            </div>
          </div>
        );
      }}
    />
  );
}
