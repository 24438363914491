import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance } from '@/utils/axiosConfig';

async function getApiKey() {
  const { data } = await axiosInstance.get<{ api_key: string }>('/v1/api_key', {
    withCredentials: true
  });

  return data.api_key;
}

export function useApiKeyQuery() {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return useQuery({
    queryKey: [queryKeys.apiKey, accountSlug, appSlug],
    queryFn: () => getApiKey(),
    staleTime: 4 * 60 * 60 * 1000
  });
}
