import { createContext, useContext, useMemo, useState } from 'react';

import { type ActiveSidebarItemType } from '@/pages/tables/toolkit-sidebar/ToolkitSidebar';

type TablesPageContextState = {
  isToolkitSidebarOpen: boolean;
  setIsToolkitSidebarOpen: (isSidebarOpen: boolean) => void;
  activeToolkitSidebarItem: ActiveSidebarItemType;
  setActiveToolkitSidebarItem: (activeSidebarItem: ActiveSidebarItemType) => void;
  handleSelectToolkitSidebarItem: (item: ActiveSidebarItemType) => void;
} | null;

type TablesPageContextProviderProps = {
  children: React.ReactNode;
};

const TablesPageContext = createContext<TablesPageContextState>(null);

export function TablesPageContextProvider({ children }: TablesPageContextProviderProps) {
  const [isToolkitSidebarOpen, setIsToolkitSidebarOpen] = useState(false);
  const [activeToolkitSidebarItem, setActiveToolkitSidebarItem] =
    useState<ActiveSidebarItemType>('');

  const handleSelectToolkitSidebarItem = (item: ActiveSidebarItemType) => {
    if (activeToolkitSidebarItem === item) {
      setIsToolkitSidebarOpen(false);
      return;
    }

    setActiveToolkitSidebarItem(item);
    setIsToolkitSidebarOpen(true);
  };

  const contextValue = useMemo(
    () => ({
      handleSelectToolkitSidebarItem,
      isToolkitSidebarOpen,
      setIsToolkitSidebarOpen,
      activeToolkitSidebarItem,
      setActiveToolkitSidebarItem
    }),
    [isToolkitSidebarOpen, activeToolkitSidebarItem]
  );

  return <TablesPageContext.Provider value={contextValue}>{children}</TablesPageContext.Provider>;
}

export const useTablesPageContext = () => {
  const context = useContext(TablesPageContext);
  if (!context) {
    throw new Error('useTablesPageContext must be used within a TablesPageContext');
  }
  return context;
};
