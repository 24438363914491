import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAdd as AddIcon } from 'react-icons/md';
import { Button } from '@knack/asterisk-react';

import { AddFieldDialog } from '@/components/field-settings/AddFieldDialog';
import { useFieldsStore } from '@/pages/tables/fields/useFieldsStore';

export function AddNewField() {
  const [t] = useTranslation();

  const [shouldShowAddFieldDialog, setShouldShowAddFieldDialog] = useState(false);

  const objectKey = useFieldsStore().use.objectKey();
  const fields = useFieldsStore().use.fields();

  if (!objectKey) return null;

  return (
    <>
      <Button
        intent="secondary"
        className="h-9 whitespace-nowrap"
        data-testid="add-new-field-button"
        onClick={() => {
          setShouldShowAddFieldDialog(true);
        }}
      >
        <AddIcon size={20} className="mr-1 flex-shrink-0" />
        {t('pages.tables_page.fields_list.header.add_field')}
      </Button>

      {shouldShowAddFieldDialog && (
        <AddFieldDialog
          objectKey={objectKey}
          onClose={() => setShouldShowAddFieldDialog(false)}
          newFieldState={{
            isBeingCreated: true,
            newIndex: fields.length
          }}
        />
      )}
    </>
  );
}
