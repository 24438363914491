import { useTranslation } from 'react-i18next';
import { Badge, Label, Switch } from '@knack/asterisk-react';

import { useGlobalState } from '@/hooks/useGlobalStore';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';

export function ChartGeneralOptions() {
  const [t] = useTranslation();

  const { chart, updateChart } = useActiveChartContext();

  const { hasAccessToNonPublicFeatures } = useGlobalState((state) => ({
    hasAccessToNonPublicFeatures: state.hasAccessToNonPublicFeatures
  }));

  const chartOptions = chart.options;

  return (
    <BuilderAccordion.Item
      isDefaultOpen
      label={`${t('pages.element_settings.report.categories.general.options')}`}
    >
      <div className="mb-2 flex items-center gap-2">
        <Switch
          id="show-hide-data-table"
          checked={chartOptions.shouldShowDataTable}
          onCheckedChange={(state) => {
            updateChart({
              options: {
                ...chartOptions,
                shouldShowDataTable: state
              }
            });
          }}
        />
        <Label htmlFor="show-hide-data-table">
          {t('pages.element_settings.report.categories.general.show_hide_data_table')}
        </Label>
      </div>
      <div className="mb-2 flex items-center gap-2">
        <Switch
          id="hide-empty-groups"
          checked={chartOptions.exclude_empties}
          onCheckedChange={(state) => {
            updateChart({
              options: {
                ...chartOptions,
                exclude_empties: state
              }
            });
          }}
        />
        <Label htmlFor="hide-empty-groups">
          {t('pages.element_settings.report.categories.general.hide_empty_groups')}
        </Label>
      </div>
      {chart.type !== 'pie' && (
        <div className="mb-2 flex items-center gap-2">
          <Switch
            id="hide-negative-ranges"
            checked={chartOptions.hide_negatives}
            onCheckedChange={(state) => {
              updateChart({
                options: {
                  ...chartOptions,
                  hide_negatives: state
                }
              });
            }}
          />
          <Label htmlFor="hide-negative-ranges">
            {t('pages.element_settings.report.categories.general.hide_negative_ranges')}
          </Label>
        </div>
      )}
      <div className="mb-2 flex items-center gap-2">
        <Switch
          id="expand-details-in-popup"
          checked={chartOptions.child_records}
          disabled={!hasAccessToNonPublicFeatures}
          onCheckedChange={(state) => {
            updateChart({
              options: {
                ...chartOptions,
                child_records: state
              }
            });
          }}
        />
        <Label htmlFor="expand-details-in-popup">
          {t('pages.element_settings.report.categories.general.expand_details_in_popup')}
          <Badge className="mx-1 px-1 py-0.5 text-xs">{t('keywords.coming_soon')}</Badge>
        </Label>
      </div>
      <div className="flex items-center gap-2">
        <Switch
          id="allow-exporting"
          checked={chartOptions.export_links}
          onCheckedChange={(state) => {
            updateChart({
              options: {
                ...chartOptions,
                export_links: state
              }
            });
          }}
        />
        <Label htmlFor="allow-exporting">
          {t('pages.element_settings.report.categories.general.allow_exporting')}
        </Label>
      </div>
    </BuilderAccordion.Item>
  );
}
