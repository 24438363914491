import { Trans } from 'react-i18next';
import { HiMiniCheckCircle as CheckIcon } from 'react-icons/hi2';

type AllowedCompatibilityCardProps = {
  oldFieldName: string;
  newFieldName: string;
};

export function AllowedCompatiblityCard({
  oldFieldName,
  newFieldName
}: AllowedCompatibilityCardProps) {
  return (
    <span className="flex rounded-lg bg-success p-4 text-success-emphasis">
      <CheckIcon size={18} className="mr-2 shrink-0" />
      <span>
        <Trans
          i18nKey="pages.tables_page.fields_list.field_row.contextual_menu.change_field_type.allowed_conversion"
          values={{
            newFieldName,
            oldFieldName
          }}
          components={[<strong key="0" />]}
        />
      </span>
    </span>
  );
}
