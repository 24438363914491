import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

async function generateApiKey() {
  const { data } = await axios.post('/v1/api_key', null, { withCredentials: true });

  return data;
}

export function useGenerateApiKeyMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: generateApiKey,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.apiKey] });
    }
  });
}
