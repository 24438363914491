import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as AddIcon } from 'react-icons/hi2';
import {
  Button,
  Checkbox,
  Label,
  MultiSelect,
  RadioCardGroup,
  Select,
  type MultiSelectOption
} from '@knack/asterisk-react';

import { type KnackObject, type KnackObjectProfileKey } from '@/types/schema/KnackObject';
import {
  LOGIN_VIEW_REGISTRATION_TYPES,
  type LoginView,
  type LoginViewRegistrationType
} from '@/types/schema/views/LoginView';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { TextTooltip } from '@/components/TextTooltip';
import { GoogleSsoModal } from './GoogleSsoModal';
import { useUpdateLoginView } from './useUpdateLoginView';

interface LoginPageAccessSettingsProps {
  loginView: LoginView;
  userRoleObjects: KnackObject[];
}

export function LoginPageAccessSettings({
  loginView,
  userRoleObjects
}: LoginPageAccessSettingsProps) {
  const [t] = useTranslation();

  const { data: application } = useApplicationQuery();
  const updateLoginView = useUpdateLoginView(loginView);

  const [isGoogleSsoModalOpen, setIsGoogleSsoModalOpen] = useState(false);

  const userRoleOptions: MultiSelectOption[] = userRoleObjects.map((role) => ({
    key: role.profile_key as string,
    label: role.name
  }));

  const selectedUserRoleOptions = userRoleOptions.filter((option) =>
    loginView.allowedProfileKeys.includes(option.key as KnackObjectProfileKey)
  );

  const hasGoogleSsoConfigured = application && application.settings.sso.google !== null;

  const onPagePermissionsModeChange = (limitProfileAccess: 'true' | 'false') => {
    updateLoginView({
      limitProfileAccess: limitProfileAccess === 'true'
    });
  };

  const onSelectUserRoles = (newSelectedOptions: MultiSelectOption[]) => {
    updateLoginView({
      allowedProfileKeys: newSelectedOptions.map((option) => option.key as KnackObjectProfileKey)
    });
  };

  const onRegistrationTypeChange = (registrationType: LoginViewRegistrationType) => {
    updateLoginView({
      registrationType
    });
  };

  return (
    <>
      <div className="mb-4">
        <h3 className="mb-2 font-medium">{t('pages.settings.access.access_control')}</h3>
        <RadioCardGroup
          value={String(loginView.limitProfileAccess)}
          onValueChange={onPagePermissionsModeChange}
        >
          <RadioCardGroup.Card
            value="false"
            className="items-center p-3 outline-2 sm:p-3"
            contentClassName="pointer-events-auto"
            width="100%"
          >
            <span className="text-emphasis">{t('pages.settings.access.all_logged_in_users')}</span>
          </RadioCardGroup.Card>
          <RadioCardGroup.Card
            value="true"
            className="p-3 outline-2 sm:p-3"
            contentClassName="pointer-events-auto"
            width="100%"
          >
            <span className="mb-2 text-emphasis">
              {t('pages.settings.access.specific_user_roles_only')}
            </span>
            <p className="mb-1 font-medium text-default">
              {t('pages.settings.access.select_the_user_roles')}
            </p>
            <MultiSelect
              id="page-settings-access-user-roles"
              options={userRoleOptions}
              selectedOptions={selectedUserRoleOptions}
              onSelectOptions={onSelectUserRoles}
            />
          </RadioCardGroup.Card>
        </RadioCardGroup>
      </div>

      <div className="mb-4">
        <h3 className="mb-2 font-medium">{t('pages.settings.access.can_new_users_register')}</h3>
        <Select value={loginView.registrationType} onValueChange={onRegistrationTypeChange}>
          <Select.Trigger placeholder={t('actions.select')} className="w-full truncate" />
          <Select.Content>
            {LOGIN_VIEW_REGISTRATION_TYPES.map((registrationType) => (
              <Select.Item key={registrationType} value={registrationType}>
                {t(`pages.settings.access.registration_type_labels.${registrationType}`)}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>

      <div>
        <h3 className="mb-2 font-medium">{t('pages.settings.access.sso_options')}</h3>
        {hasGoogleSsoConfigured ? (
          <Label htmlFor="login-option-google-sso" className="flex flex-wrap items-center">
            <Checkbox
              id="login-option-google-sso"
              className="mr-2"
              checked={loginView.sso_google}
              onCheckedChange={(checked: boolean) => {
                updateLoginView({
                  sso_google: checked
                });
              }}
            />
            <span className="flex items-center">
              {t('pages.settings.access.google_sso.label')}
              <TextTooltip label={t('pages.settings.access.google_sso.configure_settings')} asChild>
                <Button
                  intent="link"
                  size="sm"
                  className="ml-2 h-auto p-1 text-default"
                  onClick={() => setIsGoogleSsoModalOpen(true)}
                >
                  {t('actions.edit')}
                  <span className="sr-only">
                    {t('pages.settings.access.google_sso.configure_settings')}
                  </span>
                </Button>
              </TextTooltip>
            </span>
          </Label>
        ) : (
          <Button intent="secondary" onClick={() => setIsGoogleSsoModalOpen(true)}>
            <AddIcon size={16} className="mr-2" />
            {t('pages.settings.access.google_sso.add_google_sso')}
          </Button>
        )}
      </div>

      {isGoogleSsoModalOpen && <GoogleSsoModal onOpenChange={setIsGoogleSsoModalOpen} />}
    </>
  );
}
