import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useParams } from 'react-router-dom';
import { Button, DropdownMenu, Select, useToast, type ThemeMode } from '@knack/asterisk-react';

import { useThemeMutation } from '@/hooks/api/mutations/useThemeMutation';
import { useThemesMessagingContext } from '@/pages/themes/theme-editor/MessagingContext';
import { useThemesPageContext } from '@/pages/themes/ThemesPageContext';
import { ROUTES, type PageUrlParams } from '@/Router';

export function PreviewBar() {
  const [t] = useTranslation();
  const { updateMutation, applyMutation } = useThemeMutation();
  const { id: themeId } = useParams<PageUrlParams>();
  const { theme, theming } = useThemesPageContext();
  const { presentToast } = useToast();
  const { sendMessageToLiveApp } = useThemesMessagingContext();
  const [themeMode, setThemeMode] = useState<ThemeMode>('light');

  useEffect(() => {
    const isInitialModeDark =
      theme.mode === 'dark' ||
      (theme.mode === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches);
    setThemeMode(isInitialModeDark ? 'dark' : 'light');
    setTimeout(() => {
      sendMessageToLiveApp({
        action: 'set-theme-preview-mode',
        mode: isInitialModeDark ? 'dark' : 'light'
      });
    }, 0);
  }, [sendMessageToLiveApp, theme.mode]);

  if (!themeId || !theming) {
    return null;
  }

  const updateTheme = (shouldApplyTheme?: boolean) => {
    const newTheming = { ...theming };
    const newThemes = newTheming.appThemes.map((userTheme) => {
      if (userTheme.id === themeId) {
        return {
          ...theme,
          updatedOn: new Date().toISOString()
        };
      }
      return userTheme;
    });

    newTheming.appThemes = newThemes;

    if (shouldApplyTheme) {
      newTheming.activeThemeId = themeId;
    }

    applyMutation.mutate(
      {
        theming: newTheming
      },
      {
        onSuccess: () => {
          presentToast({
            title: t('themes.theme_updated')
          });
        },
        onError: () => {
          presentToast({
            title: t('themes.error_updating_theme')
          });
        }
      }
    );
  };

  return (
    <div className="flex items-center justify-between border-b border-subtle bg-muted px-6 py-4">
      <h2 className="truncate text-xl font-medium text-emphasis">
        <span>{theme?.name}</span>
      </h2>
      <div className="flex items-center">
        <div className="mr-2 shrink-0">{t('themes.view_as')}</div>
        <Select
          value={themeMode}
          onValueChange={(value) => {
            setTimeout(() => {
              setThemeMode(value as ThemeMode);
              sendMessageToLiveApp({
                action: 'set-theme-preview-mode',
                mode: value as ThemeMode
              });
            }, 0);
          }}
        >
          <Select.Trigger className="min-w-32" />
          <Select.Content>
            <Select.Item value="light">{t('themes.light_mode')}</Select.Item>
            <Select.Item value="dark">{t('themes.dark_mode')}</Select.Item>
          </Select.Content>
        </Select>
      </div>
      <div className="flex items-center">
        <Button size="sm" intent="minimal" className="ml-4 mr-2 min-w-16" asChild>
          <Link to={generatePath(ROUTES.THEMES)}>{t('actions.cancel')}</Link>
        </Button>
        <DropdownMenu>
          <DropdownMenu.Trigger asChild>
            <Button
              size="sm"
              className="min-w-16 shrink-0"
              isLoading={updateMutation.isPending}
              data-testid="theme-save-dropdown"
            >
              {t('actions.save_changes')}
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content align="end">
            <DropdownMenu.Item
              onClick={() => updateTheme()}
              disabled={updateMutation.isPending}
              data-testid="theme-save"
            >
              {t('actions.save')}
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onClick={() => updateTheme(true)}
              disabled={updateMutation.isPending}
              data-testid="theme-save-and-apply"
            >
              {t('actions.save_and_apply')}
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu>
      </div>
    </div>
  );
}
