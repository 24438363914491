import React from 'react';
import { Tooltip } from '@knack/asterisk-react';

export function TextTooltip({
  label,
  children,
  className,
  shouldPortal = false,
  asChild = false
}: {
  label: string;
  children: React.ReactNode;
  className?: string;
  shouldPortal?: boolean;
  asChild?: boolean;
}) {
  const tooltipContent = <Tooltip.Content>{label}</Tooltip.Content>;
  if (label !== '') {
    return (
      <Tooltip>
        <Tooltip.Trigger asChild={asChild} className={className}>
          {children}
        </Tooltip.Trigger>
        {shouldPortal ? <Tooltip.Portal>{tooltipContent}</Tooltip.Portal> : tooltipContent}
      </Tooltip>
    );
  }

  return children;
}
