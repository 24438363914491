import React from 'react';
import { useTranslation } from 'react-i18next';
import { HiArrowTopRightOnSquare as LinkIcon } from 'react-icons/hi2';
import { Badge } from '@knack/asterisk-react';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { formatNumberForStorage } from '@/utils/formatters';
import { getRegion } from '@/pages/settings/data-compliance-settings/utils';

interface DataComplianceItemProps {
  label: string;
  content: React.ReactNode;
  testId?: string;
}

function DataComplianceItem({ label, content, testId }: DataComplianceItemProps) {
  return (
    <div data-testid={testId}>
      <p className="mb-1 font-medium">{label}</p>
      <div className="text-subtle">{content}</div>
    </div>
  );
}

function Agreements({ application }) {
  const [t] = useTranslation();

  return (
    <div>
      <p className="mb-2">{t('app_settings.data_compliance.eu_dpa')}</p>
      {application?.account?.settings?.agreements?.dpa_gdpr ? (
        <p className="font-medium">{t('app_settings.data_compliance.signed')}</p>
      ) : (
        <p className="mb-2">{t('app_settings.data_compliance.none_on_file')}</p>
      )}
      <a
        href="https://s3.amazonaws.com/public_site/downloads/knack-eu-dpa.pdf"
        className="mb-2 flex items-center font-medium text-default"
      >
        <span className="underline">{t('app_settings.data_compliance.view_sign')}</span>
        <LinkIcon size={14} className="ml-1 shrink-0" />
      </a>
      <p className="text-xs text-subtle">
        {t('app_settings.data_compliance.sub_processor_notice')}
      </p>
      {application?.account?.settings?.hipaa?.enabled && (
        <p>{t('app_settings.data_compliance.baa_signed')}</p>
      )}
    </div>
  );
}

export function DataComplianceSettings() {
  const [t] = useTranslation();
  const { data: application } = useApplicationQuery();

  const hipaaCompliance = application?.account?.isHipaa || false;

  return (
    <div data-testid="data-compliance" className="space-y-6">
      <DataComplianceItem
        label={t('app_settings.data_compliance.total_records')}
        content={application?.counts.recordCount}
        testId="total-records"
      />
      <DataComplianceItem
        label={t('app_settings.data_compliance.total_storage')}
        content={formatNumberForStorage(application?.counts.storage || 0)}
        testId="total-storage"
      />
      <DataComplianceItem
        label={t('app_settings.data_compliance.data_location')}
        content={getRegion(application?.settings.cluster || '')}
        testId="data-location"
      />
      <DataComplianceItem
        label={t('app_settings.data_compliance.hipaa_compliance')}
        content={
          hipaaCompliance ? (
            <Badge intent="success" data-testid="hipaa_compliant">
              {t('app_settings.data_compliance.hipaa_compliant')}
            </Badge>
          ) : (
            <a
              href="https://www.knack.com/pricing/hipaa-compliance-package/"
              className="flex items-center text-default"
              target="_blank"
              rel="noreferrer"
            >
              <span className="underline">{t('app_settings.data_compliance.hipaa_contact')}</span>
              <LinkIcon size={14} className="ml-1 shrink-0" />
            </a>
          )
        }
        testId={hipaaCompliance ? 'hipaa-compliant' : 'hipaa-non-compliant'}
      />
      <DataComplianceItem
        label={t('app_settings.data_compliance.agreements')}
        content={<Agreements application={application} />}
        testId="agreements"
      />
      <DataComplianceItem
        label={t('app_settings.data_compliance.builder_version')}
        content={`${APP_VERSION} - ${APP_LAST_MOD}`}
        testId="builder-version"
      />
    </div>
  );
}
