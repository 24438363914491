import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

import { type MenuView } from '@/types/schema/views/MenuView';
import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';
import { useViewInputDeleteModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputDeleteModeContext';

function useRequiresDeleteConfirmation() {
  const { page } = usePageEditorContext();
  const { view, viewInitialState } = useActiveViewContext<MenuView>();
  const { getDirectChildPages } = usePageHelpers();
  const { viewInputIdToDelete: linkIdToDelete } = useViewInputDeleteModeContext();

  const linkToDelete = view.links.find((link) => link.id === linkIdToDelete);

  if (!linkToDelete || linkToDelete.type !== 'scene') {
    return false;
  }

  const pageHasMenusWithLinksToChildPage = page.views.some(
    (v) =>
      v.type === 'menu' &&
      v.links.some(
        (link) =>
          link.id !== linkToDelete.id && link.type === 'scene' && link.scene === linkToDelete.scene
      )
  );

  const isNewLink = !viewInitialState?.links.some((link) => link.id === linkToDelete.id);
  if (isNewLink) {
    // If the link is new (i.e. not present in the initial view state), we only need to show the confirmation modal if it links to an existing child page, and there are no other menus with links to the same child page
    return !pageHasMenusWithLinksToChildPage;
  }

  // If the page has other menu views with links to the same child page, we don't need to show the confirmation modal
  if (pageHasMenusWithLinksToChildPage) {
    return false;
  }

  // Check if there is at least one direct child page that corresponds to page the link points to
  const childPages = getDirectChildPages(page);
  return childPages.some((childPage) => childPage.slug === linkToDelete.scene);
}

export function MenuLinkDeleteModal() {
  const [t] = useTranslation();

  const { view } = useActiveViewContext<MenuView>();
  const updateView = useUpdateView<MenuView>();
  const { setIsViewInputDeleteMode, setViewInputIdToDelete, viewInputIdToDelete } =
    useViewInputDeleteModeContext();
  const requiresDeleteConfirmation = useRequiresDeleteConfirmation();

  const deleteLink = () => {
    updateView({
      links: view.links.filter((existingLink) => existingLink.id !== viewInputIdToDelete)
    });
    setIsViewInputDeleteMode(false);
    setViewInputIdToDelete(null);
  };

  // Once the component mounts, if we didn't show the confirmation modal, we can delete the link right away
  useEffect(() => {
    if (!requiresDeleteConfirmation) {
      deleteLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!setIsViewInputDeleteMode) {
    return null;
  }

  // If the link is not the only link in the view (or page) that points to a specific child page, we don't need to render anything. We simply delete the link after the component mounts
  if (!requiresDeleteConfirmation) {
    return null;
  }

  return (
    <Dialog
      open
      onOpenChange={() => {
        setIsViewInputDeleteMode(false);
        setViewInputIdToDelete(null);
      }}
    >
      <Dialog.Content>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {t('pages.element_settings.menu.delete_link_warning_modal.title')}
            </Dialog.Title>
            <Dialog.Description className="sr-only">
              {t('pages.element_settings.menu.delete_link_warning_modal.aria_description')}
            </Dialog.Description>
          </Dialog.Header>
          <div className="mt-6">
            <p>{t('pages.element_settings.menu.delete_link_warning_modal.description')}</p>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button intent="destructive" onClick={deleteLink}>
            {t('actions.delete')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
