import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Label, Select, Switch } from '@knack/asterisk-react';
import { nanoid } from 'nanoid';
import { z } from 'zod';

import { type MenuView } from '@/types/schema/views/MenuView';
import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

interface MenuLinkExistingPageFormProps {
  formId: string;
  onSubmit: () => void;
}

export function MenuLinkExistingPageForm({ formId, onSubmit }: MenuLinkExistingPageFormProps) {
  const [t] = useTranslation();

  const { page } = usePageEditorContext();
  const { view } = useActiveViewContext<MenuView>();
  const updateView = useUpdateView<MenuView>();

  const { getElegibleLinkablePagesFromObjectKey, getElegibleLinkablePagesWithoutSourceObject } =
    usePageHelpers();

  const elegibleLinkablePages = page.sourceObjectKey
    ? getElegibleLinkablePagesFromObjectKey(page.sourceObjectKey)
    : getElegibleLinkablePagesWithoutSourceObject();

  const existingPageFormSchema = z.object({
    existingPageSlug: z.string().min(1, t('errors.select_a_page')),
    openInNewTab: z.boolean().optional()
  });

  type ExistingPageFormSchema = z.infer<typeof existingPageFormSchema>;

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ExistingPageFormSchema>({
    resolver: zodResolver(existingPageFormSchema),
    defaultValues: {
      existingPageSlug: '',
      openInNewTab: false
    }
  });

  const onAddLink = (formData: ExistingPageFormSchema) => {
    const selectedPage = elegibleLinkablePages.find(
      ({ slug }) => slug === formData.existingPageSlug
    );

    if (!selectedPage) {
      return;
    }

    updateView({
      links: [
        ...view.links,
        {
          id: `link_${nanoid(10)}`,
          type: 'scene',
          name: selectedPage.name,
          scene: formData.existingPageSlug,
          new_window: formData.openInNewTab
        }
      ]
    });

    onSubmit();
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onAddLink)}>
      <Label className="mb-2 block font-medium" htmlFor="existing-page-slug">
        {t('keywords.page')}
      </Label>
      <Controller
        control={control}
        name="existingPageSlug"
        render={({ field: { value: existingPage, onChange } }) => (
          <Select value={existingPage} onValueChange={onChange}>
            <Select.Trigger
              id="existing-page-slug"
              placeholder={t('actions.select')}
              className={cn('w-full', {
                'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                  errors.existingPageSlug
              })}
            />
            <Select.Content>
              {elegibleLinkablePages.map(({ slug, label }) => (
                <Select.Item key={slug} value={slug}>
                  {label}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
      <FormErrorMessage<ExistingPageFormSchema>
        className="mt-1"
        errors={errors}
        name="existingPageSlug"
      />

      <Label className="mt-4 flex items-center font-medium">
        <Controller
          name="openInNewTab"
          control={control}
          render={({ field: { value: openInNewTab, onChange } }) => (
            <Switch
              className="mr-2"
              checked={openInNewTab}
              onCheckedChange={(checked) => onChange(checked)}
            />
          )}
        />
        {t('pages.element_settings.menu.link_open_in_new_tab')}
      </Label>
    </form>
  );
}
