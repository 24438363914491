import { AddNewRow } from '@/components/data-table/display/header/AddNewRow';
import { FilterButton } from '@/components/data-table/display/header/FilterButton';
import { SearchByKeyword } from '@/components/data-table/display/header/SearchByKeyword';
import { SortingIndicator } from '@/components/data-table/display/header/SortingIndicator';
import {
  useDataTableStore,
  useDataTableStorePersist
} from '@/components/data-table/useDataTableStore';

export function DataTableHeader() {
  const search = useDataTableStorePersist().use.search();
  const { setSearch, setPage } = useDataTableStorePersist().use.actions();
  const { refetchPagesInViewport, setPagesInViewport } = useDataTableStore().use.actions();
  const objectKey = useDataTableStore().use.objectKey();
  const isInitialLoad = useDataTableStore().use.isInitialLoad();

  if (isInitialLoad) {
    return null;
  }

  return (
    <div className="flex items-center gap-2">
      <SortingIndicator />
      <FilterButton />
      <SearchByKeyword
        localStorageKey={`${objectKey}-search-history`}
        defaultSearch={search || ''}
        onSearchChange={(newSearch) => {
          setSearch(newSearch);
          setPage(null);
          setPagesInViewport([0]);
          refetchPagesInViewport();
        }}
      />
      <AddNewRow />
    </div>
  );
}
