import { useTranslation } from 'react-i18next';

import { BuilderAccordion } from '@/components/BuilderAccordion';
import { AreaChartAppearanceSettings } from '@/pages/pages/settings-panel/view-settings/report/appearance/AreaChartAppearanceSettings';
import { BarChartAppearanceSettings } from '@/pages/pages/settings-panel/view-settings/report/appearance/BarChartAppearanceSettings';
import { LineChartAppearanceSettings } from '@/pages/pages/settings-panel/view-settings/report/appearance/LineChartAppearanceSettings';
import { PieChartAppearanceSettings } from '@/pages/pages/settings-panel/view-settings/report/appearance/PieChartAppearanceSettings';
import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';
import { ChartGeneralOptions } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ChartGeneralOptions';
import { ChartSourceTableInfo } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ChartSourceTableInfo';
import { ChartTitleDescriptionSettings } from './ChartTitleDescriptionSettings';

export function ChartGeneralSettings() {
  const [t] = useTranslation();

  const { chart } = useActiveChartContext();

  return (
    <>
      <ChartSourceTableInfo />
      <BuilderAccordion>
        <ChartTitleDescriptionSettings />

        <BuilderAccordion.Item
          isDefaultOpen
          label={t('pages.element_settings.report.categories.general.appearance')}
        >
          {chart.type === 'bar' && <BarChartAppearanceSettings />}
          {chart.type === 'pie' && <PieChartAppearanceSettings />}
          {chart.type === 'area' && <AreaChartAppearanceSettings />}
          {chart.type === 'line' && <LineChartAppearanceSettings />}
        </BuilderAccordion.Item>
        <ChartGeneralOptions />
      </BuilderAccordion>
    </>
  );
}
