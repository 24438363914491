// TODO: FE-3830 - Remove this component when display rules criteria form are implemented.
import { Controller, type FieldValues, type Path } from 'react-hook-form';
import { Input } from '@knack/asterisk-react';

interface DateTimeFieldInputProps<FormSchema extends FieldValues> {
  formFieldName: Path<FormSchema>;
}

export function DateTimeFieldInput<FormSchema extends FieldValues>({
  formFieldName
}: DateTimeFieldInputProps<FormSchema>) {
  return (
    <Controller
      name={formFieldName}
      render={({ field: { onChange, value } }) => (
        <Input
          intent="default"
          type="date"
          value={
            value?.date
              ? (() => {
                  const [month, day, year] = value.date.split('/');
                  return `${year}-${month}-${day}`;
                })()
              : ''
          }
          onChange={(e) => {
            const inputDate = e.target.value;
            const parsedDate = new Date(inputDate);

            const newDate = [
              String(parsedDate.getMonth() + 1).padStart(2, '0'),
              String(parsedDate.getDate()).padStart(2, '0'),
              parsedDate.getFullYear()
            ].join('/');

            onChange({
              ...value,
              all_day: false,
              date: newDate,
              hours: '',
              minutes: '',
              am_pm: '',
              time: ''
            });
          }}
        />
      )}
    />
  );
}
