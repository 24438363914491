import { useEffect } from 'react';
import {
  FormProvider,
  useForm,
  useWatch,
  type FieldArrayWithId,
  type FieldErrors
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiDocumentDuplicate as DuplicateIcon, HiTrash as RemoveIcon } from 'react-icons/hi2';
import { useSortable } from '@dnd-kit/sortable';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Input, Label } from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackMenuFilter } from '@/types/schema/KnackFilter';
import { type KnackObject } from '@/types/schema/KnackObject';
import { cn } from '@/utils/tailwind';
import { CriteriaForm } from '@/components/CriteriaForm';
import { DragButton } from '@/components/dnd/DragButton';
import { type CriteriaFormValueWithId } from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsMenuFiltersDialog';

type DefaultMenuFiltersFormData = {
  criteria: KnackMenuFilter[];
};

interface ViewSettingsMenuFiltersDialogFormItemProps {
  menuFilter: KnackMenuFilter;
  menuFilterIndex: number;
  sourceObject: KnackObject;
  handleUpdateMenuFilterCriteria: (
    index: number,
    criteria: FieldArrayWithId<DefaultMenuFiltersFormData, 'criteria', 'id'>
  ) => void;
  onDuplicateMenuFilter: (index: number) => void;
  onRemoveMenuFilter: (index: number) => void;
  formErrors: FieldErrors<{
    criteria: KnackMenuFilter[];
  }>;
}

export function ViewSettingsMenuFiltersDialogFormItem({
  menuFilter,
  menuFilterIndex,
  sourceObject,
  formErrors,
  handleUpdateMenuFilterCriteria,
  onDuplicateMenuFilter,
  onRemoveMenuFilter
}: ViewSettingsMenuFiltersDialogFormItemProps) {
  const [t] = useTranslation();

  // Extract the error message from the formErrors object and format the new key with only criteria.
  const formattedErrors: FieldErrors<DefaultMenuFiltersFormData> | undefined = formErrors
    ? Object.keys(formErrors).reduce((acc, key) => {
        if (key.includes(`${menuFilter.key}:criteria`)) {
          const newKey = key.replace(`${menuFilter.key}:`, '');
          acc[newKey] = formErrors[key];
        }
        return acc;
      }, {})
    : undefined;

  const { isDragging, isSorting } = useSortable({
    id: menuFilter.key
  });

  const defaultFiltersRulesSchema = z.object({
    criteria: z.custom<KnackMenuFilter[]>()
  });

  const form = useForm<DefaultMenuFiltersFormData>({
    resolver: zodResolver(defaultFiltersRulesSchema),
    defaultValues: {
      // "Menu filters/Preset filters" only handles one criteria at a time, that's why an array of a single element is used
      criteria: [menuFilter]
    },
    // Set the errors manually from the parent form
    errors: formattedErrors
  });

  const criteriaFormValue = useWatch({
    control: form.control,
    name: 'criteria'
  });

  useEffect(() => {
    handleUpdateMenuFilterCriteria(
      menuFilterIndex,
      criteriaFormValue[0] as CriteriaFormValueWithId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteriaFormValue]);

  return (
    <FormProvider {...form}>
      <div
        className={cn('group relative mb-2 w-full items-start gap-2 rounded-lg bg-subtle p-4', {
          'after:absolute after:inset-0 after:rounded-lg after:bg-muted/80': isDragging,
          'pointer-events-none': isSorting
        })}
      >
        <DragButton sortableId={menuFilter.key} />

        <div className="w-full px-2">
          <div className="flex justify-between">
            <p className="mb-4">
              {t(
                'pages.element_settings.common.categories.data_display.filtering_section.link_number',
                { linkNumber: menuFilterIndex + 1 }
              )}
            </p>
            <div>
              <Button
                intent="minimal"
                size="sm"
                onClick={() => onDuplicateMenuFilter(menuFilterIndex)}
                className="text-subtle hover:bg-action"
              >
                <DuplicateIcon />
              </Button>
              <Button
                intent="minimal"
                size="sm"
                onClick={() => onRemoveMenuFilter(menuFilterIndex)}
                className="text-subtle hover:bg-action"
              >
                <RemoveIcon />
              </Button>
            </div>
          </div>
          <div className="mb-4">
            <Label htmlFor={`${menuFilter.text}-${menuFilter.key}-input`}>
              {t(
                'pages.element_settings.common.categories.data_display.filtering_section.link_text'
              )}
            </Label>
            <Input
              id={`${menuFilter.text}-${menuFilter.key}-input`}
              className="mt-1 h-auto w-full"
              placeholder={t(
                'pages.element_settings.common.categories.data_display.filtering_section.link_text_placeholder'
              )}
              intent={
                formattedErrors?.criteria?.[menuFilterIndex]?.text ? 'destructive' : 'default'
              }
              {...form.register(`criteria.${0}.text`)}
            />
            {formattedErrors?.criteria?.[menuFilterIndex]?.text && (
              <span className="mt-1 text-xs text-destructive">
                {formattedErrors?.criteria?.[menuFilterIndex]?.text.message}
              </span>
            )}
          </div>
        </div>
        <CriteriaForm sourceObject={sourceObject} isFilterCriteria isSingleCriteriaForm />
      </div>
    </FormProvider>
  );
}
