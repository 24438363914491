import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import type { LegendPosition } from '@/types/schema/views/ReportView';
import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';

export function LegendPlacement() {
  const [t] = useTranslation();

  const { chart, updateChart } = useActiveChartContext();

  const legendItems: TabsListItem[] = [
    {
      children: <span>{t('pages.element_settings.report.categories.general.left')}</span>,
      value: 'left'
    },
    {
      children: <span>{t('pages.element_settings.report.categories.general.right')}</span>,
      value: 'right'
    },
    {
      children: <span>{t('pages.element_settings.report.categories.general.below')}</span>,
      value: 'bottom'
    },
    {
      children: <span>{t('pages.element_settings.report.categories.general.none')}</span>,
      value: 'none'
    }
  ];

  return (
    <div className="my-4">
      <div className="mb-2">
        {t('pages.element_settings.report.categories.general.legend_placement')}
      </div>
      <Tabs
        value={chart.layout.legend || 'none'}
        onValueChange={(value) => {
          updateChart({
            layout: {
              ...chart.layout,
              legend: value as LegendPosition
            }
          });
        }}
      >
        <Tabs.List items={legendItems} shouldDisableResponsive />
      </Tabs>
    </div>
  );
}
