import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type CalendarView } from '@/types/schema/views/CalendarView';
import { type CheckoutView } from '@/types/schema/views/CheckoutView';
import { type CustomerView } from '@/types/schema/views/CustomerView';
import { type DetailsView } from '@/types/schema/views/DetailsView';
import { type FormView } from '@/types/schema/views/FormView';
import { type ListView } from '@/types/schema/views/ListView';
import { type LoginView } from '@/types/schema/views/LoginView';
import { type MapView } from '@/types/schema/views/MapView';
import { type MenuView } from '@/types/schema/views/MenuView';
import { type ReportView } from '@/types/schema/views/ReportView';
import { type RichTextView } from '@/types/schema/views/RichTextView';
import { type SearchView } from '@/types/schema/views/SearchView';
import { type TableView } from '@/types/schema/views/TableView';

export const VIEW_GROUP_ID_PREFIX = 'group_';
export const VIEW_COLUMN_ID_PREFIX = 'column_';
export const VIEW_DIVIDER_INPUT_ID_PREFIX = 'divider_';
export const VIEW_SECTION_BREAK_INPUT_ID_PREFIX = 'section_break_';

export type KnackViewType =
  | 'form'
  | 'table'
  | 'search'
  | 'details'
  | 'list'
  | 'calendar'
  | 'map'
  | 'report'
  | 'rich_text'
  | 'menu'
  | 'login'
  | 'checkout'
  | 'customer'
  | 'registration';

export type DateTimeCriteriaValue = { date: string; time: string };
export type LinkCriteriaValue = { label: string; url: string };

export type RuleCriteriaValue =
  | string
  | number
  | string[]
  | boolean
  | DateTimeCriteriaValue
  | LinkCriteriaValue;

export type RuleCriteriaOperator =
  | 'contains'
  | 'does not contain'
  | 'is'
  | 'is not'
  | 'starts with'
  | 'ends with'
  | 'is blank'
  | 'is not blank'
  | 'higher than'
  | 'lower than'
  | 'is before'
  | 'is after'
  | 'is before current time'
  | 'is after current time'
  | 'is during the current'
  | 'is during the previous'
  | 'is during the next'
  | 'is before the previous'
  | 'is after the next'
  | 'is today'
  | 'is today or before'
  | 'is today or after'
  | 'is before today'
  | 'is after today'
  | 'matches regular expression'
  | 'does not match regular expression'
  | 'size is less than'
  | 'size is greater than'
  | 'file type is'
  | 'file type is not'
  | 'character count is not'
  | 'character count is'
  | 'character count is higher than'
  | 'character count is lower than'
  | 'domain must equal'
  | 'domain must not equal'
  | 'is a day of the week'
  | 'is between days of the week'
  | 'is between dates'
  | 'is a future date'
  | 'is not a future date'
  | 'has changed'
  | 'is any';

export type BuilderViewKey = `view_${string}` | `new_view_${string}`;

export interface BuilderViewBase {
  id: string;
  key: BuilderViewKey;
  name: string;
  title?: string;
  label?: string;
  description: string;
}

export type BuilderViewSourceSchema = {
  object: `object_${string}`;
  criteria: BuilderViewSourceSchemaCriteria;
  sort?: Array<BuilderViewSourceSchemaSortItem>;
  limit?: string;
  authenticated_user?: boolean;
  connection_key?: `field_${string}`;
  relationship_type?: 'local' | 'foreign';
  parent_source?: {
    connection: `field_${string}`;
    object: `object_${string}`;
  };
};

export type BuilderViewSourceSchemaSortItem = {
  field: KnackFieldKey;
  order: 'asc' | 'desc';
};

export type BuilderViewSourceSchemaCriteria = {
  match: string;
  rules: BuilderViewSourceCriteriaRule[];
  groups: BuilderViewSourceCriteriaRule[][];
};

export type BuilderViewSourceCriteriaRule = {
  field: KnackFieldKey;
  operator: string;
  value: string;
};

export type BuilderViewLinkColumnType = 'text' | 'field';
export type BuilderViewColumnType = 'link' | 'field' | 'delete' | 'action_link' | 'scene_link';
export interface BuilderViewColumnIcon {
  icon: string;
  align: 'left' | 'right';
}

export type BuilderView =
  | FormView
  | LoginView
  | TableView
  | SearchView
  | ListView
  | CalendarView
  | DetailsView
  | MapView
  | ReportView
  | RichTextView
  | MenuView
  | CheckoutView
  | CustomerView;
