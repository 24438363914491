import { useTranslation } from 'react-i18next';
import { Button, Dialog, useToast } from '@knack/asterisk-react';

import { useGenerateApiKeyMutation } from '@/hooks/api/mutations/useGenerateApiKeyMutation';

type GenerateApiKeyDialogProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export function GenerateApiKeyDialog({ isOpen, onOpenChange }: GenerateApiKeyDialogProps) {
  const [t] = useTranslation();
  const generateApiKeyMutation = useGenerateApiKeyMutation();
  const { presentToast } = useToast();

  const generateApiKey = () => {
    generateApiKeyMutation.mutate(undefined, {
      onSuccess: () => {
        onOpenChange(false);
        presentToast({
          title: t('app_settings.api.generate.success')
        });
      },
      onError: () => {
        presentToast({
          title: t('app_settings.api.generate.error')
        });
      }
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content data-testid="generate-api-key-modal" aria-describedby={undefined}>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>{t('app_settings.api.generate.title')}</Dialog.Title>
          </Dialog.Header>
          <p className="mb-2 mt-6">{t('app_settings.api.generate.subtitle')}</p>
        </Dialog.MainContent>
        <div className="flex justify-end gap-2 p-6 pt-0">
          <Dialog.Close asChild disabled={generateApiKeyMutation.isPending}>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button onClick={generateApiKey} isLoading={generateApiKeyMutation.isPending}>
            {t('app_settings.api.generate.action')}
          </Button>
        </div>
      </Dialog.Content>
    </Dialog>
  );
}
