import { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Select } from '@knack/asterisk-react';
import { type z } from 'zod';

import { cn } from '@/utils/tailwind';
import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import {
  type NameField as NameFieldDisplay,
  type NameTitle
} from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { getNameSchema } from '@/components/data-table/display/fields/name/NameSchema';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import { setCursorPositionAtTheEnd } from '@/components/data-table/helpers/setCursorPositionAtTheEnd';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';
import { DEFAULT_FORMAT_VALUE, TITLES } from './constants';

export function NameEdit({ rawValue, fieldId, rowId, type }: FieldRenderProps<NameFieldDisplay>) {
  const { moveSelectionVertical } = useSelectionStrategy();

  const [t] = useTranslation();

  const currentFieldSettings = useDataTableStore().use.currentFieldSettings();
  const selectedCell = useDataTableStore().use.selectedCell();
  const cellErrors = useDataTableStore().use.cellErrors(rowId, fieldId);

  const { saveCell, setIsEditing, clearCellErrors } = useDataTableStore().use.actions();

  if (!selectedCell) throw new Error('No selected cell');

  const nameTitleRef = useRef<NameTitle>(rawValue.title);

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);

  const { format } = currentField;
  const nameFormat = format?.format || DEFAULT_FORMAT_VALUE;
  const hasMiddleName = nameFormat.includes('Middle');
  const hasTitle = nameFormat.includes('Title');

  const nameFormSchema = getNameSchema(currentField);

  type NameFormSchema = z.infer<typeof nameFormSchema>;

  const {
    register,
    handleSubmit,
    setFocus,
    getValues,
    getFieldState,
    setValue,
    formState: { errors, isValid }
  } = useForm<NameFormSchema>({
    resolver: zodResolver(nameFormSchema),
    defaultValues: {
      first: rawValue.first,
      last: rawValue.last,
      middle: rawValue.middle,
      title: rawValue.title
    }
  });

  const handleForm = useCallback(async () => {
    const validFormState = !getFieldState('first').invalid && !getFieldState('last').invalid;
    if (validFormState) {
      // Any variable used inside addUndoRedoHistory has to be inside this scope.
      // Re-declaring it here ensures that when we call undo/redo the value inside the closure is the same as when the closure was created.
      const newValue = {
        first: getValues('first'),
        last: getValues('last'),
        middle: getValues('middle'),
        title: nameTitleRef.current
      };

      await saveCell(rowId, fieldId, newValue, {
        value: getValues('first'), // TODO: FE-2183 - We should to render the value by ourselves here, or use the raw value in the NameRender
        rawValue: newValue
      });
    }
  }, [getFieldState, getValues, saveCell, rowId, fieldId]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Clean server error on change
    clearCellErrors(rowId, fieldId);
    if (e.key === 'Escape') {
      setIsEditing(false);
      const formState = getFieldState('first');
      if (formState.invalid) {
        setValue('first', rawValue.first);
        setValue('last', rawValue.last);
        setValue('middle', rawValue.middle);
        setValue('title', rawValue.title);
      }

      e.preventDefault();
    }

    if (e.key === 'Enter') {
      if (isValid) {
        if (!selectedCell.isEditing) {
          e.preventDefault();
          setIsEditing(true);
        } else {
          e.preventDefault();
          moveSelectionVertical('down');
        }
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    // Hack needed to focus the textarea when the component is created
    setTimeout(() => {
      setFocus('first');
    }, 0);
  }, [setFocus]);

  useEffect(
    () => () => {
      setIsEditing(false);
    },
    [setIsEditing]
  );

  const firstRegister = register('first', {
    onChange: async () => {
      if (!selectedCell.isEditing) {
        setIsEditing(true);
      }

      await handleSubmit(handleForm)();
    }
  });

  const middleRegister = register('middle', {
    onChange: async () => {
      if (!selectedCell.isEditing) {
        setIsEditing(true);
      }

      await handleSubmit(handleForm)();
    }
  });

  const lastRegister = register('last', {
    onChange: async () => {
      if (!selectedCell.isEditing) {
        setIsEditing(true);
      }

      await handleSubmit(handleForm)();
    }
  });

  if (currentFieldSettings === fieldId) return null;

  return (
    <form
      className={cn('flex h-full w-[200px] min-w-full flex-col items-center justify-between p-1', {
        'bg-base': selectedCell.isEditing,
        // We don't have ring-emphasis, we should either stop using borders or add the missing color.
        // This is an edge case where we want the black border to be in internal inputs instead of the external div.
        'ring-2 ring-[#898088]': selectedCell.isEditing,
        'ring-2 ring-destructive': cellErrors || Object.keys(errors).length > 0
      })}
      data-testid="name-edit-form"
    >
      <div className="flex size-full flex-row items-start gap-1">
        {hasTitle && (
          <div
            className={cn('mx-1 resize-none items-center justify-center', {
              hidden: !selectedCell.isEditing
            })}
          >
            <Select
              value={nameTitleRef.current}
              onValueChange={async (value) => {
                nameTitleRef.current = value === 'None' ? '' : (value as NameTitle);
                await handleSubmit(handleForm)();
              }}
            >
              <Select.Trigger
                placeholder={t(
                  'components.data_table.attributes.field_labels.name.title_placeholder'
                )}
                size="sm"
                className="min-w-10 truncate py-3"
                data-testid="name-field-title-select-trigger"
              />
              <Select.Content className="min-w-16">
                {TITLES.map((title) => (
                  <Select.Item
                    data-testid={`name-field-title-select-content-option-${title}`}
                    key={`name-field-title-select-content-option-${title}`}
                    value={title}
                  >
                    {title}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          </div>
        )}

        <input
          placeholder={t('components.data_table.attributes.field_labels.name.first_placeholder')}
          className={cn(
            'z-10 min-w-0 flex-grow resize-none rounded-sm border-0 bg-base p-1 px-2 ring-2 ring-transparent hover:cursor-pointer hover:bg-subtle focus:ring-2 focus:ring-black hover:focus:bg-base',
            {
              'size-full opacity-0': !selectedCell.isEditing,
              'ring-2 ring-destructive focus:ring-2 focus:ring-destructive': cellErrors
            }
          )}
          onClick={(e) => {
            if (!selectedCell.isEditing) {
              setIsEditing(true);
              setCursorPositionAtTheEnd(e);
            }
          }}
          onKeyDown={handleKeyDown}
          data-testid={`edit-name-firstname-input-${rowId}-${fieldId}`}
          onFocus={setCursorPositionAtTheEnd}
          {...firstRegister}
        />

        {hasMiddleName && (
          <input
            placeholder={t('components.data_table.attributes.field_labels.name.middle_placeholder')}
            className={cn(
              'z-10 w-full resize-none rounded-sm border-0 bg-base p-1 px-2 ring-2 ring-transparent hover:cursor-pointer hover:bg-subtle focus:ring-2 focus:ring-black hover:focus:bg-base',
              {
                hidden: !selectedCell.isEditing,
                'ring-2 ring-destructive focus:ring-2 focus:ring-destructive': cellErrors
              }
            )}
            onKeyDown={handleKeyDown}
            data-testid={`edit-name-middlename-input-${rowId}-${fieldId}`}
            onFocus={setCursorPositionAtTheEnd}
            {...middleRegister}
          />
        )}

        <input
          placeholder={t('components.data_table.attributes.field_labels.name.last_placeholder')}
          className={cn(
            'z-10 min-w-0 flex-grow resize-none rounded-sm border-0 bg-base p-1 px-2 ring-2 ring-transparent hover:cursor-pointer hover:bg-subtle focus:ring-2 focus:ring-black hover:focus:bg-base',
            {
              hidden: !selectedCell.isEditing,
              'ring-2 ring-destructive focus:ring-2 focus:ring-destructive': cellErrors
            }
          )}
          onKeyDown={handleKeyDown}
          data-testid={`edit-name-lastname-input-${rowId}-${fieldId}`}
          onFocus={setCursorPositionAtTheEnd}
          {...lastRegister}
        />
      </div>

      <CellErrors
        rowId={rowId}
        fieldId={fieldId}
        testIdPrefix="name-edit-error"
        additionalErrors={Object.keys(errors).map((key) => errors[key])}
      />
      {currentField.required && errors.first && (
        <p className="absolute right-0 top-0 z-20 m-1 text-destructive">*</p>
      )}
    </form>
  );
}
