import { useFormContext, type FieldError, type FieldValues, type Path } from 'react-hook-form';
import { Input } from '@knack/asterisk-react';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { BooleanFieldInput } from '@/components/filters/BooleanFieldInput';
import { ConnectionFieldInput } from '@/components/filters/ConnectionFieldInput';
import { DateTimeFieldInput } from '@/components/filters/DateTimeFieldInput';
import { MultipleChoiceFieldInput } from '@/components/filters/MultipleChoiceFieldInput';

interface SourceFiltersInputProps<T extends FieldValues> {
  sourceObject: KnackObject;
  fieldKey: KnackFieldKey;
  formFieldName: Path<T>;
  error: FieldError | undefined;
}

export function FiltersInput<T extends FieldValues>({
  sourceObject,
  fieldKey,
  formFieldName,
  error
}: SourceFiltersInputProps<T>) {
  const { register } = useFormContext<T>();

  const field = sourceObject.fields.find((sourceObjectField) => sourceObjectField.key === fieldKey);

  if (!field) {
    return null;
  }

  switch (field.type) {
    case 'connection':
      return <ConnectionFieldInput<T> formFieldName={formFieldName} field={field} />;
    case 'date_time':
      return <DateTimeFieldInput<T> formFieldName={formFieldName} />;
    case 'multiple_choice':
      return <MultipleChoiceFieldInput<T> formFieldName={formFieldName} field={field} />;
    case 'boolean':
      return <BooleanFieldInput<T> formFieldName={formFieldName} field={field} />;
    default:
      break;
  }

  return <Input intent={error ? 'destructive' : 'default'} {...register(formFieldName)} />;
}
