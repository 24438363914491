import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Form, Input, Label } from '@knack/asterisk-react';
import i18n from 'i18next';
import { z } from 'zod';

import { type BuilderPageKey, type PageType } from '@/types/schema/BuilderPage';
import { type LoginView } from '@/types/schema/views/LoginView';
import { usePageMutation, type NewPagePayload } from '@/hooks/api/mutations/usePageMutation';
import { PAGE_NAME_MAX_CHAR_LENGTH } from '@/utils/constants';
import { useLeftPanelContext } from '@/pages/pages/LeftPanelContext';
import { getNewPageDefaultName } from '@/pages/pages/page-tree/getNewPageDefaultName';
import { usePagesPageContext } from '@/pages/pages/PagesPageContext';
import { NewPageFormLoginSection } from './NewPageFormLoginSection';
import { NewPageFormMenuSection } from './NewPageFormMenuSection';
import { NewPageFormUserPageSection } from './NewPageFormUserPageSection';

const newPageFormSchema = z.object({
  pageName: z.string().min(1, i18n.t('pages.dialogs.error_page_name_required')),
  requiresLogin: z.boolean().optional(),

  // Used when creating protected pages
  loginOptions: z
    .object({
      registrationType: z.custom<LoginView['registrationType']>().optional(),
      limitProfileAccess: z.boolean().optional(),
      allowedProfileKeys: z.custom<LoginView['allowedProfileKeys']>().optional()
    })
    .optional(),

  // Used when creating user pages
  limitProfileAccess: z.boolean().optional(),
  allowedProfileKeys: z.custom<LoginView['allowedProfileKeys']>().optional(),

  // Used when creating dropdown menu pages
  menuPages: z.custom<BuilderPageKey[]>().optional()
});

export type NewPageFormSchema = z.infer<typeof newPageFormSchema>;

interface NewFormProps {
  pageType: PageType;
  onSubmitCallback?: () => void;
  onCancel?: () => void;
}

export function NewPageForm({ pageType, onSubmitCallback, onCancel }: NewFormProps) {
  const [t] = useTranslation();
  const { createMutation } = usePageMutation();
  const { setNewPageKey, hasAppSingleLoginPage, appSingleLoginPage } = useLeftPanelContext();
  const { setIsSettingsPanelOpen } = usePagesPageContext();

  const form = useForm<NewPageFormSchema>({
    resolver: zodResolver(newPageFormSchema),
    defaultValues: {
      pageName: getNewPageDefaultName(pageType),
      requiresLogin: false,
      loginOptions: {
        registrationType: 'open',
        limitProfileAccess: false,
        allowedProfileKeys: []
      },
      ...(pageType === 'menu' && { menuPages: [] }),
      ...(pageType === 'user' && {
        limitProfileAccess: false,
        allowedProfileKeys: []
      })
    }
  });

  const {
    register,
    handleSubmit,
    formState: { isValid }
  } = form;

  function onSubmit(newPageData: NewPageFormSchema) {
    const { pageName, menuPages } = newPageData;

    const newPagePayload: NewPagePayload = {
      type: pageType,
      name: pageName
    };

    if (pageType === 'menu' && menuPages) {
      newPagePayload.menu_pages = menuPages;
    }

    if (pageType === 'page' && newPageData.requiresLogin) {
      newPagePayload.login_vars = {
        authenticated: true,
        limit_profile_access: newPageData.loginOptions?.limitProfileAccess || false,
        allowed_profiles: newPageData.loginOptions?.allowedProfileKeys || [],
        users: {
          registration: newPageData.loginOptions?.registrationType || 'open'
        }
      };
    }

    if (pageType === 'user') {
      newPagePayload.authenticated = true;
      newPagePayload.limit_profile_access = newPageData.limitProfileAccess || false;
      newPagePayload.allowed_profiles = newPageData.allowedProfileKeys || [];
    }

    if (hasAppSingleLoginPage && appSingleLoginPage) {
      newPagePayload.parent = appSingleLoginPage.slug;
    }

    createMutation.mutate(newPagePayload, {
      onSuccess: (response) => {
        setNewPageKey(response.scene.key);
        setIsSettingsPanelOpen(true);
        if (onSubmitCallback) {
          onSubmitCallback();
        }
      }
    });
  }

  return (
    <FormProvider {...form}>
      <form data-testid="new-page-form" onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
        <Form.Section>
          <Label htmlFor="new-page-input" className="mb-2 inline-block font-medium">
            {t(`pages.dialogs.new_${pageType}.page_name`)}
          </Label>
          <Input
            data-testid="new-page-input"
            id="new-page-input"
            maxLength={PAGE_NAME_MAX_CHAR_LENGTH}
            {...register('pageName')}
          />
        </Form.Section>

        {pageType === 'page' && !hasAppSingleLoginPage && <NewPageFormLoginSection />}
        {pageType === 'menu' && <NewPageFormMenuSection />}
        {pageType === 'user' && <NewPageFormUserPageSection />}

        <div className="mt-12 flex justify-end gap-2" data-testid="new-page-dialog-footer">
          {onCancel && (
            <Button onClick={onCancel} intent="minimal" data-testid="new-page-button-cancel">
              {t('actions.cancel')}
            </Button>
          )}
          <Button
            disabled={!isValid}
            type="submit"
            isLoading={createMutation.isPending}
            data-testid="new-page-submit-button"
          >
            {t('pages.dialogs.new_page.submit_button')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
