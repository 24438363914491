import { useTranslation } from 'react-i18next';

import { TextTooltip } from '@/components/TextTooltip';
import { useFieldsStore } from '@/pages/tables/fields/useFieldsStore';
import HideFieldKeysSvg from '@/assets/svg/hide-field-keys.svg?react';
import ShowFieldKeysSvg from '@/assets/svg/show-field-keys.svg?react';

export function ShowFieldKeys() {
  const [t] = useTranslation();

  const shouldShowFieldKeys = useFieldsStore().use.shouldShowFieldKeys();

  const { setShouldShowFieldKeys } = useFieldsStore().use.actions();

  return (
    <TextTooltip
      className="mr-2"
      label={t(
        `pages.tables_page.fields_list.header.${shouldShowFieldKeys ? 'hide' : 'show'}_field_keys`
      )}
    >
      <button type="button" onClick={() => setShouldShowFieldKeys(!shouldShowFieldKeys)}>
        {shouldShowFieldKeys ? <ShowFieldKeysSvg /> : <HideFieldKeysSvg />}
        <span className="sr-only">
          {t(
            `pages.tables_page.fields_list.header.${shouldShowFieldKeys ? 'hide' : 'show'}_field_keys`
          )}
        </span>
      </button>
    </TextTooltip>
  );
}
