import { type KnackObject } from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';

export function InlineKnackObject({ objectKey }: { objectKey: KnackObject['key'] }) {
  const { data: application } = useApplicationQuery();

  if (!application) {
    return null;
  }

  const object = application.objects.find((obj) => obj.key === objectKey);

  if (!object) {
    return null;
  }

  return (
    <span className="rounded-md border border-default bg-action p-1 leading-none text-emphasis">
      {object.name}
    </span>
  );
}
