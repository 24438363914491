import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { ApiWrapper } from '@/utils/api-wrapper';
import { getBuilderBaseUrl } from '@/utils/application';
import { isFlagEnabled } from '@/utils/flagsmith';
import { ROUTES } from '@/Router';

async function createTemplateObjects(templates: string[]) {
  const response = await ApiWrapper.createObjectFromTemplates(templates);
  return response;
}

export function useCreateTemplateObjectsMutation() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  return useMutation({
    mutationFn: (templates: string[]) => createTemplateObjects(templates),
    onSuccess: (response) => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.application] });

      const shouldRedirectToNewBuilder =
        (searchParams.has('origin') && searchParams.get('origin') === 'builder-next') ||
        isFlagEnabled('full_nextgen_access');

      if (shouldRedirectToNewBuilder) {
        if (response.objects.length > 0) {
          navigate(
            `${generatePath(ROUTES.TABLES_ID, {
              id: response.objects[response.objects.length - 1].key
            })}`
          );
        } else {
          navigate(`${generatePath(ROUTES.TABLES)}`);
        }
      } else {
        const builderBaseUrl = getBuilderBaseUrl();

        if (response.objects.length > 0) {
          // Redirect to the last object added
          window.location.replace(
            `${builderBaseUrl}/schema/list/objects/${
              response.objects[response.objects.length - 1].key
            }/fields`
          );
        } else {
          // Redirect to the list of tables
          window.location.replace(`${builderBaseUrl}/schema/list`);
        }
      }
    }
  });
}
