import { useMemo } from 'react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';

// This returns an array of all fields for a given object, including fields from inbound and outbound connections
export function useAllVisibleFields(sourceObject: KnackObject) {
  const { getObjectByKey } = useObjectHelpers();

  const connectionFields = useMemo(
    () =>
      [...sourceObject.connections.inbound, ...sourceObject.connections.outbound]
        .map((connection) => {
          const connObject = getObjectByKey(connection.object);
          if (!connObject) {
            return [];
          }
          return connObject.fields;
        })
        .flat(),
    [getObjectByKey, sourceObject]
  );

  return [...sourceObject.fields, ...connectionFields];
}
