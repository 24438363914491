import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '@/utils/tailwind';
import { FullPageSpinner } from '@/components/FullPageSpinner';
import { useFlowsPageContext } from '@/pages/flows/FlowsPageContext';

export function FlowsList() {
  const [t] = useTranslation();
  const { isLoadingFlowsUrl, flowsUrl, isError } = useFlowsPageContext();
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);

  if (isLoadingFlowsUrl) {
    return <FullPageSpinner />;
  }

  return (
    <>
      {isError && (
        <div className="flex h-full items-center justify-center">
          <div>
            <p className="text-base text-destructive-emphasis">{t('pages.flows.load_error')}</p>

            <button
              type="button"
              className="flex h-8 w-full items-center justify-center rounded border-[0.5px] border-solid border-default bg-transparent p-2 hover:border-brand-600 hover:bg-brand-50 focus:border-brand-600 focus:bg-brand-50"
              onClick={() => window.location.reload()}
            >
              {t('pages.flows.reload_button')}
            </button>
          </div>
        </div>
      )}

      {flowsUrl && (
        <div className="size-full">
          {isLoadingIframe && <FullPageSpinner />}

          <iframe
            title="flows"
            src={flowsUrl}
            className={cn('size-full', {
              hidden: isLoadingIframe
            })}
            sandbox="allow-scripts allow-same-origin allow-storage-access-by-user-activation allow-modals allow-popups"
            onLoad={() => setIsLoadingIframe(false)}
          />
        </div>
      )}
    </>
  );
}
