import { useRef, useState } from 'react';
import { Controller, useFormContext, type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, DatePicker, Input, Label } from '@knack/asterisk-react';
import { DateTime } from 'luxon';

import { type DateTimeField } from '@/types/schema/fields';
import { useDateTimeHelpers } from '@/hooks/helpers/useDateTimeHelpers';
import { cn } from '@/utils/tailwind';
import { DAY_PICKER_FROM_YEAR } from '@/components/data-table/helpers/constants';
import { DateTimeRepeatOptions } from '@/components/import/confirm-import/date-time/DateTimeRepeatOptions';
import { TimeInput } from '@/components/import/confirm-import/date-time/TimeInput';
import { defaultDateFormatMap, type DateTimePayload, type TimeValues } from './types';

export function DateTimeInput({
  targetField,
  name,
  id
}: {
  targetField: DateTimeField;
  name: string;
  id: string;
}) {
  const [t] = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);
  const { getValues, getFieldState } = useFormContext();
  const { getFormattedDatePickerDate, getRepeatDefaultOptions } = useDateTimeHelpers();

  const inputFormat = targetField.format;
  const defaultDateFormat = inputFormat.date_format || 'mm/dd/yyyy';
  const dateFormat = defaultDateFormatMap[defaultDateFormat];
  const hasError = getFieldState(name)?.error;

  let isRangeMode = !!inputFormat.calendar;

  if (inputFormat.date_format === 'Ignore Date') {
    isRangeMode = false;
  }
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isRepeatOptionsOpen, setIsRepeatOptionsOpen] = useState(
    getValues(name)?.repeat !== undefined
  );
  const [isAllDayChecked, setIsAllDayChecked] = useState(getValues(name)?.all_day || false);

  const getFormattedDate = (date: Date) => DateTime.fromJSDate(date).toFormat(dateFormat);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldValue: DateTimePayload,
    onChange: ControllerRenderProps['onChange']
  ) => {
    const { value, id: fieldId } = e.target;
    const isEndDateInput = fieldId === `${name}.end-date-input`;

    // Only allow numbers and slashes
    if (!/^[0-9/]*$/.test(value)) {
      return;
    }

    if (isEndDateInput) {
      onChange({
        ...fieldValue,
        to: {
          ...fieldValue.to,
          date: value,
          date_formatted: value
        }
      });
    } else {
      onChange({
        ...fieldValue,
        date: value,
        date_formatted: value
      });
    }
  };

  const handleSelectDate = (
    date: Date | undefined,
    fieldValue: DateTimePayload,
    onChange: ControllerRenderProps['onChange']
  ) => {
    if (!date) {
      return;
    }
    onChange({
      ...fieldValue,
      date: DateTime.fromJSDate(date).toFormat('MM/dd/yyyy'),
      dateFormatted: getFormattedDate(date)
    });
  };

  const handleSelectEndDate = (
    date: Date | undefined,
    fieldValue: DateTimePayload,
    onChange: ControllerRenderProps['onChange']
  ) => {
    if (!date) {
      return;
    }
    onChange({
      ...fieldValue,
      to: {
        ...fieldValue.to,
        date: DateTime.fromJSDate(date).toFormat('MM/dd/yyyy'),
        dateFormatted: getFormattedDate(date)
      }
    });
  };

  const handleChangeTime = (
    time: TimeValues,
    fieldValue: DateTimePayload,
    onChange: ControllerRenderProps['onChange'],
    isEndDate?: boolean
  ) => {
    const { hours, minutes, am_pm: amPM, rawTime } = time;

    if (isEndDate) {
      if (fieldValue.to) {
        onChange({
          ...fieldValue,
          to: {
            ...fieldValue.to,
            hours,
            minutes,
            am_pm: amPM,
            rawTime
          }
        });
      } else {
        onChange(fieldValue);
      }
    } else {
      onChange({
        ...fieldValue,
        hours,
        minutes,
        am_pm: amPM,
        rawTime
      });
    }
  };

  const handleCheckboxAllDayChange = (
    fieldValue: DateTimePayload,
    onChange: ControllerRenderProps['onChange']
  ) => {
    onChange({
      ...fieldValue,
      all_day: !fieldValue.all_day
    });
    setIsAllDayChecked(!isAllDayChecked);
  };

  const handleCheckRepeatOptions = (
    fieldValue: DateTimePayload,
    onChange: ControllerRenderProps['onChange']
  ) => {
    setIsRepeatOptionsOpen(!isRepeatOptionsOpen);

    if (isRepeatOptionsOpen) {
      // Reset repeat options when unchecked
      onChange({
        ...fieldValue,
        repeat: undefined
      });
      return;
    }

    onChange({
      ...fieldValue,
      repeat: getRepeatDefaultOptions()
    });
  };

  return (
    <>
      <div
        className={cn('flex flex-wrap items-center gap-2 [&>button]:grow', {
          '*:w-full': !(inputFormat.time_format !== 'Ignore Time' && !isAllDayChecked)
        })}
      >
        {inputFormat.date_format !== 'Ignore Date' && (
          <Controller
            name={name}
            render={({ field }) => {
              const formattedDate = getFormattedDatePickerDate(
                field.value.dateFormatted,
                dateFormat
              );
              return (
                <DatePicker
                  selected={formattedDate}
                  onSelect={(val) => handleSelectDate(val, field.value, field.onChange)}
                  mode="single"
                  triggerRef={inputRef}
                  onDatePickerOpenChange={setIsDatePickerOpen}
                  calendarProps={{
                    mode: 'single',
                    fromYear: DAY_PICKER_FROM_YEAR,
                    disabled: field.value.to &&
                      field.value.to.dateFormatted && {
                        after: getFormattedDatePickerDate(field.value.to.dateFormatted, dateFormat)
                      }
                  }}
                >
                  <Input
                    id={id}
                    ref={inputRef}
                    data-testid="date-picker-input"
                    placeholder={defaultDateFormat}
                    className="text-sm"
                    intent={hasError ? 'destructive' : 'default'}
                    value={field.value.dateFormatted}
                    onChange={(e) => handleInputChange(e, field.value, field.onChange)}
                    onClick={(e) => {
                      if (isDatePickerOpen) {
                        e.stopPropagation();
                      }
                    }}
                  />
                </DatePicker>
              );
            }}
          />
        )}
        {inputFormat.time_format !== 'Ignore Time' && !isAllDayChecked && (
          <Controller
            name={name}
            render={({ field }) => (
              <TimeInput
                format={
                  inputFormat.time_format === 'Ignore Time'
                    ? 'HH:MM am'
                    : inputFormat.time_format || 'HH:MM am'
                }
                selectedTime={field.value}
                onChangeTime={(time) => handleChangeTime(time, field.value, field.onChange)}
              />
            )}
          />
        )}
        {isRangeMode && (
          <>
            <span className="w-full">{t('keywords.to')}</span>
            <Controller
              name={name}
              render={({ field }) => {
                const formattedDate = getFormattedDatePickerDate(
                  field.value.dateFormatted,
                  dateFormat
                );
                const formattedEndDate = getFormattedDatePickerDate(
                  field.value.to?.dateFormatted,
                  dateFormat
                );
                return (
                  <>
                    <DatePicker
                      selected={formattedEndDate}
                      onSelect={(val) => handleSelectEndDate(val, field.value, field.onChange)}
                      mode="single"
                      triggerRef={endInputRef}
                      onDatePickerOpenChange={setIsDatePickerOpen}
                      calendarProps={{
                        fromYear: DateTime.fromJSDate(formattedDate).year,
                        fromMonth: formattedDate,
                        mode: 'single',
                        disabled: {
                          before: getFormattedDatePickerDate(field.value.dateFormatted, dateFormat)
                        }
                      }}
                    >
                      <Input
                        id={id}
                        ref={endInputRef}
                        placeholder={defaultDateFormat}
                        className="text-sm"
                        value={field.value?.to?.dateFormatted}
                        onChange={(e) => handleInputChange(e, field.value, field.onChange)}
                        onClick={(e) => {
                          if (isDatePickerOpen) {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </DatePicker>
                    {inputFormat.time_format !== 'Ignore Time' &&
                      field.value.to &&
                      !isAllDayChecked && (
                        <TimeInput
                          format={inputFormat.time_format || 'HH:MM am'}
                          selectedTime={field.value.to}
                          onChangeTime={(time) =>
                            handleChangeTime(time, field.value, field.onChange, true)
                          }
                        />
                      )}
                  </>
                );
              }}
            />
          </>
        )}
      </div>
      {isRangeMode && (
        <>
          <div className="mt-2 flex items-center gap-2">
            <Controller
              name={name}
              render={({ field }) => (
                <>
                  <Checkbox
                    id="all_day"
                    className="bg-base"
                    checked={field.value.all_day}
                    onClick={() => handleCheckboxAllDayChange(field.value, field.onChange)}
                  />
                  <Label htmlFor="all_day">{t('attributes.field_labels.date_time.all_day')}</Label>
                  <Checkbox
                    id="repeat"
                    className="bg-base"
                    checked={field.value.repeat !== undefined}
                    onClick={() => handleCheckRepeatOptions(field.value, field.onChange)}
                  />
                  <Label htmlFor="repeat">{t('attributes.field_labels.date_time.repeat')} </Label>
                </>
              )}
            />
          </div>
          {isRepeatOptionsOpen && (
            <DateTimeRepeatOptions
              dateFormat={dateFormat}
              getFormattedDate={getFormattedDate}
              name={name}
            />
          )}
        </>
      )}
    </>
  );
}
