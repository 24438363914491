import { useTranslation } from 'react-i18next';
import { Label, Switch } from '@knack/asterisk-react';

import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';

export function ShowPoints() {
  const [t] = useTranslation();

  const { chart, updateChart } = useActiveChartContext();

  return (
    <div className="mb-2 flex items-center gap-2">
      <Switch
        id="show-point-labels"
        checked={chart.layout.data_points}
        onCheckedChange={(state) => {
          updateChart({
            layout: {
              ...chart.layout,
              data_points: state
            }
          });
        }}
      />
      <Label htmlFor="show-point-labels">
        {t('pages.element_settings.report.categories.general.show_points')}
      </Label>
    </div>
  );
}
