import { type KnackProductPlan } from '@/types/schema/KnackAccount';
import { type KnackObject } from '@/types/schema/KnackObject';

export interface BuilderApplication {
  id: string;
  slug: string;
  name: string;
  description: string;
  status: 'beta' | 'active' | 'frozen' | 'delinquent' | 'pending';
  objects: KnackObject[];
  counts: BuilderApplicationCounts;
  account: {
    id: string;
    name: string;
    slug: string;
    productPlan: KnackProductPlan;
    betaDeadline: string;
    isInternalAccount: boolean;
    isTrial: boolean;
    isHipaa: boolean;
  };
  users: {
    enabled: boolean;
    scope: BuilderUsersScope;
    registration?: 'open' | 'closed';
  };
  ecommerce: {
    enabled: boolean;
  };
  settings: BuilderApplicationSettings;
}

export const builderUsersScopeOptions = ['scene', 'application', 'none'] as const;
type BuilderUsersScope = (typeof builderUsersScopeOptions)[number];

export interface BuilderApplicationCounts {
  objects: {
    [key: string]: number;
  };
  recordCount: number;
  storage: number;
}

export interface BuilderApplicationSettings {
  cluster: string;
  clientSubdomainMap: {
    read: string[];
    write: string[];
  };
  lockoutOptions: {
    isLockoutEnforced: boolean;
    hasLockoutPasswordReset: boolean;
    lockoutMessage: string;
    passwordResetMessage: string;
    sendUserLockoutEmail: boolean;
    userLockoutEmailMessage: string;
    lockoutFailedAttempts: LockoutFailedAttempts;
    lockoutAttemptWindowInMinutes: LockoutAttemptWindowInMinutes;
    lockoutLength: LockoutLength;
  };
  inactivityTimeout: {
    isEnabled: boolean;
    timeoutInMinutes: InactivityTimeoutInMinutes;
    inactivityMessage: string;
  };
  isMainNavAuthBased: boolean;
  shouldEncodeRecordIds: boolean;
  hasSupportAccess: boolean;
  mapsAndGeocoderProvider: 'google' | 'here' | null;
  googleMapId: string | null;
  googleMapsApiKey: string;
  sso: {
    google: {
      client_id: string;
      client_secret: string;
      domain_restriction: string;
    } | null;
  };
  passwordOptions: {
    requireExpiration: boolean;
    requireExpirationMessage: string;
    requireNoReUse: boolean;
    requireNoReUseMessage: string;
    minimumCharacter: boolean;
    requireNoCommon: boolean;
    requireNumber: boolean;
    specialCharacter: boolean;
    requireLowerCase: boolean;
    requireUpperCase: boolean;
  };
  isRecordHistoryEnabled: boolean;
  isSystemFieldsEnabled: boolean;
  isSecureViewApiPayloadsEnabled: boolean;
  isHttpsRedirectEnabled: boolean;
  isScriptProtectionEnabled: boolean;
  ipWhitelist: string;
  fromEmail: string;
  technicalContact: string;
}

export const inactivityTimeoutInMinutesOptions = [1, 5, 10, 15, 30, 60] as const;
type InactivityTimeoutInMinutes = (typeof inactivityTimeoutInMinutesOptions)[number];

export const lockoutFailedAttemptsOptions = [3, 5, 10] as const;
type LockoutFailedAttempts = (typeof lockoutFailedAttemptsOptions)[number];

export const lockoutAttemptWindowInMinutesOptions = [1, 5, 15, 60] as const;
type LockoutAttemptWindowInMinutes = (typeof lockoutAttemptWindowInMinutesOptions)[number];

export const lockoutLengthOptions = [5, 15, 60, 1440, 'forever'] as const;
type LockoutLength = (typeof lockoutLengthOptions)[number];
