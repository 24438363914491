import { t } from 'i18next';

import { type MenuView } from '@/types/schema/views/MenuView';
import { generateNewViewKey } from './generateNewViewKey';

export function getMenuViewSchema() {
  const menuViewSchema: Partial<MenuView> = {
    key: generateNewViewKey(),
    type: 'menu',
    format: 'none',
    description: '',
    links: [],
    name: t('views.new_view_defaults.menu.name'),
    label: t('views.new_view_defaults.menu.label')
  };

  return menuViewSchema;
}
