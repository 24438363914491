import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCollapseVertical as CollapseIcon, BiExpandVertical as ExpandIcon } from 'react-icons/bi';
import { HiChevronDown as ChevronDownIcon, HiPlus as PlusIcon } from 'react-icons/hi2';
import { MdPlaylistAddCheck as DocumentCheckIcon } from 'react-icons/md';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type DragEndEvent
} from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { Button, Chip, Collapsible, useToast } from '@knack/asterisk-react';

import { type KnackField, type ValidationRule } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useFieldMutation } from '@/hooks/api/mutations/useFieldMutation';
import { EmptyState } from '@/components/EmptyState';
import { FieldIcon } from '@/components/FieldIcon';
import { SidePanel } from '@/components/SidePanel';
import { ValidationRuleCardWrapper } from './ValidationRuleCardWrapper';
import { ValidationRuleForm } from './ValidationRuleForm';

interface RuleToDuplicate {
  rule: ValidationRule;
  fieldKey: string;
}

export function ValidationRulesPanel({ table }: { table: KnackObject }) {
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));
  const { mutate: updateField } = useFieldMutation();
  const [shouldRenderForm, setShouldRenderForm] = useState(false);
  const [ruleToDuplicate, setRuleToDuplicate] = useState<RuleToDuplicate | null>(null);
  const [fieldsWithRules, setFieldsWithRules] = useState<KnackField[]>(
    table.fields.filter((field) => field.validation?.length)
  );
  const [fieldsWithExpandedRules, setFieldsWithExpandedRules] =
    useState<KnackField[]>(fieldsWithRules);

  useEffect(() => {
    const newFields = table.fields.filter((field) => field.validation?.length);
    setFieldsWithRules(newFields);
    setFieldsWithExpandedRules(newFields);
  }, [table.fields]);

  const handleDragEnd = (event: DragEndEvent, fieldKey: string) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const field = fieldsWithRules.find((f) => f.key === fieldKey);

      if (!field || !field.validation) return;

      const oldIndex = field.validation.findIndex((v) => v.key === active.id);
      const newIndex = field.validation.findIndex((v) => v.key === over.id);
      const newFieldsWithRules = fieldsWithRules.map((f) => {
        if (f.key === fieldKey) {
          return {
            ...f,
            validation: arrayMove(f.validation as ValidationRule[], oldIndex, newIndex)
          };
        }

        return f;
      });

      setFieldsWithRules(newFieldsWithRules as KnackField[]);

      updateField(
        {
          tableKey: table.key,
          updatedField: {
            ...field,
            validation: arrayMove(field.validation as ValidationRule[], oldIndex, newIndex)
          } as KnackField // TODO: FE-1944 iteratively refine type while working on additional field types
        },
        {
          onError: () => {
            presentToast({
              title: t(
                'components.data_table.right_sidebar.validation_rules.errors.rule_sort_error'
              )
            });
          }
        }
      );
    }
  };

  const handleRenderDuplicationForm = ({ rule, fieldKey }) => {
    setRuleToDuplicate({ rule, fieldKey });
    setShouldRenderForm(true);
  };
  // TODO: FE-1944 - scroll to added rule
  return (
    <>
      <SidePanel.Header>
        <SidePanel.Title
          className="text-xl font-medium leading-6"
          data-testid="validation-rules-title"
        >
          {t('components.data_table.right_sidebar.validation_rules.title')}
        </SidePanel.Title>
        <SidePanel.Description className="text-xs">
          {t('components.data_table.right_sidebar.validation_rules.subtitle')}
        </SidePanel.Description>
      </SidePanel.Header>

      {!fieldsWithRules.length && !shouldRenderForm && (
        <div className="mt-2">
          <EmptyState>
            <EmptyState.Icon icon={<DocumentCheckIcon />} />
            <EmptyState.Description
              className="text-emphasis"
              data-testid="validation-rules-empty-state-text"
            >
              {t('components.data_table.right_sidebar.validation_rules.description')}
            </EmptyState.Description>
          </EmptyState>
        </div>
      )}

      {shouldRenderForm ? (
        <div className="-mx-2 mt-4 h-[calc(100%-64px)] overflow-auto px-2">
          <ValidationRuleForm
            table={table}
            setShouldRenderForm={setShouldRenderForm}
            ruleToDuplicate={ruleToDuplicate?.rule}
            fieldKey={ruleToDuplicate?.fieldKey}
            setRuleToDuplicate={setRuleToDuplicate}
          />
        </div>
      ) : (
        <div className="align-center mt-4 flex flex-row justify-between gap-2">
          <Button
            className={fieldsWithRules.length <= 1 ? 'w-full' : ''}
            intent="secondary"
            onClick={() => setShouldRenderForm(true)}
            data-testid="validation-rule-add-button"
          >
            <Button.Icon icon={PlusIcon} position="left" />
            {t('components.data_table.right_sidebar.validation_rules.validation_rule')}
          </Button>

          {fieldsWithRules.length > 1 && (
            <span className="flex flex-row items-center gap-2">
              <Button
                className="py-2 pl-1 pr-2"
                intent="link"
                onClick={() => setFieldsWithExpandedRules(fieldsWithRules)}
                // We disable the 'Expand All' button if all fields are already expanded.
                disabled={fieldsWithExpandedRules.length === fieldsWithRules.length}
              >
                <Button.Icon icon={ExpandIcon} position="left" />
                {t('components.data_table.right_sidebar.expand_all')}
              </Button>
              <Button
                className="py-2 pl-1 pr-2"
                intent="link"
                onClick={() => setFieldsWithExpandedRules([])}
                // We disabled the 'Collapse All' button if both all fields are already collapsed.
                disabled={fieldsWithExpandedRules.length === 0}
              >
                <Button.Icon icon={CollapseIcon} position="left" />
                {t('components.data_table.right_sidebar.collapse_all')}
              </Button>
            </span>
          )}
        </div>
      )}

      {!shouldRenderForm && fieldsWithRules.length > 0 && (
        <>
          <div role="separator" className="mt-4 h-px bg-emphasis" />
          <div
            className="-mx-2 h-[calc(100%-124px)] space-y-4 overflow-auto px-4 pt-4"
            data-testid="validation-rules-list"
          >
            {fieldsWithRules.map((field) => (
              <Collapsible
                defaultOpen
                key={field.key}
                open={fieldsWithExpandedRules.includes(field)}
                onOpenChange={(isExpanded) => {
                  if (isExpanded) {
                    setFieldsWithExpandedRules([...fieldsWithExpandedRules, field]);
                  } else {
                    setFieldsWithExpandedRules(
                      fieldsWithExpandedRules.filter((f) => f.key !== field.key)
                    );
                  }
                }}
              >
                <Collapsible.Trigger className="group flex w-full items-center">
                  <span className="flex max-w-full items-center">
                    <FieldIcon name={field.type} size={16} className="mr-0.5 min-w-4 text-subtle" />
                    <span className="mr-1 truncate font-semibold text-emphasis">{field.name}</span>
                    <Chip
                      className="m-0 size-5 justify-center gap-0 rounded-sm bg-muted p-1 text-xs font-medium"
                      text={String(field.validation?.length)}
                    />
                  </span>
                  <ChevronDownIcon
                    size={16}
                    className="ml-auto transition-transform duration-300 group-data-[state=open]:rotate-180"
                  />
                </Collapsible.Trigger>

                <Collapsible.Content className="mt-2">
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={(e) => handleDragEnd(e, field.key)}
                    modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                  >
                    <SortableContext
                      items={field.validation?.map((rule: ValidationRule) => rule.key) ?? []}
                    >
                      <div className="space-y-2">
                        {field.validation?.map((rule: ValidationRule) => (
                          <ValidationRuleCardWrapper
                            key={rule.key}
                            rule={rule}
                            table={table}
                            field={field}
                            handleRenderDuplicationForm={handleRenderDuplicationForm}
                          />
                        ))}
                      </div>
                    </SortableContext>
                  </DndContext>
                </Collapsible.Content>
              </Collapsible>
            ))}
          </div>
        </>
      )}
    </>
  );
}
