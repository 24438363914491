import { type Dispatch, type SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Dialog, useToast } from '@knack/asterisk-react';

import {
  useApplicationSettingsMutation,
  type AppSettingsPayload
} from '@/hooks/api/mutations/useApplicationSettingsMutation';

interface EnableScriptProtectionDialogProps {
  isOpen: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
}

export function EnableScriptProtectionDialog({
  isOpen,
  onOpenChange
}: EnableScriptProtectionDialogProps) {
  const [t] = useTranslation();

  const { mutate: updateApplicationSettings } = useApplicationSettingsMutation();
  const { presentToast } = useToast();

  const handleDisableScriptProtection = async () => {
    const payload: AppSettingsPayload = {
      settings: {
        scriptProtectionEnabled: true
      }
    };
    updateApplicationSettings(payload, {
      onSuccess: () => {
        presentToast({
          title: t('app_settings.settings_saved_successfully')
        });
      },
      onError: () => {
        presentToast({
          title: t('app_settings.settings_saved_error')
        });
      }
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content>
        <Dialog.MainContent>
          <Dialog.Header className="mb-4">
            <Dialog.Title>{t('app_settings.security.confirmation_required')}</Dialog.Title>
          </Dialog.Header>
          <p>
            <Trans
              i18nKey="app_settings.security.by_enabling_script_protection"
              components={{
                1: (
                  <a
                    href="https://learn.knack.com/article/tt3nyix86r-live-app-security-settings"
                    target="_blank"
                    className="underline"
                    rel="noopener noreferrer"
                  >
                    this setting
                  </a>
                ),
                2: (
                  <a
                    href="https://learn.knack.com/article/0niud439eg-script-attack-protection-whitelisted-custom-code-elements-and-attributes"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    whitelist
                  </a>
                )
              }}
            />
          </p>
          <p className="mt-2 text-emphasis">
            {t('app_settings.security.would_you_like_to_enable')}
          </p>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button
              intent="primary-gradient"
              data-testid="discard-changes-modal-cancel-button"
              onClick={handleDisableScriptProtection}
            >
              {t('app_settings.security.i_understand_enable')}
            </Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
