import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Form, Input, Select } from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useTableMutation } from '@/hooks/api/mutations/useTableMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { TABLE_NAME_MAX_CHAR_LENGTH } from '@/utils/constants';
import { getTableValidDisplayFields, hasTableValidDisplayFields } from '@/utils/tables';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { NavigateAwayModal } from '@/components/NavigateAwayModal';

interface SettingsFormProps {
  table: KnackObject;
  onSave?: () => void;
  onCancel?: () => void;
  isUser?: boolean;
}

export function TableSettingsForm({ table, onSave, onCancel, isUser }: SettingsFormProps) {
  const [t] = useTranslation();
  const { updateMutation } = useTableMutation();
  const { data: application } = useApplicationQuery();
  const existingTableNames = application?.objects.map((object) => object.name) ?? [];

  type FormSchema = z.infer<typeof formSchema>;

  const formSchema = z.object({
    tableName: z
      .string()
      .min(1, t('components.dialogs.tables.settings.table_name_required'))
      .max(
        TABLE_NAME_MAX_CHAR_LENGTH,
        t('components.dialogs.tables.settings.table_name_max_length', {
          max: TABLE_NAME_MAX_CHAR_LENGTH
        })
      )
      .refine((name) => name === table.name || !existingTableNames.includes(name), {
        message: isUser
          ? t('components.add_table.errors.role_name_unique')
          : t('components.add_table.errors.table_name_unique')
      })
      .refine(
        (val) => val !== 'Accounts',
        (val) => ({
          type: 'table_name_reserved',
          message: t('errors.accounts_is_a_reserved_name', {
            fieldName: val
          })
        })
      ),
    displayField: z.string()
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isSubmitSuccessful }
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      tableName: table.name,
      displayField: table.identifier
    }
  });

  const handleSelectChange = (newValue: string) => {
    setValue('displayField', newValue, { shouldValidate: true });
  };

  function onSubmit(values: FormSchema) {
    updateMutation.mutate(
      {
        object_key: table.key,
        name: values.tableName,
        identifier: values.displayField
      },
      {
        onSuccess: () => {
          if (onSave) {
            onSave();
          }
        }
      }
    );
  }

  const validDisplayFields = getTableValidDisplayFields(table);
  const shouldDisableNameInput = isUser && table.profile_key === 'all_users';

  return (
    <Form data-testid="table-settings-form" onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      {!shouldDisableNameInput && (
        <Form.Section>
          <Form.Label htmlFor="table-name-input">
            {isUser
              ? t('components.dialogs.roles.settings.role_name')
              : t('components.dialogs.tables.table_name')}
          </Form.Label>
          <Input.Container>
            <Input
              data-testid="table-name-input"
              id="table-name-input"
              type="text"
              title={t('components.dialogs.tables.table_name')}
              intent={errors.tableName && 'destructive'}
              {...register('tableName')}
            />
          </Input.Container>
          <FormErrorMessage name="tableName" errors={errors} className="text-md text-destructive" />
        </Form.Section>
      )}
      {hasTableValidDisplayFields(table) && (
        <Form.Section className="flex flex-col gap-2">
          <Form.Label
            htmlFor="display-field-select"
            data-testid="display-field-select-label"
            className="mb-0"
          >
            {t('components.dialogs.tables.settings.display_field')}
          </Form.Label>
          <Select
            onValueChange={handleSelectChange}
            data-testid="display-field-select"
            defaultValue={table.identifier}
          >
            <Select.Trigger
              className="w-full truncate"
              data-testid="display-field-select-trigger"
            />
            <Select.Content>
              {validDisplayFields.map((field) => (
                <Select.Item
                  key={field.key}
                  className="truncate hover:bg-subtle"
                  value={field.key}
                  data-testid={`display-field-select-option-${field.key}`}
                >
                  {field.name}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
          <span className="text-subtle">
            {t('components.dialogs.tables.settings.if_another_table')}
          </span>
        </Form.Section>
      )}
      <div className="flex justify-end gap-2" data-testid="table-settings-dialog-footer">
        {onCancel && (
          <Button onClick={onCancel} data-testid="table-settings-button-cancel" intent="minimal">
            {t('actions.cancel')}
          </Button>
        )}
        <Button
          data-testid="table-settings-button-save"
          type="submit"
          isLoading={updateMutation.isPending}
        >
          {t('actions.save')}
        </Button>
      </div>
      <NavigateAwayModal condition={isDirty && !isSubmitSuccessful} />
    </Form>
  );
}
