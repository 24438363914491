import { Dialog } from '@knack/asterisk-react';

import {
  type ChartType,
  type ReportView,
  type ReportViewChart
} from '@/types/schema/views/ReportView';
import { AddRecordViewFlow } from '@/pages/pages/page-editor/add-view/add-record-view/AddRecordViewFlow';
import { AddRecordViewFlowContextProvider } from '@/pages/pages/page-editor/add-view/add-record-view/AddRecordViewFlowContext';
import { usePageDataSources } from '@/pages/pages/page-editor/add-view/helpers/usePageDataSources';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

interface AddNewChartModalProps {
  chartType: ChartType;
  onClose: () => void;
}

export function AddNewChartModal({ chartType, onClose }: AddNewChartModalProps) {
  const { page } = usePageEditorContext();
  const { view } = useActiveViewContext<ReportView>();
  const updateView = useUpdateView<ReportView>();
  const { getPageDataSources } = usePageDataSources();

  const pageDataSources = getPageDataSources(page);

  return (
    <Dialog open onOpenChange={onClose}>
      <Dialog.Content aria-describedby={undefined}>
        <AddRecordViewFlowContextProvider
          page={page}
          pageDataSources={pageDataSources}
          selectedViewType="report"
          selectedViewSubType={chartType}
          isAddingChartToReportView
          handleAddChartToReportView={(newChartSchema) => {
            updateView({
              rows: [
                {
                  ...view.rows[0],
                  reports: [...view.rows[0].reports, newChartSchema as ReportViewChart]
                }
              ]
            });

            onClose();
          }}
          onBack={() => {}} // the back button is not needed in this use case
        >
          <AddRecordViewFlow />
        </AddRecordViewFlowContextProvider>
      </Dialog.Content>
    </Dialog>
  );
}
