import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiCalendar as CalendarIcon } from 'react-icons/hi2';
import { DatePicker, Input, Label } from '@knack/asterisk-react';
import capitalize from 'lodash.capitalize';
import { DateTime } from 'luxon';

import { type ReportViewChartGroup } from '@/types/schema/views/ReportView';
import { useDateTimeHelpers } from '@/hooks/helpers/useDateTimeHelpers';
import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';

const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';

type CategoryGroupDateByInputsProps = {
  group: ReportViewChartGroup;
  index: number;
};

type DatePickerInputProps = {
  defaultDateValue?: string;
  disableBeforeDate?: string;
  onSubmitDate: (date: string) => void;
};

function DatePickerInput({
  defaultDateValue,
  disableBeforeDate,
  onSubmitDate
}: DatePickerInputProps) {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const dateTimeInputRef = useRef<HTMLInputElement>(null);

  const { getFormattedDatePickerDate } = useDateTimeHelpers();

  const formattedFromDate =
    getFormattedDatePickerDate(defaultDateValue, DEFAULT_DATE_FORMAT) || new Date();

  const getFormattedDate = (date: Date) => DateTime.fromJSDate(date).toFormat(DEFAULT_DATE_FORMAT);

  return (
    <div className="flex h-auto grow items-center rounded-lg border border-default bg-input p-1">
      <DatePicker
        selected={formattedFromDate}
        onSelect={(date) => {
          onSubmitDate(getFormattedDate(date));
        }}
        mode="single"
        triggerRef={dateTimeInputRef}
        onDatePickerOpenChange={setIsDatePickerOpen}
        calendarProps={{
          mode: 'single',
          ...(disableBeforeDate && {
            disabled: {
              before: getFormattedDatePickerDate(disableBeforeDate, DEFAULT_DATE_FORMAT)
            }
          })
        }}
      >
        <div className="flex items-center justify-center">
          <CalendarIcon size={20} className="shrink-0 pb-0.5" />
          <Input
            ref={dateTimeInputRef}
            value={defaultDateValue || getFormattedDate(new Date())}
            className="h-auto border-0 p-1 focus:outline-0"
            onChange={(e) => {
              const { value } = e.target;

              // Only allow numbers and slashes
              if (!/^[0-9/]*$/.test(value)) {
                return;
              }

              onSubmitDate(value);
            }}
            onClick={(e) => {
              if (isDatePickerOpen) {
                e.stopPropagation();
              }
            }}
          />
        </div>
      </DatePicker>
    </div>
  );
}

export function CategoryGroupDateByInputs({ group, index }: CategoryGroupDateByInputsProps) {
  const [t] = useTranslation();

  const { chart, updateChart } = useActiveChartContext();

  switch (group.limit) {
    case 'number':
      return (
        <Input
          data-testid={`group-limit-number-${index}`}
          type="number"
          defaultValue={group.number}
          className="[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          placeholder={t('components.add_into_existing_table.enter_number')}
          onChange={(e) => {
            const newGroups = [...chart.groups];
            newGroups[index].number = Number(e.target.value);

            updateChart({
              groups: newGroups
            });
          }}
        />
      );
    case 'from':
      return (
        <DatePickerInput
          defaultDateValue={group.date_from}
          onSubmitDate={(date) => {
            const newGroups = [...chart.groups];
            newGroups[index].date_from = date;

            updateChart({
              groups: newGroups
            });
          }}
        />
      );
    case 'from_to':
      return (
        <div className="space-y-2">
          <div className="space-y-1">
            <Label htmlFor="date-from">{t('keywords.from')}</Label>
            <DatePickerInput
              defaultDateValue={group.date_from}
              onSubmitDate={(date) => {
                const newGroups = [...chart.groups];
                newGroups[index].date_from = date;

                updateChart({
                  groups: newGroups
                });
              }}
            />
          </div>
          <div className="space-y-1">
            <Label htmlFor="date-to">{capitalize(t('keywords.to'))}</Label>
            <DatePickerInput
              defaultDateValue={group.date_to}
              onSubmitDate={(date) => {
                const newGroups = [...chart.groups];
                newGroups[index].date_to = date;

                updateChart({
                  groups: newGroups
                });
              }}
              disableBeforeDate={group.date_from}
            />
          </div>
        </div>
      );
    default:
      return null;
  }
}
