import { useTranslation } from 'react-i18next';
import { DropdownMenu } from '@knack/asterisk-react';

import { FieldsWrapper } from '@/components/import/field-type-dropdown/FieldsWrapper';
import { type Column } from '@/components/import/types';

export function Submenus({ column }: { column: Column }) {
  const [t] = useTranslation();

  return (
    <>
      <DropdownMenu.SubMenu>
        <DropdownMenu.SubTrigger
          data-testid="import-create-new-field-dropdown-trigger"
          className="p-2"
        >
          {t('components.add_into_existing_table.create_new_field')}
        </DropdownMenu.SubTrigger>
        <DropdownMenu.Portal>
          <DropdownMenu.SubContent
            className="my-2 flex w-48 flex-col p-0"
            data-testid="import-create-new-field-dropdown-content"
          >
            <FieldsWrapper column={column} />
          </DropdownMenu.SubContent>
        </DropdownMenu.Portal>
      </DropdownMenu.SubMenu>
      <DropdownMenu.SubMenu>
        <DropdownMenu.SubTrigger
          data-testid="import-map-to-existing-field-dropdown-trigger"
          className="p-2"
        >
          {t('components.add_into_existing_table.map_to_existing_field')}
        </DropdownMenu.SubTrigger>
        <DropdownMenu.Portal>
          <DropdownMenu.SubContent
            className="my-2 flex w-48 flex-col p-0"
            data-testid="import-map-to-existing-field-dropdown-content"
          >
            <FieldsWrapper column={column} shouldShowExistingFields />
          </DropdownMenu.SubContent>
        </DropdownMenu.Portal>
      </DropdownMenu.SubMenu>
    </>
  );
}
