import { t } from 'i18next';

import { type ChartType } from '@/types/schema/views/ReportView';

export function getChartNameFromType(type: ChartType) {
  switch (type) {
    case 'bar':
      return t('views.view_names.bar_chart');
    case 'line':
      return t('views.view_names.line_chart');
    case 'pie':
      return t('views.view_names.pie_chart');
    case 'area':
      return t('views.view_names.area_chart');
    case 'table':
      return t('views.view_names.pivot_table');
    default:
      return '';
  }
}
