import { Trans, useTranslation } from 'react-i18next';

import { getChartNameFromType } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { useAddRecordViewFlowContext } from './AddRecordViewFlowContext';

export function FlowConfirmContent() {
  const [t] = useTranslation();

  const {
    selectedViewSourcePath,
    selectedViewSource,
    selectedViewType,
    selectedViewSubType,
    isAddingChartToReportView
  } = useAddRecordViewFlowContext();

  if (!selectedViewSourcePath || !selectedViewSource || !selectedViewType) {
    return null;
  }

  let actionKey = 'displays';
  if (selectedViewType === 'form') {
    if (selectedViewSource?.recordDisplayQuantity === 'one') {
      actionKey = 'updates';
    } else {
      actionKey = 'inserts';
    }
  }

  // Lowercase the first letter
  const recordDisplayOption =
    selectedViewSourcePath.label.charAt(0).toLowerCase() + selectedViewSourcePath.label.slice(1);

  if (isAddingChartToReportView && selectedViewSubType) {
    return (
      <div>
        <Trans
          i18nKey="pages.element_settings.report.add_new_chart.adding_chart_that_displays"
          values={{
            chartName: getChartNameFromType(selectedViewSubType),
            recordDisplayOption
          }}
          components={[
            <span key="0" className="font-semibold" />,
            <span key="1" className="font-semibold" />
          ]}
        />
      </div>
    );
  }

  return (
    <div>
      <Trans
        i18nKey={`views.add_view_dialog.confirm_add_view.adding_element_that_${actionKey}`}
        values={{
          viewName: t(`views.view_names.${selectedViewType}`),
          recordDisplayOption
        }}
      >
        <span className="font-semibold">{t(`views.view_names.${selectedViewType}`)}</span>
        <span className="font-semibold">{recordDisplayOption}</span>
      </Trans>
    </div>
  );
}
