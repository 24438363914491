import { type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { HiInformationCircle as InfoIcon } from 'react-icons/hi2';
import { Banner, Button, Dialog, Table, Tabs } from '@knack/asterisk-react';
import { DateTime } from 'luxon';

import { useMapUsageQuery } from '@/hooks/api/queries/useMapUsageQuery';

interface MapUsageDialogProps {
  isOpen: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
}

export function MapUsageDialog({ isOpen, onOpenChange }: MapUsageDialogProps) {
  const [t] = useTranslation();
  const items = [
    { value: 'map', children: t('app_settings.map_provider.map_view_loads') },
    { value: 'geocoding', children: t('app_settings.map_provider.geocoding_of_records') }
  ];

  const { data: maps } = useMapUsageQuery();

  const todayFormatted = DateTime.now().toFormat('MM/dd/yyyy');
  const headerCellClasses =
    'h-8 border-separate overflow-hidden text-ellipsis whitespace-nowrap border-x-0 bg-muted text-subtle';
  const bodyCellClasses =
    'overflow-hidden text-ellipsis whitespace-nowrap border-x-0 py-1 text-subtle';

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content width="lg">
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>{t('app_settings.map_provider.maps_usage_summary')}</Dialog.Title>
          </Dialog.Header>
          <Banner icon={InfoIcon} className="mt-4 bg-muted text-subtle" type="inline">
            <Banner.Message className="text-justify font-normal text-subtle">
              <p>{t('app_settings.map_provider.maps_usage_info')}</p>
            </Banner.Message>
          </Banner>

          <Tabs defaultValue="map" className="mt-8">
            <Tabs.List items={items} intent="page" />
            <Tabs.Content value="map">
              <p>
                {`${t('app_settings.map_provider.lifetime_monthly_average', { date: todayFormatted })} ${maps?.map_loads.monthly_average}`}
              </p>
              <div className="mt-4 max-h-60 max-w-md overflow-y-scroll rounded-lg border border-subtle">
                <Table>
                  <Table.Header className="bg-base">
                    <Table.Row className="[&>:first-child]:rounded-tl-lg [&>:last-child]:rounded-tr-lg">
                      <Table.Head className={headerCellClasses}>
                        {t('app_settings.map_provider.status')}
                      </Table.Head>
                      <Table.Head className={headerCellClasses}>
                        {t('app_settings.map_provider.lifetime_load_map_count')}
                      </Table.Head>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {maps?.map_loads.views.map((view) => (
                      <Table.Row key={view.view_key}>
                        <Table.Cell className={bodyCellClasses}>{view.view_key}</Table.Cell>
                        <Table.Cell className={bodyCellClasses}>{view.count}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </Tabs.Content>
            <Tabs.Content value="geocoding">
              <p>
                {`${t('app_settings.map_provider.estimated_number', { date: todayFormatted })} ${maps?.total_existing_geocode_count}`}
              </p>
              <div className="mt-4 max-h-60 overflow-y-scroll rounded-lg border border-subtle">
                <Table>
                  <Table.Header className="bg-base">
                    <Table.Row>
                      <Table.Head className={headerCellClasses}>
                        {t('app_settings.map_provider.status')}
                      </Table.Head>
                      <Table.Head className={headerCellClasses}>
                        {t('app_settings.map_provider.n_geocoded_address')}
                      </Table.Head>
                      <Table.Head className={headerCellClasses}>x</Table.Head>
                      <Table.Head className={headerCellClasses}>
                        {t('app_settings.map_provider.record_count')}
                      </Table.Head>
                      <Table.Head className={headerCellClasses}>=</Table.Head>
                      <Table.Head className={headerCellClasses}>
                        {t('app_settings.map_provider.total_count')}
                      </Table.Head>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {maps?.objects.map((object) => (
                      <Table.Row key={object.key}>
                        <Table.Cell className={bodyCellClasses}>{object.key}</Table.Cell>
                        <Table.Cell className={bodyCellClasses}>
                          {object.address_field_count}
                        </Table.Cell>
                        <Table.Cell className={bodyCellClasses}>x</Table.Cell>
                        <Table.Cell className={bodyCellClasses}>{object.record_count}</Table.Cell>
                        <Table.Cell className={bodyCellClasses}>=</Table.Cell>
                        <Table.Cell className={bodyCellClasses}>
                          {object.record_count * object.address_field_count}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </Tabs.Content>
          </Tabs>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal" data-testid="discard-changes-modal-cancel-button">
              {t('actions.close')}
            </Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
