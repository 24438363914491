import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@knack/asterisk-react';
import { type WorkBook } from 'xlsx';

import { useImportGoogleSheetsMutation } from '@/hooks/api/mutations/useImportGoogleSheetsMutation';
import { useImportFileMutation } from '@/hooks/api/mutations/useImportMutation';
import { useErrorToast } from '@/hooks/useErrorToast';
import { Import } from '@/components/import/Import';
import { ImportStep } from '@/components/import/types';
import { useImportStore } from '@/components/import/useImportStore';
import {
  getDefaultSubFieldParts,
  getSerializedMappedColumns,
  getSerializedNewColumns
} from '@/components/import/utils';
import { WizardLayout } from '@/components/layout/WizardLayout';
import { SPREADSHEET_FORMATS } from '@/pages/add-table/useGooglePickerApi';
import { hasUploadedFileChanged } from '@/pages/import-records/isUploadedFileChanged';

export function AddIntoExistingTable({
  onBack,
  workbook,
  file,
  token,
  googleSheetRowCountMap
}: {
  onBack: () => void;
  workbook?: WorkBook;
  file?: File | google.picker.DocumentObject;
  token?: string;
  googleSheetRowCountMap?: Record<string, number>;
}) {
  const [t] = useTranslation();
  const [step, setStep] = useState(ImportStep.FieldMapping);
  const {
    columns,
    hasHeaderRow,
    columnVisibility,
    totalRecords,
    fileName,
    existingTable,
    shouldUpdateExistingRecords,
    shouldSkipImportIfNoMatch,
    matchRules,
    getTotalVisibleColumns,
    getSelectedSheetIndex,
    shouldSkipRecordsWithErrors,
    defaultValues,
    hasPendingUploads,
    setTotalRecords
  } = useImportStore((state) => ({
    columns: state.columns,
    hasHeaderRow: state.hasHeaderRow,
    columnVisibility: state.columnVisibility,
    totalRecords: state.totalRecords,
    fileName: state.fileName,
    shouldSkipRecordsWithErrors: state.shouldSkipRecordsWithErrors,
    getTotalVisibleColumns: state.getTotalVisibleColumns,
    existingTable: state.existingTable,
    shouldUpdateExistingRecords: state.shouldUpdateExistingRecords,
    shouldSkipImportIfNoMatch: state.shouldSkipImportIfNoMatch,
    matchRules: state.matchRules,
    getSelectedSheetIndex: state.getSelectedSheetIndex,
    defaultValues: state.defaultValues,
    hasPendingUploads: state.hasPendingUploads,
    setTotalRecords: state.setTotalRecords
  }));
  const importFile = useImportFileMutation(existingTable?.key);
  const importGoogleSheet = useImportGoogleSheetsMutation(existingTable?.key);
  const presentErrorToast = useErrorToast();
  const isGoogleSheetFile =
    (file as google.picker.DocumentObject).mimeType === SPREADSHEET_FORMATS.googleSheets;

  const apiIsPending = importFile.isPending || importGoogleSheet.isPending;

  if (!file || !workbook) return null;

  const mutationCallbacks = {
    onError: async (error: Error) => {
      if (file instanceof File && (await hasUploadedFileChanged(file))) {
        presentErrorToast({
          error: [
            {
              message: t('components.add_table.errors.file_modified'),
              errorCode: 'file_modified'
            }
          ],
          fallbackKey: 'components.add_table.errors.file_modified',
          translationPrefix: 'components.add_table.errors'
        });

        onBack();
        return;
      }

      presentErrorToast({
        error,
        fallbackKey: 'components.add_table.errors.data_import',
        translationPrefix: 'components.add_table.errors'
      });
    }
  };

  const handleGoBack = () => {
    if (step === ImportStep.FieldMapping) {
      onBack();
    } else if (step === ImportStep.AdvancedOptions) {
      setStep(ImportStep.FieldMapping);
    } else {
      setStep(ImportStep.AdvancedOptions);
    }
  };
  const handleImportFileClick = () => {
    if (step === ImportStep.FieldMapping) {
      setStep(ImportStep.AdvancedOptions);
      return;
    }
    if (step === ImportStep.AdvancedOptions) {
      setStep(ImportStep.ConfirmImport);
      return;
    }

    // The selected columns to be added as new fields
    const selectedNewColumns = columns.filter((column, index) => {
      const { existingKnackField, isThisColumnMapped, mappedColumnIndex } = column.meta;

      const isVisibleAndNotMapped =
        columnVisibility[index] && !existingKnackField && !isThisColumnMapped;

      const isVisibleAndMappedToSelf =
        columnVisibility[index] &&
        isThisColumnMapped &&
        !existingKnackField &&
        mappedColumnIndex !== undefined &&
        mappedColumnIndex === column.accessorKey;

      return isVisibleAndNotMapped || isVisibleAndMappedToSelf;
    });
    const serializedNewColumns = getSerializedNewColumns(selectedNewColumns);

    // The selected columns to be mapped into existing fields
    const selectedMappedColumns = columns.filter((column, index) => {
      const { existingKnackField, isThisColumnMapped, mappedColumnIndex } = column.meta;

      const isExistingFieldVisibleAndNotMapped =
        columnVisibility[index] && existingKnackField && !isThisColumnMapped;

      const isExistingFieldVisibleAndMappedToSelf =
        columnVisibility[index] &&
        isThisColumnMapped &&
        existingKnackField &&
        mappedColumnIndex !== undefined &&
        mappedColumnIndex === column.accessorKey;

      return isExistingFieldVisibleAndNotMapped || isExistingFieldVisibleAndMappedToSelf;
    });

    const serializedMappedColumns = getSerializedMappedColumns(selectedMappedColumns);
    const serializedFieldsWithDefaultValues = defaultValues
      .filter(
        (defaultValue) => !serializedMappedColumns.some((col) => col.key === defaultValue.field)
      )
      .map((defaultValue) => {
        const foundColumn = serializedMappedColumns.find((col) => col.key === defaultValue.field);
        const field = existingTable?.fields.find((f) => f.key === defaultValue.field);
        const complex =
          field?.type === 'name' || field?.type === 'address' || field?.type === 'link';
        const parts = getDefaultSubFieldParts(field?.type ?? 'name');

        return {
          key: defaultValue.field,
          mapped: foundColumn?.mapped ?? false,
          mappedColumnIndex: foundColumn?.mappedColumnIndex,
          matchable: foundColumn?.matchable ?? false,
          name: existingTable?.fields.find((f) => f.key === defaultValue.field)?.name ?? '',
          complex,
          parts
        };
      });

    serializedMappedColumns.push(...serializedFieldsWithDefaultValues);

    const match = {
      updateRecords: shouldUpdateExistingRecords,
      insertUnmatched: !shouldSkipImportIfNoMatch,
      rules: matchRules
    };
    if (isGoogleSheetFile && token) {
      importGoogleSheet.mutate(
        {
          action: 'update',
          hasHeaderRow,
          match,
          file: file as google.picker.DocumentObject,
          serializedNewColumns,
          serializedMappedColumns,
          token,
          selectedSheetIndex: getSelectedSheetIndex(),
          tableKeyToUpdate: existingTable?.key,
          shouldSkipRecordsWithErrors,
          defaultValues,
          spreadsheetId: (file as google.picker.DocumentObject).id
        },
        {
          onError: mutationCallbacks.onError
        }
      );
    } else if (file instanceof File) {
      importFile.mutate(
        {
          action: 'update',
          hasHeaderRow,
          match,
          file,
          serializedNewColumns,
          serializedMappedColumns,
          tableKeyToUpdate: existingTable?.key,
          selectedSheetIndex: getSelectedSheetIndex(),
          shouldSkipRecordsWithErrors,
          defaultValues,
          shouldRedirectAfterImport: true
        },
        {
          onError: mutationCallbacks.onError
        }
      );
    }
  };

  const getRowCount = (): number => {
    if (isGoogleSheetFile && googleSheetRowCountMap) {
      const googleSheetRowCountArray = Object.values(googleSheetRowCountMap);
      const selectedRowCount = googleSheetRowCountArray[getSelectedSheetIndex()];
      if (hasHeaderRow) {
        setTotalRecords(selectedRowCount - 1);
        return selectedRowCount - 1;
      }
      setTotalRecords(selectedRowCount);
      return selectedRowCount;
    }
    return totalRecords;
  };

  return (
    <>
      <WizardLayout
        title={t('components.add_into_existing_table.title', {
          table_name: existingTable?.name ?? ''
        })}
        contentWidth="full"
        onClose={onBack}
        dataTestid="add-into-existing-table-wizard-layout"
      >
        <Import file={file} workbook={workbook} step={step} />
      </WizardLayout>
      <div className="fixed bottom-0 flex h-16 w-full items-center justify-center bg-base px-7 shadow-sm">
        <span className="text-subtle">
          {t('components.add_table.step', { step1: step, step2: ImportStep.ConfirmImport })}
        </span>
        <div className="fixed right-0 flex items-center">
          <span className="mr-4 text-subtle" data-testid="total-records-importing">
            {t('components.add_table.importing_records', { count: getRowCount() })}
          </span>
          <Button intent="secondary" onClick={handleGoBack} data-testid="go-back-button">
            {t('actions.back')}
          </Button>
          <Button
            className="mx-4"
            onClick={handleImportFileClick}
            isLoading={apiIsPending}
            disabled={
              apiIsPending || getTotalVisibleColumns() === 0 || !fileName || hasPendingUploads
            }
            data-testid="import-file-button"
          >
            {t('actions.next')}
          </Button>
        </div>
      </div>
    </>
  );
}
