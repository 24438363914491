import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import { ROUTES } from '@/Router';

export function SettingsPageGeneralSection() {
  const { pathname } = useLocation();
  const [t] = useTranslation();
  const tabsListItems: TabsListItem[] = [
    {
      children: (
        <Link to={ROUTES.SETTINGS_GENERAL_CORE}>{t('app_settings.general.core_settings')}</Link>
      ),
      value: ROUTES.SETTINGS_GENERAL_CORE
    },
    {
      children: (
        <Link to={ROUTES.SETTINGS_GENERAL_SECURITY}>{t('app_settings.general.security')}</Link>
      ),
      value: ROUTES.SETTINGS_GENERAL_SECURITY
    },
    {
      children: (
        <Link to={ROUTES.SETTINGS_GENERAL_MAPS}>{t('app_settings.general.map_provider')}</Link>
      ),
      value: ROUTES.SETTINGS_GENERAL_MAPS
    },
    {
      children: (
        <Link to={ROUTES.SETTINGS_GENERALS_COMPLIANCE}>
          {t('app_settings.general.data_compliance')}
        </Link>
      ),
      value: ROUTES.SETTINGS_GENERALS_COMPLIANCE
    }
  ];

  return (
    <div>
      <Tabs value={pathname}>
        <Tabs.List
          className="w-auto"
          items={tabsListItems}
          shouldDisableResponsive
          intent="page"
          shouldUseGradient
        />
      </Tabs>
      <Outlet />
    </div>
  );
}
