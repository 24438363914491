import { useRef, useState } from 'react';
import { Controller, useFormContext, type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiCalendar as CalendarIcon } from 'react-icons/hi2';
import { DatePicker, Form, Input, Label, Select } from '@knack/asterisk-react';
import { DateTime } from 'luxon';

import {
  TASK_SCHEDULE_REPEAT_FREQUENCY_OPTIONS,
  type KnackTask
} from '@/types/schema/tasks/KnackTask';
import { useDateTimeHelpers } from '@/hooks/helpers/useDateTimeHelpers';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { TimeInput } from '@/components/inputs/TimeInput';
import {
  DEFAULT_TASK_DATE_FORMAT,
  DEFAULT_TASK_TIME_FORMAT
} from '@/pages/tables/toolkit-sidebar/tasks/constants';

export function TaskScheduleFormSection() {
  const [t] = useTranslation();

  const { getFormattedDatePickerDate, getFutureDate, getCurrentTime } = useDateTimeHelpers();

  const {
    getValues,
    formState: { errors }
  } = useFormContext<KnackTask>();

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: ControllerRenderProps['onChange']
  ) => {
    const { value } = e.target;

    // Only allow numbers and slashes
    if (!/^[0-9/]*$/.test(value)) {
      return;
    }
    onChange(value);
  };

  const getFormattedDate = (date: Date) =>
    DateTime.fromJSDate(date).toFormat(DEFAULT_TASK_DATE_FORMAT);

  const handleSelectDate = (
    date: Date | undefined,
    onChange: ControllerRenderProps['onChange']
  ) => {
    if (!date) {
      return;
    }
    onChange(getFormattedDate(date));
  };

  return (
    <Form.Section>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <Label htmlFor="task-frequency-select" className="font-medium">
            {t('components.data_table.right_sidebar.tasks.schedule.frequency_label')}
          </Label>
          <Controller
            name="schedule.repeat"
            defaultValue={getValues('schedule.repeat') || TASK_SCHEDULE_REPEAT_FREQUENCY_OPTIONS[0]}
            render={({ field: currentFrequency }) => (
              <Select
                onValueChange={currentFrequency.onChange}
                defaultValue={
                  getValues('schedule.repeat') || TASK_SCHEDULE_REPEAT_FREQUENCY_OPTIONS[0]
                }
              >
                <Select.Trigger
                  id="task-frequency-select"
                  placeholder={t('actions.select')}
                  className="w-full"
                  data-testid="task-frequency-select"
                  {...currentFrequency}
                />
                <Select.Content>
                  {TASK_SCHEDULE_REPEAT_FREQUENCY_OPTIONS.map((frequencyOption) => (
                    <Select.Item key={frequencyOption} value={frequencyOption}>
                      {t(
                        `components.data_table.right_sidebar.tasks.schedule.frequency_options.${frequencyOption}`
                      )}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            )}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="task-schedule-input" className="font-medium">
            {t('components.data_table.right_sidebar.tasks.schedule.next_run_date_label')}
          </Label>
          <div className="flex w-full justify-between gap-2">
            <Controller
              name="schedule.date"
              defaultValue={getFutureDate(DEFAULT_TASK_DATE_FORMAT, 1)}
              render={({ field: { value, onChange } }) => {
                const formattedDate =
                  getFormattedDatePickerDate(value, DEFAULT_TASK_DATE_FORMAT) ||
                  getFutureDate(DEFAULT_TASK_DATE_FORMAT, 1);
                return (
                  <div
                    className={cn(
                      'flex h-auto grow items-center rounded-lg border border-default bg-input p-1',
                      {
                        'border-destructive': errors?.schedule?.date
                      }
                    )}
                  >
                    <DatePicker
                      selected={formattedDate}
                      onSelect={(val) => handleSelectDate(val, onChange)}
                      mode="single"
                      triggerRef={inputRef}
                      onDatePickerOpenChange={setIsDatePickerOpen}
                      calendarProps={{
                        mode: 'single',
                        disabled: {
                          before: new Date(getFutureDate(DEFAULT_TASK_DATE_FORMAT, 1))
                        }
                      }}
                    >
                      <div className="flex items-center justify-center">
                        <CalendarIcon size={24} className="shrink-0 pb-0.5" />
                        <Input
                          ref={inputRef}
                          value={value}
                          className="h-auto border-0 p-1 focus:outline-0"
                          onChange={(e) => handleInputChange(e, onChange)}
                          onClick={(e) => {
                            if (isDatePickerOpen) {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </div>
                    </DatePicker>
                  </div>
                );
              }}
            />
            <Controller
              name="schedule.time"
              defaultValue={getCurrentTime()}
              render={({ field: { value, onChange } }) => {
                // value comes as HH:MMam format
                const timeModeValue = value.slice(-2);
                const timeValue = value.slice(0, -2);

                return (
                  <TimeInput
                    value={timeValue}
                    format={DEFAULT_TASK_TIME_FORMAT}
                    onValueChange={(val) => {
                      onChange(`${val}${timeModeValue}`);
                    }}
                    className={cn('focus:outline-0', {
                      'border-destructive hover:border-destructive hover:outline-0 focus:border-destructive':
                        errors?.schedule?.date || errors?.schedule?.time
                    })}
                  >
                    <TimeInput.TimeModeSelect
                      value={timeModeValue.toUpperCase()}
                      onValueChange={(val) => onChange(`${timeValue}${val}`)}
                      className={cn('focus:outline-0', {
                        'border-destructive hover:border-destructive focus:border-destructive':
                          errors?.schedule?.date || errors?.schedule?.time
                      })}
                    />
                  </TimeInput>
                );
              }}
            />
          </div>
          <FormErrorMessage errors={errors} name="schedule.date" />
        </div>
      </div>
    </Form.Section>
  );
}
