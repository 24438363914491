import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon, HiXMark as RemoveIcon } from 'react-icons/hi2';
import { Fragment } from 'react/jsx-runtime';
import { Button, Form, Select } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackOperator } from '@/types/schema/KnackOperator';
import { type TableView, type TableViewDisplayRule } from '@/types/schema/views/TableView';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { FieldIcon } from '@/components/FieldIcon';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { FiltersInput } from '@/pages/pages/settings-panel/view-settings/common/FiltersInput';
import { useTableDisplayRulesHelpers } from '@/pages/pages/settings-panel/view-settings/table/data-display/display-rules/useTableDisplayRulesHelpers';

export function ViewDisplayRuleFormCriteria({
  availableViewFields
}: {
  availableViewFields: KnackField[];
}) {
  const [t] = useTranslation();

  const { view, sourceObject } = useActiveViewContext<TableView>();
  const { getDefaultDisplayRuleCriteria } = useTableDisplayRulesHelpers({ view, sourceObject });
  const { getOperatorsForField } = useFieldHelpers();
  const {
    getDefaultCriteriaValue,
    getDefaultCriteriaOperator,
    shouldResetCriteriaValue,
    shouldResetCriteriaOperator
  } = useCriteriaHelpers();

  const {
    control,
    getValues,
    clearErrors: clearFormErrors,
    formState: { errors }
  } = useFormContext<TableViewDisplayRule>();

  const {
    fields: criteriaFormFields,
    append: appendCriteria,
    remove: removeCriteria,
    update: updateCriteria
  } = useFieldArray({
    name: 'criteria',
    control
  });

  const onCriteriaFieldChange = ({
    criteriaIndex,
    newFieldKey
  }: {
    criteriaIndex: number;
    newFieldKey: KnackFieldKey;
  }) => {
    const criteriaToEdit = getValues(`criteria.${criteriaIndex}`);
    const previousField = availableViewFields.find((field) => field.key === criteriaToEdit.field);
    const newField = availableViewFields.find((field) => field.key === newFieldKey);

    if (!newField) {
      return;
    }

    updateCriteria(criteriaIndex, {
      field: newFieldKey,
      operator: shouldResetCriteriaOperator(newField, criteriaToEdit.operator)
        ? getDefaultCriteriaOperator(newField)
        : criteriaToEdit.operator,
      value:
        previousField && shouldResetCriteriaValue(newField, previousField)
          ? getDefaultCriteriaValue(newField)
          : criteriaToEdit.value
    });

    clearFormErrors();
  };

  return (
    <Form.Section className="rounded-lg bg-subtle p-2">
      {criteriaFormFields.length === 0 && (
        <p className="text-subtle">{t('components.rules.no_conditions')}</p>
      )}

      {criteriaFormFields.map((criteriaFormField, criteriaFormFieldIndex) => {
        const isFieldInView = availableViewFields.some(
          (field) => field.key === criteriaFormField.field
        );

        return (
          <Fragment key={criteriaFormField.id}>
            {criteriaFormFieldIndex > 0 && (
              <p className="my-3 text-xs font-medium">{t('components.rules.and_uppercase')}</p>
            )}

            <div className="flex">
              <div className="-m-1 flex-1 overflow-hidden p-1">
                <div className="flex gap-2">
                  <div className="flex-1">
                    <Controller
                      name={`criteria.${criteriaFormFieldIndex}.field`}
                      render={({ field: { value: fieldKey } }) => (
                        <Select
                          value={isFieldInView ? fieldKey : undefined}
                          onValueChange={(newFieldKey: KnackFieldKey) => {
                            onCriteriaFieldChange({
                              criteriaIndex: criteriaFormFieldIndex,
                              newFieldKey
                            });
                          }}
                          disabled={availableViewFields.length === 0}
                        >
                          <Select.Trigger
                            placeholder={t('actions.select')}
                            className={cn('w-full', {
                              'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                                errors?.criteria?.[criteriaFormFieldIndex]?.field
                            })}
                          />
                          <Select.Content>
                            {availableViewFields.map((field) => (
                              <Select.Item key={field.key} value={field.key}>
                                <span className="flex items-center">
                                  <FieldIcon
                                    className="mr-2 shrink-0 text-subtle"
                                    size={16}
                                    name={field.type}
                                  />
                                  {field.name}
                                </span>
                              </Select.Item>
                            ))}
                          </Select.Content>
                        </Select>
                      )}
                    />
                    <FormErrorMessage
                      name={`criteria.${criteriaFormFieldIndex}.field`}
                      className="mt-1"
                      errors={errors}
                    />
                  </div>
                  <div className="flex-1">
                    <Controller
                      name={`criteria.${criteriaFormFieldIndex}.operator`}
                      render={({ field: { value: operator } }) => (
                        <Select
                          disabled={!isFieldInView}
                          value={operator}
                          onValueChange={(newOperator: KnackOperator) => {
                            updateCriteria(criteriaFormFieldIndex, {
                              ...getValues(`criteria.${criteriaFormFieldIndex}`),
                              operator: newOperator
                            });
                            clearFormErrors();
                          }}
                        >
                          <Select.Trigger placeholder={t('actions.select')} className="w-full" />
                          <Select.Content>
                            {getOperatorsForField(criteriaFormField.field, sourceObject.fields).map(
                              (fieldOperator: string) => (
                                <Select.Item key={fieldOperator} value={fieldOperator}>
                                  {t(`operators.${snakeCase(fieldOperator)}`)}
                                </Select.Item>
                              )
                            )}
                          </Select.Content>
                        </Select>
                      )}
                    />
                  </div>
                </div>
                {!shouldHideValueBasedOnOperator(criteriaFormField.operator) && (
                  <div className="mt-2">
                    <FiltersInput<TableViewDisplayRule>
                      sourceObject={sourceObject}
                      error={errors.criteria?.[criteriaFormFieldIndex]?.value}
                      fieldKey={criteriaFormField.field}
                      formFieldName={`criteria.${criteriaFormFieldIndex}.value`}
                    />
                    <FormErrorMessage
                      errors={errors}
                      name={`criteria.${criteriaFormFieldIndex}.value`}
                      className="mt-1"
                    />
                  </div>
                )}
              </div>

              {criteriaFormFields.length > 1 && (
                <Button
                  intent="minimal"
                  aria-label={t('components.rules.delete_condition')}
                  size="xs"
                  className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
                  onClick={() => removeCriteria(criteriaFormFieldIndex)}
                >
                  <RemoveIcon size={16} />
                </Button>
              )}
            </div>
          </Fragment>
        );
      })}

      {availableViewFields.length > 0 && (
        <Button
          intent="secondary"
          aria-label={t('components.rules.add_condition')}
          className="mt-3"
          onClick={() => {
            const defaultCriteria = getDefaultDisplayRuleCriteria();
            if (defaultCriteria) {
              appendCriteria(defaultCriteria);
            }
          }}
        >
          <Button.Icon icon={PlusIcon} position="left" />
          {t('components.rules.condition')}
        </Button>
      )}
    </Form.Section>
  );
}
