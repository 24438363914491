import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label } from '@knack/asterisk-react';

import { type AddressFormatOptions } from '@/types/schema/fields';
import { cn } from '@/utils/tailwind';
import { formatAddressPlaceholder } from '@/components/data-table/display/fields/address/formatters/formatPlaceholder';
import { setCursorPositionAtTheEnd } from '@/components/data-table/helpers/setCursorPositionAtTheEnd';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export type AddressSchema = {
  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
};

type AddressEditInputProps = {
  rowId: string;
  fieldId: string;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onValid: (params: AddressSchema) => void;
  hasAutoCompleteEnabled: boolean;
  setEditAutocompleteAddress: (value: string) => void;
  editAutocompleteAddress: string;
  autoCompleteRef: React.RefObject<HTMLInputElement>;
  addressFormat: AddressFormatOptions;
};

type EditInputProps = {
  rowId: string;
  fieldId: string;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  editableInputValue: string;
  onValid: (params: AddressSchema) => void;
  addressFormat: AddressFormatOptions;
  className?: string;
};

const FIELD_INPUT_MAX_LENGTH = 100;

function EditInput({
  editableInputValue,
  rowId,
  fieldId,
  handleKeyDown,
  onValid,
  isEditing,
  setIsEditing,
  addressFormat,
  className
}: EditInputProps) {
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useFormContext();

  return (
    <div className="flex size-full flex-col gap-1">
      <Label className="font-medium">
        {formatAddressPlaceholder(editableInputValue, addressFormat)}
      </Label>
      <Input
        maxLength={FIELD_INPUT_MAX_LENGTH}
        className={cn(
          'size-full rounded border-2 bg-base ring-0 [appearance:textfield] focus:border-current focus:outline-0 focus:ring-0',
          className
        )}
        onKeyDown={handleKeyDown}
        data-testid={`edit-address-${editableInputValue}-input-${rowId}-${fieldId}`}
        onFocus={(e) => {
          setCursorPositionAtTheEnd(e);
        }}
        {...register(editableInputValue, {
          onChange: async () => {
            if (!isEditing) {
              setIsEditing(true);
            }

            await handleSubmit(onValid)();
          }
        })}
      />
      {errors[editableInputValue] && (
        <div className="w-20 min-w-full bg-action-destructive-primary">
          <p
            key={`${editableInputValue}-edit-address-error-${rowId}-${fieldId}`}
            data-testid={`${editableInputValue}-edit-address-error-${rowId}-${fieldId}`}
            className="m-1 text-inverted"
          >
            {errors[editableInputValue]?.message as string}
          </p>
        </div>
      )}
    </div>
  );
}

export function AddressEditInput({
  rowId,
  fieldId,
  handleKeyDown,
  onValid,
  hasAutoCompleteEnabled,
  setEditAutocompleteAddress,
  editAutocompleteAddress,
  autoCompleteRef,
  addressFormat
}: AddressEditInputProps) {
  const [t] = useTranslation();
  const selectedCell = useDataTableStore().use.selectedCell();
  const { setIsEditing } = useDataTableStore().use.actions();

  const { handleSubmit } = useFormContext();

  if (!selectedCell) throw new Error('No selected cell');

  const showCountryInput = addressFormat === 'international_country';

  return (
    <div className="flex flex-col gap-2">
      {hasAutoCompleteEnabled ? (
        <div className="flex flex-col gap-1">
          <Label className="font-medium">
            {t('components.data_table.attributes.field_labels.address.labels.street')}
          </Label>
          <Input
            ref={autoCompleteRef}
            key={`street-edit-address-input-${rowId}-${fieldId}`}
            data-testid={`edit-address-street-autocomplete-input-${rowId}-${fieldId}`}
            type="text"
            className="h-full grow resize-none rounded border-2 border-transparent bg-base ring-0 focus:border-current focus:outline-0 focus:ring-0"
            onChange={async (e) => {
              setEditAutocompleteAddress(e.target.value);
              await handleSubmit(onValid)();
            }}
            onKeyDown={handleKeyDown}
            value={editAutocompleteAddress || ''}
            onFocus={setCursorPositionAtTheEnd}
            maxLength={FIELD_INPUT_MAX_LENGTH}
          />
        </div>
      ) : (
        <EditInput
          editableInputValue="street"
          rowId={rowId}
          fieldId={fieldId}
          handleKeyDown={handleKeyDown}
          onValid={onValid}
          isEditing={selectedCell.isEditing}
          setIsEditing={setIsEditing}
          addressFormat={addressFormat}
        />
      )}

      <EditInput
        editableInputValue="street2"
        rowId={rowId}
        fieldId={fieldId}
        handleKeyDown={handleKeyDown}
        onValid={onValid}
        isEditing={selectedCell.isEditing}
        setIsEditing={setIsEditing}
        addressFormat={addressFormat}
      />

      <div className="flex justify-between gap-2">
        <EditInput
          editableInputValue="city"
          rowId={rowId}
          fieldId={fieldId}
          handleKeyDown={handleKeyDown}
          onValid={onValid}
          isEditing={selectedCell.isEditing}
          setIsEditing={setIsEditing}
          addressFormat={addressFormat}
        />

        <EditInput
          editableInputValue="state"
          rowId={rowId}
          fieldId={fieldId}
          handleKeyDown={handleKeyDown}
          onValid={onValid}
          isEditing={selectedCell.isEditing}
          setIsEditing={setIsEditing}
          addressFormat={addressFormat}
        />
      </div>

      <div className="flex justify-between gap-2">
        <EditInput
          editableInputValue="zip"
          rowId={rowId}
          fieldId={fieldId}
          handleKeyDown={handleKeyDown}
          onValid={onValid}
          isEditing={selectedCell.isEditing}
          setIsEditing={setIsEditing}
          addressFormat={addressFormat}
        />

        {showCountryInput && (
          <EditInput
            editableInputValue="country"
            rowId={rowId}
            fieldId={fieldId}
            handleKeyDown={handleKeyDown}
            onValid={onValid}
            isEditing={selectedCell.isEditing}
            setIsEditing={setIsEditing}
            addressFormat={addressFormat}
          />
        )}
      </div>
    </div>
  );
}
