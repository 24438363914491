import { type ReportViewChart } from '@/types/schema/views/ReportView';
import AreaChartIcon from '@/assets/svg/views/view-area-chart.svg?react';
import BarChartIcon from '@/assets/svg/views/view-bar-chart.svg?react';
import LineChartIcon from '@/assets/svg/views/view-line-chart.svg?react';
import PieChartIcon from '@/assets/svg/views/view-pie-chart.svg?react';
import PivotTableIcon from '@/assets/svg/views/view-pivot-table.svg?react';

export function ReportViewIcon({
  type,
  className
}: {
  type: ReportViewChart['type'];
  className?: string;
}) {
  switch (type) {
    case 'bar':
      return <BarChartIcon className={className} />;
    case 'line':
      return <LineChartIcon className={className} />;
    case 'pie':
      return <PieChartIcon className={className} />;
    case 'area':
      return <AreaChartIcon className={className} />;
    case 'table':
      return <PivotTableIcon className={className} />;
    default:
      return null;
  }
}
