import { lazy, Suspense } from 'react';
import { Spinner } from '@knack/asterisk-react';

import { type FontAwesomeIconLazyProps } from '@/components/font-awesome/FontAwesomeIconLazy';

export const FontAwesomeIconLazy = lazy(() =>
  import('@/components/font-awesome/FontAwesomeIconLazy').then((module) => ({
    default: module.FontAwesomeIconLazy
  }))
);

export function KnackFontAwesomeIcon(props: FontAwesomeIconLazyProps) {
  return (
    <Suspense fallback={<Spinner />}>
      <FontAwesomeIconLazy {...props} />
    </Suspense>
  );
}
