import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Tooltip } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type RecordRule } from '@/types/schema/rules/RecordRule';
import { InlineKnackCriteriaValue } from '@/components/InlineKnackCriteriaValue';
import { InlineKnackField } from '@/components/InlineKnackField';
import { RecordRuleFormDialog } from '@/components/record-rule/RecordRuleFormDialog';
import { EmptyRuleValue } from '@/pages/pages/settings-panel/view-settings/common/EmptyRuleValue';
import { ViewRecordRuleCardConnectionContent } from '@/pages/pages/settings-panel/view-settings/common/record-rules/ViewRecordRuleCardConnectionContent';
import { ViewRecordRuleCardEmailContent } from '@/pages/pages/settings-panel/view-settings/common/record-rules/ViewRecordRuleCardEmailContent';
import { ViewRecordRuleRecordCard } from '@/pages/pages/settings-panel/view-settings/common/record-rules/ViewRecordRuleCardRecordContent';
import { SortableCardWrapper } from '@/pages/pages/settings-panel/view-settings/common/SortableCardWrapper';

interface ViewRecordRuleCardProps {
  recordRule: RecordRule;
  sourceObject: KnackObject;
  sourceObjectFieldsMap: Record<KnackFieldKey, KnackField>;
  ruleNumber: number;
  isInvalid?: boolean;
  shouldScrollIntoView?: boolean;
  isDragOverlay?: boolean;
  onRuleSave: (updatedDisplayRule: RecordRule) => void;
  onRuleDuplicate: (displayRuleToDuplicate: RecordRule) => void;
  onRuleDelete: (displayRuleKey: RecordRule['key']) => void;
}

const MAX_NUMBER_OF_CONDITIONS = 4;

export function ViewRecordRuleCard({
  recordRule,
  sourceObject,
  sourceObjectFieldsMap,
  ruleNumber,
  isInvalid = false,
  shouldScrollIntoView = false,
  isDragOverlay = false,
  onRuleSave,
  onRuleDuplicate,
  onRuleDelete
}: ViewRecordRuleCardProps) {
  const [t] = useTranslation();

  const recordRuleCardRef = useRef<HTMLDivElement | null>(null);
  const [isEditRuleModalOpen, setIsEditRuleModalOpen] = useState(false);

  useEffect(() => {
    if (shouldScrollIntoView && recordRuleCardRef.current) {
      recordRuleCardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SortableCardWrapper
        ref={recordRuleCardRef}
        sortableItemId={recordRule.key}
        isDragOverlay={isDragOverlay}
        isInactiveState={isInvalid}
        onEditClick={() => setIsEditRuleModalOpen(true)}
        onDuplicateClick={() => onRuleDuplicate(recordRule)}
        onDeleteClick={() => onRuleDelete(recordRule.key)}
      >
        <div className="mb-2 inline-flex items-center">
          <span className="text-xs font-medium text-emphasis">
            {t('components.display_rule_card.rule_number', {
              number: ruleNumber
            })}
          </span>

          {isInvalid && (
            <Tooltip>
              <Tooltip.Trigger asChild>
                <Badge intent="warning" className="ml-2 cursor-pointer leading-none">
                  {t('components.display_rule_card.invalid_rule_badge')}
                </Badge>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content className="max-w-80" side="bottom">
                  {t('components.display_rule_card.invalid_form_rule_tooltip')}
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip>
          )}
        </div>
        {(recordRule.action === 'insert' || recordRule.action === 'connection') && (
          <span className="mb-2 font-medium">
            {recordRule.action === 'insert'
              ? t('components.record_rule_card.insert_new_connected_record')
              : t('components.record_rule_card.update_connected_records')}
          </span>
        )}
        {!recordRule.criteria.length && (
          <span className="text-xs">{t('components.rules.run_every_time')}</span>
        )}
        {recordRule.criteria.map((criteria, index) => {
          const criteriaField = sourceObjectFieldsMap[criteria.field];
          const isLastCriteria = index === recordRule.criteria.length - 1;
          const isMaxNumberOfConditionsReached = index === MAX_NUMBER_OF_CONDITIONS - 1;

          if (index >= MAX_NUMBER_OF_CONDITIONS) {
            return null;
          }

          if (isMaxNumberOfConditionsReached) {
            return (
              <Button
                key="max-conditions"
                intent="link"
                className="focus:bg-transparent active:bg-transparent"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditRuleModalOpen(true);
                }}
              >
                + {recordRule.criteria.length + 1 - MAX_NUMBER_OF_CONDITIONS} {t('keywords.more')}
              </Button>
            );
          }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`${criteria.field}-${index}`}>
              {criteriaField ? (
                <div className="flex flex-wrap items-center gap-1">
                  <span>{t('components.rules.when')}</span>
                  <InlineKnackField
                    fieldType={criteriaField.type}
                    fieldName={criteriaField.name}
                    className="border-none bg-action"
                  />{' '}
                  {t(`operators.${snakeCase(criteria.operator)}`)}{' '}
                  <InlineKnackCriteriaValue
                    criteria={criteria}
                    criteriaField={criteriaField}
                    className="bg-action"
                  />
                </div>
              ) : (
                <EmptyRuleValue />
              )}

              {!isLastCriteria && <div className="my-1">{t('components.rules.and_uppercase')}</div>}
            </Fragment>
          );
        })}
        {!recordRule.action && <EmptyRuleValue />}
        <div className="my-2 flex flex-wrap items-center gap-1">
          {(recordRule.action === 'insert' || recordRule.action === 'connection') && (
            <ViewRecordRuleCardConnectionContent recordRule={recordRule} />
          )}
          {recordRule.action === 'email' && (
            <ViewRecordRuleCardEmailContent
              recordRule={recordRule}
              sourceObject={sourceObject}
              setIsEditRuleModalOpen={setIsEditRuleModalOpen}
            />
          )}
          {recordRule.action === 'record' && (
            <ViewRecordRuleRecordCard
              recordRule={recordRule}
              sourceObjectFieldsMap={sourceObjectFieldsMap}
            />
          )}
        </div>
      </SortableCardWrapper>

      {isEditRuleModalOpen && (
        <RecordRuleFormDialog
          sourceObject={sourceObject}
          onOpenChange={setIsEditRuleModalOpen}
          recordRule={recordRule}
          onRuleSave={(updatedRecordRule) => {
            setIsEditRuleModalOpen(false);
            onRuleSave(updatedRecordRule);
          }}
        />
      )}
    </>
  );
}
