import React from 'react';
import { HiExternalLink as ExternalIcon } from 'react-icons/hi';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

function EmptyState({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn('flex max-w-[480px] flex-col items-center justify-center', className)}
      {...props}
    />
  );
}

function EmptyStateIcon({ className, icon }: { className?: string; icon: JSX.Element }) {
  const IconElement = icon
    ? React.cloneElement(icon, {
        size: 128,
        className: cn('fill-[url(#svg-gradient-2)] opacity-40', className)
      })
    : null;

  return IconElement;
}

function Title({ className, children, ...props }: React.HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h2 className={cn('mt-2 text-center text-lg font-medium', className)} {...props}>
      {children}
    </h2>
  );
}

function Description({ className, ...props }: React.HTMLAttributes<HTMLParagraphElement>) {
  return <p className={cn('text-center text-gray-500', className)} {...props} />;
}

function Cta({
  className,
  label,
  icon,
  callback
}: {
  className?: string;
  label: string;
  icon?: JSX.Element;
  callback: () => void;
}) {
  const IconElement = icon ? React.cloneElement(icon, { size: 16, className: 'mr-1' }) : null;

  return (
    <Button className={cn('mt-6', className)} onClick={callback}>
      {IconElement}
      {label}
    </Button>
  );
}

function Link({
  className,
  link,
  linkText,
  isExternalLink = false
}: {
  className?: string;
  link: string;
  linkText: string;
  isExternalLink?: boolean;
}) {
  return (
    <Button
      intent="link"
      asChild={isExternalLink}
      className={cn('mt-6 flex items-center', className)}
    >
      {isExternalLink ? (
        <a href={link} target="_blank" rel="noreferrer" className="mt-6 flex items-center">
          <span>{linkText}</span>
          <ExternalIcon size="16" className="ml-1" data-testid="empty-state-external-icon" />
        </a>
      ) : (
        <RouterLink to={{ pathname: link }} className="mt-6 flex items-center">
          <span>{linkText}</span>
        </RouterLink>
      )}
    </Button>
  );
}

const CompoundEmptyState = Object.assign(EmptyState, {
  Icon: EmptyStateIcon,
  Title,
  Description,
  Cta,
  Link
});

EmptyStateIcon.displayName = 'EmptyState.Icon';
Title.displayName = 'EmptyState.Title';
Description.displayName = 'EmptyState.Description';
Cta.displayName = 'EmptyState.Cta';
Link.displayName = 'EmptyState.Link';

export { CompoundEmptyState as EmptyState };
