import { useTranslation } from 'react-i18next';

import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { ChartsSettings } from '@/pages/pages/settings-panel/view-settings/report/ChartsSettings';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';
import { ChartSettings } from './chart-settings/ChartSettings';

export function ReportViewSettings() {
  const [t] = useTranslation();

  const { viewInputId, isViewInputEditMode, setIsViewInputEditMode, setViewInputId } =
    useViewInputEditModeContext();

  const onBackHandler = () => {
    if (viewInputId) {
      setIsViewInputEditMode(false);
      setViewInputId(null);
    }
  };

  return (
    <CollapsiblePanel.Content
      title={
        isViewInputEditMode
          ? t('pages.element_settings.report.chart_settings_heading')
          : t('pages.element_settings.report.heading')
      }
      onBack={isViewInputEditMode ? onBackHandler : undefined}
      shouldHideDivider={isViewInputEditMode}
    >
      {isViewInputEditMode ? <ChartSettings /> : <ChartsSettings />}
    </CollapsiblePanel.Content>
  );
}
