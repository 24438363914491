import { useEffect, useState } from 'react';
import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiTrash as DeleteIcon, HiEye as EyeIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Divider,
  Form,
  Input,
  InputPassword,
  RadioCardGroup,
  useToast
} from '@knack/asterisk-react';
import { z } from 'zod';

import {
  useApplicationSettingsMutation,
  type AppSettingsPayload
} from '@/hooks/api/mutations/useApplicationSettingsMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useCredentialsQuery } from '@/hooks/api/queries/useCredentialsQuery';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { MapUsageDialog } from '@/pages/settings/map-provider-settings/MapUsageDialog';

export function MapProviderSettings() {
  const [t] = useTranslation();
  const { data: application } = useApplicationQuery();

  const [selectedProvider, setSelectedProvider] = useState<'google' | 'here' | null>(
    application?.settings.mapsAndGeocoderProvider || null
  );
  const { mutate: updateApplicationSettings } = useApplicationSettingsMutation();
  const { presentToast } = useToast();

  const { data: decodedApiKey } = useCredentialsQuery({
    enabled: !!application?.settings.googleMapsApiKey,
    credential: application?.settings.googleMapsApiKey || ''
  });
  const [isUsageDialogOpen, setIsUsageDialogOpen] = useState(false);
  const mapProviderSettingsSchema = z.object({
    settings: z.object({
      googleMapsApiKey: z.string().nullable(),
      googleMapId: z.string().nullable()
    })
  });

  const formMethods = useForm({
    resolver: zodResolver(mapProviderSettingsSchema),
    defaultValues: {
      settings: {
        googleMapsApiKey: '',
        googleMapId: application?.settings.googleMapId || ''
      }
    }
  });
  type MapProviderSettingsSchema = z.infer<typeof mapProviderSettingsSchema>;
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = formMethods;

  const onFormSubmit: SubmitHandler<MapProviderSettingsSchema> = (data) => {
    if (selectedProvider === 'google') {
      if (!data.settings.googleMapsApiKey?.trim()) {
        setError('settings.googleMapsApiKey', {
          type: 'manual',
          message: t('app_settings.map_provider.api_key_required')
        });
        return;
      }
      if (!data.settings.googleMapId?.trim()) {
        setError('settings.googleMapId', {
          type: 'manual',
          message: t('app_settings.map_provider.map_id_required')
        });
        return;
      }
    }
    const payload: AppSettingsPayload = {
      settings: {
        mapsAndGeocoderProvider: selectedProvider,
        ...(selectedProvider === 'google' && {
          googleMapsApiKey: data.settings.googleMapsApiKey || undefined,
          googleMapId: data.settings.googleMapId || undefined
        })
      }
    };

    updateApplicationSettings(payload, {
      onSuccess: () => {
        presentToast({
          title: t('app_settings.settings_saved_successfully')
        });
      },
      onError: () => {
        setError('settings.googleMapsApiKey', {
          type: 'manual',
          message: t('app_settings.map_provider.invalid_key')
        });
      }
    });
  };

  const handleDeleteApiKey = () => {
    formMethods.setValue('settings.googleMapsApiKey', '', { shouldDirty: true });
    const payload: AppSettingsPayload = {
      settings: {
        googleMapsApiKey: ''
      }
    };
    updateApplicationSettings(payload, {
      onSuccess: () => {
        presentToast({
          title: t('app_settings.map_provider.api_key_deleted')
        });
      }
    });
  };

  const handleProviderChange = (value: 'google' | 'here' | null) => {
    setSelectedProvider(value);
  };

  useEffect(() => {
    if (decodedApiKey) {
      formMethods.setValue('settings.googleMapsApiKey', decodedApiKey, {
        shouldDirty: true
      });
    }
  }, [decodedApiKey, formMethods]);

  return (
    <>
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(onFormSubmit)} className="space-y-8">
          <Form.Section className="space-y-3">
            <Form.Label htmlFor="app_name">
              {t('app_settings.map_provider.map_provider')}
            </Form.Label>

            <RadioCardGroup
              className="flex"
              defaultValue={selectedProvider || undefined}
              onValueChange={(value: string) =>
                handleProviderChange(value as 'google' | 'here' | null)
              }
            >
              <RadioCardGroup.Card
                value="here"
                data-testid="here-radio-card"
                className="p-3 !shadow-none outline-2 sm:p-3"
                contentClassName="pointer-events-auto"
                width="100%"
              >
                <span className="text-emphasis">{t('app_settings.map_provider.knack_maps')}</span>
                <p className="text-xs text-subtle">
                  {t('app_settings.map_provider.knack_maps_description')}
                </p>
              </RadioCardGroup.Card>
              <RadioCardGroup.Card
                value="google"
                data-testid="maps-radio-card"
                className="p-3 !shadow-none outline-2 sm:p-3"
                contentClassName="pointer-events-auto"
                width="100%"
              >
                <span className="text-emphasis">{t('app_settings.map_provider.google_maps')}</span>
                <p className="text-xs text-subtle">
                  {t('app_settings.map_provider.google_maps_description')}
                </p>
              </RadioCardGroup.Card>
            </RadioCardGroup>
          </Form.Section>
          {selectedProvider === 'google' && (
            <>
              <Form.Section className="space-y-4">
                <div className="flex flex-col">
                  <Form.Label
                    htmlFor="google_maps_api_key"
                    intent={errors.settings?.googleMapsApiKey ? 'destructive' : undefined}
                  >
                    {t('app_settings.map_provider.google_maps_api_key')}
                  </Form.Label>
                  <div className="flex items-center space-x-2">
                    <div className="flex-grow">
                      <InputPassword
                        id="google_maps_api_key"
                        intent={errors.settings?.googleMapsApiKey ? 'destructive' : undefined}
                        {...register('settings.googleMapsApiKey')}
                      />
                    </div>
                    {application?.settings.googleMapsApiKey && (
                      <Button intent="minimal">
                        <DeleteIcon
                          size={20}
                          className="text-default"
                          onClick={handleDeleteApiKey}
                        />
                      </Button>
                    )}
                  </div>
                  <FormErrorMessage
                    name="settings.googleMapsApiKey"
                    errors={errors}
                    className="mt-2"
                  />
                  <Form.Label
                    htmlFor="google_map_id"
                    className="mt-4"
                    intent={errors.settings?.googleMapId ? 'destructive' : undefined}
                  >
                    {t('app_settings.map_provider.google_map_id')}
                  </Form.Label>
                  <div className="flex items-center space-x-2">
                    <div className="flex-grow">
                      <Input
                        {...register('settings.googleMapId')}
                        id="google_map_id"
                        intent={errors.settings?.googleMapId ? 'destructive' : undefined}
                      />
                      <FormErrorMessage
                        name="settings.googleMapId"
                        errors={errors}
                        className="mt-2"
                      />
                    </div>
                    {application?.settings.googleMapsApiKey && <div className="w-9" />}
                  </div>
                </div>
              </Form.Section>
              <Divider />
            </>
          )}
          <Form.Section className="flex flex-col space-y-2">
            <Form.Label htmlFor="app_name">{t('app_settings.map_provider.usage')}</Form.Label>
            <p className="text-subtle">{t('app_settings.map_provider.see_summary')}</p>
            <Button
              intent="secondary"
              className="mt-2 h-9 w-40 whitespace-nowrap"
              data-testid="view-usage-button"
              onClick={() => setIsUsageDialogOpen(true)}
            >
              <EyeIcon size={20} className="mr-1 flex-shrink-0 text-default" />
              {t('app_settings.map_provider.view_map_usage')}
            </Button>
          </Form.Section>

          <div className="flex">
            <Button data-testid="save-settings-button" type="submit" className="ml-auto mt-6">
              {t('app_settings.save_settings')}
            </Button>
          </div>
        </Form>
      </FormProvider>
      <MapUsageDialog isOpen={isUsageDialogOpen} onOpenChange={setIsUsageDialogOpen} />
    </>
  );
}
