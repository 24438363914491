import { useRef, useState } from 'react';
import { Controller, useFormContext, type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiCalendar as CalendarIcon } from 'react-icons/hi2';
import { Checkbox, DatePicker, Input, Label } from '@knack/asterisk-react';
import { DateTime } from 'luxon';

import { type DateTimeField } from '@/types/schema/fields';
import { useDateTimeHelpers } from '@/hooks/helpers/useDateTimeHelpers';
import { defaultDateFormatMap } from '@/components/import/confirm-import/date-time/types';
import { TimeInput } from '@/components/inputs/TimeInput';

export function FilterDateTimeInput({
  formFieldName,
  field
}: {
  formFieldName: string;
  field: DateTimeField;
}) {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [t] = useTranslation();

  const { setValue, getValues } = useFormContext();

  const inputFormat = field.format;
  const defaultDateFormat = inputFormat.date_format;
  const dateFormat = defaultDateFormatMap[defaultDateFormat as keyof typeof defaultDateFormatMap];
  const shouldRenderTime = inputFormat.time_format !== 'Ignore Time';
  const shouldShowTimeMode = field.format.time_format === 'HH:MM am';
  const shouldRenderDate = defaultDateFormat !== 'Ignore Date';
  const [anyDate, setAnyDate] = useState(getValues(`${formFieldName}.date`) === '');
  const [anyTime, setAnyTime] = useState(getValues(`${formFieldName}.time`) === '');
  const handleAnyTimeCheckboxChange = (checked: boolean) => {
    setValue(`${formFieldName}.anyTime`, checked);
    setAnyTime(checked);
  };

  const handleAnyDateCheckboxChange = (checked: boolean) => {
    setValue(`${formFieldName}.anyDate`, checked);
    setAnyDate(checked);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: ControllerRenderProps['onChange']
  ) => {
    const { value } = e.target;

    // Only allow numbers and slashes
    if (!/^[0-9/]*$/.test(value)) {
      return;
    }
    onChange(value);
  };

  const getFormattedDate = (date: Date) => DateTime.fromJSDate(date).toFormat(dateFormat);

  const { getFormattedDatePickerDate } = useDateTimeHelpers();

  const handleSelectDate = (
    date: Date | undefined,
    onChange: ControllerRenderProps['onChange']
  ) => {
    if (!date) {
      return;
    }
    onChange(getFormattedDate(date));
  };

  const getDefaultDate = () => DateTime.now().toFormat(dateFormat);

  const defaultValueTime = shouldShowTimeMode
    ? DateTime.now().toFormat('hh:mma').toLowerCase()
    : DateTime.now().toFormat('HH:mm');

  return (
    <div className="flex flex-col">
      <div className="flex h-auto items-center justify-between gap-1 rounded-lg border border-default bg-input p-1">
        {shouldRenderDate && (
          <Controller
            name={`${formFieldName}.date`}
            defaultValue={getDefaultDate()}
            render={({ field: { value, onChange } }) => {
              const formattedDate =
                getFormattedDatePickerDate(value, dateFormat) || getDefaultDate();
              return (
                <DatePicker
                  selected={formattedDate}
                  onSelect={(val) => handleSelectDate(val, onChange)}
                  mode="single"
                  triggerRef={inputRef}
                  onDatePickerOpenChange={setIsDatePickerOpen}
                >
                  <Input.Container>
                    <CalendarIcon
                      size={16}
                      className="absolute top-0 ml-2 flex h-full flex-col justify-center"
                    />
                    <Input
                      id={`${formFieldName}-filter-date-input`}
                      ref={inputRef}
                      placeholder={anyDate ? getDefaultDate() : ''}
                      value={value}
                      disabled={anyDate}
                      className="h-auto min-w-[120px] border-0 p-1 pl-7 focus:outline-0"
                      onChange={(e) => handleInputChange(e, onChange)}
                      onClick={(e) => {
                        if (isDatePickerOpen) {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </Input.Container>
                </DatePicker>
              );
            }}
          />
        )}

        {shouldRenderTime && (
          <Controller
            name={`${formFieldName}.time`}
            defaultValue={defaultValueTime}
            render={({ field: { value, onChange } }) => {
              // value comes as 00:00am format
              const timeModeSelectValue = value.slice(-2);
              const timeValue = shouldShowTimeMode ? value.slice(0, -2) : value;

              return (
                <TimeInput
                  disabled={anyTime}
                  value={timeValue}
                  format={field.format.time_format}
                  onValueChange={(val) => {
                    if (shouldShowTimeMode) {
                      onChange(`${val}${timeModeSelectValue}`);
                      return;
                    }
                    onChange(val);
                  }}
                >
                  {shouldShowTimeMode && (
                    <TimeInput.TimeModeSelect
                      value={timeModeSelectValue.toUpperCase()}
                      disabled={anyTime}
                      onValueChange={(val) => onChange(`${timeValue}${val.toLowerCase()}`)}
                    />
                  )}
                </TimeInput>
              );
            }}
          />
        )}
      </div>
      {shouldRenderTime && shouldRenderDate && (
        <div className="flex w-full">
          <div className="mt-1 flex w-full gap-1">
            <Checkbox onCheckedChange={handleAnyDateCheckboxChange} checked={anyDate} />
            <Label>{t('components.data_table.attributes.field_labels.date_time.any_date')}</Label>
          </div>
          <div className="mt-1 flex w-full gap-1">
            <Checkbox onCheckedChange={handleAnyTimeCheckboxChange} checked={anyTime} />
            <Label>{t('components.data_table.attributes.field_labels.date_time.any_time')}</Label>
          </div>
        </div>
      )}
    </div>
  );
}
