import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { TbSeparatorHorizontal as DividerIcon } from 'react-icons/tb';
import { Button, DropdownMenu } from '@knack/asterisk-react';

import { type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { type ViewWithDetails } from '@/types/schema/views/ViewWithDetails';
import { FieldIcon } from '@/components/FieldIcon';
import { generateDetailsViewDividerInput } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/detailsViewSchema';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewDetailsRichTextInputEditModal } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/ViewDetailsRichTextInputEditModal';
import { useUpdateViewColumns } from '@/pages/pages/settings-panel/view-settings/useUpdateViewColumns';

export function ViewDetailsAddStaticInputButton({ disabled }: { disabled?: boolean }) {
  const [t] = useTranslation();

  const { view } = useActiveViewContext<ViewWithDetails>();

  const [isSectionBreakInputModalOpen, setIsSectionBreakInputModalOpen] = useState(false);

  const updateViewColumnsSchema = useUpdateViewColumns<ViewWithDetails>();

  let { columns } = view;
  if (view.type === 'map') {
    columns = view.details.columns;
  }

  const addInputToView = (detailsInput: DetailsViewInput) => {
    if (detailsInput.type !== 'divider' && detailsInput.type !== 'special_title') {
      return;
    }

    const updatedView = {
      columns: columns.map((outerColumn) => ({
        ...outerColumn,
        // Add the new static input to the last column in the last group
        groups: outerColumn.groups.map((group, groupIndex) => {
          if (groupIndex === columns[0].groups.length - 1) {
            return {
              ...group,
              columns: group.columns.map((column, columnIndex) => {
                if (columnIndex === group.columns.length - 1) {
                  return [...column, detailsInput];
                }
                return column;
              })
            };
          }
          return group;
        })
      }))
    };

    updateViewColumnsSchema(updatedView);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenu.Trigger disabled={disabled} asChild>
          <Button intent="secondary" className="w-full">
            <PlusIcon size={16} className="mr-2" />
            {t(
              'pages.element_settings.common.categories.data_display.field_management.add_static_input'
            )}
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          <DropdownMenu.Item onClick={() => setIsSectionBreakInputModalOpen(true)}>
            <FieldIcon name="rich_text" size={16} className="mr-2 shrink-0" />
            {t(
              'pages.element_settings.common.categories.data_display.field_management.rich_text_input'
            )}
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => addInputToView(generateDetailsViewDividerInput())}>
            <DividerIcon size={16} className="mr-2 shrink-0" />
            {t(
              'pages.element_settings.common.categories.data_display.field_management.divider_input'
            )}
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu>

      {isSectionBreakInputModalOpen && (
        <ViewDetailsRichTextInputEditModal onOpenChange={setIsSectionBreakInputModalOpen} />
      )}
    </>
  );
}
