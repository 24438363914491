import { BuilderAccordion } from '@/components/BuilderAccordion';
import { ViewPaginationSettings } from '@/pages/pages/settings-panel/view-settings/common/general-settings/ViewPaginationSettings';
import { ViewTitleDescriptionSettings } from '@/pages/pages/settings-panel/view-settings/common/general-settings/ViewTitleDescriptionSettings';

export function MapGeneralSettingsCategory() {
  return (
    <BuilderAccordion>
      <ViewTitleDescriptionSettings />
      <ViewPaginationSettings />
    </BuilderAccordion>
  );
}
