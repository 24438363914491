import { ShowFieldKeys } from '@/pages/tables/fields/header/ShowFieldKeys';
import { AddNewField } from './AddNewField';
import { Searchbar } from './Searchbar';

export function FieldsHeader() {
  return (
    <div className="flex gap-2">
      <ShowFieldKeys />
      <Searchbar />
      <AddNewField />
    </div>
  );
}
