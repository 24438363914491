import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import type { LineType } from '@/types/schema/views/ReportView';
import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';

export function ChartLineType() {
  const [t] = useTranslation();

  const { chart, updateChart } = useActiveChartContext();

  const lineTypeOptions: TabsListItem[] = [
    {
      children: <span>{t('pages.element_settings.report.categories.general.curved')}</span>,
      value: 'natural'
    },
    {
      children: <span>{t('pages.element_settings.report.categories.general.straight')}</span>,
      value: 'linear'
    }
  ];

  return (
    <div className="mb-4">
      <div className="mb-2">{t('pages.element_settings.report.categories.general.line_type')}</div>
      <Tabs
        defaultValue={chart.layout.line_type}
        onValueChange={(value) => {
          updateChart({
            layout: {
              ...chart.layout,
              line_type: value as LineType
            }
          });
        }}
      >
        <Tabs.List items={lineTypeOptions} shouldDisableResponsive />
      </Tabs>
    </div>
  );
}
