import { useTranslation } from 'react-i18next';
import {
  InputWithDisabledText,
  Label,
  RadioCardGroup,
  Switch,
  type RowSpacing,
  type TableBorder
} from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { useThemesPageContext } from '@/pages/themes/ThemesPageContext';

export function TableDesign() {
  const [t] = useTranslation();
  const { theme, setThemeField } = useThemesPageContext();
  const borderOptions = [
    {
      i18nKey: 'table_format_rows_only',
      value: 'rows-only'
    },
    {
      i18nKey: 'table_format_rows_and_columns',
      value: 'rows-and-columns'
    }
  ];
  const rowSpacingOptions = [
    {
      i18nKey: 'row_spacing_compact',
      value: 'compact'
    },
    {
      i18nKey: 'row_spacing_default',
      value: 'default'
    },
    {
      i18nKey: 'row_spacing_comfortable',
      value: 'comfortable'
    }
  ];

  return (
    <div>
      <div className="my-2">{t('themes.borders')}</div>
      <RadioCardGroup
        value={theme.tables.border}
        onValueChange={(value) => {
          setThemeField((draft) => {
            draft.tables.border = value as TableBorder;
          });
        }}
      >
        {borderOptions.map((option) => (
          <RadioCardGroup.Card
            key={option.value}
            value={option.value}
            className="items-center p-3 outline-2 sm:p-3"
            width="100%"
          >
            {t(`themes.${option.i18nKey}`)}
          </RadioCardGroup.Card>
        ))}
      </RadioCardGroup>
      <div className="mb-2 mt-4 flex items-center gap-2">
        <Switch
          id="tables-max-height"
          checked={theme.tables.hasMaxHeight}
          onCheckedChange={(state) =>
            setThemeField((draft) => {
              draft.tables.hasMaxHeight = state;
            })
          }
        />
        <Label htmlFor="tables-max-height">{t('themes.max_height')}</Label>
      </div>
      <InputWithDisabledText
        type="number"
        className={cn('ml-10 w-auto max-w-[100px]', {
          hidden: !theme.tables.hasMaxHeight
        })}
        inputClassName="max-w-16"
        disabledTextRight="px"
        value={theme.tables.maxHeightPx}
        onChange={(e) => {
          setThemeField((draft) => {
            draft.tables.maxHeightPx = Number(e.target.value);
          });
        }}
      />
      <div className="mb-2 mt-6">{t('themes.row_spacing')}</div>
      <RadioCardGroup
        value={theme.tables.rowSpacing}
        onValueChange={(value) => {
          setThemeField((draft) => {
            draft.tables.rowSpacing = value as RowSpacing;
          });
        }}
      >
        {rowSpacingOptions.map((option) => (
          <RadioCardGroup.Card
            key={option.value}
            value={option.value}
            className="items-center p-3 outline-2 sm:p-3"
            width="100%"
          >
            {t(`themes.${option.i18nKey}`)}
          </RadioCardGroup.Card>
        ))}
      </RadioCardGroup>
      <div className="mb-2 mt-4 flex items-center gap-2">
        <Switch
          id="tables-alternate-row-color"
          checked={theme.tables.hasAlternateRowColor}
          onCheckedChange={(state) =>
            setThemeField((draft) => {
              draft.tables.hasAlternateRowColor = state;
            })
          }
        />
        <Label htmlFor="tables-alternate-row-color">{t('themes.alternate_row_colors')}</Label>
      </div>
      <div className="mb-2 mt-4 flex items-center gap-2">
        <Switch
          id="tables-hover-row-colors"
          checked={theme.tables.hasHoverRowColor}
          onCheckedChange={(state) =>
            setThemeField((draft) => {
              draft.tables.hasHoverRowColor = state;
            })
          }
        />
        <Label htmlFor="tables-hover-row-colors">{t('themes.hover_row_colors')}</Label>
      </div>
    </div>
  );
}
