// import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// HiCheck as CheckIcon,
import { HiOutlineLogout as LogOutIcon } from 'react-icons/hi';
// import { HiWrench as SettingsIcon } from 'react-icons/hi2';
// ThemeProviderContext
import { DropdownMenu } from '@knack/asterisk-react';

import { useLogoutUserMutation } from '@/hooks/api/mutations/useLogoutUserMutation';
import { useSession } from '@/hooks/useSession';
import { ProfileCard } from '@/components/ProfileCard';

export function NavItemProfile() {
  const [t] = useTranslation();
  const session = useSession();
  const logout = useLogoutUserMutation();
  // const { currentMode, setCurrentMode } = useContext(ThemeProviderContext);

  const handleSignOut = () => {
    logout.mutate();
  };

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger
        aria-label={t('components.layout.left_sidebar.user_options')}
        className="flex justify-center overflow-hidden rounded-lg p-1 hover:bg-subtle"
        data-testid="sidebar-profile-options-button"
      >
        <ProfileCard
          firstName={session.user.firstName}
          email={session.user.email}
          avatarUri={session.user.avatarUri}
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className="min-w-[calc(100vw-32px)] sm:min-w-[260px]"
        side="top"
        collisionPadding={16}
      >
        {/* <DropdownMenu.SubMenu>
          <DropdownMenu.SubTrigger>
            <SettingsIcon size={16} className="mr-2" data-testid="navigation-appearance" />
            <span>{t('navigation.appearance')}</span>
          </DropdownMenu.SubTrigger>
          <DropdownMenu.Portal>
            <DropdownMenu.SubContent alignOffset={-80} className="w-48">
              <DropdownMenu.Item onClick={() => setCurrentMode('light')}>
                <span>{t('navigation.light_mode')}</span>
                <DropdownMenu.Shortcut>
                  {currentMode === 'light' && <CheckIcon />}
                </DropdownMenu.Shortcut>
              </DropdownMenu.Item>
              <DropdownMenu.Item onClick={() => setCurrentMode('dark')}>
                <span>{t('navigation.dark_mode')}</span>
                <DropdownMenu.Shortcut>
                  {currentMode === 'dark' && <CheckIcon />}
                </DropdownMenu.Shortcut>
              </DropdownMenu.Item>
              <DropdownMenu.Item onClick={() => setCurrentMode('system')}>
                <span>{t('navigation.system_default')}</span>
                <DropdownMenu.Shortcut>
                  {currentMode === 'system' && <CheckIcon />}
                </DropdownMenu.Shortcut>
              </DropdownMenu.Item>
            </DropdownMenu.SubContent>
          </DropdownMenu.Portal>
        </DropdownMenu.SubMenu> */}
        <DropdownMenu.Item onSelect={handleSignOut}>
          <LogOutIcon size={16} className="mr-2" data-testid="auth-logout-button" />
          <span>{t('actions.sign_out')}</span>
        </DropdownMenu.Item>
        <DropdownMenu.Separator />
        <DropdownMenu.Item>
          <span className="max-w-56 truncate">
            {t('navigation.signed_in_as', {
              name: session.user.firstName
                ? `${session.user.firstName} ${session.user.lastName}`
                : session.user.email
            })}
          </span>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu>
  );
}
