import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Form,
  Input,
  Label,
  MultiSelect,
  type MultiSelectOption
} from '@knack/asterisk-react';
import { z } from 'zod';

import { type BuilderPage, type BuilderPageKey } from '@/types/schema/BuilderPage';
import { usePageMutation } from '@/hooks/api/mutations/usePageMutation';
import { useLeftPanelContext } from '@/pages/pages/LeftPanelContext';
import { getDropdownMenuOptions } from '@/pages/pages/page-tree/getDropdownMenuOptions';

interface NewFormProps {
  page: BuilderPage;
  onSubmitCallback?: () => void;
  onCancel?: () => void;
}

export function DropdownMenuSettings({ page, onSubmitCallback, onCancel }: NewFormProps) {
  const [t] = useTranslation();
  const { updateDropdownMutation } = usePageMutation();
  const { nonUserPages, appSingleLoginPage } = useLeftPanelContext();
  const dropDownMenuOptions = getDropdownMenuOptions({
    allPages: nonUserPages,
    dropdownPageKey: page.key,
    appSingleLoginPage
  });
  const selectedPagesForDropdown = nonUserPages?.filter((item) => item.menuPageKey === page.key);
  const [selectedPages, setSelectedPages] = useState<MultiSelectOption[]>(
    selectedPagesForDropdown?.map((item) => ({
      label: item.name,
      key: item.key,
      value: item.id
    })) || []
  );

  type FormSchema = z.infer<typeof formSchema>;

  const formSchema = z.object({
    pageName: z.string().min(1, t('pages.dialogs.error_page_name_required')),
    pageParentSlug: z.string().optional()
  });

  const {
    register,
    handleSubmit,
    formState: { isValid },
    getValues
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      pageName: page.key ? nonUserPages?.find((item) => item.key === page.key)?.name : '',
      pageParentSlug: ''
    }
  });

  function onSubmit() {
    const menuPages = selectedPages.map((item) => item.key as BuilderPageKey);

    updateDropdownMutation.mutate(
      {
        pageKey: page.key,
        name: getValues('pageName'),
        menuPages
      },
      {
        onSuccess: () => {
          if (onSubmitCallback) {
            onSubmitCallback();
          }
        }
      }
    );
  }

  return (
    <Form
      data-testid="dropdown-menu-settings-form"
      onSubmit={handleSubmit(onSubmit)}
      className="mt-2 flex flex-col gap-4"
    >
      <Form.Section>
        <Input
          data-testid="dropdown-menu-settings-input"
          id="dropdown-menu-settings-input"
          {...register('pageName')}
          placeholder={t(`pages.page_tree.new_${page.type}_name`)}
        />
        <div className="mt-2">
          <Label>{t('pages.dialogs.new_menu.select_pages')}</Label>
          <div className="mt-2">
            <MultiSelect
              id="new-menu-pages"
              shouldRenderInline
              maxVisibleChips={3}
              placeholder={t('pages.page_tree.select_pages')}
              options={dropDownMenuOptions}
              selectedOptions={selectedPages}
              isSearchEnabled
              contentClassName="z-50"
              onSelectOptions={setSelectedPages}
            />
          </div>
        </div>
      </Form.Section>
      <div className="mt-4 flex justify-end gap-2" data-testid="dropdown-menu-settings-footer">
        {onCancel && (
          <Button
            onClick={onCancel}
            intent="minimal"
            data-testid="dropdown-menu-settings-button-cancel"
          >
            {t('actions.cancel')}
          </Button>
        )}
        <Button
          disabled={!isValid}
          type="submit"
          isLoading={updateDropdownMutation.isPending}
          data-testid="dropdown-menu-settings-submit-button"
        >
          {t('actions.submit')}
        </Button>
      </div>
    </Form>
  );
}
