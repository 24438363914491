import { defaultAppTheme } from '@knack/asterisk-react';

import { FullPageSpinner } from '@/components/FullPageSpinner';
import { ThemeCard } from '@/pages/themes/themes-list/ThemeCard';
import { TopBar } from '@/pages/themes/themes-list/TopBar';
import { useThemesPageContext } from '@/pages/themes/ThemesPageContext';

export function ThemesList() {
  const { theming, isLoadingTheming } = useThemesPageContext();

  if (isLoadingTheming) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <TopBar />
      <div className="grid gap-6 p-6 lg:grid-cols-2 xl:grid-cols-3">
        <ThemeCard
          theme={defaultAppTheme}
          isActive={theming?.activeThemeId === 'default'}
          isDefaultTheme
        />
        {theming?.appThemes?.map((theme) => (
          <ThemeCard key={theme.id} theme={theme} isActive={theming?.activeThemeId === theme.id} />
        ))}
      </div>
    </>
  );
}
