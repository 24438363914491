import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineUpload as UploadIcon } from 'react-icons/hi';
import { Button } from '@knack/asterisk-react';

import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { type ImageField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { useImageUpload } from '@/components/data-table/display/fields/image/upload/useImageUpload';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function ImageUploadRenderEmpty(props: FieldRenderProps<ImageField>) {
  const { rowId, fieldId, isFloating } = props;

  const [t] = useTranslation();
  const { setIsEditing } = useDataTableStore().use.actions();

  const { getInputProps, getRootProps, isDragActive, open } = useImageUpload(props);

  useEffect(
    () => () => {
      if (isFloating) {
        setIsEditing(false);
      }
    },
    [isFloating, setIsEditing]
  );

  return (
    <div
      className="flex size-full cursor-pointer select-none flex-wrap content-start items-center justify-center gap-2 overflow-hidden p-1 align-middle"
      {...getRootProps({
        onClick: () => {
          // Open the file dialog
          open();
        }
      })}
    >
      {isFloating || isDragActive ? (
        <>
          <Button intent="secondary" className="m-0 h-fit rounded-md p-2">
            <UploadIcon size={16} />
          </Button>

          <input
            data-testid={`image-upload-input-${isFloating ? 'floating-' : ''}${rowId}-${fieldId}`}
            {...getInputProps()}
          />
          <p className="grow basis-15">
            {isDragActive
              ? t('components.data_table.image_field.upload_file')
              : t('components.data_table.image_field.drop_file')}
          </p>
        </>
      ) : (
        <div
          data-testid={`image-field-no-image-${isFloating ? 'floating-' : ''}${rowId}-${fieldId}`}
        >
          <span className="sr-only">{t('components.data_table.image_field.no_preview')}</span>
        </div>
      )}
      <CellErrors rowId={rowId} fieldId={fieldId} />
    </div>
  );
}
