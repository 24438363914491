import { type FieldErrors, type FieldValues, type Path } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { cn } from '@/utils/tailwind';

interface FormErrorMessageProps<FormSchema extends FieldValues> {
  name: Path<FormSchema>;
  className?: string;
  errors: FieldErrors;
}

export function FormErrorMessage<FormSchema extends FieldValues>({
  name,
  className,
  errors
}: FormErrorMessageProps<FormSchema>) {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <p className={cn('text-xs text-destructive', className)}>{message}</p>
      )}
    />
  );
}
