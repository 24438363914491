import { type BuilderViewSourceSchemaSortItem } from '@/types/schema/BuilderView';
import { type ListView } from '@/types/schema/views/ListView';
import { InlineKnackField } from '@/components/InlineKnackField';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { getViewSettingsSortingOptionLabel } from '@/pages/pages/settings-panel/view-settings/common/sorting/helper';

interface SortingListItemProps {
  sortItem: BuilderViewSourceSchemaSortItem;
  dataTestId: string;
}

export function SortingListItem({ sortItem, dataTestId }: SortingListItemProps) {
  const { sourceObject } = useActiveViewContext<ListView>();

  const sourceField = sourceObject.fields.find((f) => f.key === sortItem.field);

  if (!sourceField) {
    return null;
  }

  return (
    <div className="flex items-center gap-2 rounded-lg bg-subtle p-2" data-testid={dataTestId}>
      <InlineKnackField fieldType={sourceField.type} fieldName={sourceField.name} />
      <p>{getViewSettingsSortingOptionLabel(sortItem, sourceField.type)}</p>
    </div>
  );
}
