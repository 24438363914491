import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { createSelectors, zustandHashStorage } from '@/utils/zustand';
import { type FilterFormat } from '@/components/data-table/helpers/getRecords';
import { DATA_TABLE_STORE_ID } from './helpers/constants';

export type Rect = { top: number; left: number; width: number; height: number };

type DataTableStorePersist = {
  // Url params is the data that will be persisted using the hashStorage.
  filters: FilterFormat | null;
  scroll: number | null;
  sortBy: string | null;
  sortOrder: 'asc' | 'desc' | null;
  shouldSortAutomatically: boolean | null;
  page: number | null;
  rowsPerPage: number | null;
  search: string | null;

  actions: {
    setScroll: (scroll: number | null) => void;
    setSort: (sortBy: string | null, sortOrder: 'asc' | 'desc') => void;
    setFilters: (filter: FilterFormat | null) => void;
    setShouldSortAutomatically: (state: boolean) => void;
    setPage: (page: number | null) => void;
    setRowsPerPage: (page: number | null) => void;
    setSearch: (search: string | null) => void;
    reset: () => void;
  };
};

const initialStatePersist = {
  filters: null,
  scroll: null,
  sortBy: null,
  sortOrder: null,
  shouldSortAutomatically: null,
  page: null,
  rowsPerPage: null,
  search: null
};

export const createDataTableStorePersist = () =>
  createSelectors(
    create<DataTableStorePersist>()(
      persist(
        (set) => ({
          ...initialStatePersist,
          actions: {
            setScroll: async (scroll) => {
              set(() => ({ scroll }));
            },
            setFilters: async (filters) => {
              set(() => ({ filters }));
            },
            setSort: async (sortBy, sortOrder) => {
              set(() => ({ sortBy, sortOrder }));
            },
            setShouldSortAutomatically: async (shouldSortAutomatically) => {
              set(() => ({ shouldSortAutomatically }));
            },
            setPage: async (page) => {
              set(() => ({ page }));
            },
            setRowsPerPage: async (rowsPerPage) => {
              set(() => ({ rowsPerPage }));
            },
            setSearch: async (search) => {
              set(() => ({ search }));
            },
            reset: () => set(initialStatePersist)
          }
        }),
        {
          name: DATA_TABLE_STORE_ID, // unique name used for storage
          storage: createJSONStorage(() => zustandHashStorage),
          partialize: (state) => ({
            filters: state.filters,
            scroll: state.scroll,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder,
            shouldSortAutomatically: state.shouldSortAutomatically,
            page: state.page,
            rowsPerPage: state.rowsPerPage,
            search: state.search
          })
        }
      )
    )
  );
