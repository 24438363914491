type CellErrorProps = {
  message: string;
  testId?: string;
};

export function CellError(props: CellErrorProps) {
  const { message, testId } = props;
  return (
    <div className="absolute left-[-2px] top-full w-[calc(100%+4px)] bg-action-destructive-primary p-1">
      <p key={testId} className="m-1 text-inverted" data-testid={testId}>
        {message}
      </p>
    </div>
  );
}
