import { useRef, useState, type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { HiXMark as ClearSearchIcon, HiMagnifyingGlass as SearchIcon } from 'react-icons/hi2';
import { MdOutlineHistory as HistoryIcon } from 'react-icons/md';
import { Button, Divider, Input } from '@knack/asterisk-react';
import { useLocalStorage } from 'usehooks-ts';

import useKeyPress from '@/hooks/helpers/useKeyPress';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { cn } from '@/utils/tailwind';

function SearchHistoryList({
  onSearchChange,
  localStorageSearchHistory,
  setLocalStorageSearchHistory
}: {
  onSearchChange: (newValue: string) => void;
  localStorageSearchHistory: string[];
  setLocalStorageSearchHistory: Dispatch<SetStateAction<string[]>>;
}) {
  const [t] = useTranslation();
  if (localStorageSearchHistory.length <= 0) return null;

  return (
    <>
      <Divider />
      <div
        role="listbox"
        aria-label={t('components.data_table.search.search_history')}
        className="flex flex-col items-start py-2"
      >
        {localStorageSearchHistory.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="relative flex w-full justify-between" key={item + index}>
            <Button
              data-testid={`search-history-item-${index}`}
              intent="minimal"
              className="h-fit flex-1 justify-start p-0.5 py-1 pr-20 text-left text-sm text-default"
              onClick={() => {
                onSearchChange(item);
              }}
            >
              <div className="p-1">
                <HistoryIcon size="16" className="text-subtle" />
              </div>
              {item}
            </Button>
            <Button
              intent="link"
              onClick={() => {
                setLocalStorageSearchHistory((prev) => {
                  const newSearchHistory = [...prev];
                  newSearchHistory.splice(index, 1);
                  return newSearchHistory;
                });
              }}
              className="absolute inset-y-0 right-0 p-3 text-xs no-underline hover:underline"
            >
              {t('components.data_table.search.remove')}
            </Button>
          </div>
        ))}
      </div>
    </>
  );
}

export function SearchByKeyword({
  onSearchChange,
  defaultSearch,
  localStorageKey
}: {
  defaultSearch: string;
  onSearchChange: (newValue: string) => void;
  localStorageKey: string;
}) {
  const [t] = useTranslation();
  const [searchOpened, setIsSearchOpened] = useState(false);
  const [currentSearch, setCurrentSearch] = useState(defaultSearch || '');
  const [localStorageSearchHistory, setLocalStorageSearchHistory] = useLocalStorage<string[]>(
    localStorageKey,
    []
  );

  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, () => {
    setIsSearchOpened(false);
    setCurrentSearch(defaultSearch || '');
  });

  useKeyPress('Escape', () => {
    setIsSearchOpened(false);
    setCurrentSearch(defaultSearch || '');
    containerRef.current?.focus();
  });

  const handleSearch = (newValue: string) => {
    setIsSearchOpened(false);
    setCurrentSearch(newValue || '');

    // If we clear the search and it was already empty, do not trigger the search
    if (defaultSearch === '' && newValue === '') {
      return;
    }

    onSearchChange(newValue);

    // If the search is the same as the first item in the history, or it is empty do not add it to the history
    if (!(newValue !== '' && localStorageSearchHistory[0] !== newValue)) {
      return;
    }

    // If the search is already in the history, remove it and add it to the top
    const localStorageSearchHistoryFiltered = localStorageSearchHistory.filter(
      (item) => item !== newValue
    );
    // Limit the search history to 10 items
    const newSearchHistory = [newValue, ...localStorageSearchHistoryFiltered].slice(0, 10);
    setLocalStorageSearchHistory(newSearchHistory);
  };

  return (
    <div className="relative z-[11] h-9 min-w-[176px]">
      <div className="absolute right-0">
        <div
          ref={containerRef}
          tabIndex={-1}
          className={cn(
            'w-[176px] rounded-lg border border-default bg-base px-2 py-[1px] transition-all',
            {
              'w-[384px]': searchOpened
            }
          )}
        >
          <div
            className={cn('relative flex w-full items-center self-start', {
              'border-b border-black': searchOpened
            })}
          >
            <Input.Icon className="left-0 right-auto">
              <SearchIcon
                className="h-4 w-4 text-gray-500"
                onClick={() => {
                  handleSearch(currentSearch);
                }}
              />
            </Input.Icon>
            <Input
              value={currentSearch || ''}
              onChange={(e) => setCurrentSearch(e.target.value)}
              data-testid="search-by-keyword-input"
              placeholder={t('components.data_table.search.search_by_keyword')}
              className={cn('h-8 !rounded-none border-0 pl-5 text-sm focus:outline-0')}
              onFocus={() => setIsSearchOpened(true)}
              onClick={() => setIsSearchOpened(true)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch(currentSearch);
                }
              }}
            />
            {currentSearch !== '' && (
              <Button
                className="h-fit p-1"
                intent="minimal"
                data-testid="clear-search-button"
                onClick={() => {
                  handleSearch('');
                }}
              >
                <ClearSearchIcon />
              </Button>
            )}
          </div>
          {searchOpened && (
            <>
              <p className="px-0.5 py-2 text-sm text-subtle">
                {t('components.data_table.search.press_enter_to_search')}
              </p>
              <SearchHistoryList
                onSearchChange={(value) => {
                  handleSearch(value);
                }}
                localStorageSearchHistory={localStorageSearchHistory}
                setLocalStorageSearchHistory={setLocalStorageSearchHistory}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
