import { useTranslation } from 'react-i18next';
import { Dialog } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { FieldSettings } from '@/components/field-settings/FieldSettings';

type EditFieldSettingsDialogProps = {
  objectKey: string;
  field: KnackField;
  onClose: () => void;
};

export function EditFieldSettingsDialog({
  objectKey,
  field,
  onClose
}: EditFieldSettingsDialogProps) {
  const [t] = useTranslation();
  return (
    <Dialog open onOpenChange={onClose}>
      <Dialog.Content data-testid="edit-field-settings-dialog">
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {`${field.name} ${t('pages.tables_page.fields_list.field_row.contextual_menu.settings')}`}
            </Dialog.Title>
          </Dialog.Header>
          <div className="mt-6">
            <FieldSettings
              objectKey={objectKey}
              fieldId={field.key}
              initialType={field.type}
              onCancel={onClose}
              onSave={onClose}
              newFieldState={{
                isBeingCreated: false
              }}
            />
          </div>
        </Dialog.MainContent>
      </Dialog.Content>
    </Dialog>
  );
}
