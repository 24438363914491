import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiLink as LinkIcon,
  HiDocumentPlus as NewPageIcon,
  HiDocument as PageIcon,
  HiPlus as PlusIcon
} from 'react-icons/hi2';
import { Button, DropdownMenu, Label, Tabs } from '@knack/asterisk-react';

import { type MenuView } from '@/types/schema/views/MenuView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { EditableItemList } from '@/components/EditableItemList';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';
import { useViewInputDeleteModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputDeleteModeContext';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';
import { MenuAddNewLinkModal } from './add-new-link/MenuAddNewLinkModal';

export type MenuLinkType = 'existing_page' | 'new_child_page' | 'external_url';

export function MenuLinksSettings() {
  const [t] = useTranslation();

  const { view } = useActiveViewContext<MenuView>();
  const updateView = useUpdateView<MenuView>();
  const { setIsViewInputEditMode, setViewInputId } = useViewInputEditModeContext();
  const { setIsViewInputDeleteMode, setViewInputIdToDelete } = useViewInputDeleteModeContext();

  const [newLinkTypeToAdd, setNewLinkTypeToAdd] = useState<MenuLinkType>();

  const linksFormatOptions = [
    {
      children: t('pages.element_settings.menu.links_format_buttons'),
      value: 'buttons'
    },
    {
      children: t('pages.element_settings.menu.links_format_tabs'),
      value: 'tabs'
    }
  ];

  const onLinkDelete = (linkId: string) => {
    setIsViewInputDeleteMode(true);
    setViewInputIdToDelete(linkId);
  };

  const onLinkEdit = (linkId: string) => {
    setIsViewInputEditMode(true);
    setViewInputId(linkId);
  };

  return (
    <BuilderAccordion.Item isDefaultOpen label={t('pages.element_settings.menu.links')}>
      <div className="mb-4">
        <Label className="mb-2 block font-medium" htmlFor={`${view.key}-menu-link-format`}>
          {t('pages.element_settings.menu.links_format')}
        </Label>
        <Tabs
          value={view.format === 'tabs' ? 'tabs' : 'buttons'}
          onValueChange={(value) => {
            updateView({ format: value === 'tabs' ? 'tabs' : 'none' });
          }}
        >
          <Tabs.List items={linksFormatOptions} shouldDisableResponsive />
        </Tabs>
        <p className="mt-2 text-xs text-subtle">
          {t('pages.element_settings.menu.links_format_description')}
        </p>
      </div>

      <DropdownMenu>
        <DropdownMenu.Trigger asChild>
          <Button intent="secondary" className="w-full">
            <PlusIcon size={16} className="mr-2" />
            {t('pages.element_settings.menu.link')}
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          <DropdownMenu.Item onClick={() => setNewLinkTypeToAdd('existing_page')}>
            <PageIcon size={16} className="mr-2 shrink-0" />
            {t('pages.element_settings.menu.link_types.existing_page')}
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => setNewLinkTypeToAdd('new_child_page')}>
            <NewPageIcon size={16} className="mr-2 shrink-0" />
            {t('pages.element_settings.menu.link_types.new_child_page')}
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => setNewLinkTypeToAdd('external_url')}>
            <LinkIcon size={16} className="mr-2 shrink-0" />
            {t('pages.element_settings.menu.link_types.external_link')}
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu>

      {newLinkTypeToAdd && (
        <MenuAddNewLinkModal
          linkType={newLinkTypeToAdd}
          onClose={() => setNewLinkTypeToAdd(undefined)}
        />
      )}

      <EditableItemList className="mt-4">
        {view.links.map((link) => {
          const linkIcon = link.type === 'scene' ? PageIcon : LinkIcon;

          return (
            <EditableItemList.Item
              key={link.id}
              itemName={link.name}
              itemIcon={linkIcon}
              onDeleteClick={() => {
                onLinkDelete(link.id);
              }}
              onEditClick={() => {
                onLinkEdit(link.id);
              }}
            />
          );
        })}
      </EditableItemList>
    </BuilderAccordion.Item>
  );
}
