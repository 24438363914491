import { useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { type MenuLinkType } from '@/pages/pages/settings-panel/view-settings/menu/MenuLinksSettings';
import { MenuLinkExistingPageForm } from './MenuLinkExistingPageForm';
import { MenuLinkExternalUrlForm } from './MenuLinkExternalUrlForm';
import { MenuLinkNewChildPageForm } from './MenuLinkNewChildPageForm';

interface MenuAddNewLinkModalProps {
  linkType: MenuLinkType;
  onClose: () => void;
}

export function MenuAddNewLinkModal({ linkType, onClose }: MenuAddNewLinkModalProps) {
  const [t] = useTranslation();

  const { page } = usePageEditorContext();

  const formId = 'new-link-form';

  return (
    <Dialog open onOpenChange={onClose}>
      <Dialog.Content>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {t(`pages.element_settings.menu.add_new_link_modal.to_${linkType}`)}
            </Dialog.Title>
            <Dialog.Description>
              {t(
                `pages.element_settings.menu.add_new_link_modal.to_${linkType}_description`,
                linkType === 'new_child_page' ? { pageName: page.name } : undefined
              )}
            </Dialog.Description>
          </Dialog.Header>
          <div className="mt-6">
            {linkType === 'new_child_page' && (
              <MenuLinkNewChildPageForm formId={formId} onSubmit={onClose} />
            )}
            {linkType === 'existing_page' && (
              <MenuLinkExistingPageForm formId={formId} onSubmit={onClose} />
            )}
            {linkType === 'external_url' && (
              <MenuLinkExternalUrlForm formId={formId} onSubmit={onClose} />
            )}
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button type="submit" form={formId}>
            {t('actions.add')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
