export const containsOperators = ['contains', 'does not contain'] as const;
export const isOperators = ['is', 'is not'] as const;
export const stringOperators = ['starts with', 'ends with'] as const;
export const blankOperators = ['is blank', 'is not blank'] as const;
export const isOneOfOperators = ['is one of', 'is not one of'] as const;
export const higherLowerOperators = ['higher than', 'lower than'] as const;
export const isAnyOperators = ['is any'] as const;
export const characterCountOperators = [
  'character count is',
  'character count is not',
  'character count is higher than',
  'character count is lower than'
] as const;
export const regexOperators = [
  'matches regular expression',
  'does not match regular expression'
] as const;
export const timeComparisonOperators = [
  'is before',
  'is after',
  'is before current time',
  'is after current time'
] as const;
export const dayComparisonOperators = [
  'is today',
  'is today or before',
  'is today or after',
  'is before today',
  'is after today'
] as const;
export const hideValueOperators = [
  'is blank',
  'is not blank',
  'is any',
  'is today',
  'is before today',
  'is after today',
  'is before current time',
  'is after current time',
  'is today or before',
  'is today or after',
  'is a future date',
  'is not a future date',
  'has changed',
  'user'
] as const;
export const sizeOperators = ['size is less than', 'size is greater than'] as const;
export const fileTypeOperators = ['file type is', 'file type is not'] as const;
export const domainOperators = ['domain must equal', 'domain must not equal'] as const;
export const geocodingOperators = ['near'] as const;
export const dateTimeRangeOperators = [
  'is during the current',
  'is during the previous',
  'is during the next',
  'is before the previous',
  'is after the next'
] as const;
export const dateTimeExtendedRangeOperators = [
  'is a day of the week',
  'is between days of the week',
  'is between dates',
  'is a future date',
  'is not a future date'
] as const;

export const dateTimeOperators = [
  ...isOperators,
  ...timeComparisonOperators,
  ...dayComparisonOperators,
  ...dateTimeRangeOperators,
  ...blankOperators
] as const;
export const dateTimeValidationOperators = [
  ...dateTimeOperators,
  ...dateTimeExtendedRangeOperators
] as const;
export const numberOperators = [...isOperators, ...higherLowerOperators, ...blankOperators];
export const timeOperators = [...isOperators, ...timeComparisonOperators, ...blankOperators];
export const defaultOperators = [
  ...containsOperators,
  ...isOperators,
  ...stringOperators,
  ...blankOperators
];

// Universal operator type for all available Knack operators
export type KnackOperator =
  | (typeof containsOperators)[number]
  | (typeof isOperators)[number]
  | (typeof stringOperators)[number]
  | (typeof blankOperators)[number]
  | (typeof isOneOfOperators)[number]
  | (typeof higherLowerOperators)[number]
  | (typeof isAnyOperators)[number]
  | (typeof characterCountOperators)[number]
  | (typeof regexOperators)[number]
  | (typeof timeComparisonOperators)[number]
  | (typeof dayComparisonOperators)[number]
  | (typeof hideValueOperators)[number]
  | (typeof sizeOperators)[number]
  | (typeof fileTypeOperators)[number]
  | (typeof domainOperators)[number]
  | (typeof geocodingOperators)[number]
  | (typeof dateTimeRangeOperators)[number]
  | (typeof dateTimeExtendedRangeOperators)[number];

export type DateTimeOperator = (typeof dateTimeOperators)[number];
export type DateTimeValidationOperator = (typeof dateTimeValidationOperators)[number];
export type HideValueOperator = (typeof hideValueOperators)[number];
