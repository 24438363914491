import {
  type DateTimeOperator,
  type DateTimeValidationOperator
} from '@/types/schema/KnackOperator';
import {
  type AddressField,
  type AddressFieldConditionalRuleValue,
  type AddressFieldConditionalRuleValues,
  type AutoIncrementField,
  type AverageField,
  type BooleanField,
  type ConcatenationField,
  type ConnectionField,
  type ConnectionFieldConditionalRuleValue,
  type ConnectionOption,
  type CountField,
  type CurrencyField,
  type DateTimeField,
  type DateTimeFieldConditionalRuleCriteriaValue,
  type DateTimeFieldConditionalRuleValue,
  type DateTimeFieldConditionalRuleValues,
  type DateTimeFieldValidationRuleCriteria,
  type EmailField,
  type EmailFieldConditionalRuleValue,
  type EquationField,
  type FileField,
  type ImageField,
  type LinkField,
  type LinkFieldConditionalRuleValue,
  type MaxField,
  type MinField,
  type MultipleChoiceField,
  type NameField,
  type NameFieldConditionalRuleValue,
  type NumberField,
  type ParagraphTextField,
  type PasswordField,
  type PhoneField,
  type RatingField,
  type RichTextField,
  type ShortTextField,
  type SignatureField,
  type SignatureFieldConditionalRuleValue,
  type SumField,
  type TimerField,
  type TimerFieldConditionalRuleValue,
  type UserRolesField
} from './fields';

export type KnackFieldKey = `field_${string}`;

export interface BaseKnackField {
  _id: string;
  key: KnackFieldKey;
  name: string;
  required: boolean;
  unique: boolean;
  user: boolean;
  conditional: boolean;
  rules?: ConditionalRule[] | null;
  validation: ValidationRule[] | null;
  default?: string | boolean | number | null;
  immutable?: boolean;
  fromSource?: string;
  integration?: string;
  read_only?: boolean;
  meta?: {
    description?: string;
  };
  format?: unknown;
  relationship?: KnackFieldRelationship;
  ecommerce?: boolean;
}

export interface KnackFieldRelationship {
  belongs_to: ConnectionOption;
  has: ConnectionOption;
  object?: string;
}

export interface ConditionalRule {
  key: string;
  action?: string;
  connection?: string;
  criteria: ConditionalRuleCriteria[];
  values: ConditionalRuleValues[];
}

export interface ConditionalRuleValues {
  connection_field: string | null;
  field: string;
  type:
    | 'value'
    | 'record'
    | AddressFieldConditionalRuleValues['type']
    | DateTimeFieldConditionalRuleValues['type'];
  input?: string;
  value:
    | string
    | number
    | boolean
    | AddressFieldConditionalRuleValue
    | DateTimeFieldConditionalRuleValue
    | EmailFieldConditionalRuleValue
    | LinkFieldConditionalRuleValue
    | NameFieldConditionalRuleValue
    | SignatureFieldConditionalRuleValue
    | TimerFieldConditionalRuleValue
    | ConnectionFieldConditionalRuleValue;
}

export type ConditionalRuleCriteria = {
  field: string;
  operator: string | DateTimeOperator;
  value:
    | string
    | boolean
    | string[]
    | DateTimeFieldConditionalRuleCriteriaValue
    | ConnectionFieldConditionalRuleValue;
  value_field: string | boolean;
  value_type: 'custom' | 'field';
};

export type ValidationRule = {
  key: string;
  message: string;
  criteria: ValidationRuleCriteria[] | ValidationRuleCriteriaForAsset[];
  value: string;
};

export type ValidationRuleCriteria = {
  field: string;
  operator: string | DateTimeValidationOperator;
  value: string | boolean | number | DateTimeFieldValidationRuleCriteria['value'];
};

export interface ValidationRuleCriteriaForAsset extends ValidationRuleCriteria {
  unit: 'bytes' | 'kilobytes' | 'megabytes';
}

export const KNACK_FIELD_TYPES = [
  'short_text',
  'paragraph_text',
  'rich_text',
  'concatenation',
  'number',
  'currency',
  'equation',
  'auto_increment',
  'sum',
  'min',
  'max',
  'average',
  'count',
  'multiple_choice',
  'boolean',
  'date_time',
  'timer',
  'file',
  'image',
  'name',
  'email',
  'address',
  'phone',
  'link',
  'signature',
  'rating',
  'connection',
  'user_roles',
  'password'
] as const;

export type KnackFieldType = (typeof KNACK_FIELD_TYPES)[number];

export type KnackField =
  | ShortTextField
  | ParagraphTextField
  | RichTextField
  | ConcatenationField
  | NumberField
  | CurrencyField
  | EquationField
  | AutoIncrementField
  | MultipleChoiceField
  | BooleanField
  | DateTimeField
  | TimerField
  | FileField
  | ImageField
  | NameField
  | EmailField
  | AddressField
  | PhoneField
  | LinkField
  | SignatureField
  | RatingField
  | ConnectionField
  | SumField
  | AverageField
  | MinField
  | MaxField
  | CountField
  | PasswordField
  | UserRolesField;
