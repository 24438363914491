import { forwardRef, type TextareaHTMLAttributes } from 'react';

import { cn } from '@/utils/tailwind';

export interface TextareaCellProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  intent?: 'destructive' | 'default';
}

export const TextareaCell = forwardRef<HTMLTextAreaElement, TextareaCellProps>(
  ({ className, intent = 'default', ...props }, ref) => (
    <textarea
      ref={ref}
      spellCheck
      rows={1}
      cols={1}
      className={cn(
        'min-h-full min-w-full resize border-0 bg-base p-2 ring-2 ring-black focus:ring-2 focus:ring-black',
        {
          'ring-2 ring-destructive focus:ring-destructive': intent === 'destructive'
        },
        className
      )}
      {...props}
    />
  )
);

TextareaCell.displayName = 'TextareaCell';
