import { useQuery } from '@tanstack/react-query';

import { type KnackObject } from '@/types/schema/KnackObject';
import { type KnackView } from '@/types/schema/KnackView';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

export type MapUsage = {
  map_loads: {
    total: number;
    monthly_average: number;
    views: {
      view_key: KnackView['key'] | null;
      scene_key: string | null;
      count: number;
    }[];
  };
  objects: {
    key: KnackObject['key'];
    address_field_count: number;
    record_count: number;
    existing_geocode_count: number;
  }[];
  total_existing_geocode_count: number;
};

export async function getMapUsage(applicationId: string) {
  const endpoint = `/v1/applications/${applicationId}/maps/usage`;
  const { data } = await axios.get<MapUsage>(endpoint, {
    withCredentials: true
  });

  return data;
}

export function useMapUsageQuery() {
  const { data: application } = useApplicationQuery();
  const applicationId = application?.id;
  return useQuery({
    queryKey: [queryKeys.applicationPages, applicationId],
    queryFn: () => getMapUsage(applicationId as string),
    staleTime: 1000 * 60 * 5
  });
}
