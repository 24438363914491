import { useTranslation } from 'react-i18next';

import { type BuilderViewSourceSchemaCriteria } from '@/types/schema/BuilderView';
import { type ReportView } from '@/types/schema/views/ReportView';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { SourceFiltersDialog } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFiltersDialog';
import { SourceFiltersGroupsCard } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFiltersGroupCard';
import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';
import { ChartSourceTableInfo } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ChartSourceTableInfo';
import { ReportDataDisplayCalculation } from '@/pages/pages/settings-panel/view-settings/report/data-display/ReportDataDisplayCalculation';
import { ReportDataDisplayCategory } from '@/pages/pages/settings-panel/view-settings/report/data-display/ReportDataDisplayCategory';

export function ReportDataDisplay() {
  const [t] = useTranslation();

  const { data: application } = useApplicationQuery();

  const { hasAccessToNonPublicFeatures } = useGlobalState((state) => ({
    hasAccessToNonPublicFeatures: state.hasAccessToNonPublicFeatures
  }));
  const { sourceObject } = useActiveViewContext<ReportView>();
  const { chart, updateChart } = useActiveChartContext();

  const handleSourceFiltersSubmit = (data: BuilderViewSourceSchemaCriteria) => {
    updateChart({
      source: {
        ...chart.source,
        criteria: {
          match: 'any',
          rules: data.rules,
          groups: data.groups
        }
      }
    });
  };

  const hasAccessToReportsDataDisplay =
    hasAccessToNonPublicFeatures || !!application?.account.isInternalAccount;

  return hasAccessToReportsDataDisplay ? (
    <>
      <ChartSourceTableInfo />
      <BuilderAccordion>
        <BuilderAccordion.Item
          isDefaultOpen
          label={`${t('pages.element_settings.report.categories.data_display.source_filters')}`}
        >
          <div className="space-y-4">
            <SourceFiltersGroupsCard
              sourceObject={sourceObject}
              sourceFiltersCriteria={chart.source.criteria}
            />
            <SourceFiltersDialog
              sourceObject={sourceObject}
              sourceFiltersCriteria={chart.source.criteria}
              onFormSubmit={handleSourceFiltersSubmit}
            />
          </div>
        </BuilderAccordion.Item>
        <ReportDataDisplayCategory />
        <ReportDataDisplayCalculation />
      </BuilderAccordion>
    </>
  ) : (
    <div className="text-subtle">{t('keywords.coming_soon')}</div>
  );
}
