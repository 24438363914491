import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Form, Input } from '@knack/asterisk-react';
import { z } from 'zod';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { usePageMutation } from '@/hooks/api/mutations/usePageMutation';

interface DuplicateFormProps {
  page: BuilderPage;
  onDuplicate?: () => void;
  onCancel?: () => void;
}

export function DuplicatePageForm({ page, onDuplicate, onCancel }: DuplicateFormProps) {
  const [t] = useTranslation();
  const { duplicateMutation } = usePageMutation();
  type FormSchema = z.infer<typeof formSchema>;

  const formSchema = z.object({
    pageName: z.string().min(1, t('pages.dialogs.error_page_name_required'))
  });

  const {
    register,
    handleSubmit,
    formState: { isValid },
    getValues
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      pageName: t('pages.dialogs.duplicate.copy_of', {
        pageName: page.name
      })
    }
  });

  function onSubmit() {
    duplicateMutation.mutate(
      { pageKey: page.key, name: getValues('pageName') },
      {
        onSuccess: () => {
          if (onDuplicate) {
            onDuplicate();
          }
        }
      }
    );
  }

  return (
    <Form
      data-testid="duplicate-page-form"
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4"
    >
      <Form.Section>
        {t('pages.dialogs.duplicate.description', { pageName: page.name })}
      </Form.Section>
      <Form.Section>
        <Form.Label
          className="font-medium"
          htmlFor="duplicate-page-input"
          data-testid="duplicate-page-label"
        >
          {t('pages.dialogs.duplicate.type_page_name')}
        </Form.Label>
        <div className="mt-1">
          <Input
            data-testid="duplicate-page-input"
            id="duplicate-page-input"
            {...register('pageName')}
          />
        </div>
      </Form.Section>
      <div className="mt-4 flex justify-end gap-2" data-testid="duplicate-page-dialog-footer">
        {onCancel && (
          <Button onClick={onCancel} intent="minimal" data-testid="duplicate-page-button-cancel">
            {t('actions.cancel')}
          </Button>
        )}
        <Button
          disabled={!isValid}
          type="submit"
          isLoading={duplicateMutation.isPending}
          data-testid="duplicate-page-submit-button"
        >
          {t('actions.duplicate')}
        </Button>
      </div>
    </Form>
  );
}
