import { useTranslation } from 'react-i18next';
import { HiPlus as AddIcon } from 'react-icons/hi2';
import { Badge, Button, Input, Label } from '@knack/asterisk-react';

import { type TableViewColumn } from '@/types/schema/views/TableView';
import { BuilderAccordion } from '@/components/BuilderAccordion';

export function TableColumnSettingsActionButtonSection({
  columnItem,
  updateViewColumn
}: {
  columnItem: TableViewColumn;
  updateViewColumn: (column: Partial<TableViewColumn>) => void;
}) {
  const [t] = useTranslation();
  // TODO: This is a new feature that is not yet implemented. We need to wait for the design and product team to provide final thoughts.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const shouldRenderOpenLinkSwitch = columnItem.type === 'link';

  const getButtonSectionLabel = () => {
    if (columnItem.type === 'delete') {
      return t(
        'pages.element_settings.common.categories.data_display.field_management.delete_button'
      );
    }
    return t('pages.element_settings.common.categories.data_display.field_management.link_button');
  };

  return (
    <BuilderAccordion.Item label={getButtonSectionLabel()} isDefaultOpen>
      <div>
        <Label>
          {t('pages.element_settings.common.categories.data_display.field_management.button_text')}
          <Input
            placeholder={t('actions.delete')}
            className="mt-2"
            value={columnItem.link_text}
            onChange={(e) => updateViewColumn({ id: columnItem.id, link_text: e.target.value })}
          />
        </Label>
      </div>
      <div className="mt-4">
        <p>{t('keywords.icon')}</p>
        <Button intent="secondary" className="mt-2 items-center" size="sm" disabled>
          <Button.Icon icon={AddIcon} />
          {t('keywords.icon')}
        </Button>
        <Badge className="ml-2">{t('keywords.coming_soon')}</Badge>
      </div>
      {/* {shouldRenderOpenLinkSwitch && <ColumnSettingsOpenLinkTabs />} */}
    </BuilderAccordion.Item>
  );
}
