import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { HiArrowTopRightOnSquare as LinkIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Badge,
  Button,
  Checkbox,
  Divider,
  Label,
  RadioCardGroup,
  Select,
  Textarea,
  useToast
} from '@knack/asterisk-react';
import { z } from 'zod';

import {
  builderUsersScopeOptions,
  inactivityTimeoutInMinutesOptions,
  lockoutAttemptWindowInMinutesOptions,
  lockoutFailedAttemptsOptions,
  lockoutLengthOptions,
  type BuilderApplication,
  type BuilderApplicationSettings
} from '@/types/schema/BuilderApplication';
import {
  useApplicationSettingsMutation,
  type AppSettingsPayload
} from '@/hooks/api/mutations/useApplicationSettingsMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';

function UsersLoginSettings({ application }: { application: BuilderApplication }) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const { mutate: updateApplicationSettings } = useApplicationSettingsMutation();

  const userLoginSettingsSchema = z.object({
    users: z.custom<BuilderApplication['users']>(),
    isMainNavAuthBased: z.custom<BuilderApplicationSettings['isMainNavAuthBased']>(),
    inactivityTimeout: z.custom<BuilderApplicationSettings['inactivityTimeout']>(),
    passwordOptions: z.custom<BuilderApplicationSettings['passwordOptions']>(),
    lockoutOptions: z.custom<BuilderApplicationSettings['lockoutOptions']>()
  });

  type UserLoginSettingsSchema = z.infer<typeof userLoginSettingsSchema>;

  const formMethods = useForm<UserLoginSettingsSchema>({
    resolver: zodResolver(userLoginSettingsSchema),
    defaultValues: {
      users: application.users,
      isMainNavAuthBased: application.settings.isMainNavAuthBased,
      inactivityTimeout: application.settings.inactivityTimeout,
      passwordOptions: application.settings.passwordOptions,
      lockoutOptions: application.settings.lockoutOptions
    }
  });

  const { register, handleSubmit, control, watch } = formMethods;

  const isAuthPageBased = watch('users.scope') === 'scene';
  const isInactivityTimeoutEnabled = watch('inactivityTimeout.isEnabled');
  const isPasswordExpirationEnabled = watch('passwordOptions.requireExpiration');
  const isNoPasswordReuseEnabled = watch('passwordOptions.requireNoReUse');
  const isUserLockoutEnabled = watch('lockoutOptions.isLockoutEnforced');
  const isUserLockoutResetEnabled = watch('lockoutOptions.hasLockoutPasswordReset');
  const isSendUserLockoutEmailEnabled = watch('lockoutOptions.sendUserLockoutEmail');

  const onFormSubmit = (data: UserLoginSettingsSchema) => {
    const payload: AppSettingsPayload = {
      users: {
        enabled: data.users.enabled,
        scope: data.users.scope,
        registration: data.users.registration
      },
      settings: {
        isMainNavAuthBased: data.isMainNavAuthBased,
        inactivity_timeout_enabled: data.inactivityTimeout.isEnabled,
        inactivity_timeout: data.inactivityTimeout.timeoutInMinutes,
        inactivity_message: data.inactivityTimeout.inactivityMessage,
        password_options: {
          password_require_expiration: data.passwordOptions.requireExpiration,
          password_require_expiration_message: data.passwordOptions.requireExpirationMessage,
          password_require_no_reuse: data.passwordOptions.requireNoReUse,
          password_require_no_reuse_message: data.passwordOptions.requireNoReUseMessage,
          password_require_no_common: data.passwordOptions.requireNoCommon,
          password_require_number: data.passwordOptions.requireNumber,
          password_require_lowercase: data.passwordOptions.requireLowerCase,
          password_require_uppercase: data.passwordOptions.requireUpperCase,
          password_special_character: data.passwordOptions.specialCharacter,
          password_minimum_character: data.passwordOptions.minimumCharacter
        },
        lockout_options: {
          lockout_enforced: data.lockoutOptions.isLockoutEnforced,
          lockout_password_reset: data.lockoutOptions.hasLockoutPasswordReset,
          lockout_message: data.lockoutOptions.lockoutMessage,
          password_reset_message: data.lockoutOptions.passwordResetMessage,
          lockout_user_email: data.lockoutOptions.sendUserLockoutEmail,
          lockout_failed_attempts: data.lockoutOptions.lockoutFailedAttempts,
          lockout_attempt_window: data.lockoutOptions.lockoutAttemptWindowInMinutes,
          lockout_length: data.lockoutOptions.lockoutLength
        }
      }
    };

    updateApplicationSettings(payload, {
      onSuccess: () => {
        presentToast({
          title: t('app_settings.settings_saved_successfully')
        });
      },
      onError: () => {
        presentToast({
          title: t('app_settings.settings_saved_error')
        });
      }
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form data-testid="user-login-settings-form" onSubmit={handleSubmit(onFormSubmit)}>
        <section>
          <h4 className="mb-2 font-medium">{t('app_settings.users.login_access')}</h4>
          <Controller
            name="users.scope"
            control={control}
            render={({ field: { value, onChange } }) => (
              <RadioCardGroup value={value} onValueChange={onChange}>
                {builderUsersScopeOptions.map((scope) => (
                  <RadioCardGroup.Card
                    key={scope}
                    value={scope}
                    className="relative items-center p-3 !shadow-none outline-2 sm:p-3"
                    width="100%"
                    data-testid={`${scope}-scope-radio-card`}
                  >
                    <span className="mb-1 text-emphasis">
                      {t(`app_settings.users.users_scopes.${scope}`)}
                    </span>
                    <span className="text-xs">
                      {t(`app_settings.users.users_scopes.${scope}_description`)}
                    </span>
                    {scope === 'scene' && (
                      <Badge className="absolute right-2 top-2 cursor-pointer leading-none">
                        {t('keywords.preferred')}
                      </Badge>
                    )}
                  </RadioCardGroup.Card>
                ))}
              </RadioCardGroup>
            )}
          />
          <Button asChild intent="secondary" className="mt-4">
            <a
              href="https://learn.knack.com/apps/user-roles/about#activating-users"
              target="_blank"
              rel="noreferrer"
            >
              <span>{t('app_settings.users.login_access_learn_more')}</span>
              <LinkIcon size={14} className="ml-1 shrink-0" />
            </a>
          </Button>
        </section>

        <Divider className="my-6" />

        <section>
          <h3 className="mb-6 font-medium">{t('app_settings.users.security_settings')}</h3>
          {isAuthPageBased && (
            <div className="mb-6">
              <h4 className="mb-2 font-medium">{t('app_settings.users.nav_auth_based.heading')}</h4>
              <Controller
                name="isMainNavAuthBased"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Label className="flex items-center" htmlFor="main-nav-auth-based">
                    <Checkbox
                      id="main-nav-auth-based"
                      className="mr-2"
                      checked={value}
                      onCheckedChange={onChange}
                    />
                    {t('app_settings.users.nav_auth_based.label')}
                  </Label>
                )}
              />
            </div>
          )}

          <div className="mb-6">
            <h4 className="mb-2 font-medium">{t('app_settings.users.inactivity.heading')}</h4>
            <Controller
              name="inactivityTimeout.isEnabled"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Label className="flex" htmlFor="inactivity-timeout-toggle">
                  <Checkbox
                    id="inactivity-timeout-toggle"
                    className="mr-2 mt-1"
                    checked={value}
                    onCheckedChange={onChange}
                  />
                  <div className="flex flex-col">
                    <span className="text-emphasis">
                      {t('app_settings.users.inactivity.label')}
                    </span>
                    <span>{t('app_settings.users.inactivity.description')}</span>
                  </div>
                </Label>
              )}
            />

            {isInactivityTimeoutEnabled && (
              <div className="ml-6 mt-2">
                <div className="mb-4">
                  <Label htmlFor="inactivity-timeout-value" className="mb-2 block font-medium">
                    {t('app_settings.users.inactivity.logout_after')}
                  </Label>
                  <Controller
                    name="inactivityTimeout.timeoutInMinutes"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Select value={String(value)} onValueChange={(val) => onChange(Number(val))}>
                        <Select.Trigger
                          id="inactivity-timeout-value"
                          placeholder={t('actions.select')}
                          className="w-full"
                        />
                        <Select.Content>
                          {inactivityTimeoutInMinutesOptions.map((option) => (
                            <Select.Item key={option} value={String(option)}>
                              {t('app_settings.users.inactivity.logout_after_minutes', {
                                count: option
                              })}
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select>
                    )}
                  />
                </div>

                <div>
                  <Label htmlFor="inactivity-message" className="mb-2 block font-medium">
                    {t('app_settings.users.inactivity.inactivity_message')}
                  </Label>
                  <Textarea
                    id="inactivity-message"
                    className="w-full"
                    {...register('inactivityTimeout.inactivityMessage')}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="mb-6">
            <h4 className="mb-2 font-medium">
              {t('app_settings.users.password_requirements.heading')}
            </h4>
            <Controller
              name="passwordOptions.minimumCharacter"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Label className="mb-1 flex items-center" htmlFor="password-minimum-characters">
                  <Checkbox
                    id="password-minimum-characters"
                    className="mr-2"
                    checked={value}
                    onCheckedChange={onChange}
                  />
                  {t('app_settings.users.password_requirements.minimum_characters')}
                </Label>
              )}
            />
            <Controller
              name="passwordOptions.requireNoCommon"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Label className="mb-1 flex items-center" htmlFor="password-no-common-words">
                  <Checkbox
                    id="password-no-common-words"
                    className="mr-2"
                    checked={value}
                    onCheckedChange={onChange}
                  />
                  {t('app_settings.users.password_requirements.no_common_words')}
                </Label>
              )}
            />
            <Controller
              name="passwordOptions.requireNumber"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Label className="mb-1 flex items-center" htmlFor="password-one-number">
                  <Checkbox
                    id="password-one-number"
                    className="mr-2"
                    checked={value}
                    onCheckedChange={onChange}
                  />
                  {t('app_settings.users.password_requirements.one_number')}
                </Label>
              )}
            />
            <Controller
              name="passwordOptions.specialCharacter"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Label className="mb-1 flex items-center" htmlFor="password-special-character">
                  <Checkbox
                    id="password-special-character"
                    className="mr-2"
                    checked={value}
                    onCheckedChange={onChange}
                  />
                  {t('app_settings.users.password_requirements.one_special_character')}
                </Label>
              )}
            />
            <Controller
              name="passwordOptions.requireLowerCase"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Label className="mb-1 flex items-center" htmlFor="password-lowercase-character">
                  <Checkbox
                    id="password-lowercase-character"
                    className="mr-2"
                    checked={value}
                    onCheckedChange={onChange}
                  />
                  {t('app_settings.users.password_requirements.one_lowercase')}
                </Label>
              )}
            />
            <Controller
              name="passwordOptions.requireUpperCase"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Label className="mb-1 flex items-center" htmlFor="password-uppercase-character">
                  <Checkbox
                    id="password-uppercase-character"
                    className="mr-2"
                    checked={value}
                    onCheckedChange={onChange}
                  />
                  {t('app_settings.users.password_requirements.one_uppercase')}
                </Label>
              )}
            />
          </div>
          <div className="mb-6">
            <h4 className="mb-2 font-medium">
              {t('app_settings.users.password_settings.heading')}
            </h4>
            <div className="mb-1">
              <Controller
                name="passwordOptions.requireExpiration"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Label className="mb-1 flex items-center" htmlFor="password-expires">
                    <Checkbox
                      id="password-expires"
                      className="mr-2"
                      checked={value}
                      onCheckedChange={onChange}
                    />
                    {t('app_settings.users.password_settings.expiration_days')}
                  </Label>
                )}
              />
              {isPasswordExpirationEnabled && (
                <div className="mb-4 ml-6 mt-2">
                  <Label htmlFor="expiration-message" className="mb-2 block font-medium">
                    {t('app_settings.users.password_settings.expiration_message')}
                  </Label>
                  <Textarea
                    id="expiration-message"
                    className="w-full"
                    {...register('passwordOptions.requireExpirationMessage')}
                  />
                </div>
              )}
            </div>
            <div>
              <Controller
                name="passwordOptions.requireNoReUse"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Label className="mb-1 flex items-center" htmlFor="password-require-no-reuse">
                    <Checkbox
                      id="password-require-no-reuse"
                      className="mr-2"
                      checked={value}
                      onCheckedChange={onChange}
                    />
                    {t('app_settings.users.password_settings.cannot_reuse')}
                  </Label>
                )}
              />
              {isNoPasswordReuseEnabled && (
                <div className="ml-6 mt-2">
                  <Label htmlFor="password-reuse-message" className="mb-2 block font-medium">
                    {t('app_settings.users.password_settings.cannot_reuse_message')}
                  </Label>
                  <Textarea
                    id="password-reuse-message"
                    className="mb-1 w-full"
                    {...register('passwordOptions.requireNoReUseMessage')}
                  />
                  <p className="text-xs text-subtle">
                    {t('app_settings.users.password_settings.cannot_reuse_message_description')}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="mb-6">
            <h4 className="mb-2 font-medium">
              {t('app_settings.users.failed_login_settings.heading')}
            </h4>
            <Controller
              name="lockoutOptions.isLockoutEnforced"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Label className="flex" htmlFor="lockout-toggle">
                  <Checkbox
                    id="lockout-toggle"
                    className="mr-2 mt-1"
                    checked={value}
                    onCheckedChange={onChange}
                  />
                  <div className="flex flex-col">
                    <span className="text-emphasis">
                      {t('app_settings.users.failed_login_settings.lockout_users')}
                    </span>
                    <span>
                      {t('app_settings.users.failed_login_settings.lockout_users_description')}
                    </span>
                  </div>
                </Label>
              )}
            />
            {isUserLockoutEnabled && (
              <div className="ml-6 mt-2">
                <div className="mb-4">
                  <Label htmlFor="lockout-after" className="mb-2 block font-medium">
                    {t('app_settings.users.failed_login_settings.lockout_after')}
                  </Label>
                  <Controller
                    name="lockoutOptions.lockoutFailedAttempts"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Select value={String(value)} onValueChange={(val) => onChange(Number(val))}>
                        <Select.Trigger
                          id="lockout-after"
                          placeholder={t('actions.select')}
                          className="w-full"
                        />
                        <Select.Content>
                          {lockoutFailedAttemptsOptions.map((option) => (
                            <Select.Item key={option} value={String(option)}>
                              {t(
                                'app_settings.users.failed_login_settings.lockout_after_attempts',
                                {
                                  count: option
                                }
                              )}
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select>
                    )}
                  />
                </div>

                <div className="mb-4">
                  <Label htmlFor="lockout-within" className="mb-2 block font-medium">
                    {t('app_settings.users.failed_login_settings.lockout_within')}
                  </Label>
                  <Controller
                    name="lockoutOptions.lockoutAttemptWindowInMinutes"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Select value={String(value)} onValueChange={(val) => onChange(Number(val))}>
                        <Select.Trigger
                          id="lockout-within"
                          placeholder={t('actions.select')}
                          className="w-full"
                        />
                        <Select.Content>
                          {lockoutAttemptWindowInMinutesOptions.map((option) => (
                            <Select.Item key={option} value={String(option)}>
                              {t(
                                'app_settings.users.failed_login_settings.lockout_within_minutes',
                                {
                                  count: option
                                }
                              )}
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select>
                    )}
                  />
                </div>

                <div className="mb-4">
                  <Label htmlFor="lockout-period" className="mb-2 block font-medium">
                    {t('app_settings.users.failed_login_settings.lockout_period')}
                  </Label>
                  <Controller
                    name="lockoutOptions.lockoutLength"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        value={String(value)}
                        onValueChange={(val) => {
                          if (val === 'forever') {
                            onChange(val);
                            return;
                          }
                          onChange(Number(val));
                        }}
                      >
                        <Select.Trigger
                          id="lockout-period"
                          placeholder={t('actions.select')}
                          className="w-full"
                        />
                        <Select.Content>
                          {lockoutLengthOptions.map((option) =>
                            option === 'forever' ? (
                              <Select.Item key={option} value="forever">
                                {t(
                                  'app_settings.users.failed_login_settings.lockout_period_forever'
                                )}
                              </Select.Item>
                            ) : (
                              <Select.Item key={option} value={String(option)}>
                                {option === 1440
                                  ? t(
                                      'app_settings.users.failed_login_settings.lockout_period_one_day'
                                    )
                                  : t(
                                      'app_settings.users.failed_login_settings.lockout_period_minutes',
                                      {
                                        count: option
                                      }
                                    )}
                              </Select.Item>
                            )
                          )}
                        </Select.Content>
                      </Select>
                    )}
                  />
                </div>

                <div className="mb-4">
                  <Label htmlFor="lockout-message" className="mb-2 block font-medium">
                    {t('app_settings.users.failed_login_settings.lockout_message')}
                  </Label>
                  <Textarea
                    id="lockout-message"
                    className="w-full"
                    {...register('lockoutOptions.lockoutMessage')}
                  />
                </div>

                <div className="mb-4">
                  <h4 className="mb-2 font-medium">
                    {t('app_settings.users.failed_login_settings.lockout_email_options')}
                  </h4>
                  <div className="mb-1">
                    <Controller
                      name="lockoutOptions.hasLockoutPasswordReset"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Label className="flex items-center" htmlFor="lockout-allow-reset-request">
                          <Checkbox
                            id="lockout-allow-reset-request"
                            className="mr-2"
                            checked={value}
                            onCheckedChange={onChange}
                          />
                          {t(
                            'app_settings.users.failed_login_settings.allow_reset_password_request'
                          )}
                        </Label>
                      )}
                    />
                    {isUserLockoutResetEnabled && (
                      <div className="mb-4 ml-6 mt-2">
                        <Label
                          htmlFor="password-reset-request-message"
                          className="mb-2 block font-medium"
                        >
                          {t(
                            'app_settings.users.failed_login_settings.password_reset_request_message'
                          )}
                        </Label>
                        <Textarea
                          id="password-reset-request-message"
                          className="w-full"
                          {...register('lockoutOptions.passwordResetMessage')}
                        />
                      </div>
                    )}
                  </div>
                  <div className="mb-1">
                    <Controller
                      name="lockoutOptions.sendUserLockoutEmail"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Label className="flex items-center" htmlFor="lockout-send-user-email">
                          <Checkbox
                            id="lockout-send-user-email"
                            className="mr-2"
                            checked={value}
                            onCheckedChange={onChange}
                          />
                          {t('app_settings.users.failed_login_settings.send_lockout_email')}
                        </Label>
                      )}
                    />
                    {isSendUserLockoutEmailEnabled && (
                      <div className="ml-6 mt-2">
                        <Label htmlFor="lockout-email-message" className="mb-2 block font-medium">
                          {t('app_settings.users.failed_login_settings.email_message')}
                        </Label>
                        <Textarea
                          id="lockout-email-message"
                          className="w-full"
                          {...register('lockoutOptions.userLockoutEmailMessage')}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>

        <div className="mt-6 flex">
          <Button data-testid="save-settings-button" type="submit" className="ml-auto">
            {t('app_settings.save_settings')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}

export function SettingsPageUsersLoginSection() {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const { data: application, isPending } = useApplicationQuery();
  const { mutate: updateApplicationSettings } = useApplicationSettingsMutation();

  if (isPending) {
    return null;
  }

  if (!application) {
    throw new Error('Application not found');
  }

  const onActivateUsers = () => {
    updateApplicationSettings(
      {
        users: {
          enabled: true
        }
      },
      {
        onSuccess: () => {
          presentToast({
            title: t('app_settings.users.users_activated_successfully')
          });
        }
      }
    );
  };

  if (!application.users.enabled) {
    return (
      <div>
        <Trans
          i18nKey="app_settings.users.inactive_users_message"
          components={[<span key="0" className="font-medium" />]}
        />

        <div className="mt-4 flex">
          <Button className="mr-2" onClick={onActivateUsers}>
            {t('app_settings.users.activate_users')}
          </Button>
          <Button asChild intent="secondary">
            <a
              href="https://learn.knack.com/apps/user-roles/about"
              target="_blank"
              rel="noreferrer"
            >
              <span>{t('actions.learn_more')}</span>
              <LinkIcon size={14} className="ml-1 shrink-0" />
            </a>
          </Button>
        </div>
      </div>
    );
  }

  return <UsersLoginSettings application={application} />;
}
