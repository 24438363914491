import { nanoid } from 'nanoid';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import {
  VIEW_COLUMN_ID_PREFIX,
  VIEW_GROUP_ID_PREFIX,
  type BuilderViewSourceSchema
} from '@/types/schema/BuilderView';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { type ListView } from '@/types/schema/views/ListView';
import { type ViewSource } from '@/pages/pages/page-editor/add-view/helpers/useViewSources';
import { generateDetailsViewFieldInput } from './detailsViewSchema';
import { generateNewViewKey } from './generateNewViewKey';

const LIST_MAX_INPUT_FIELD_NUMBER = 7;
const INPUT_FIELD_ROWS_PER_PAGE = '25';

export const baseListViewSchema: Partial<ListView> = {
  type: 'list',
  description: '',
  groups: [],
  inputs: [],
  links: [],
  preset_filters: [],
  menu_filters: [],
  label_format: 'left',
  layout: 'full',
  list_layout: 'full',
  rows_per_page: INPUT_FIELD_ROWS_PER_PAGE,
  keyword_search: true,
  keyword_search_fields: 'view',
  filter_fields: 'view',
  filter_type: 'fields',
  allow_exporting: false,
  allow_preset_filters: false,
  allow_limit: true,
  hide_fields: false,
  rules: {
    fields: []
  }
};

function generateListViewInputs({
  page,
  viewSourceObject,
  viewSourceSchema
}: {
  page: BuilderPage;
  viewSourceObject: KnackObject;
  viewSourceSchema: BuilderViewSourceSchema;
}) {
  const detailsInputs: DetailsViewInput[] = [];

  let addedInputsCount = 0;

  viewSourceObject.fields.forEach((field) => {
    if (addedInputsCount >= LIST_MAX_INPUT_FIELD_NUMBER) {
      return;
    }

    // Name and email fields are allowed by default, so we can add it and skip the rest of the checks
    if (field.type === 'email' || field.type === 'name') {
      detailsInputs.push(generateDetailsViewFieldInput(field));
      addedInputsCount += 1;
      return;
    }

    // Skip password fields and any other user fields
    if (field.type === 'password' || field.user) {
      return;
    }

    // Skip connection fields if they are connected to the source object of the page
    if (
      field.type === 'connection' &&
      page.sourceObjectKey &&
      field.relationship.object === page.sourceObjectKey
    ) {
      return;
    }

    // Skip immutable fields if the view is behind authentication
    if (viewSourceSchema.authenticated_user && field.immutable) {
      return;
    }

    detailsInputs.push(generateDetailsViewFieldInput(field));

    addedInputsCount += 1;
  });

  return detailsInputs;
}

export function getListViewSchema(
  page: BuilderPage,
  viewSource: ViewSource,
  viewSourceSchema: BuilderViewSourceSchema
) {
  const viewTitle =
    viewSource.recordDisplayQuantity === 'many'
      ? viewSource.object.inflections.plural
      : viewSource.object.inflections.singular;

  const listViewSchema: Partial<ListView> = {
    ...baseListViewSchema,
    key: generateNewViewKey(),
    name: viewTitle,
    title: viewTitle,
    source: viewSourceSchema,
    columns: [
      {
        id: `${VIEW_COLUMN_ID_PREFIX}${nanoid(10)}`,
        groups: [
          {
            id: `${VIEW_GROUP_ID_PREFIX}${nanoid(10)}`,
            columns: [
              generateListViewInputs({
                page,
                viewSourceObject: viewSource.object,
                viewSourceSchema
              })
            ]
          }
        ],
        width: 100
      }
    ]
  };

  return listViewSchema;
}
