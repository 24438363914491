import React, { type ComponentProps } from 'react';
import { RichTextRenderer } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

type InlineKnackValueProps = ComponentProps<'span'> & {
  value?: string | boolean | string[];
  asRichTextRenderer?: boolean;
  asHtml?: boolean;
  children?: React.ReactNode;
};

export function InlineKnackValue({
  value,
  className,
  asRichTextRenderer,
  asHtml,
  children,
  ...props
}: InlineKnackValueProps) {
  if (asRichTextRenderer && value) {
    return (
      <RichTextRenderer
        className={cn('rounded-md bg-subtle p-1', className)}
        dangerouslySetInnerHTML={{
          __html: value
        }}
        {...props}
      />
    );
  }

  const commonClassNames = cn(
    'inline-flex rounded-md bg-subtle p-1 align-middle leading-none items-center text-emphasis',
    className
  );

  if (asHtml && value) {
    return (
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: value }}
        className={commonClassNames}
        {...props}
      />
    );
  }

  if (children) {
    return (
      <span className={commonClassNames} {...props}>
        {children}
      </span>
    );
  }

  return (
    <span className={commonClassNames} {...props}>
      {value}
    </span>
  );
}
