import { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Spinner } from '@knack/asterisk-react';

const FontAwesomeIconGrid = lazy(() =>
  import('@/components/font-awesome/FontAwesomeIconGrid').then((module) => ({
    default: module.FontAwesomeIconGrid
  }))
);

interface SelectIconModalProps {
  onSubmit: (newSelectedIconName: string | undefined) => void;
  onClose: () => void;
  preSelectedIcon?: string;
}

export function SelectIconModal({ preSelectedIcon, onClose, onSubmit }: SelectIconModalProps) {
  const [t] = useTranslation();

  const [selectedIconName, setSelectedIconName] = useState(preSelectedIcon);

  return (
    <Dialog open onOpenChange={onClose}>
      <Dialog.Content className="overflow-hidden">
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>{t('components.icons.select_icon')}</Dialog.Title>
            <Dialog.Description className="sr-only">
              {t('components.icons.select_icon_aria_description')}
            </Dialog.Description>
          </Dialog.Header>
          <div className="mt-6">
            <Suspense
              fallback={
                <div className="text-center">
                  <Spinner />
                </div>
              }
            >
              <FontAwesomeIconGrid
                selectedIconName={selectedIconName}
                onSelectedIconChange={(newSelectedIconName) =>
                  setSelectedIconName(newSelectedIconName)
                }
              />
            </Suspense>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button onClick={() => onSubmit(selectedIconName)}>{t('actions.select')}</Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
