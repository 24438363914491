import { useState } from 'react';
import { Trans } from 'react-i18next';
import {
  HiViewColumns as ColumnsIcon,
  HiTableCells as FieldsIcon,
  HiCog6Tooth as GeneralSettingsIcon
} from 'react-icons/hi2';
import { t } from 'i18next';

import { type DetailsView } from '@/types/schema/views/DetailsView';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewWithDetailsActionsSettingsCategory } from '@/pages/pages/settings-panel/view-settings/common/details-actions/ViewWithDetailsActionsSettingsCategory';
import { DetailsDataDisplaySettingsCategory } from '@/pages/pages/settings-panel/view-settings/details/DetailsDataDisplaySettingsCategory';
import { DetailsGeneralSettingsCategory } from '@/pages/pages/settings-panel/view-settings/details/DetailsGeneralSettingsCategory';
import { DetailsInputEditModal } from '@/pages/pages/settings-panel/view-settings/details/DetailsInputEditModal';
import {
  ViewSettingsCategoryList,
  type ViewSettingsCategoryItem
} from '@/pages/pages/settings-panel/view-settings/ViewSettingsCategoryList';
import { ViewSourceBanner } from '@/pages/pages/settings-panel/view-settings/ViewSourceBanner';

type DetailsSettingsCategoryId = 'general-settings' | 'data-display' | 'actions' | 'styling';
type DetailsSettingsCategoryItem = ViewSettingsCategoryItem<DetailsSettingsCategoryId>;

const DETAILS_SETTINGS_CATEGORIES: DetailsSettingsCategoryItem[] = [
  {
    id: 'general-settings',
    name: t('pages.element_settings.common.categories.general_settings.name'),
    description: t('pages.element_settings.details.categories.general_settings.description'),
    icon: GeneralSettingsIcon
  },
  {
    id: 'data-display',
    name: t('pages.element_settings.common.categories.data_display.name'),
    description: t('pages.element_settings.details.categories.data_display.description'),
    icon: FieldsIcon
  },
  {
    id: 'actions',
    name: t('pages.element_settings.common.categories.actions.name'),
    description: t('pages.element_settings.details.categories.actions.description'),
    icon: ColumnsIcon
  }
];

function getPanelTitle(
  activeCategoryId: DetailsSettingsCategoryId | null,
  categories: DetailsSettingsCategoryItem[]
) {
  const activeCategory = activeCategoryId
    ? categories.find((category) => category.id === activeCategoryId)
    : null;

  return activeCategory ? activeCategory.name : t('pages.element_settings.details.heading');
}

function SourceBanner() {
  const { sourceObject } = useActiveViewContext<DetailsView>();

  return (
    <ViewSourceBanner className="mb-4">
      <Trans
        i18nKey="pages.element_settings.details.categories.this_details_displays"
        values={{ tableName: sourceObject.name }}
      >
        <ViewSourceBanner.ObjectName objectName={sourceObject.name} />
      </Trans>
    </ViewSourceBanner>
  );
}

export function DetailsSettings() {
  const [activeCategoryId, setActiveCategoryId] = useState<DetailsSettingsCategoryId | null>(null);
  const panelTitle = getPanelTitle(activeCategoryId, DETAILS_SETTINGS_CATEGORIES);

  return (
    <>
      <CollapsiblePanel.Content
        title={panelTitle}
        onBack={activeCategoryId ? () => setActiveCategoryId(null) : undefined}
        shouldHideDivider={activeCategoryId === 'data-display'}
      >
        {activeCategoryId ? (
          <>
            {activeCategoryId === 'general-settings' && <DetailsGeneralSettingsCategory />}
            {activeCategoryId === 'data-display' && <DetailsDataDisplaySettingsCategory />}
            {activeCategoryId === 'actions' && <ViewWithDetailsActionsSettingsCategory />}
            {activeCategoryId === 'styling' && <p>Styling Coming Soon</p>}
          </>
        ) : (
          <>
            <SourceBanner />
            <ViewSettingsCategoryList
              categories={DETAILS_SETTINGS_CATEGORIES}
              onCategorySelect={(selectedCategoryId: string) => {
                setActiveCategoryId(selectedCategoryId as DetailsSettingsCategoryId);
              }}
            />
          </>
        )}
      </CollapsiblePanel.Content>
      <DetailsInputEditModal />
    </>
  );
}
