import { t } from 'i18next';

export function getRegion(cluster: string) {
  switch (cluster) {
    case 'eu-central':
      return t('app_settings.data_compliance.eu_frankfurt');
    case 'us-govcloud':
      return t('app_settings.data_compliance.us_california');
    case 'ap-southeast-2':
      return t('app_settings.data_compliance.aus_sydney');
    case 'custom':
      return t('app_settings.data_compliance.private_server');
    default:
      return t('app_settings.data_compliance.us_virginia');
  }
}
