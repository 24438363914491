import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillCursorFill as CursorIcon } from 'react-icons/bs';
import { HiQuestionMarkCircle as HelpIcon } from 'react-icons/hi2';
import { getNodesBounds } from 'reactflow';
import {
  Button,
  Dialog,
  DropdownMenu,
  Select,
  Switch,
  Tooltip,
  useToast
} from '@knack/asterisk-react';

import { useDataModelDiagramMutation } from '@/hooks/api/mutations/useDataModelDiagramMutation';
import { cn } from '@/utils/tailwind';
import { isMacOS } from '@/utils/user-agent';
import { DataModelSearch } from './DataModelSearch';
import { DataModelShortcutsDialogContent } from './DataModelShortcutsDialogContent';
import { DataModelZoomDropdown } from './DataModelZoomDropdown';
import { useDataModelStore } from './helpers/useDataModelStore';
import {
  FieldsCollapsibleStatus,
  FieldsVisibilityStatus,
  useDataModelViewOptionsStore
} from './helpers/useDataModelViewOptionsStore';
import { useDataModelZoom } from './helpers/useDataModelZoom';
import { useElkAutoLayout } from './helpers/useElkAutoLayout';

export function DataModelToolbar() {
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const { mutate: saveDiagram, isPending: isSavingDiagram } = useDataModelDiagramMutation();

  const { getAutoLayoutNodes } = useElkAutoLayout();
  const { resetLayout, nodes, edges } = useDataModelStore((state) => ({
    resetLayout: state.resetLayout,
    nodes: state.nodes,
    edges: state.edges
  }));
  const {
    isMultiSelect,
    showGrid,
    showFields,
    showFieldKeys,
    showAllEdges,
    showConnectionTypeLabels,
    fieldsVisibilityStatus,
    toggleIsMultiSelect,
    setShowGrid,
    setShowFields,
    setShowFieldKeys,
    setShowAllEdges,
    setShowConnectionTypeLabels,
    setFieldsVisibilityStatus,
    setFieldsCollapsibleStatus
  } = useDataModelViewOptionsStore();
  const { zoomIn, zoomOut, zoomTo, fitView } = useDataModelZoom();

  const onSaveDiagram = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (isSavingDiagram) return;

    const diagramToSave = {
      nodes: nodes.map((node) => ({
        id: node.id,
        position: node.position
      })),
      nodesBounds: getNodesBounds(nodes)
    };

    saveDiagram(diagramToSave, {
      onSuccess: () => {
        presentToast({
          title: t('components.data_model.save_diagram_success')
        });
      },
      onError: () => {
        presentToast({
          title: t('components.data_model.save_diagram_error')
        });
      }
    });
  };

  const setAutoLayout = useCallback(async () => {
    if (!getAutoLayoutNodes) return;

    const newNodes = await getAutoLayoutNodes(nodes, edges);
    resetLayout(newNodes);

    window.requestAnimationFrame(() => {
      fitView();
    });
  }, [nodes, edges, fitView, getAutoLayoutNodes, resetLayout]);

  useEffect(() => {
    function handleShorcuts(event: KeyboardEvent) {
      const modifierKey = isMacOS() ? event.metaKey : event.ctrlKey;

      if (modifierKey && (event.key === '+' || event.key === '=')) {
        event.preventDefault();
        zoomIn();
      }
      if (event.key === '-' && modifierKey) {
        event.preventDefault();
        zoomOut();
      }
      if (event.key === '0' && modifierKey) {
        event.preventDefault();
        zoomTo(100);
      }
      if (event.key === '!') {
        event.preventDefault();
        fitView();
      }
      if (event.key === 'v') {
        toggleIsMultiSelect();
      }
      if (event.key === 'C') {
        setFieldsCollapsibleStatus(FieldsCollapsibleStatus.ALL_COLLAPSED);
      }
      if (event.key === 'E') {
        setFieldsCollapsibleStatus(FieldsCollapsibleStatus.ALL_EXPANDED);
      }
      if (event.key === 'A') {
        setShowAllEdges(!showAllEdges);
      }
    }

    document.addEventListener('keydown', handleShorcuts);

    return () => {
      document.removeEventListener('keydown', handleShorcuts);
    };
  }, [
    zoomIn,
    zoomOut,
    fitView,
    zoomTo,
    toggleIsMultiSelect,
    setFieldsCollapsibleStatus,
    setShowAllEdges,
    showAllEdges
  ]);

  return (
    <div
      className="flex justify-between rounded-xl bg-muted px-2 py-1"
      data-testid="data-model-toolbar"
    >
      <div className="flex items-center gap-3">
        <DataModelSearch />
        <div>
          <Tooltip>
            <Tooltip.Trigger asChild>
              <Button
                aria-label={t('components.data_model.select_tool')}
                intent="minimal"
                onClick={toggleIsMultiSelect}
                className={cn('h-auto p-2', {
                  'bg-emphasis focus:bg-emphasis': isMultiSelect
                })}
                data-testid="data-model-toolbar-select-tool-button"
              >
                <CursorIcon className="scale-x-[-1] text-default" size={16} />
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content side="bottom" className="z-10">
              <span className="mr-2 font-semibold">{t('components.data_model.select_tool')}</span>
              <span className="text-xs">{t('components.data_model.hold_select_tool')}</span>
            </Tooltip.Content>
          </Tooltip>
        </div>
        <div role="separator" aria-orientation="vertical" className="h-6 w-px bg-subtle" />
        <DropdownMenu>
          <Tooltip>
            <Tooltip.Trigger asChild>
              <DropdownMenu.Trigger asChild>
                <Button
                  intent="minimal"
                  className="text-base text-default aria-expanded:bg-subtle"
                  data-testid="data-model-toolbar-view-button"
                >
                  {t('components.data_model.view')}
                </Button>
              </DropdownMenu.Trigger>
            </Tooltip.Trigger>
            <Tooltip.Content side="bottom" className="z-10">
              {t('components.data_model.view_options')}
            </Tooltip.Content>
          </Tooltip>
          <DropdownMenu.Content
            className="min-w-[305px]"
            align="start"
            onCloseAutoFocus={(e) => e.preventDefault()}
          >
            <DropdownMenu.Label className="font-medium text-subtle">
              {t('components.data_model.appearance')}
            </DropdownMenu.Label>
            <DropdownMenu.Item
              className="gap-1"
              data-testid="data-model-toolbar-show-dot-grid"
              onSelect={(event) => {
                event.preventDefault();
                setShowGrid(!showGrid);
              }}
            >
              <Switch checked={showGrid} />
              {t('components.data_model.show_dot_grid')}
            </DropdownMenu.Item>
            <DropdownMenu.Item
              className="gap-1"
              data-testid="data-model-toolbar-show-connections-arrows"
              onSelect={(event) => {
                event.preventDefault();
                setShowAllEdges(!showAllEdges);
              }}
            >
              <Switch checked={showAllEdges} />
              {t('components.data_model.show_connection_arrows')}
            </DropdownMenu.Item>
            {showAllEdges && (
              <DropdownMenu.Item
                className="ml-3 gap-1"
                data-testid="data-model-toolbar-show-connection-labels"
                onSelect={(event) => {
                  event.preventDefault();
                  setShowConnectionTypeLabels(!showConnectionTypeLabels);
                }}
              >
                <Switch checked={showConnectionTypeLabels} />
                {t('components.data_model.show_connection_labels')}
              </DropdownMenu.Item>
            )}
            <DropdownMenu.Separator className="my-4" />
            <DropdownMenu.Label className="font-medium text-subtle">
              {t('components.data_model.fields')}
            </DropdownMenu.Label>
            <DropdownMenu.Item
              className="gap-1"
              data-testid="data-model-toolbar-fields-visibility-select"
              onSelect={(event) => {
                event.preventDefault();
                setShowFields(!showFields);
              }}
            >
              <Select
                onValueChange={setFieldsVisibilityStatus}
                defaultValue={fieldsVisibilityStatus}
              >
                <Select.Trigger placeholder="Show only connection fields" className="min-w-full" />
                <Select.Content>
                  <Select.Item
                    value={FieldsVisibilityStatus.ALL_FIELDS}
                    data-testid="data-model-toolbar-show-all-fields"
                  >
                    {t('components.data_model.show_all_fields')}
                  </Select.Item>
                  <Select.Item
                    value={FieldsVisibilityStatus.ONLY_CONNECTIONS}
                    data-testid="data-model-toolbar-show-only-connection-fields"
                  >
                    {t('components.data_model.show_only_connection_fields')}
                  </Select.Item>
                </Select.Content>
              </Select>
            </DropdownMenu.Item>
            <DropdownMenu.Item
              className="gap-1"
              data-testid="data-model-toolbar-show-field-keys"
              onSelect={(event) => {
                event.preventDefault();
                setShowFieldKeys(!showFieldKeys);
              }}
            >
              <Switch checked={showFieldKeys} />
              {t('components.data_model.show_field_keys')}
            </DropdownMenu.Item>
            <DropdownMenu.Separator className="my-4" />
            <DropdownMenu.Item
              className="gap-1"
              data-testid="data-model-toolbar-collapse-all-fields"
              onSelect={(event) => {
                event.preventDefault();
                setFieldsCollapsibleStatus(FieldsCollapsibleStatus.ALL_COLLAPSED);
              }}
            >
              {t('components.data_model.collapse_all')}
              <DropdownMenu.Shortcut>⇧C</DropdownMenu.Shortcut>
            </DropdownMenu.Item>
            <DropdownMenu.Item
              data-testid="data-model-toolbar-expand-all-fields"
              onSelect={(event) => {
                event.preventDefault();
                setFieldsCollapsibleStatus(FieldsCollapsibleStatus.ALL_EXPANDED);
              }}
            >
              {t('components.data_model.expand_all')}
              <DropdownMenu.Shortcut>⇧E</DropdownMenu.Shortcut>
            </DropdownMenu.Item>
            <DropdownMenu.Item
              data-testid="data-model-toolbar-reset-auto-layout"
              onSelect={() => setAutoLayout()}
            >
              {t('components.data_model.reset_layout')}{' '}
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu>
      </div>
      <div className="flex items-center gap-4">
        <Button
          intent="minimal"
          onClick={onSaveDiagram}
          isLoading={isSavingDiagram}
          data-testid="data-model-toolbar-save-button"
        >
          {t('components.data_model.save_diagram')}
        </Button>
        <DataModelZoomDropdown />
        <Dialog>
          <Tooltip>
            <Tooltip.Trigger asChild>
              <Dialog.Trigger
                data-testid="shortcuts-dialog-trigger"
                aria-label={t('components.data_model.keyboard_shortcuts')}
              >
                <HelpIcon size={24} />
              </Dialog.Trigger>
            </Tooltip.Trigger>
            <Tooltip.Content side="bottom" className="z-20">
              {t('components.data_model.keyboard_shortcuts')}
            </Tooltip.Content>
          </Tooltip>
          <DataModelShortcutsDialogContent />
        </Dialog>
      </div>
    </div>
  );
}
