import { Trans, useTranslation } from 'react-i18next';
import { Label, RadioGroup } from '@knack/asterisk-react';

import { InlineKnackField } from '@/components/InlineKnackField';
import { type ViewSourcePathConnection } from '@/pages/pages/page-editor/add-view/helpers/useViewSources';
import { useAddRecordViewFlowContext } from './AddRecordViewFlowContext';

interface ConnectionDisambiguationSectionProps {
  label: string;
  connections: ViewSourcePathConnection[];
  value: string | undefined;
  setValue: (value: string) => void;
}

function ConnectionDisambiguationSection({
  label,
  connections,
  value,
  setValue
}: ConnectionDisambiguationSectionProps) {
  if (!connections || connections.length === 0) {
    return null;
  }

  return (
    <div>
      <p className="mb-2">{label}</p>
      <RadioGroup className="gap-1" onValueChange={(newValue) => setValue(newValue)} value={value}>
        {connections.map((connection) => (
          <RadioGroup.Container key={connection.field.key}>
            <RadioGroup.Item value={connection.field.key} />
            <Label>
              <Trans
                i18nKey="views.add_view_dialog.view_source_record_display_options.connection_disambiguation.connection_disambiguation_option"
                components={[
                  <InlineKnackField
                    key={connection.field.key}
                    fieldType="connection"
                    fieldName={connection.field.names.field}
                    className="bg-subtle"
                  />
                ]}
                values={{
                  objectName: connection.field.names.object
                }}
              />
            </Label>
          </RadioGroup.Container>
        ))}
      </RadioGroup>
    </div>
  );
}

export function ConnectionDisambiguation({ sourcePathKey }: { sourcePathKey: string }) {
  const [t] = useTranslation(undefined, {
    keyPrefix: 'views.add_view_dialog.view_source_record_display_options.connection_disambiguation'
  });
  const {
    selectedViewType,
    selectedViewSourcePath,
    selectedViewSourcePathDirectConnectionFieldKey,
    selectedViewSourcePathParentConnectionFieldKey,
    setSelectedViewSourcePathDirectConnectionFieldKey,
    setSelectedViewSourcePathParentConnectionFieldKey
  } = useAddRecordViewFlowContext();

  if (
    !selectedViewSourcePath ||
    !selectedViewSourcePath.connections ||
    sourcePathKey !== selectedViewSourcePath.key
  ) {
    return null;
  }

  const hasMultipleDirectConnections = selectedViewSourcePath.connections.direct.length > 1;
  const hasMultipleParentConnections = selectedViewSourcePath.connections.parent.length > 1;

  // If either the direct or parent connections have multiple ways to connect, we need to show options to let the user choose which field to use
  const needsConnectionDisambiguation =
    hasMultipleDirectConnections || hasMultipleParentConnections;

  if (!needsConnectionDisambiguation) {
    return null;
  }

  function getConnectionDisambiguationLabel(connectionType: 'direct' | 'parent') {
    if (!selectedViewSourcePath?.sourceParts) return '';

    const { fromObject, toObject, parentObject } = selectedViewSourcePath.sourceParts;

    const recordQuantity = selectedViewSourcePath.recordDisplayQuantity;

    if (connectionType === 'direct' && fromObject && toObject) {
      if (toObject.origin === 'userObject') {
        if (recordQuantity === 'one' || selectedViewType === 'form') {
          return t('which_connection_to_logged_in_user', {
            objectName: fromObject.inflections.singular,
            userObjectName: toObject.inflections.singular
          });
        }
        return t('which_connection_to_logged_in_user', {
          objectName: fromObject.inflections.plural,
          userObjectName: toObject.inflections.singular
        });
      }

      if (toObject.origin === 'pageObject') {
        if (recordQuantity === 'one' || selectedViewType === 'form') {
          return t('which_connection_to_page_object', {
            objectName: fromObject.inflections.singular,
            pageObjectName: toObject.inflections.singular
          });
        }
        return t('which_connection_to_page_object', {
          objectName: fromObject.inflections.plural,
          pageObjectName: toObject.inflections.singular
        });
      }

      if (recordQuantity === 'one' || selectedViewType === 'form') {
        return t('which_connection_to_object', {
          fromObjectName: fromObject.inflections.singular,
          toObjectName: toObject.inflections.singular
        });
      }
      return t('which_connection_to_object', {
        fromObjectName: fromObject.inflections.plural,
        toObjectName: toObject.inflections.singular
      });
    }

    if (connectionType === 'parent' && toObject && parentObject) {
      if (parentObject.origin === 'userObject') {
        return t('which_connection_to_logged_in_user', {
          objectName: toObject.inflections.singular,
          userObjectName: parentObject.inflections.singular
        });
      }

      if (parentObject.origin === 'pageObject') {
        return t('which_connection_to_page_object', {
          objectName: toObject.inflections.singular,
          pageObjectName: parentObject.inflections.singular
        });
      }

      return t('which_connection_to_object', {
        fromObjectName: toObject.inflections.singular,
        toObjectName: parentObject.inflections.singular
      });
    }

    return '';
  }

  return (
    <div className="[&>*:not(:last-child)]:mb-3">
      {hasMultipleDirectConnections && (
        <ConnectionDisambiguationSection
          label={getConnectionDisambiguationLabel('direct')}
          connections={selectedViewSourcePath.connections.direct}
          value={selectedViewSourcePathDirectConnectionFieldKey}
          setValue={setSelectedViewSourcePathDirectConnectionFieldKey}
        />
      )}
      {hasMultipleParentConnections && (
        <ConnectionDisambiguationSection
          label={getConnectionDisambiguationLabel('parent')}
          connections={selectedViewSourcePath.connections.parent}
          value={selectedViewSourcePathParentConnectionFieldKey}
          setValue={setSelectedViewSourcePathParentConnectionFieldKey}
        />
      )}
    </div>
  );
}
