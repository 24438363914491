import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, DropdownMenu, Label } from '@knack/asterisk-react';

import { type KnackFieldType } from '@/types/schema/KnackField';
import { FieldIcon } from '@/components/FieldIcon';
import { type Column } from '@/components/import/types';
import { useImportStore } from '@/components/import/useImportStore';
import { getDefaultSubFieldParts } from '@/components/import/utils';

interface Section {
  name: string;
  fields: KnackFieldType[];
}

export const sections: Section[] = [
  {
    name: 'text',
    fields: ['short_text', 'paragraph_text', 'rich_text']
  },
  {
    name: 'number',
    fields: ['number', 'currency']
  },
  {
    name: 'choice',
    fields: ['multiple_choice', 'boolean']
  },
  {
    name: 'date',
    fields: ['date_time', 'timer']
  },
  {
    name: 'file',
    fields: ['file', 'image']
  },
  {
    name: 'personal',
    fields: ['name', 'email', 'address', 'phone']
  },
  {
    name: 'other',
    fields: ['link', 'rating', 'connection']
  }
];

function Item({
  fieldType,
  updateColumns
}: {
  fieldType: KnackFieldType;
  updateColumns: (selectedFieldType: KnackFieldType) => void;
}) {
  const [t] = useTranslation();

  return (
    <DropdownMenu.Item
      onSelect={() => {
        updateColumns(fieldType);
      }}
      data-testid={`import-csv-dropdown-field-type-${fieldType}`}
      className="gap-2"
    >
      <FieldIcon name={fieldType as KnackFieldType} size={16} />
      {t(`attributes.field_types.${fieldType}`)}
    </DropdownMenu.Item>
  );
}

export function NewFields({
  searchKeyword = '',
  column
}: {
  searchKeyword?: string;
  column: Column;
}) {
  const [t] = useTranslation();
  const lowerCaseKeyword = searchKeyword.toLowerCase();
  const fieldsTypes = sections.reduce(
    (acc: KnackFieldType[], section: Section): KnackFieldType[] => [...acc, ...section.fields],
    []
  );
  const { columns, setColumns } = useImportStore((state) => ({
    columns: state.columns,
    setColumns: state.setColumns
  }));
  const filteredFieldsTypes = fieldsTypes.filter((field) =>
    t(`attributes.field_types.${field}`).toLowerCase().includes(lowerCaseKeyword)
  );

  const updateColumns = (newFieldType: KnackFieldType) => {
    const areFieldTypesEqual = column.meta?.newFieldType === newFieldType;

    if (areFieldTypesEqual && column.meta?.existingKnackField === undefined) {
      return;
    }

    const isTheNewFieldTheSameAsTheExistingField =
      column.meta?.existingKnackField?.type === newFieldType;

    const { thisColumnHasActiveSubfields, mappedSubFieldsInThisColumn } = column.meta.parts.reduce<{
      thisColumnHasActiveSubfields: boolean;
      mappedSubFieldsInThisColumn: number[];
    }>(
      (acc, part) => {
        if (part.mapped && part.mappedColumnIndex) {
          acc.thisColumnHasActiveSubfields = true;
          acc.mappedSubFieldsInThisColumn.push(part.mappedColumnIndex);
        }
        return acc;
      },
      { thisColumnHasActiveSubfields: false, mappedSubFieldsInThisColumn: [] }
    );

    const { mappedColumns, isThisColumnMappedByAnotherColumn } = columns.reduce<{
      mappedColumns: Column[];
      isThisColumnMappedByAnotherColumn: boolean;
    }>(
      (acc, col: Column) => {
        if (col.meta?.isThisColumnMapped) {
          acc.mappedColumns.push(col);
          acc.isThisColumnMappedByAnotherColumn = col.meta.mappedColumnIndex === column.accessorKey;
        }
        return acc;
      },
      { mappedColumns: [], isThisColumnMappedByAnotherColumn: false }
    );

    const updatedColumn: Column = {
      ...column,
      meta: {
        ...column.meta,
        newFieldType,
        // If the new field type is the same as the existing field type, we don't want to reset the subfield mapping.
        parts:
          thisColumnHasActiveSubfields && areFieldTypesEqual
            ? column.meta.parts
            : getDefaultSubFieldParts(newFieldType),
        existingKnackField: undefined
      }
    };

    const updatedColumns = columns.map((col) =>
      col.header === updatedColumn.header ? updatedColumn : col
    );

    // We need to reset the mapping of all columns that are mapped as subfields of this column, if the new field type is different from the existing field type.
    if (isThisColumnMappedByAnotherColumn && !isTheNewFieldTheSameAsTheExistingField) {
      mappedColumns.forEach((mappedColumn) => {
        // We only want to reset the mapping of the subfields of this column.
        if (mappedSubFieldsInThisColumn.includes(Number(mappedColumn.accessorKey))) {
          const isMappedToTheOriginColumn =
            mappedColumn.accessorKey === mappedColumn.meta.mappedColumnIndex;

          const updatedMappedColumn = {
            ...mappedColumn,
            meta: {
              ...mappedColumn.meta,
              newFieldType: isMappedToTheOriginColumn
                ? newFieldType
                : mappedColumn.meta.newFieldType,
              isThisColumnMapped: false,
              mappedColumnIndex: undefined,
              existingKnackField: undefined
            }
          };
          updatedColumns[mappedColumn.accessorKey] = updatedMappedColumn;
        }
      });
    }

    setColumns(updatedColumns);
  };

  if (searchKeyword !== '' && filteredFieldsTypes.length === 0) {
    return <Label className="mb-1 block">{t('components.add_table.no_results')}</Label>;
  }

  if (searchKeyword !== '' && filteredFieldsTypes.length > 0) {
    return (
      <>
        <Label className="mb-1 block">{t('components.add_table.search_results')}</Label>
        {filteredFieldsTypes.map((fieldType) => (
          <Item key={fieldType} fieldType={fieldType} updateColumns={updateColumns} />
        ))}
      </>
    );
  }

  return sections.map((section, index) => (
    <Fragment key={section.name}>
      <Label className="block px-2 pb-2" data-testid="import-csv-dropdown-section">
        {t(`attributes.field_categories.${section.name}`)}
      </Label>
      <div>
        {section.fields.map((fieldType) => (
          <Item
            key={`${section.name}-${fieldType}`}
            fieldType={fieldType}
            updateColumns={updateColumns}
          />
        ))}
      </div>
      {index !== sections.length - 1 && <Divider className="my-2" />}
    </Fragment>
  ));
}
