import { useTranslation } from 'react-i18next';
import { Input, Label } from '@knack/asterisk-react';
import { z } from 'zod';

import { type MenuViewLink } from '@/types/schema/views/MenuView';
import { urlRegex } from '@/components/data-table/helpers/zod';

function isUrlValid(url: string) {
  return z.string().regex(urlRegex).safeParse(url).success;
}

interface MenuLinkUrlInputProps {
  link: MenuViewLink;
  onChange: (newValue: string) => void;
}

export function MenuLinkUrlInput({ link, onChange }: MenuLinkUrlInputProps) {
  const [t] = useTranslation();

  if (link.type !== 'url') {
    return null;
  }

  const isExternalUrlValid = isUrlValid(link.url);

  return (
    <div>
      <Label className="mb-2 block font-medium" htmlFor={`${link.id}-menu-link-url`}>
        {t('keywords.url')}
      </Label>
      <Input
        id={`${link.id}-menu-link-url`}
        className="w-full"
        intent={!isExternalUrlValid ? 'destructive' : undefined}
        value={link.url}
        placeholder={t('keywords.url_example')}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />

      {!isExternalUrlValid && (
        <p className="mt-1 text-xs text-destructive">{t('errors.url_invalid')}</p>
      )}
    </div>
  );
}
