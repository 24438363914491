import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiTrash as DeleteIcon,
  HiDocumentDuplicate as DuplicateIcon,
  HiPencil as PencilIcon
} from 'react-icons/hi2';
import { MdDragIndicator as DragIcon } from 'react-icons/md';
import { type UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { Button } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

interface SortableCardWrapperProps {
  sortableItemId: UniqueIdentifier;
  isDragOverlay: boolean;
  isInactiveState?: boolean;
  isDraggingAllowed?: boolean;
  children: React.ReactNode;
  onEditClick?: () => void;
  onDuplicateClick?: () => void;
  onDeleteClick?: () => void;
}

function SortableCardWrapper(
  {
    sortableItemId,
    isDragOverlay,
    isInactiveState = false,
    isDraggingAllowed = true,
    children,
    onEditClick,
    onDuplicateClick,
    onDeleteClick
  }: SortableCardWrapperProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const [t] = useTranslation();

  const { listeners, setActivatorNodeRef, isDragging, isSorting } = useSortable({
    id: sortableItemId
  });

  return (
    <div
      ref={ref}
      className={cn(
        'group relative flex flex-col items-start rounded-lg border border-transparent bg-subtle p-3 hover:bg-emphasis',
        {
          'border-subtle bg-muted hover:border-transparent': isInactiveState,
          'bg-emphasis': isDragOverlay,
          'after:absolute after:inset-0 after:rounded-lg after:bg-muted/80': isDragging,
          'pointer-events-none': isSorting
        }
      )}
    >
      <button
        type="button"
        className={cn(
          'invisible absolute -left-2.5 top-4 cursor-grab rounded-l bg-subtle align-middle active:cursor-grabbing',
          {
            'group-hover:visible': isDraggingAllowed,
            'visible z-10': isDragging
          }
        )}
        ref={setActivatorNodeRef}
        {...listeners}
      >
        <DragIcon size={16} />
      </button>

      {children}

      {(onEditClick || onDuplicateClick || onDeleteClick) && (
        <div className="invisible absolute right-2 top-2 space-x-2 group-hover:visible">
          {onEditClick && (
            <Button
              intent="minimal"
              aria-label={t('actions.edit')}
              size="xs"
              className="text-subtle"
              onClick={onEditClick}
            >
              <PencilIcon size={14} />
            </Button>
          )}
          {onDuplicateClick && (
            <Button
              intent="minimal"
              aria-label={t('actions.duplicate')}
              size="xs"
              className="text-subtle"
              onClick={onDuplicateClick}
            >
              <DuplicateIcon size={14} />
            </Button>
          )}
          {onDeleteClick && (
            <Button
              intent="minimal"
              aria-label={t('actions.delete')}
              size="xs"
              className="text-subtle"
              onClick={onDeleteClick}
            >
              <DeleteIcon size={14} />
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

const SortableCardWrapperWithRef = forwardRef<HTMLDivElement, SortableCardWrapperProps>(
  SortableCardWrapper
);

export { SortableCardWrapperWithRef as SortableCardWrapper };
