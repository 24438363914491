import { useEffect, useRef } from 'react';
import { Controller, useFormContext, type Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, Input, Label, RichTextEditor } from '@knack/asterisk-react';
import { type Editor } from '@tiptap/react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { type RecordRule, type RecordRuleEmail } from '@/types/schema/rules/RecordRule';
import { type KnackTask } from '@/types/schema/tasks/KnackTask';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { cn } from '@/utils/tailwind';
import { EmailRecipientsFormSection } from '@/components/email/EmailRecipientsFormSection';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { FieldSelector } from '@/pages/tables/toolkit-sidebar/tasks/task-email/FieldSelector';

type BuilderRecordRuleEmailPath = keyof Pick<RecordRule, 'email'>;
type BuilderTasksEmailPath = Extract<Path<Pick<KnackTask, 'action'>>, 'action.email'>;

export type SendCustomEmailFormPath = BuilderRecordRuleEmailPath | BuilderTasksEmailPath;

interface SendCustomEmailFormProps {
  sourceObject: KnackObject;
  formFieldNamePrefix: SendCustomEmailFormPath;
}

export function SendCustomEmailForm({
  sourceObject,
  formFieldNamePrefix
}: SendCustomEmailFormProps) {
  const [t] = useTranslation();
  const { convertFieldKeysToFieldNames } = useFieldHelpers();

  const editorRef = useRef<Editor | null>(null);

  const {
    register,
    getValues,
    setValue,
    getFieldState,
    formState: { errors }
  } = useFormContext<KnackTask | RecordRule>();

  const getDefaultMessageValue = (message: string = '') =>
    convertFieldKeysToFieldNames(message, sourceObject.fields);

  const getDefaultSubjectValue = (subject: string = '') =>
    convertFieldKeysToFieldNames(subject, sourceObject.fields);

  useEffect(() => {
    const subject = getDefaultSubjectValue(getValues(`${formFieldNamePrefix}.subject`) || '');

    setValue(`${formFieldNamePrefix}.subject`, subject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasThisFieldError = (fieldName: Path<RecordRuleEmail>) =>
    getFieldState(`${formFieldNamePrefix}.${fieldName}`).error;

  return (
    <Form.Section>
      <div className="flex flex-col gap-4">
        <div className="flex w-full gap-2">
          <div className="flex w-1/2 flex-col gap-2">
            <Label htmlFor="send-custom-email-from-name-input" className="font-medium">
              {t('components.rules.record_rules.email.senders_name')}
            </Label>
            <Input
              id="send-custom-email-from-name-input"
              data-testid="send-custom-email-from-name-input"
              className="w-full"
              intent={hasThisFieldError('from_name') ? 'destructive' : undefined}
              {...register(`${formFieldNamePrefix}.from_name`)}
            />
          </div>
          <div className="flex w-1/2 flex-col gap-2">
            <Label htmlFor="send-custom-email-from-email-input" className="font-medium">
              {t('components.rules.record_rules.email.senders_email')}
            </Label>
            <Input
              id="send-custom-email-from-email-input"
              data-testid="send-custom-email-from-email-input"
              className="w-full"
              type="email"
              intent={hasThisFieldError('from_email') ? 'destructive' : undefined}
              {...register(`${formFieldNamePrefix}.from_email`)}
            />
            <FormErrorMessage name={`${formFieldNamePrefix}.from_email`} errors={errors} />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="send-custom-recipients" className="font-medium">
            {t('keywords.recipients')}
          </Label>
          <EmailRecipientsFormSection
            sourceObject={sourceObject}
            formFieldNamePrefix={formFieldNamePrefix}
          />
        </div>
        <div className="flex flex-col">
          <Label htmlFor="send-custom-email-subject" className="text-sm font-medium">
            {t('keywords.subject')}
          </Label>
          <Controller
            name={`${formFieldNamePrefix}.subject`}
            render={({ field: { value, onChange } }) => (
              <>
                <div
                  className={cn(
                    'mt-2 flex items-center rounded-lg border border-default hover:border-emphasis',
                    {
                      'border-destructive hover:border-destructive': hasThisFieldError('subject')
                    }
                  )}
                >
                  <Input
                    id="send-custom-email-subject"
                    data-testid="send-custom-email-subject-input"
                    className="w-full rounded-r-none border-0 focus:-outline-offset-2"
                    intent={hasThisFieldError('subject') ? 'destructive' : undefined}
                    value={value ?? ''}
                    onChange={(e) => onChange(e.target.value)}
                  />
                  <FieldSelector
                    className="rounded-l-none bg-input"
                    availableFields={sourceObject.fields}
                    onSelectField={(selectedField) => {
                      onChange(`${value} {${selectedField.name}}`);
                    }}
                  />
                </div>
                <FormErrorMessage
                  name={`${formFieldNamePrefix}.subject`}
                  errors={errors}
                  className="mt-1"
                />
              </>
            )}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="send-custom-email-message" className="font-medium">
            {t('keywords.message')}
          </Label>
          <Controller
            name={`${formFieldNamePrefix}.message`}
            render={({ field: { value, onChange } }) => (
              <RichTextEditor
                content={value}
                data-testid="send-custom-email-message"
                editorProps={{
                  attributes: {
                    'data-testid': 'send-custom-email-message-rich-text'
                  }
                }}
                toolbarChildren={
                  <FieldSelector
                    contentAlign="start"
                    className="bg-transparent hover:border-0"
                    availableFields={sourceObject.fields}
                    onSelectField={(selectedField) => {
                      editorRef.current?.commands.insertContent(`{${selectedField.name}}`);
                    }}
                  />
                }
                onCreate={({ editor }) => {
                  editorRef.current = editor;
                  editor.commands.setContent(getDefaultMessageValue(value));
                }}
                onUpdate={({ editor }) => {
                  const content = editor.isEmpty ? '' : editor.getHTML();
                  onChange(content);
                }}
              />
            )}
          />
        </div>
      </div>
    </Form.Section>
  );
}
