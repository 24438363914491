import { type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EQUATION_DATE_FORMAT_OPTIONS, EQUATION_TIME_FORMAT_OPTIONS } from '@/types/schema/fields';
import { type EquationSchemaType } from '@/components/field-settings/equation/EquationFormSettings';
import { SelectWithLabel } from '@/components/field-settings/utils/SelectWithLabel';

type EquationTypeSelectorProps = {
  currentField: ControllerRenderProps<EquationSchemaType, 'format'>;
};

export function EquationDateResultFormat({ currentField }: EquationTypeSelectorProps) {
  const [t] = useTranslation();
  return (
    <div>
      <div className="flex gap-2">
        <div className="flex w-1/2 grow flex-col gap-2">
          <SelectWithLabel
            label={t('components.data_table.attributes.field_settings.equation.date_format.label')}
            values={EQUATION_DATE_FORMAT_OPTIONS}
            defaultValue={currentField.value.date_format || EQUATION_DATE_FORMAT_OPTIONS[0]}
            onValueChange={(value) => {
              currentField.onChange({
                ...currentField.value,
                date_format: value
              });
            }}
            translationPrefix="components.data_table.attributes.field_settings.equation.date_format"
            data_testid="equation-settings-date-format"
          />
        </div>
        <div className="flex w-1/2 grow flex-col gap-2">
          <SelectWithLabel
            label={t('components.data_table.attributes.field_settings.equation.time_format.label')}
            values={EQUATION_TIME_FORMAT_OPTIONS}
            defaultValue={currentField.value.time_format || EQUATION_TIME_FORMAT_OPTIONS[0]}
            onValueChange={(value) => {
              currentField.onChange({
                ...currentField.value,
                time_format: value,
                date_type:
                  value === 'Ignore Time' && currentField.value.date_type === 'hours'
                    ? 'days'
                    : currentField.value.date_type
              });
            }}
            translationPrefix="components.data_table.attributes.field_settings.equation.time_format"
            data_testid="equation-settings-time-format"
          />
        </div>
      </div>
    </div>
  );
}
