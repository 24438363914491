import { useState } from 'react';
import { Controller, FormProvider, useForm, type SubmitHandler } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { HiTrash as DeleteIcon, HiArrowTopRightOnSquare as LinkIcon } from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Badge,
  Banner,
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Switch,
  useToast
} from '@knack/asterisk-react';
import { z } from 'zod';

import {
  useApplicationSettingsMutation,
  type AppSettingsPayload
} from '@/hooks/api/mutations/useApplicationSettingsMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { cn } from '@/utils/tailwind';
import { TextTooltip } from '@/components/TextTooltip';
import { DisableScriptProtectionDialog } from '@/pages/settings/security-settings/DisableScriptProtectionDialog';
import { EnableScriptProtectionDialog } from '@/pages/settings/security-settings/EnableScriptProtectionDialog';

export function SecuritySettings() {
  const [t] = useTranslation();
  const { data: application } = useApplicationQuery();

  const securitySettingsSchema = z.object({
    settings: z.object({
      isScriptProtectionEnabled: z.boolean(),
      hasSupportAccess: z.boolean(),
      ipWhitelist: z.string(),
      isSecureViewApiPayloadsEnabled: z.boolean(),
      isHttpsRedirectEnabled: z.boolean()
    })
  });

  const formMethods = useForm({
    resolver: zodResolver(securitySettingsSchema),
    defaultValues: {
      settings: {
        isScriptProtectionEnabled: application?.settings.isScriptProtectionEnabled || false,
        hasSupportAccess: application?.settings.hasSupportAccess || false,
        ipWhitelist: application?.settings.ipWhitelist || '',
        isSecureViewApiPayloadsEnabled:
          application?.settings.isSecureViewApiPayloadsEnabled || false,
        isHttpsRedirectEnabled: application?.settings.isHttpsRedirectEnabled || false
      }
    }
  });

  const { handleSubmit } = formMethods;
  const { presentToast } = useToast();
  const getAllowedIPs = () => {
    const allowedIPsString = application?.settings.ipWhitelist || '';
    return allowedIPsString.split('\n').filter((ip) => ip.trim() !== '');
  };

  const { mutate: updateApplicationSettings } = useApplicationSettingsMutation();

  const [allowedIPs, setAllowedIPs] = useState<string[]>(getAllowedIPs());
  const [newAllowedIP, setNewAllowedIP] = useState('');

  const [isIPRestrictingEnabled, setIsIPRestrictingEnabled] = useState(
    !!application?.settings.ipWhitelist
  );

  const [isScriptSecurityDialogOpen, setIsScriptSecurityDialogOpen] = useState(false);
  const [showIPBanner, setShowIPBanner] = useState(false);

  const handleDeleteIP = (ip: string) => {
    const updatedIps = allowedIPs.filter((d) => d !== ip);
    setAllowedIPs(updatedIps);
    presentToast({
      title: t('app_settings.security.ip_removed', {
        ip
      })
    });
  };

  const handleAddIP = () => {
    if (newAllowedIP) {
      if (allowedIPs.includes(newAllowedIP)) {
        setShowIPBanner(true);
        return;
      }
      setAllowedIPs([newAllowedIP, ...allowedIPs]);
      setNewAllowedIP('');
    }
  };

  const handleSwitch = (value: boolean) => {
    if (!value) {
      setIsIPRestrictingEnabled(false);
    } else {
      setIsIPRestrictingEnabled(true);
    }
  };

  type SecuritySettingsSchema = z.infer<typeof securitySettingsSchema>;

  const onFormSubmit: SubmitHandler<SecuritySettingsSchema> = (data) => {
    const ipWhitelist = isIPRestrictingEnabled ? allowedIPs.join('\n') : '';

    const payload: AppSettingsPayload = {
      settings: {
        scriptProtectionEnabled: data.settings.isScriptProtectionEnabled,
        support_access: data.settings.hasSupportAccess,
        ip_whitelist: ipWhitelist,
        secureViewApiPayloads: data.settings.isSecureViewApiPayloadsEnabled,
        https_redirect: data.settings.isHttpsRedirectEnabled
      }
    };

    updateApplicationSettings(payload, {
      onSuccess: () => {
        presentToast({
          title: t('app_settings.settings_saved_successfully')
        });
      },
      onError: () => {
        presentToast({
          title: t('app_settings.settings_saved_error')
        });
      }
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onFormSubmit)} className="space-y-6" data-testid="security-form">
        <section>
          <div className="mb-2">
            <Form.Label htmlFor="app_name" className="mr-2">
              {t('app_settings.security.script_attack_protection')}
            </Form.Label>
            {application?.settings.isScriptProtectionEnabled ? (
              <Badge intent="success">{t('app_settings.security.enabled')}</Badge>
            ) : (
              <Badge intent="destructive">{t('app_settings.security.disabled')}</Badge>
            )}
          </div>

          <p className="mb-4 text-sm text-subtle">
            <Trans
              i18nKey="app_settings.security.script_attack_protection_description"
              components={{
                1: (
                  <a
                    href="https://learn.knack.com/article/tt3nyix86r-live-app-security-settings"
                    target="_blank"
                    className="underline"
                    rel="noopener noreferrer"
                  >
                    This setting
                  </a>
                ),
                2: (
                  <a
                    href="https://learn.knack.com/article/wrgxmjttk1-managing-your-apps#security"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    whitelisted
                  </a>
                ),
                3: (
                  <a
                    href="https://owasp.org/www-community/attacks/xss/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    input-based hacks
                  </a>
                )
              }}
            />
          </p>
          <a
            href="https://learn.knack.com/article/ywwp8xxx1m-script-attack-protection-protected-areas"
            target="_blank"
            rel="noreferrer"
            className="mb-4 flex items-center"
          >
            <span className="underline">{t('actions.learn_more')}</span>
            <LinkIcon size={14} className="ml-1 shrink-0" />
          </a>
          {application?.settings.isScriptProtectionEnabled ? (
            <Button
              intent="destructiveSecondary"
              onClick={() => setIsScriptSecurityDialogOpen(true)}
            >
              {t('actions.disable')}
            </Button>
          ) : (
            <Button intent="secondary" onClick={() => setIsScriptSecurityDialogOpen(true)}>
              {t('actions.enable')}
            </Button>
          )}
        </section>
        <Divider />
        <Form.Section className="space-y-2">
          <Form.Label htmlFor="enable-support-access-checkbox">
            {t('app_settings.security.support_access')}
          </Form.Label>
          <div className="flex items-start gap-2">
            <Controller
              name="settings.hasSupportAccess"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  id="enable-support-access-checkbox"
                  className="mr-2 mt-1"
                  checked={field.value}
                  onCheckedChange={(value) => field.onChange(value)}
                />
              )}
            />
            <div className="space-y-2">
              <p className="text-default">{t('app_settings.security.enable_support_access')}</p>
              <Link
                to="https://learn.knack.com/article/1ew3hb6ecm-working-with-support"
                className="flex items-center underline"
              >
                <p className="text-xs text-subtle">
                  {t('app_settings.security.learn_about_support')}
                </p>
                <LinkIcon size={14} className="ml-1 shrink-0" />
              </Link>
            </div>
          </div>
        </Form.Section>
        <Form.Section>
          <Form.Label htmlFor="enable-ip-restricting-switch" className="mb-4 block">
            {t('app_settings.security.ip_restricting')}
          </Form.Label>
          <div className="flex items-start gap-2">
            <Switch
              id="enable-ip-restricting-switch"
              className="mr-2"
              checked={isIPRestrictingEnabled}
              onCheckedChange={handleSwitch}
            />
            <div className="space-y-4">
              <div className="space-y-2">
                <p className="text-default">{t('app_settings.security.enable_ip_restricting')}</p>
                <p className="text-xs text-subtle">
                  {t('app_settings.security.enable_ip_restricting_description')}
                </p>
                <Link
                  to="https://learn.knack.com/article/tt3nyix86r-live-app-security-settings#ip_whitelisting"
                  className="flex items-center"
                >
                  <p className="underline">{t('actions.learn_more')}</p>
                  <LinkIcon size={14} className="ml-1 shrink-0" />
                </Link>
              </div>
              <div
                className={cn({
                  hidden: !isIPRestrictingEnabled
                })}
              >
                <Form.Label htmlFor="allowed_ips">
                  {t('app_settings.security.allowed_ips')}
                </Form.Label>
                <div className="flex items-center justify-between gap-2">
                  <Input
                    id="allowed_ips"
                    data-testid="add-ip-input"
                    placeholder={t('app_settings.security.ip_example')}
                    value={newAllowedIP}
                    onChange={(e) => {
                      setShowIPBanner(false);
                      setNewAllowedIP(e.target.value);
                    }}
                  />
                  <Button
                    className="whitespace-nowrap"
                    intent="secondary"
                    data-testid="add-ip-button"
                    onClick={handleAddIP}
                  >
                    {t('app_settings.security.add_ip')}
                  </Button>
                </div>

                {allowedIPs.length === 0 ? (
                  <p className="mt-2 text-xs text-subtle" data-testid="no-ips-message">
                    {t('app_settings.security.no_ips_added_yet')}
                  </p>
                ) : (
                  <div className="mt-2 space-y-1">
                    {showIPBanner && (
                      <Banner intent="destructive" data-testid="ip-banner">
                        <Banner.Message>
                          {t('app_settings.security.ip_already_allowed')}
                        </Banner.Message>
                      </Banner>
                    )}

                    {allowedIPs.map((ip, index) => (
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className="flex justify-between rounded-md bg-subtle p-2"
                        data-testid={`allowed-ip-${index}`}
                      >
                        <p className="text-xs text-emphasis" data-testid={`ip-address-${index}`}>
                          {ip}
                        </p>
                        <TextTooltip label={t('actions.remove')} asChild>
                          <Button
                            intent="minimalStandalone"
                            className="h-4 p-0"
                            onClick={() => handleDeleteIP(ip)}
                            data-testid={`delete-ip-button-${index}`}
                          >
                            <DeleteIcon size={16} className="cursor-pointer" />
                          </Button>
                        </TextTooltip>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Form.Section>
        <Form.Section>
          <Form.Label htmlFor="enable_restrict_api_responses_switch" className="mb-4">
            {t('app_settings.security.restrict_api_responses')}
          </Form.Label>
          <div className="flex items-start gap-2">
            <Controller
              name="settings.isSecureViewApiPayloadsEnabled"
              render={({ field }) => (
                <Switch
                  {...field}
                  id="enable_restrict_api_responses_switch"
                  className="mr-2"
                  checked={field.value}
                  onCheckedChange={(value) => field.onChange(value)}
                />
              )}
            />
            <div className="space-y-2">
              <p className="text-default">
                {t('app_settings.security.enable_restrict_api_responses')}
              </p>
              <p className="text-xs text-subtle">
                <Trans
                  i18nKey="app_settings.security.enable_restrict_api_responses_description"
                  components={{
                    1: (
                      <a
                        href="https://docs.knack.com/docs/using-javascript-with-knack"
                        target="_blank"
                        className="underline"
                        rel="noopener noreferrer"
                      >
                        Live App JavaScript events
                      </a>
                    ),
                    2: (
                      <a
                        href="https://docs.knack.com/docs/view-based-requests"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                      >
                        view-based API requests
                      </a>
                    )
                  }}
                />
              </p>
            </div>
          </div>
        </Form.Section>
        <Form.Section>
          <Form.Label htmlFor="enable_secure_browser_switch" className="mb-4">
            {t('app_settings.security.secure_browser')}
          </Form.Label>
          <div className="flex items-start gap-2">
            <Controller
              name="settings.isHttpsRedirectEnabled"
              render={({ field }) => (
                <Switch
                  {...field}
                  id="enable_secure_browser_switch"
                  className="mr-2"
                  checked={field.value}
                  onCheckedChange={(value) => field.onChange(value)}
                />
              )}
            />
            <p className="text-default">{t('app_settings.security.enable_secure_browser')}</p>
          </div>
        </Form.Section>
        <div className="flex">
          <Button data-testid="save-settings-button" type="submit" className="ml-auto mt-6">
            {t('app_settings.save_settings')}
          </Button>
        </div>
        {application?.settings.isScriptProtectionEnabled ? (
          <DisableScriptProtectionDialog
            isOpen={isScriptSecurityDialogOpen}
            onOpenChange={setIsScriptSecurityDialogOpen}
          />
        ) : (
          <EnableScriptProtectionDialog
            isOpen={isScriptSecurityDialogOpen}
            onOpenChange={setIsScriptSecurityDialogOpen}
          />
        )}
      </Form>
    </FormProvider>
  );
}
