import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import type { ColorOptions } from '@/types/schema/views/ReportView';
import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';

export function Contrast() {
  const [t] = useTranslation();

  const { chart, updateChart } = useActiveChartContext();

  const contrastItems: TabsListItem[] = [
    {
      children: <span>{t('pages.element_settings.report.categories.general.monochromatic')}</span>,
      value: 'monochromatic'
    },
    {
      children: <span>{t('pages.element_settings.report.categories.general.high_contrast')}</span>,
      value: 'highContrast'
    }
  ];

  return (
    <div className="my-4">
      <div className="mb-2">{t('pages.element_settings.report.categories.general.contrast')}</div>
      <Tabs
        value={chart.layout.colors || 'monochromatic'}
        onValueChange={(value) => {
          updateChart({
            layout: {
              ...chart.layout,
              colors: value as ColorOptions
            }
          });
        }}
      >
        <Tabs.List items={contrastItems} shouldDisableResponsive />
      </Tabs>
    </div>
  );
}
