import { useTranslation } from 'react-i18next';
import { Input, Label, RichTextEditor } from '@knack/asterisk-react';

import { type ReportView } from '@/types/schema/views/ReportView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';

export function ChartTitleDescriptionSettings() {
  const [t] = useTranslation();

  const { view } = useActiveViewContext<ReportView>();
  const { chart, updateChart } = useActiveChartContext();

  return (
    <BuilderAccordion.Item
      isDefaultOpen
      label={t('pages.element_settings.report.categories.general.chart_title_and_description')}
    >
      <div className="mb-4">
        <Label className="mb-2 block font-medium" htmlFor={`${view.key}-${chart.id}-chart-title`}>
          {t('pages.element_settings.report.categories.general.chart_title')}
        </Label>
        <Input
          value={chart.title ?? ''}
          onChange={(e) => {
            updateChart({
              title: e.target.value
            });
          }}
        />
        <p className="mt-2 text-xs text-subtle">
          {t('pages.element_settings.report.categories.general.chart_title_hint')}
        </p>
      </div>
      <div className="mb-4">
        <span className="mb-2 block font-medium">
          {t('pages.element_settings.report.categories.general.chart_description')}
        </span>
        <RichTextEditor
          onUpdate={({ editor }) => {
            updateChart({
              description: editor.getText() ? editor.getHTML() : ''
            });
          }}
          content={chart.description || ''}
        />
        <p className="mt-2 text-xs text-subtle">
          {t('pages.element_settings.report.categories.general.chart_description_hint')}
        </p>
      </div>
    </BuilderAccordion.Item>
  );
}
