import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiEllipsisHorizontal as EllipsisHorizontalIcon } from 'react-icons/hi2';
import { Button, Input, useToast } from '@knack/asterisk-react';

import { useTableMutation } from '@/hooks/api/mutations/useTableMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { TABLE_NAME_MAX_CHAR_LENGTH } from '@/utils/constants';
import { RoleDropdownMenu } from '@/pages/roles/role-dropdown-menu/RoleDropdownMenu';
import { TableDropdownMenu } from '@/pages/tables/tables-tree/table-dropdown-menu/TableDropdownMenu';

export type TablesPageTopbarProps = {
  objectKey: string;
};
export function TablesPageTopbar({ objectKey }: TablesPageTopbarProps) {
  const { data: app } = useApplicationQuery();
  const knackTable = app?.objects.find((object) => object.key === objectKey);
  const [t] = useTranslation();

  const existingTableNames = app?.objects.map((object) => object.name) ?? [];
  const isUser = knackTable?.user ?? false;
  const shouldDisableInlineEdit = isUser && knackTable?.profile_key === 'all_users';

  const [isEditingTableName, setIsEditingTableName] = useState(false);
  const { updateMutation } = useTableMutation();
  const [tableNameValue, setTableNameValue] = useState(knackTable?.name);
  const { presentToast } = useToast();

  const inputIntent = useMemo(() => {
    const trimmedValue = tableNameValue?.trim() || '';
    if (!trimmedValue || trimmedValue.length > TABLE_NAME_MAX_CHAR_LENGTH) {
      return 'destructive';
    }
    return 'default';
  }, [tableNameValue]);

  useEffect(() => {
    setTableNameValue(knackTable?.name);
  }, [knackTable?.name]);

  if (!knackTable) return null;

  const handleNameChangeSubmit = () => {
    setIsEditingTableName(false);

    const trimmedTableNameValue = tableNameValue?.trim() || '';

    if (trimmedTableNameValue === knackTable.name) return;

    if (existingTableNames.includes(trimmedTableNameValue)) {
      presentToast({
        title: isUser
          ? t('components.add_table.errors.role_name_unique')
          : t('components.add_table.errors.table_name_unique')
      });
      setTableNameValue(knackTable.name);
      return;
    }

    if (trimmedTableNameValue === 'Accounts') {
      presentToast({
        title: t('errors.accounts_is_a_reserved_name')
      });
      setTableNameValue(knackTable.name);
      return;
    }

    if (!trimmedTableNameValue || trimmedTableNameValue.length > TABLE_NAME_MAX_CHAR_LENGTH) {
      setTableNameValue(knackTable.name);
      return;
    }

    updateMutation.mutate(
      {
        object_key: knackTable.key,
        name: trimmedTableNameValue
      },
      {
        onError: () => {
          setTableNameValue(knackTable.name);
          presentToast({
            title: t('components.something_went_wrong.title')
          });
        }
      }
    );
  };

  const renderTableName = () => {
    if (shouldDisableInlineEdit) {
      return (
        <span
          className="mx-4 truncate text-xl font-semibold text-emphasis"
          data-testid="data-table-heading"
        >
          {tableNameValue}
        </span>
      );
    }
    return (
      <Button
        intent="minimal"
        onClick={() => setIsEditingTableName(true)}
        className="min-w-0"
        data-testid="data-table-heading"
      >
        <span className="truncate text-xl font-semibold text-emphasis">{tableNameValue}</span>
      </Button>
    );
  };

  const getDropDownMenu = () => {
    if (isUser) {
      return (
        <RoleDropdownMenu
          table={knackTable}
          triggerElement={
            <Button intent="minimalStandalone">
              <EllipsisHorizontalIcon size={20} />
            </Button>
          }
        />
      );
    }
    return (
      <TableDropdownMenu
        table={knackTable}
        triggerElement={
          <Button intent="minimalStandalone">
            <EllipsisHorizontalIcon size={20} />
          </Button>
        }
      />
    );
  };

  return (
    <div className="flex items-center gap-2 px-6 pt-6">
      {isEditingTableName ? (
        <form onSubmit={handleNameChangeSubmit}>
          <Input
            autoFocus
            placeholder={t('components.dialogs.tables.table_name')}
            className="w-60"
            onChange={(e) => setTableNameValue(e.target.value)}
            value={tableNameValue}
            onBlur={handleNameChangeSubmit}
            intent={inputIntent}
          />
        </form>
      ) : (
        renderTableName()
      )}
      {getDropDownMenu()}
    </div>
  );
}
