import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, InputSearch } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import {
  DISALLOWED_FIELD_TYPES_IN_FORM_VIEWS,
  type FormView,
  type FormViewFieldInput,
  type FormViewInput
} from '@/types/schema/views/FormView';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { AddStaticInputButton } from './AddStaticInputButton';
import { FormFieldInputItem } from './FormFieldInputItem';
import { FormLayout } from './FormLayout';

function getFieldsByEligibility(fields: KnackField[]) {
  const allowed: KnackField[] = [];
  const disallowed: KnackField[] = [];

  fields.forEach((field) => {
    const isFieldAllowedInForm =
      !DISALLOWED_FIELD_TYPES_IN_FORM_VIEWS.includes(field.type) && !field.conditional;

    if (isFieldAllowedInForm) {
      allowed.push(field);
    } else {
      disallowed.push(field);
    }
  });

  return [allowed, disallowed];
}

function createFieldInput(field: KnackField): FormViewFieldInput {
  return {
    id: field.key,
    type: field.type,
    label: field.name,
    instructions: '',
    format: field.format,
    field: { key: field.key },
    ...(field.type === 'connection' && { source: { filters: [] } })
  } as FormViewFieldInput;
}

export function FormFieldManagement() {
  const [t] = useTranslation();
  const { updatePage } = usePageEditorContext();
  const { view, sourceObject } = useActiveViewContext<FormView>();

  const [visibleFields, setVisibleFields] = useState<KnackField[]>(sourceObject.fields);
  const [searchValue, setSearchValue] = useState('');

  // Allowed fields from the initial list of fields from the source object
  const [allowedFields, disallowedFields] = useMemo(
    () => getFieldsByEligibility(sourceObject.fields),
    [sourceObject.fields]
  );

  // Allowed and disallowed fields that are visible after a search
  const [visibleAllowedFields, visibleDisallowedFields] = useMemo(
    () => getFieldsByEligibility(visibleFields),
    [visibleFields]
  );

  // The non-static field inputs that have been already added to the form
  const activeFieldInputs = useMemo(
    () =>
      view.groups.reduce((acc, group) => {
        group.columns.forEach((column) => {
          column.inputs.forEach((input) => {
            if (input.type !== 'divider' && input.type !== 'section_break') {
              acc.push(input);
            }
          });
        });
        return acc;
      }, [] as FormViewFieldInput[]),
    [view.groups]
  );

  // The non-static field inputs that have been already added to the form and are visible after a search
  const visibleActiveFieldInputs = activeFieldInputs.filter((input) =>
    visibleAllowedFields.some((field) => input.field.key === field.key)
  );

  // There might be disallowed fields that are already in the form, so we need to account for them
  const disallowedFieldsInTheForm = activeFieldInputs.filter((input) =>
    disallowedFields.some((field) => input.field.key === field.key)
  );

  const onAddFieldInput = (field: KnackField) => {
    updatePage({
      type: 'view',
      action: 'update',
      origin: 'builder',
      viewKey: view.key,
      viewSchema: {
        // Add the new field input to the last column in the last group
        groups: view.groups.map((group, groupIndex) => {
          if (groupIndex === view.groups.length - 1) {
            return {
              ...group,
              columns: group.columns.map((column, columnIndex) => {
                if (columnIndex === group.columns.length - 1) {
                  return {
                    ...column,
                    inputs: [...column.inputs, createFieldInput(field)]
                  };
                }
                return column;
              })
            };
          }
          return group;
        })
      }
    });
  };

  const onRemoveFieldInput = (field: KnackField) => {
    updatePage({
      type: 'view',
      origin: 'builder',
      action: 'update',
      viewKey: view.key,
      viewSchema: {
        groups: view.groups.map((group) => ({
          ...group,
          columns: group.columns.map((column) => ({
            ...column,
            inputs: column.inputs.filter((input) => {
              const isStaticInput = input.type === 'divider' || input.type === 'section_break';

              if (!isStaticInput) {
                return input.field.key !== field.key;
              }

              return input;
            })
          }))
        }))
      }
    });
  };

  const onAddAllFieldInputs = () => {
    const inputsToAdd: FormViewInput[] = [];

    // Create inputs for all the fields that are not already in the form
    visibleAllowedFields.forEach((field) => {
      if (activeFieldInputs.some((input) => input.field.key === field.key)) {
        return;
      }

      inputsToAdd.push(createFieldInput(field));
    });

    updatePage({
      type: 'view',
      origin: 'builder',
      action: 'update',
      viewKey: view.key,
      viewSchema: {
        // Add the new field inputs to the last column in the last group
        groups: view.groups.map((group, groupIndex) => {
          if (groupIndex === view.groups.length - 1) {
            return {
              ...group,
              columns: group.columns.map((column, columnIndex) => {
                if (columnIndex === group.columns.length - 1) {
                  return {
                    ...column,
                    inputs: [...column.inputs, ...inputsToAdd]
                  };
                }
                return column;
              })
            };
          }
          return group;
        })
      }
    });
  };

  const onRemoveAllFieldInputs = () => {
    updatePage({
      type: 'view',
      origin: 'builder',
      action: 'update',
      viewKey: view.key,
      viewSchema: {
        groups: view.groups.map((group) => ({
          ...group,
          columns: group.columns.map((column) => ({
            ...column,
            inputs: column.inputs.filter((input) => {
              const isStaticInput = input.type === 'divider' || input.type === 'section_break';
              return (
                isStaticInput ||
                !visibleAllowedFields.some((field) => input.field.key === field.key)
              );
            })
          }))
        }))
      }
    });
  };

  const onSearch = (value: string) => {
    const trimmedValue = value.trim().toLowerCase();

    setSearchValue(trimmedValue);

    if (trimmedValue === '') {
      setVisibleFields(sourceObject.fields);
      return;
    }

    setVisibleFields(
      sourceObject.fields.filter((field) => field.name.toLowerCase().includes(trimmedValue))
    );
  };

  return (
    <>
      <div className="mb-4">
        <FormLayout />
        <div className="mb-2 flex items-center gap-1">
          <span className="text-xs font-medium text-emphasis">
            {t('pages.element_settings.form.categories.form_fields.field_management.manage_fields')}
          </span>
          <span className="rounded-sm bg-subtle px-1 py-0.5 text-xs font-medium text-default">
            {t(
              'pages.element_settings.form.categories.form_fields.field_management.active_inputs_count',
              {
                currentCount: activeFieldInputs.length,
                totalCount: allowedFields.length + disallowedFieldsInTheForm.length
              }
            )}
          </span>
        </div>
        <p className="mb-4 text-xs text-subtle">
          {t(
            'pages.element_settings.form.categories.form_fields.field_management.manage_fields_description'
          )}
        </p>
        <InputSearch
          className="mb-4"
          aria-label={t(
            'pages.element_settings.form.categories.form_fields.field_management.search_fields'
          )}
          placeholder={t(
            'pages.element_settings.form.categories.form_fields.field_management.search_fields'
          )}
          value={searchValue}
          onChange={(e) => onSearch(e.target.value)}
        />

        <div className="mb-4 flex gap-3">
          <Button
            disabled={
              visibleAllowedFields.length === 0 ||
              visibleAllowedFields.length === visibleActiveFieldInputs.length ||
              visibleFields.length === 0
            }
            intent="link"
            className="text-xs"
            onClick={onAddAllFieldInputs}
          >
            {t('pages.element_settings.form.categories.form_fields.field_management.add_all')}
          </Button>
          <Button
            disabled={visibleActiveFieldInputs.length === 0 || visibleFields.length === 0}
            intent="link"
            className="text-xs"
            onClick={onRemoveAllFieldInputs}
          >
            {t('pages.element_settings.form.categories.form_fields.field_management.remove_all')}
          </Button>
        </div>

        {visibleAllowedFields.length > 0 && (
          <div className="space-y-2">
            {visibleAllowedFields.map((field) => {
              const fieldInput = activeFieldInputs.find((input) => input.field.key === field.key);

              return (
                <FormFieldInputItem
                  key={field.key}
                  field={field}
                  fieldInput={fieldInput}
                  isChecked={fieldInput?.field.key === field.key}
                  onCheck={(isChecked) => {
                    if (isChecked) {
                      onAddFieldInput(field);
                    } else {
                      onRemoveFieldInput(field);
                    }
                  }}
                />
              );
            })}
          </div>
        )}

        {visibleDisallowedFields.length > 0 && (
          <div className="mt-6">
            <p className="mb-2 text-xs font-medium text-emphasis">
              {t(
                'pages.element_settings.form.categories.form_fields.field_management.disallowed_fields'
              )}
            </p>
            <div className="space-y-2">
              {visibleDisallowedFields.map((field) => {
                // Determine if the disallowed field is already in the form so we can show it as checked
                const isChecked = disallowedFieldsInTheForm.some((f) => f.field.key === field.key);

                return (
                  <FormFieldInputItem
                    key={field.key}
                    field={field}
                    isDisallowed
                    isChecked={isChecked}
                  />
                );
              })}
            </div>
          </div>
        )}

        {visibleFields.length === 0 && (
          <div className="text-subtle">
            <Trans
              i18nKey="pages.element_settings.form.categories.form_fields.field_management.no_results"
              components={[<p key="0" className="mb-2" />, <p key="1" />]}
              values={{
                objectName: sourceObject.name
              }}
            />
          </div>
        )}
      </div>

      <div className="sticky -bottom-6 -mb-6 mt-auto border-t border-t-subtle bg-muted py-4">
        <AddStaticInputButton />
      </div>
    </>
  );
}
