import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { sendMessageToIframe } from '@/contexts/utils';

interface MessagingContextState<MessageToLiveApp = unknown, MessageFromLiveApp = unknown> {
  iframeRef: React.RefObject<HTMLIFrameElement>;
  registerIframeRef: (iFrameElement: HTMLIFrameElement) => void;
  messageFromLiveApp: MessageFromLiveApp | null;
  sendMessageToLiveApp: (message: MessageToLiveApp) => void;
  resetMessageFromLiveApp: () => void;
}

// This is a context factory that generates a context with the specified types for the messages, allowing the context to be used in multiple places with different message types.
export function createMessagingContext<MessageToLiveApp = unknown, MessageFromLiveApp = unknown>() {
  return createContext<MessagingContextState<MessageToLiveApp, MessageFromLiveApp> | null>(null);
}

// Consumers of this provider need to explicit pass a context generated by `createMessagingContext`
export function MessagingContextProvider<MessageToLiveApp, MessageFromLiveApp>({
  context: Context,
  children
}: {
  context: React.Context<MessagingContextState<MessageToLiveApp, MessageFromLiveApp> | null>;
  children: React.ReactNode;
}) {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [messageFromLiveApp, setMessageFromLiveApp] = useState<MessageFromLiveApp | null>(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [iframeRegistered, setIframeRegistered] = useState(false);

  const registerIframeRef = (iFrameElement: HTMLIFrameElement) => {
    iframeRef.current = iFrameElement;
    setIframeRegistered(true);
  };

  const sendMessageToLiveApp = useCallback(
    (message: MessageToLiveApp) => sendMessageToIframe(message, iframeLoaded, iframeRef.current),
    [iframeLoaded]
  );

  const handleIncomingMessage = (event: MessageEvent) => {
    if (event.origin !== new URL(import.meta.env.PUBLIC_LIVE_APP_URL).origin) {
      return;
    }
    setMessageFromLiveApp(event.data);
  };

  const resetMessageFromLiveApp = () => {
    setMessageFromLiveApp(null);
  };

  const contextValue = useMemo(
    () => ({
      iframeRef,
      registerIframeRef,
      messageFromLiveApp,
      sendMessageToLiveApp,
      resetMessageFromLiveApp
    }),
    [iframeRef, messageFromLiveApp, sendMessageToLiveApp]
  );

  useEffect(() => {
    const handleLoad = () => {
      setIframeLoaded(true);
    };

    if (iframeRef.current) {
      iframeRef.current.addEventListener('load', handleLoad);
    }

    window.addEventListener('message', handleIncomingMessage);

    return () => {
      window.removeEventListener('message', handleIncomingMessage);

      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', handleLoad);
      }
    };
  }, [iframeRegistered]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}
