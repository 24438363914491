import { type BaseKnackField } from '@/types/schema/KnackField';

export const EQUATION_TYPE_OPTIONS = ['date', 'numeric'] as const;
export const EQUATION_DATE_TYPE_OPTIONS = ['hours', 'days', 'seconds', 'weeks', 'years'] as const;
export const EQUATION_DATE_RESULT_OPTIONS = ['date', 'number'] as const;
export const EQUATION_MARK_DECIMAL_OPTIONS = ['none', 'period', 'comma', 'space'] as const;
export const EQUATION_ROUNDING_OPTIONS = ['none', 'up', 'down'] as const;
export const EQUATION_THOUSANDS_OPTIONS = ['none', 'period', 'comma', 'space'] as const;
export const EQUATION_NUMBER_FORMAT_OPTIONS = [
  'none',
  '$',
  '£',
  '€_after',
  '€',
  '%',
  'custom'
] as const;

export const EQUATION_DATE_FORMAT_OPTIONS = [
  'mm/dd/yyyy',
  'M D, yyyy',
  'dd/mm/yyyy',
  'Ignore Date'
] as const;

export const EQUATION_TIME_FORMAT_OPTIONS = [
  'Ignore Time',
  'HH:MM am',
  'HH MM (military)'
] as const;

export interface EquationField extends BaseKnackField {
  type: 'equation';
  format: {
    equation: string;
    equation_type: (typeof EQUATION_TYPE_OPTIONS)[number];
    date_type: (typeof EQUATION_DATE_TYPE_OPTIONS)[number];
    date_result: (typeof EQUATION_DATE_RESULT_OPTIONS)[number];
    mark_decimal: (typeof EQUATION_MARK_DECIMAL_OPTIONS)[number];
    precision: string;
    rounding: (typeof EQUATION_ROUNDING_OPTIONS)[number];
    mark_thousands: (typeof EQUATION_THOUSANDS_OPTIONS)[number];
    format: (typeof EQUATION_NUMBER_FORMAT_OPTIONS)[number];
    post: string;
    pre: string;
    date_format: (typeof EQUATION_DATE_FORMAT_OPTIONS)[number];
    time_format: (typeof EQUATION_TIME_FORMAT_OPTIONS)[number];
    // Unused
    // formula_field: string;
    // count_field: string;
  };
}
