import { useTranslation } from 'react-i18next';
import { HiChevronDown as ChevronDownIcon } from 'react-icons/hi2';
import { Button, Collapsible } from '@knack/asterisk-react';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { ViewDetailsFieldInputItem } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/ViewDetailsFieldInputItem';

interface ViewDetailsFieldListCollapsibleProps {
  connectionKey?: KnackFieldKey;
  visibleFields: KnackField[];
  activeFieldInputs: DetailsViewInput[];
  triggerElement: React.ReactNode;
  onAddFieldInput: (field: KnackField, connectionKey?: KnackFieldKey) => void;
  onRemoveFieldInput: (field: KnackField, connectionKey?: KnackFieldKey) => void;
  onAddAllFieldInputs: (fieldsToAdd: KnackField[], connectionKey?: KnackFieldKey) => void;
  onRemoveAllFieldInputs: (fieldsToRemove: KnackField[], connectionKey?: KnackFieldKey) => void;
  dataTestId?: string;
}

export function ViewDetailsFieldListCollapsible({
  connectionKey,
  visibleFields,
  activeFieldInputs,
  triggerElement,
  onAddFieldInput,
  onRemoveFieldInput,
  onAddAllFieldInputs,
  onRemoveAllFieldInputs,
  dataTestId
}: ViewDetailsFieldListCollapsibleProps) {
  const [t] = useTranslation();

  const visibleFieldKeys = visibleFields.map((visibleField) => visibleField.key);
  const visibleFieldInputs = activeFieldInputs.filter((activeField) => {
    if (connectionKey) {
      return (
        visibleFieldKeys.includes(activeField.key) && connectionKey === activeField.connection?.key
      );
    }
    return visibleFieldKeys.includes(activeField.key);
  });

  return (
    <Collapsible defaultOpen className="flex flex-col gap-2" data-testid={dataTestId}>
      <Collapsible.Trigger className="group flex gap-1 text-start text-emphasis">
        {triggerElement}
        <ChevronDownIcon
          size={14}
          className="ml-auto transition-transform duration-300 group-data-[state=open]:rotate-180"
        />
      </Collapsible.Trigger>

      <Collapsible.Content className="flex flex-col gap-2">
        {visibleFields.length ? (
          <>
            <div className="mb-1 mt-2 flex gap-3">
              <Button
                disabled={
                  visibleFields.length === visibleFieldInputs.length || visibleFields.length === 0
                }
                intent="link"
                className="text-xs"
                onClick={() => onAddAllFieldInputs(visibleFields, connectionKey)}
                aria-label={t(
                  'pages.element_settings.common.categories.data_display.field_management.add_all'
                )}
              >
                {t(
                  'pages.element_settings.common.categories.data_display.field_management.add_all'
                )}
              </Button>
              <Button
                aria-label={t(
                  'pages.element_settings.common.categories.data_display.field_management.remove_all'
                )}
                disabled={visibleFields.length === 0 || visibleFieldInputs.length === 0}
                intent="link"
                className="text-xs"
                onClick={() => onRemoveAllFieldInputs(visibleFields, connectionKey)}
              >
                {t(
                  'pages.element_settings.common.categories.data_display.field_management.remove_all'
                )}
                {visibleFieldInputs.length > 0 && ` (${visibleFieldInputs.length})`}
              </Button>
            </div>
            {visibleFields.map((visibleField) => {
              const activeFieldInput = activeFieldInputs.find((fieldInput) =>
                connectionKey
                  ? fieldInput.key === visibleField.key &&
                    fieldInput.connection?.key === connectionKey
                  : fieldInput.key === visibleField.key
              );
              const isSameField = connectionKey
                ? activeFieldInput?.key === visibleField.key &&
                  activeFieldInput?.connection?.key === connectionKey
                : activeFieldInput?.key === visibleField.key;

              return (
                <ViewDetailsFieldInputItem
                  key={`connection-field-${visibleField.key}`}
                  field={visibleField}
                  fieldInput={activeFieldInput}
                  isChecked={isSameField}
                  onCheck={(isChecked) => {
                    if (isChecked) {
                      onAddFieldInput(visibleField, connectionKey);
                    } else {
                      onRemoveFieldInput(visibleField, connectionKey);
                    }
                  }}
                />
              );
            })}
          </>
        ) : (
          <p className="mt-2 text-center text-xs text-subtle">
            {t(
              'pages.element_settings.common.categories.data_display.field_management.no_fields_found'
            )}
          </p>
        )}
      </Collapsible.Content>
    </Collapsible>
  );
}
