import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type RecordRule } from '@/types/schema/rules/RecordRule';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { InlineKnackField } from '@/components/InlineKnackField';
import { InlineKnackRecordValue } from '@/components/InlineKnackRecordValue';
import { EmptyRuleValue } from '@/pages/pages/settings-panel/view-settings/common/EmptyRuleValue';

interface ViewRecordRuleCardProps {
  recordRule: RecordRule;
  sourceObjectFieldsMap: Record<KnackFieldKey, KnackField>;
}

export function ViewRecordRuleRecordCard({
  recordRule,
  sourceObjectFieldsMap
}: ViewRecordRuleCardProps) {
  const [t] = useTranslation();
  const { getConnectedFieldInOtherSourceObject, getFieldByKey } = useFieldHelpers();

  return (
    <>
      <span>{t('components.rules.then')}</span>
      {recordRule.values.map((value, valueIndex) => {
        const valueField = getFieldByKey(value.field);
        const inputField = value.input ? sourceObjectFieldsMap[value.input] : null;
        const connectedFieldInOtherTable = getConnectedFieldInOtherSourceObject(
          value.connection_field,
          sourceObjectFieldsMap
        );

        if (!valueField) {
          return (
            <EmptyRuleValue
              // eslint-disable-next-line react/no-array-index-key
              key={`${value.field}-${valueIndex}`}
            />
          );
        }

        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${value.field}-${valueIndex}`}
            className="flex flex-wrap items-center gap-1"
          >
            {recordRule.action === 'record' && t('components.record_rule_card.update')}
            <InlineKnackField
              fieldType={valueField.type}
              fieldName={valueField.name}
              className="border-none bg-action"
            />
            {valueField.type !== 'signature' && t('keywords.with')}
            {value.type === 'current_location' && (
              <span className="inline-flex items-center rounded-md border-none bg-action p-1 align-middle leading-none text-emphasis">
                {t('components.record_rule_card.current_location')}
              </span>
            )}
            {value.type === 'current_date' && (
              <span className="inline-flex items-center rounded-md border-none bg-action p-1 align-middle leading-none text-emphasis">
                {t('components.record_rule_card.current_date')}
              </span>
            )}
            {value.type === 'value' && !isNil(value.value) && valueField.type !== 'signature' && (
              <InlineKnackRecordValue
                value={value.value}
                className="border-none bg-action"
                field={valueField}
              />
            )}
            {isNil(value.value) && <EmptyRuleValue />}
            {value.type === 'record' && inputField && (
              <InlineKnackField
                fieldName={inputField.name}
                fieldType={inputField.type}
                className="border-none bg-action"
              />
            )}
            {value.type === 'connection' && connectedFieldInOtherTable && (
              <InlineKnackField
                fieldName={connectedFieldInOtherTable.name}
                fieldType={connectedFieldInOtherTable.type}
                className="border-none bg-action"
              />
            )}
          </div>
        );
      })}
    </>
  );
}
