import { Controller, useFormContext, type FieldArrayWithId } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiXMark as RemoveIcon } from 'react-icons/hi2';
import { useSortable } from '@dnd-kit/sortable';
import { Button, Input, Label, Select } from '@knack/asterisk-react';

import { type TableView } from '@/types/schema/views/TableView';
import { cn } from '@/utils/tailwind';
import { DragButton } from '@/components/dnd/DragButton';

interface ColumnSummaryDialogItemProps {
  summary: FieldArrayWithId<
    {
      totals: TableView['totals'];
    },
    'totals',
    'id'
  >;
  columnSummaryIndex: number;
  removeColumnSummary: (index: number) => void;
  enabledOptions: string[];
}

export function ColumnSummaryDialogItem({
  summary,
  columnSummaryIndex,
  enabledOptions,
  removeColumnSummary
}: ColumnSummaryDialogItemProps) {
  const [t] = useTranslation();
  const { register, formState } = useFormContext();

  const { isDragging, isSorting } = useSortable({
    id: summary.id
  });

  return (
    <div
      className={cn(
        'group relative mb-2 flex w-full items-start gap-2 rounded-lg bg-subtle px-4 py-2',
        {
          'after:absolute after:inset-0 after:rounded-lg after:bg-muted/80': isDragging,
          'pointer-events-none': isSorting
        }
      )}
    >
      <DragButton sortableId={summary.id} />

      <div className="w-full">
        <Label htmlFor={`${summary.label}-${summary.id}-input`}>{t('keywords.name')}</Label>
        <Input
          id={`${summary.label}-${summary.id}-input`}
          className="mt-1 h-auto w-full"
          placeholder={t(
            'pages.element_settings.common.categories.data_display.general_settings.summary_placeholder'
          )}
          intent={formState.errors.totals?.[columnSummaryIndex]?.label ? 'destructive' : 'default'}
          {...register(`totals.${columnSummaryIndex}.label` as const)}
        />
        {formState.errors.totals?.[columnSummaryIndex]?.label && (
          <span className="mt-1 text-xs text-destructive">
            {formState.errors.totals[columnSummaryIndex].label.message}
          </span>
        )}
      </div>
      <div className="w-full space-y-1">
        <Label htmlFor={`${summary.label}-${summary.id}-select`}>
          {t('pages.element_settings.common.categories.data_display.general_settings.summary_type')}
        </Label>
        <div className="flex w-full items-center gap-2 space-y-1">
          <Controller
            name={`totals.${columnSummaryIndex}.calc` as const}
            render={({ field, fieldState }) => {
              const isOptionDisabled = (option) => enabledOptions.includes(option);

              return (
                <Select value={field.value} onValueChange={(value) => field.onChange(value)}>
                  <Select.Trigger
                    id={`${summary.label}-${summary.id}-select`}
                    className={cn('w-full', {
                      'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                        fieldState.error
                    })}
                    placeholder={`${t('actions.select')}...`}
                  />
                  <Select.Content>
                    <Select.Item disabled={isOptionDisabled('sum')} value="sum">
                      {t('keywords.sum')}
                    </Select.Item>
                    <Select.Item disabled={isOptionDisabled('average')} value="average">
                      {t('keywords.average')}
                    </Select.Item>
                    <Select.Item disabled={isOptionDisabled('min')} value="min">
                      {t('keywords.minimum')}
                    </Select.Item>
                    <Select.Item disabled={isOptionDisabled('max')} value="max">
                      {t('keywords.maximum')}
                    </Select.Item>
                  </Select.Content>
                </Select>
              );
            }}
          />
          <Button
            intent="minimal"
            size="xs"
            onClick={() => removeColumnSummary(columnSummaryIndex)}
          >
            <RemoveIcon size={16} />
          </Button>
        </div>
        {formState.errors.totals?.[columnSummaryIndex]?.calc && (
          <span className="mt-1 text-xs text-destructive">
            {formState.errors.totals[columnSummaryIndex].calc.message}
          </span>
        )}
      </div>
    </div>
  );
}
