import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { type BuilderViewKey } from '@/types/schema/BuilderView';
import { usePageEditorMessagingContext } from '@/pages/pages/page-editor/PageEditorMessagingContext';

type ViewInputDeleteModeContextState = {
  isViewInputDeleteMode: boolean;
  setIsViewInputDeleteMode: (isViewInputDeleteMode: boolean) => void;
  viewInputIdToDelete: string | null;
  setViewInputIdToDelete: (viewInputId: string | null) => void;
} | null;

type ViewInputDeleteModeContextProviderProps = {
  children: React.ReactNode;
};

export type ViewInputDeleteModeData = {
  viewKey: BuilderViewKey;
  viewInputId: string;
};

const ViewInputDeleteModeContext = createContext<ViewInputDeleteModeContextState>(null);

export function ViewInputDeleteModeContextProvider({
  children
}: ViewInputDeleteModeContextProviderProps) {
  const { messageFromLiveApp } = usePageEditorMessagingContext();

  const [isViewInputDeleteMode, setIsViewInputDeleteMode] = useState(false);
  const [viewInputIdToDelete, setViewInputIdToDelete] = useState<string | null>(null);

  const contextValue = useMemo(
    () => ({
      isViewInputDeleteMode,
      viewInputIdToDelete,
      setIsViewInputDeleteMode,
      setViewInputIdToDelete
    }),
    [isViewInputDeleteMode, viewInputIdToDelete]
  );

  useEffect(() => {
    if (!messageFromLiveApp) {
      return;
    }

    if (messageFromLiveApp.action === 'start-delete-view-input') {
      setIsViewInputDeleteMode(true);
      setViewInputIdToDelete(messageFromLiveApp.viewInputId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageFromLiveApp]);

  return (
    <ViewInputDeleteModeContext.Provider value={contextValue}>
      {children}
    </ViewInputDeleteModeContext.Provider>
  );
}

export const useViewInputDeleteModeContext = () => {
  const context = useContext(ViewInputDeleteModeContext);
  if (!context) {
    throw new Error(
      'useViewInputDeleteModeContext must be used within a ViewInputDeleteModeContext'
    );
  }
  return context;
};
