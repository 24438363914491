import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { cn } from '@/utils/tailwind';
import { type ImageField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { ImageUrlRender } from '@/components/data-table/display/fields/image/url/ImageUrlRender';
import { TextareaCell } from '@/components/data-table/display/TextareaCell';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import { setCursorPositionFullText } from '@/components/data-table/helpers/setCursorPositionAtTheEnd';
import { urlRegex } from '@/components/data-table/helpers/zod';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function ImageUrlEdit(props: FieldRenderProps<ImageField>) {
  const { value, rawValue, fieldId, rowId, type } = props;
  let thumbUrl = '';
  if (typeof rawValue === 'string') {
    thumbUrl = rawValue;
  } else {
    thumbUrl = rawValue.thumb_url;
  }

  const { moveSelectionVertical, moveSelectionHorizontal } = useSelectionStrategy();

  const [t] = useTranslation();

  const selectedCell = useDataTableStore().use.selectedCell();
  const cellErrors = useDataTableStore().use.cellErrors(rowId, fieldId);

  const { saveCell, setIsEditing, clearCellErrors } = useDataTableStore().use.actions();

  if (!selectedCell) throw new Error('No selected cell');

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);
  const { required } = currentField;

  const linkFormSchema = z.object({
    url: z
      .string()
      .regex(urlRegex, {
        message: t('components.data_table.errors.no_valid_field', { field: type })
      })
      .optional()
      .or(z.literal(''))
      .refine((url) => !required || url, t('components.data_table.errors.required_field'))
  });

  type ShortTextFormSchema = z.infer<typeof linkFormSchema>;

  const {
    register,
    handleSubmit,
    setFocus,
    getValues,
    getFieldState,
    setValue,
    formState: { errors, isValid }
  } = useForm<ShortTextFormSchema>({
    resolver: zodResolver(linkFormSchema),
    defaultValues: {
      url: thumbUrl
    }
  });

  const handleForm = useCallback(async () => {
    const formState = getFieldState('url');
    if (!formState.invalid) {
      const newValue = getValues('url');

      await saveCell(rowId, fieldId, newValue, {
        value: getValues('url'),
        rawValue: { url: getValues('url'), thumb_url: getValues('url') }
      });
    }
  }, [fieldId, getFieldState, getValues, rowId, saveCell]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Clean server error on change
    clearCellErrors(rowId, fieldId);
    if (e.key === 'Escape') {
      setIsEditing(false);
      const formState = getFieldState('url');
      if (formState.invalid) {
        setValue('url', thumbUrl);
      }

      e.preventDefault();
    }

    if (e.key === 'Enter' || e.key === 'Tab') {
      if (e.key === 'Tab' && isValid) {
        moveSelectionHorizontal('right');
      }
      if (e.key === 'Enter' && isValid) {
        if (!selectedCell.isEditing) {
          e.preventDefault();
          setIsEditing(true);
        } else {
          e.preventDefault();
          moveSelectionVertical('down');
        }
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    // Hack needed to focus the textarea when the component is created
    setTimeout(() => {
      setFocus('url');
    }, 0);
  }, [setFocus]);

  useEffect(
    () => () => {
      setIsEditing(false);
    },
    [setIsEditing]
  );

  const urlRegister = register('url', {
    onChange: () => {
      if (!selectedCell.isEditing) {
        setIsEditing(true);
      }

      void handleSubmit(handleForm)();
    }
  });

  return (
    <div className="flex size-full">
      {!selectedCell.isEditing && (
        // absolute and z-index needed to make it clickable when the cell is selected
        <div className="absolute size-full">
          <ImageUrlRender {...props} isFloating={false} />
        </div>
      )}
      <TextareaCell
        placeholder={t('attributes.field_labels.image.url_placeholder')}
        defaultValue={value}
        intent={cellErrors ? 'destructive' : 'default'}
        className={cn({
          'opacity-0': !selectedCell.isEditing
        })}
        onKeyDown={handleKeyDown}
        onClick={(e) => {
          if (!selectedCell.isEditing) {
            setIsEditing(true);
            setCursorPositionFullText(e);
          }
        }}
        data-testid={`image-field-url-input-${rowId}-${fieldId}`}
        onFocus={setCursorPositionFullText}
        {...urlRegister}
      />

      <div className="absolute top-full mt-1 w-full bg-base">
        {selectedCell.isEditing && errors?.url?.message && (
          <div className="w-20 min-w-full bg-subtle">
            <p
              key={`image-field-url-error-${rowId}-${fieldId}`}
              data-testid={`image-field-url-error-${rowId}-${fieldId}`}
              className="m-1"
            >
              {errors.url.message}
            </p>
          </div>
        )}

        {cellErrors && (
          <div className="w-20 min-w-full bg-action-destructive-primary/20">
            <p
              key={`link-edit-error-${rowId}-${fieldId}`}
              data-testid={`link-edit-error-${rowId}-${fieldId}`}
              className="m-1"
            >
              {cellErrors.map((error) => (
                <span key={error.message}>{error.message}</span>
              ))}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
