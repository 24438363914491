import { useTranslation } from 'react-i18next';
import { HiPencil as EditIcon } from 'react-icons/hi2';
import { MdCallSplit as ConditionalIcon } from 'react-icons/md';
import { Button, Checkbox, Label } from '@knack/asterisk-react';
import i18n from 'i18next';

import { type KnackField } from '@/types/schema/KnackField';
import {
  DISALLOWED_FIELD_TYPES_IN_FORM_VIEWS,
  type FormView,
  type FormViewFieldInput
} from '@/types/schema/views/FormView';
import { cn } from '@/utils/tailwind';
import { FieldIcon } from '@/components/FieldIcon';
import { TextTooltip } from '@/components/TextTooltip';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';

interface FormFieldInputItemProps {
  field: KnackField;
  fieldInput?: FormViewFieldInput;
  isChecked?: boolean;
  isDisallowed?: boolean;
  onCheck?: (isChecked: boolean) => void;
}

function getFieldDisallowedReason(field: KnackField) {
  if (field.conditional) {
    return i18n.t(
      'pages.element_settings.form.categories.form_fields.field_management.disallowed_field_is_conditional'
    );
  }

  if (DISALLOWED_FIELD_TYPES_IN_FORM_VIEWS.includes(field.type)) {
    return i18n.t(
      'pages.element_settings.form.categories.form_fields.field_management.disallowed_field_is_immutable'
    );
  }

  return '';
}

export function FormFieldInputItem({
  field,
  fieldInput,
  onCheck,
  isChecked = false,
  isDisallowed = false
}: FormFieldInputItemProps) {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<FormView>();
  const { setIsViewInputEditMode, setViewInputId } = useViewInputEditModeContext();

  // Get the total number of display rules in the form that use this field as its criteria
  const displayRulesCount = view.rules.fields
    ? view.rules.fields.reduce((acc, rule) => {
        const count = rule.criteria.filter((criteria) => criteria.field === field.key).length;
        return acc + count;
      }, 0)
    : 0;

  const disallowedReason = isDisallowed ? getFieldDisallowedReason(field) : '';

  return (
    <TextTooltip label={disallowedReason} asChild>
      <div
        key={field.key}
        className={cn(
          'group flex h-9 items-center justify-between rounded-lg border border-default bg-card py-1 pl-2.5 pr-2 hover:border-emphasis hover:bg-muted',
          {
            'border-emphasis': isChecked && !isDisallowed,
            'bg-muted hover:border-default hover:bg-muted': isDisallowed
          }
        )}
      >
        <Label
          className="-ml-1 flex items-center truncate py-1 pl-1 text-emphasis"
          htmlFor={`field-checkbox-${field.key}`}
        >
          <Checkbox
            id={`field-checkbox-${field.key}`}
            disabled={isDisallowed}
            className="mr-2"
            checked={isChecked}
            onCheckedChange={onCheck}
          />
          <FieldIcon size={16} className="text-subtle" name={field.type} />
          <span className="ml-2 truncate">{field.name}</span>
          {displayRulesCount > 0 && (
            <TextTooltip
              asChild
              label={t(
                'pages.element_settings.form.categories.form_fields.field_management.display_rules_badge',
                {
                  count: displayRulesCount
                }
              )}
            >
              <span className="ml-2 inline-flex items-center rounded-sm bg-subtle px-1 py-0.5 text-xs font-medium text-subtle">
                <ConditionalIcon size={16} className="text-subtle" />
                <span className="ml-1">{displayRulesCount}</span>
              </span>
            </TextTooltip>
          )}
        </Label>

        {!isDisallowed && fieldInput && (
          <Button
            intent="minimal"
            size="xs"
            className="hidden size-6 rounded-md text-subtle group-hover:flex"
            onClick={() => {
              setIsViewInputEditMode(true);
              setViewInputId(fieldInput.id);
            }}
          >
            <EditIcon size={14} />
            <span className="sr-only">
              {t(
                'pages.element_settings.form.categories.form_fields.field_management.edit_field_input'
              )}
            </span>
          </Button>
        )}
      </div>
    </TextTooltip>
  );
}
