import { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IMask, useIMask } from 'react-imask';
import { Input } from '@knack/asterisk-react';

import { type PhoneField } from '@/types/schema/fields';
import {
  PHONE_EMPTY_PLACEHOLDERS,
  PHONE_EXTENSION_MASK,
  PHONE_FORMAT_TO_MASK
} from '@/utils/constants';

type PhoneInputProps = {
  targetField: PhoneField;
  name: string;
  id: string;
};

export function PhoneInput({ targetField, name, id }: PhoneInputProps) {
  const { getValues, setValue, getFieldState } = useFormContext();
  const hasError = getFieldState(name)?.error;

  const [localValue, setLocalValue] = useState('');

  const includeExtension = targetField.format?.extension;
  const phoneFormat = includeExtension
    ? PHONE_FORMAT_TO_MASK[targetField.format?.format] + PHONE_EXTENSION_MASK
    : PHONE_FORMAT_TO_MASK[targetField.format?.format];
  const placeholder = PHONE_EMPTY_PLACEHOLDERS[targetField.format?.format];

  // IMask gives the value with placeholder, so we need to remove it by applying the mask again
  const getCurrentValueWithoutPlaceholder = useCallback(
    (currentValue: string) => {
      const phoneMask = IMask.createPipe({
        // Mask can be string or RegExp. But it is not typed correctly in react-imask and needs to be casted to any if the variable has both types.
        mask: phoneFormat as any
      });
      return phoneMask(currentValue);
    },
    [phoneFormat]
  );

  const { ref: inputRef } = useIMask<HTMLInputElement>(
    {
      // Mask can be string or RegExp. But it is not typed correctly in react-imask and needs to be casted to any if the variable has both types.
      mask: phoneFormat as any,
      lazy: false,
      placeholderChar: '_'
    },
    {
      defaultValue: getValues(name) || '',
      onAccept: (val, mask) => {
        const valueWithoutPlaceholder = getCurrentValueWithoutPlaceholder(val);
        setLocalValue(valueWithoutPlaceholder);

        // if value is empty string, set value empty to trigger validation error if needed
        if (!mask.unmaskedValue) {
          setValue(name, '');
          return;
        }

        setValue(name, valueWithoutPlaceholder);
      }
    }
  );

  return (
    <Controller
      name={name}
      render={({ field }) => (
        <Input
          id={id}
          data-testid="phone-input"
          className="text-sm"
          intent={hasError ? 'destructive' : 'default'}
          placeholder={placeholder}
          ref={inputRef}
          defaultValue={localValue || field.value?.formatted || ''}
          onFocus={(e) => {
            e.currentTarget.setSelectionRange(0, 0);
          }}
        />
      )}
    />
  );
}
