import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@knack/asterisk-react';

import { type LinkField } from '@/types/schema/fields';

interface LinkInputProps {
  targetField: LinkField;
  name: string;
  id: string;
}

export function LinkInput({ name, targetField, id }: LinkInputProps) {
  const [t] = useTranslation();

  const { register, getFieldState } = useFormContext();
  const showLabelInput = targetField.format?.text_format === 'unique';

  const hasError = getFieldState(name)?.error;

  return (
    <div className="space-y-2">
      {showLabelInput && (
        <Input
          className="text-sm"
          placeholder={t('components.add_into_existing_table.link_text')}
          {...register(`${name}.label`)}
        />
      )}
      <Input
        id={id}
        className="text-sm"
        intent={hasError ? 'destructive' : 'default'}
        placeholder={t('components.add_into_existing_table.link_example')}
        {...register(`${name}.url`)}
      />
    </div>
  );
}
