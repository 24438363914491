import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiArrowsRightLeft as ChangeFieldTypeIcon,
  HiTrash as DeleteIcon,
  HiMiniViewColumns as FieldDisplayIcon,
  HiQuestionMarkCircle as HelpIcon,
  HiInformationCircle as InfoIcon,
  HiCog6Tooth as SettingsIcon,
  HiMiniEllipsisHorizontal as ThreeDotsIcon
} from 'react-icons/hi2';
import {
  MdCallSplit as ConditionalRulesIcon,
  MdPlaylistAddCheck as ValidationRulesIcon
} from 'react-icons/md';
import { Badge, Button, DropdownMenu, Tooltip, useToast } from '@knack/asterisk-react';

import { useTableMutation } from '@/hooks/api/mutations/useTableMutation';
import { cn } from '@/utils/tailwind';
import { DeleteFieldDialog } from '@/components/field-settings/DeleteFieldDialog';
import { FieldIcon } from '@/components/FieldIcon';
import { TextTooltip } from '@/components/TextTooltip';
import { ChangeFieldTypeDialog } from '@/pages/tables/fields/change-field-type/ChangeFieldTypeDialog';
import { EditFieldSettingsDialog } from '@/pages/tables/fields/EditFieldSettingsDialog';
import { type FieldProps } from '@/pages/tables/fields/Field';
import { useFieldsStore } from '@/pages/tables/fields/useFieldsStore';
import { isValidDisplayField } from '@/pages/tables/fields/utils';
import { useTablesPageContext } from '@/pages/tables/TablesPageContext';

type FieldRowProps = FieldProps & {
  isDraggingRow?: boolean;
};

export function FieldRow({ field, index, isDraggingRow }: FieldRowProps) {
  const { presentToast } = useToast();
  const [t] = useTranslation();

  const { updateMutation } = useTableMutation();

  const [isEditFieldSettingsDialogOpen, setIsEditFieldSettingsDialogOpen] = useState(false);
  const [isDeleteFieldDialogOpen, setIsDeleteFieldDialogOpen] = useState(false);
  const [isChangeFieldTypeDialogOpen, setIsChangeFieldTypeDialogOpen] = useState(false);

  const fields = useFieldsStore().use.fields();
  const objectKey = useFieldsStore().use.objectKey();
  const displayFieldKey = useFieldsStore().use.displayFieldKey();
  const shouldShowFieldKeys = useFieldsStore().use.shouldShowFieldKeys();

  const { handleSelectToolkitSidebarItem } = useTablesPageContext();

  const isCurrentDisplayField = displayFieldKey === field.key;
  const hasFieldDescription = field.meta?.description && field.meta.description.length > 0;

  const isImmutableField = field.immutable;

  if (!objectKey) {
    throw new Error('No object key provided');
  }

  const handleChangeDisplayField = () => {
    updateMutation.mutate(
      {
        object_key: objectKey,
        identifier: field.key
      },
      {
        onSuccess: () => {
          presentToast({
            title: t(
              'pages.tables_page.fields_list.field_row.contextual_menu.display_field.updated_successfully'
            )
          });
        },
        onError: () => {
          presentToast({
            title: t('errors.generic_error')
          });
        }
      }
    );
  };

  return (
    <div
      key={field.key}
      className="flex h-10 items-center justify-between rounded-lg bg-muted px-2 py-1 ring-0 hover:bg-brand-muted focus:ring-0"
      data-testid="field-row"
    >
      <div className="flex w-full items-center gap-3" data-testid="field-row-left-side">
        <p className={cn({ invisible: isDraggingRow })}>{String(index + 1)}</p>
        <div
          className="flex flex-grow items-center gap-1.5 font-medium"
          data-testid={`${field.key}-row-content`}
        >
          <FieldIcon name={field.type} size={16} className="shrink-0" />
          <button
            className="flex flex-grow items-center"
            onClick={() => setIsEditFieldSettingsDialogOpen(true)}
            type="button"
          >
            <span className="flex flex-col text-left">
              {field.name}
              {shouldShowFieldKeys && <span className="w-full text-xs">{field.key}</span>}
            </span>

            {hasFieldDescription && (
              <Tooltip>
                <Tooltip.Trigger className="m-1">
                  <InfoIcon size={16} className="shrink-0" />
                </Tooltip.Trigger>
                <Tooltip.Content>
                  <p
                    className="max-w-[400px] content-center p-2"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: field.meta?.description || '' }}
                  />
                </Tooltip.Content>
              </Tooltip>
            )}
          </button>
        </div>
      </div>
      <div className="flex items-center gap-1" data-testid="field-row-right-side">
        {isCurrentDisplayField && (
          <TextTooltip
            shouldPortal
            label={t(
              'pages.tables_page.fields_list.field_row.contextual_menu.display_field.details'
            )}
          >
            <Badge className="whitespace-nowrap" data-testid={`display-field-badge-${field.key}`}>
              {t('keywords.display_field')}
            </Badge>
          </TextTooltip>
        )}

        {field.validation && field.validation.length > 0 && (
          <TextTooltip
            label={t(
              'pages.tables_page.fields_list.field_row.contextual_menu.view_validation_rules'
            )}
          >
            <Button
              intent="minimalStandalone"
              size="xs"
              className="w-9 gap-1 p-1 hover:bg-brand-subtle focus:bg-brand-subtle"
              onClick={() => {
                handleSelectToolkitSidebarItem('validationRules');
              }}
            >
              <span>{field.validation.length}</span>
              <ValidationRulesIcon size={16} className="shrink-0" />
            </Button>
          </TextTooltip>
        )}

        {field.rules && field.rules.length > 0 && (
          <TextTooltip
            label={t(
              'pages.tables_page.fields_list.field_row.contextual_menu.view_conditional_rules'
            )}
          >
            <Button
              intent="minimalStandalone"
              size="xs"
              className="w-9 gap-1 p-1 hover:bg-brand-subtle focus:bg-brand-subtle"
              onClick={() => {
                handleSelectToolkitSidebarItem('conditionalRules');
              }}
            >
              <span>{field.rules.length}</span>
              <ConditionalRulesIcon size={16} className="shrink-0" />
            </Button>
          </TextTooltip>
        )}

        <DropdownMenu>
          <DropdownMenu.Trigger
            data-testid={`field-row-${field.key}-contextual-menu`}
            className="flex items-center rounded p-1 hover:bg-brand-subtle focus:bg-brand-subtle"
          >
            <TextTooltip label={t('pages.tables_page.fields_list.field_row.contextual_menu.more')}>
              <ThreeDotsIcon size={16} className="shrink-0" />
            </TextTooltip>
          </DropdownMenu.Trigger>

          <DropdownMenu.Content align="end" alignOffset={-20}>
            <DropdownMenu.Item
              data-testid={`fields-list-contextual-menu-edit-field-settings-${field.key}`}
              onClick={() => {
                setIsEditFieldSettingsDialogOpen(true);
              }}
            >
              <SettingsIcon size={18} className="mr-1 shrink-0" />
              {t('pages.tables_page.fields_list.field_row.contextual_menu.open_field_settings')}
            </DropdownMenu.Item>
            {!isImmutableField && (
              <DropdownMenu.Item
                data-testid={`fields-list-contextual-menu-change-field-type-${field.key}`}
                onClick={() => {
                  setIsChangeFieldTypeDialogOpen(true);
                }}
              >
                <ChangeFieldTypeIcon size={18} className="mr-1 shrink-0" />
                {t(
                  'pages.tables_page.fields_list.field_row.contextual_menu.change_field_type.title'
                )}
              </DropdownMenu.Item>
            )}

            <TextTooltip
              label={
                isCurrentDisplayField || !isValidDisplayField(field)
                  ? t(
                      `pages.tables_page.fields_list.field_row.contextual_menu.display_field.${isCurrentDisplayField ? 'already_set' : 'invalid'}`
                    )
                  : t('pages.tables_page.fields_list.field_row.contextual_menu.display_field.info')
              }
            >
              <DropdownMenu.Item
                disabled={isCurrentDisplayField || !isValidDisplayField(field)}
                onClick={handleChangeDisplayField}
                data-testid={`fields-list-contextual-menu-make-display-field-${field.key}`}
              >
                <FieldDisplayIcon size={18} className="mr-1 shrink-0" />
                {t('pages.tables_page.fields_list.field_row.contextual_menu.make_display_field')}
                <HelpIcon size={16} />
              </DropdownMenu.Item>
            </TextTooltip>

            <DropdownMenu.Separator />

            <DropdownMenu.Item
              onClick={() => {
                handleSelectToolkitSidebarItem('validationRules');
              }}
            >
              <ValidationRulesIcon size={18} className="mr-1 shrink-0" />
              {t('pages.tables_page.fields_list.field_row.contextual_menu.validation_rules')}
            </DropdownMenu.Item>

            <DropdownMenu.Item
              onClick={() => {
                handleSelectToolkitSidebarItem('conditionalRules');
              }}
            >
              <ConditionalRulesIcon size={18} className="mr-1 shrink-0" />
              {t('pages.tables_page.fields_list.field_row.contextual_menu.conditional_rules')}
            </DropdownMenu.Item>

            {!isImmutableField && (
              <>
                <DropdownMenu.Separator />

                <DropdownMenu.Item
                  disabled={fields.length === 1}
                  data-testid={`fields-list-contextual-menu-delete-field-${field.key}`}
                  className="w-full text-destructive"
                  onClick={() => {
                    setIsDeleteFieldDialogOpen(true);
                  }}
                >
                  <DeleteIcon size={18} className="mr-1 shrink-0" />
                  {t('pages.tables_page.fields_list.field_row.contextual_menu.delete_field')}
                </DropdownMenu.Item>
              </>
            )}
          </DropdownMenu.Content>
        </DropdownMenu>
      </div>

      {isDeleteFieldDialogOpen && (
        <DeleteFieldDialog
          tableKey={objectKey}
          field={field}
          onClose={() => setIsDeleteFieldDialogOpen(false)}
        />
      )}

      {isEditFieldSettingsDialogOpen && (
        <EditFieldSettingsDialog
          objectKey={objectKey}
          field={field}
          onClose={() => setIsEditFieldSettingsDialogOpen(false)}
        />
      )}

      {isChangeFieldTypeDialogOpen && (
        <ChangeFieldTypeDialog
          objectKey={objectKey}
          field={field}
          onClose={() => setIsChangeFieldTypeDialogOpen(false)}
        />
      )}
    </div>
  );
}
