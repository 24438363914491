import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import snakeCase from 'lodash.snakecase';

import { type DateTimeFieldValidationRuleCriteria } from '@/types/schema/fields';
import {
  type KnackField,
  type ValidationRule,
  type ValidationRuleCriteria,
  type ValidationRuleCriteriaForAsset
} from '@/types/schema/KnackField';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { getBooleanFieldLabel } from '@/utils/fields';
import { getDateTimeValidationRuleCriteriaValue } from '@/utils/rules/getDateTimeValidationRuleCriteriaValue';
import { InlineKnackConnectionRecordValue } from '@/components/InlineKnackConnectionRecordValue';
import { InlineKnackField } from '@/components/InlineKnackField';
import { InlineKnackValue } from '@/components/InlineKnackValue';

interface ValidationRuleCriteriaValueProps {
  criteria: ValidationRuleCriteria;
  criteriaField: KnackField;
  dataTestId?: string;
}

interface FieldSidePanelValidationRulesListProps {
  validationRules: ValidationRule[] | null;
  tableFields: KnackField[];
}

function ValidationRuleCriteriaValue({
  criteria,
  criteriaField,
  dataTestId
}: ValidationRuleCriteriaValueProps) {
  if (shouldHideValueBasedOnOperator(criteria.operator)) return null;

  // If the criteria value is an array, it means it's a connection field, so we need to display the record value
  if (Array.isArray(criteria.value) && criteriaField.type === 'connection') {
    return criteria.value.map((value, valueIndex) => (
      <InlineKnackConnectionRecordValue
        // eslint-disable-next-line react/no-array-index-key
        key={`${value}-${valueIndex}`}
        tableKey={criteriaField.relationship.object}
        recordId={value}
        dataTestId={dataTestId}
      />
    ));
  }

  let valueToReturn: string | undefined;

  if (criteriaField.type === 'image' || criteriaField.type === 'file') {
    const { unit, value } = criteria as ValidationRuleCriteriaForAsset;
    valueToReturn = `${value} ${unit}`;
  } else if (criteriaField.type === 'date_time') {
    valueToReturn = getDateTimeValidationRuleCriteriaValue(
      criteria as DateTimeFieldValidationRuleCriteria
    );
  } else if (criteriaField.type === 'boolean' && typeof criteria.value === 'boolean') {
    valueToReturn = getBooleanFieldLabel(criteriaField.format, criteria.value);
  } else if (typeof criteria.value === 'string') {
    valueToReturn = criteria.value;
  }

  if (!valueToReturn) {
    return null;
  }

  return (
    <>
      {' '}
      <InlineKnackValue value={valueToReturn} data-testid={dataTestId} />
    </>
  );
}

export function FieldSidePanelValidationRulesList({
  validationRules,
  tableFields
}: FieldSidePanelValidationRulesListProps) {
  const [t] = useTranslation();

  if (!validationRules || validationRules.length === 0) {
    return <span>{t('components.data_model.fields_validation_rules_empty')}</span>;
  }

  return validationRules.map((validationRule) => {
    if (validationRule.criteria.length === 0) return null;

    return (
      <div
        key={validationRule.key}
        className="rounded-md border border-subtle px-2 py-1.5 shadow-sm [&:not(:last-child)]:mb-2"
        data-testid={`data-model-field-validation-rule-${validationRule.key}`}
      >
        {t('components.data_model.field_rule_when')}
        {validationRule.criteria.map((criteria: ValidationRuleCriteria, index: number) => {
          const criteriaField = tableFields.find((f) => f.key === criteria.field);
          if (!criteriaField) return null;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`${criteria.field}-${index}`}>
              <InlineKnackField
                fieldType={criteriaField.type}
                fieldName={criteriaField.name}
                data-testid="data-model-field-validation-rule-criteria-field"
              />{' '}
              <span data-testid="data-model-field-validation-rule-criteria-operator">
                {t(`operators.${snakeCase(criteria.operator)}`)}
              </span>
              <ValidationRuleCriteriaValue
                dataTestId="data-model-field-validation-rule-criteria-value"
                criteria={criteria}
                criteriaField={criteriaField}
              />
              {index !== validationRule.criteria.length - 1 &&
                t('components.data_model.field_rule_and')}
            </Fragment>
          );
        })}
        {t('components.data_model.field_validation_rule_action')}
        <InlineKnackValue
          value={validationRule.message}
          data-testid="data-model-field-validation-rule-action-value"
        />
      </div>
    );
  });
}
