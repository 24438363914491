import { useTranslation } from 'react-i18next';
import { Label, Switch } from '@knack/asterisk-react';

import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';

export function ShowLabels() {
  const [t] = useTranslation();

  const { chart, updateChart } = useActiveChartContext();

  return (
    <div className="mb-2 flex items-center gap-2">
      <Switch
        id="show-labels"
        checked={chart.layout.data_labels}
        onCheckedChange={(state) => {
          updateChart({
            layout: {
              ...chart.layout,
              data_labels: state
            }
          });
        }}
      />
      <Label htmlFor="show-labels">
        {t('pages.element_settings.report.categories.general.show_labels')}
      </Label>
    </div>
  );
}
