import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiXMark as CloseIcon } from 'react-icons/hi2';
import { MdFilterAlt as FilterIcon } from 'react-icons/md';
import { Button } from '@knack/asterisk-react';

import {
  useDataTableStore,
  useDataTableStorePersist
} from '@/components/data-table/useDataTableStore';

export function FilterToast() {
  const [shouldShowFilterToast, setShouldShowFilterToast] = useState(true);
  const totalRecords = useDataTableStore().use.totalRecords();
  const filters = useDataTableStorePersist().use.filters();
  const search = useDataTableStorePersist().use.search();

  const [t] = useTranslation();

  useEffect(() => {
    if (filters || search) {
      setShouldShowFilterToast(true);
    }
  }, [filters, search]);

  if ((!filters && !search) || !shouldShowFilterToast) return null;

  return (
    <div
      data-testid="data-table-filter-popup"
      className="fixed inset-x-0 bottom-10 z-10 mx-auto flex h-9 max-w-fit items-center space-x-2 rounded-xl bg-intense px-2 text-inverted drop-shadow-2xl"
    >
      <FilterIcon size="20" />
      <p>
        {t('components.data_table.filtering.met_your_filter', {
          count: totalRecords
        })}
      </p>
      <Button
        data-testid="data-table-filter-popup-dismiss-button"
        className="h-6 p-0 text-inverted"
        intent="minimal"
        onClick={() => {
          setShouldShowFilterToast(false);
        }}
      >
        <CloseIcon size={20} />
      </Button>
    </div>
  );
}
