import React, { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HiArrowLeft as BackIcon, HiXMark as CloseIcon } from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';
import * as SidePanelPrimitive from '@radix-ui/react-dialog';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/utils/tailwind';

const contentVariants = cva(
  'z-20 gap-4 bg-default px-6 py-12 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-300',
  {
    variants: {
      side: {
        top: 'inset-x-0 top-0 data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
        bottom:
          'inset-x-0 bottom-0 data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
        left: 'inset-y-0 left-0 h-full w-3/4 data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-md',
        right:
          'inset-y-0 right-0 h-full w-3/4 data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-md'
      }
    },
    defaultVariants: {
      side: 'right'
    }
  }
);

interface RootProps extends React.ComponentPropsWithoutRef<typeof SidePanelPrimitive.Root> {
  onCloseFinish?: () => void;
}

interface ContentProps
  extends React.ComponentPropsWithoutRef<typeof SidePanelPrimitive.Content>,
    VariantProps<typeof contentVariants> {
  position?: 'fixed' | 'absolute';
  isScrollable?: boolean;
  portalContainer?: HTMLElement | null;
  hasModalOverlayBlur?: boolean;
  hasModalOverlayBackground?: boolean;
  onBack?: () => void;
}

function Root({ open, onCloseFinish, children, ...props }: RootProps) {
  useEffect(() => {
    if (open || !onCloseFinish) {
      return undefined;
    }

    // This value in ms should match the duration of the animation in the CSS
    const animationTiming = 300;

    const timeoutId = setTimeout(() => {
      if (!open) {
        onCloseFinish();
      }
    }, animationTiming);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <SidePanelPrimitive.Root {...props} open={open} modal={false}>
      {children}
    </SidePanelPrimitive.Root>
  );
}

function Content(
  {
    side = 'right',
    position = 'fixed',
    isScrollable = true,
    hasModalOverlayBlur = true,
    hasModalOverlayBackground = false,
    className,
    children,
    portalContainer,
    onBack,
    ...props
  }: ContentProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const [t] = useTranslation();
  return (
    <SidePanelPrimitive.Portal container={portalContainer}>
      <SidePanelPrimitive.Overlay
        className={cn(
          position,
          { 'backdrop-blur-sm': hasModalOverlayBlur },
          { 'bg-overlay': hasModalOverlayBackground },
          'inset-0 z-50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
          className
        )}
      />
      <SidePanelPrimitive.Content
        ref={ref}
        className={cn(
          contentVariants({ side }),
          { 'overflow-auto': isScrollable },
          position,
          className
        )}
        {...props}
      >
        {children}
        {onBack && (
          <Button intent="minimal" className="absolute left-4 top-4 gap-2" onClick={onBack}>
            <BackIcon size={16} />
            <span>{t('actions.back')}</span>
          </Button>
        )}
        <SidePanelPrimitive.Close
          className="absolute right-2 top-2 opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none"
          data-testid="side-panel-close-button"
          asChild
        >
          <Button intent="minimal">
            <CloseIcon size={24} />
            <span className="sr-only">{t('actions.close')}</span>
          </Button>
        </SidePanelPrimitive.Close>
      </SidePanelPrimitive.Content>
    </SidePanelPrimitive.Portal>
  );
}

function Header({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn('flex flex-col space-y-2 text-center sm:text-left', className)} {...props} />
  );
}

function Footer({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2', className)}
      {...props}
    />
  );
}

function Title(
  { className, ...props }: React.ComponentPropsWithoutRef<typeof SidePanelPrimitive.Title>,
  ref: React.ForwardedRef<HTMLHeadingElement>
) {
  return (
    <SidePanelPrimitive.Title
      ref={ref}
      className={cn('text-lg text-emphasis', className)}
      {...props}
    />
  );
}

function Description(
  { className, ...props }: React.ComponentPropsWithoutRef<typeof SidePanelPrimitive.Description>,
  ref: React.ForwardedRef<HTMLParagraphElement>
) {
  return (
    <SidePanelPrimitive.Description ref={ref} className={cn('text-subtle', className)} {...props} />
  );
}

const CompoundSidePanel = Object.assign(Root, {
  Content: forwardRef<React.ElementRef<typeof SidePanelPrimitive.Content>, ContentProps>(Content),
  Title: forwardRef<
    HTMLHeadingElement,
    React.ComponentPropsWithoutRef<typeof SidePanelPrimitive.Title>
  >(Title),
  Description: forwardRef<
    HTMLParagraphElement,
    React.ComponentPropsWithoutRef<typeof SidePanelPrimitive.Description>
  >(Description),
  Header,
  Footer,
  Trigger: SidePanelPrimitive.Trigger,
  Close: SidePanelPrimitive.Close
});

Content.displayName = 'SidePanel.Content';
Title.displayName = 'SidePanel.Title';
Description.displayName = 'SidePanel.Description';
Header.displayName = 'SidePanel.Header';
Footer.displayName = 'SidePanel.Footer';

export { CompoundSidePanel as SidePanel };
