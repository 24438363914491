export const queryKeys = {
  application: 'application',
  applicationPages: 'application-pages',
  applicationTables: 'application-tables',
  session: 'session',
  auth: {
    session: 'session'
  },
  account: 'account',
  records: 'records',
  connectionRecords: 'connection-records',
  dataModelDiagram: 'data-model-diagram',
  assetInfo: 'asset-info',
  gravatar: 'gravatar',
  connectionRecord: 'connectionRecord',
  theming: 'theming',
  flows: 'flows',
  tasks: 'tasks',
  approvalTemplates: 'approval-templates',
  accountInfoTemplates: 'account-info-templates',
  apiKey: 'api-key',
  apiUsage: 'api-usage',
  mapUsage: 'map-usage',
  credential: 'credential'
} as const;
